import React from "react";
import get from "lodash/get";
import StudentInfo from "core/StudentInfo";
import { useEntity } from "student-front-commons/src/hooks";
import { PROFILE_SCHEMA } from "student-front-commons/src/schemas";

function StudentInfoContainer() {
  const profile = useEntity(PROFILE_SCHEMA, sessionStorage.getItem("id"));

  return (
    <StudentInfo
      name={profile.name}
      nickname={profile.nickname}
      profilePicture={profile.profilePicture}
      course={get(
        profile.activeCourses.find((course) => !course.distributor),
        "name",
        ""
      )}
    />
  );
}

export default StudentInfoContainer;
