import React from "react";
import get from "lodash/get";
import Flow from "student-front-commons/src/components/Flow";
import MicrophoneErrorModal from "core/MicrophoneErrorModal";
import { MICROPHONE_ERROR_MODAL_FLOW } from "consts";

class MicrophoneErrorModalContainer extends React.PureComponent {
  render() {
    return (
      <Flow autoStart={false} id={MICROPHONE_ERROR_MODAL_FLOW}>
        {({ isPending, getParams }) => (
          <MicrophoneErrorModal
            isOpen={isPending}
            message={get(getParams(), "message", "")}
            type={get(getParams(), "type", "")}
          />
        )}
      </Flow>
    );
  }
}

export default MicrophoneErrorModalContainer;
