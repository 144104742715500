import React, { useState, useEffect } from "react";
import { useEntity } from "student-front-commons/src/hooks";
import Input from "./Input";
import Separator from "./Separator";
import TranslatedText from "./TranslatedText";
import withTranslation from "./withTranslation";
import IconButton from "./IconButton";
import Row from "./Row";
import Button from "./Button";
import { GREY_1, GREY_2, GREY_4, RED_2, WHITE } from "../consts/colors";
import Col from "./Col";
import HtmlForm from "./HtmlForm";
import Spinner from "./Spinner";
import Lottie from "react-lottie";
import SuccessfullySent from "../assets/lottie/successfully-sent.json";

const UserErrorReportForm = ({
  getValue,
  setValue,
  getError,
  submit,
  isSubmitted,
  onDiscard,
  isSubmitting,
  isLoading,
  isSuccessfulReported,
  getTranslation,
  apiError,
}) => {
  const profile = useEntity("profile", sessionStorage.getItem("id"));
  const [isFormOnRight, setIsFormOnRight] = useState(true);
  const [isEmailFound, setIsEmailFound] = useState(false);

  useEffect(() => {
    if (profile && !isEmailFound) {
      setValue("email", profile.email);
      setIsEmailFound(true);
    }
  }, [setValue, profile, isEmailFound]);

  return (
    <div
      style={{
        width: "400px",
        height: "auto",
        maxHeight: "95vh",
        borderRadius: "20px",
        overflow: "hidden",
        boxShadow: "rgb(0 0 0 / 10%) 0px 11px 30px 0px",
        boxSizing: "border-box",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        background: "rgb(255, 255, 255)",
        position: "fixed",
        padding: "25px",
        cursor: "auto",
        bottom: "10px",
        right: isFormOnRight ? "10px" : "calc(100% - 410px)",
        zIndex: Number.MAX_SAFE_INTEGER,
        transition: "right 0.7s ease",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
          borderBottom: "1px solid rgba(33, 43, 55, 0.1)",
          marginBottom: "16px",
          paddingBottom: "8px",
        }}
      >
        <TranslatedText
          translateKey="userErrorReport.title"
          as="h1"
          style={{
            color: "#4e4e4e",
            margin: 0,
            fontSize: "16px",
            fontWeight: 600,
          }}
        />
        <IconButton
          name={isFormOnRight ? "transfer-left" : "transfer-right"}
          color="#4e4e4e"
          size={24}
          onClick={() => setIsFormOnRight(!isFormOnRight)}
        />
      </div>
      {isLoading && <Spinner size="sm" />}
      {!isLoading && !isSuccessfulReported && (
        <HtmlForm onSubmit={submit}>
          <Input
            placeholder="userErrorReport.labels.email"
            isDisabled={isSubmitting || isEmailFound}
            value={getValue("email")}
            onChange={(value) => setValue("email", value)}
            errorText={isSubmitted() && getError("email")}
          />
          <Separator size="md" />
          <Input
            placeholder="userErrorReport.labels.subject"
            isDisabled={isSubmitting}
            value={getValue("subject")}
            onChange={(value) => setValue("subject", value)}
            errorText={isSubmitted() && getError("subject")}
          />
          <Separator size="md" />
          <Col>
            <textarea
              onChange={(event) => setValue("content", event.target.value)}
              value={getValue("content")}
              rows={2}
              disabled={isSubmitting}
              placeholder={getTranslation("userErrorReport.labels.content")}
              style={{
                fontSize: 16,
                border: `1px solid ${!!getError("content") ? RED_2 : GREY_2}`,
                backgroundColor: GREY_1,
                borderRadius: "20px",
                resize: "none",
                outline: "none",
                boxShadow: "0px 1px 3px 0 rgba(0, 0, 0, 0.2)",
                padding: "12px 30px",
                fontFamily: "Baloo 2",
                color: GREY_4,
                opacity: isSubmitting ? 0.5 : 1,
                width: "240px",
              }}
            />
          </Col>
          <Separator size="md" />
          {!!apiError && (
            <>
              <div
                style={{
                  padding: 10,
                  borderRadius: 6,
                  backgroundColor: "#f8d7da",
                  borderColor: "#f5c2c7",
                }}
              >
                <TranslatedText
                  as="p"
                  translateKey={
                    apiError === "student_not_found"
                      ? "userErrorReport.errorMessages.studentNotFound"
                      : "userErrorReport.errorMessages.apiError"
                  }
                  style={{
                    color: "#842029",
                    fontSize: 12,
                    margin: 0,
                    padding: 0,
                  }}
                />
              </div>
              <Separator size="md" />
            </>
          )}
          <Row justifyContent="center" alignItems="center">
            <Button onClick={onDiscard} isDisabled={isSubmitting}>
              <TranslatedText
                translateKey="userErrorReport.buttonsText.discardChanges"
                size={14}
                color="#4e4e4e"
                boldType="bold"
              />
            </Button>
            <Separator size="md" />
            <Button type="submit" backgroundColor="#3D5059" isDisabled={isSubmitting}>
              <Row justifyContent="center" alignItems="center">
                <TranslatedText
                  translateKey="userErrorReport.buttonsText.submit"
                  size={14}
                  color={WHITE}
                  boldType="bold"
                />
                {isSubmitting && (
                  <>
                    <Separator size="sm" />
                    <Spinner size="xxs" />
                  </>
                )}
              </Row>
            </Button>
          </Row>
        </HtmlForm>
      )}
      {!isLoading && isSuccessfulReported && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "100%",
            height: "100%",
          }}
        >
          <Lottie
            options={{
              loop: false,
              autoplay: true,
              animationData: SuccessfullySent,
              rendererSettings: {
                preserveAspectRatio: "xMidYMid slice",
              },
            }}
            width={250}
            height={250}
          />
        </div>
      )}
    </div>
  );
};

export default withTranslation(UserErrorReportForm);
