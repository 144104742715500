import React from "react";
import Col from "core/Col";
import { GREY_4, GREY_8 } from "consts/colors";

class CardRope extends React.PureComponent {
  render() {
    const { backgroundColor } = this.props;
    return (
      <Col
        width={12}
        height={12}
        borderRadius="100%"
        backgroundColor={backgroundColor || GREY_8}
        justifyContent="center"
        alignItems="center"
      >
        <Col position="absolute" backgroundColor={GREY_4} width={6} height={6} borderRadius="100%" />
        <Col position="absolute" bottom={6} backgroundColor={GREY_4} width={2} height="100vh" />
      </Col>
    );
  }
}

export default CardRope;
