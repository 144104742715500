import React from "react";
import { withRouter } from "react-router-dom";
import WhitelabelContext from "student-front-commons/src/components/WhitelabelContext";
import PublicScreen from "core/PublicScreen";
import Card from "core/Card";
import Col from "core/Col";
import Row from "core/Row";
import Separator from "core/Separator";
import ScreenContainer from "core/ScreenContainer";
import GoogleChromeIcon from "assets/google-chrome.ico";
import SafariIcon from "assets/safari.png";
import Image from "core/Image";
import Button from "core/Button";
import { MD_SIZE } from "utils";
import { GREY_4 } from "consts/colors";
import TranslatedText from "core/TranslatedText";

const InvalidBrowserScreen = ({ history }) => (
  <WhitelabelContext.Consumer>
    {({ primaryColor, secondaryColor }) => (
      <PublicScreen>
        <ScreenContainer>
          <Col height="100vh" justifyContent="center" alignItems="center">
            <Card showRopes={true} padding={50}>
              <Row
                alignItems="center"
                mediaQueries={{
                  sm: `(max-width: ${MD_SIZE}px)`,
                  md: `(min-width: ${MD_SIZE}px)`,
                }}
                smFlexDirection="column"
                mdFlexDirection="row"
              >
                <Col width={240} alignItems="center">
                  <Image src={history.location.search === "?for=ios" ? SafariIcon : GoogleChromeIcon} />
                </Col>
                <Separator size="xxxl" />
                <Col alignItems="center">
                  <TranslatedText
                    translateKey={
                      {
                        "?for=desktop": "invalidBrowser.desktop",
                        "?for=android": "invalidBrowser.android",
                        "?for=ios": "invalidBrowser.ios",
                      }[history.location.search]
                    }
                    boldType="extra-bold"
                    size={22}
                    color={GREY_4}
                    align="center"
                  >
                    Por favor,
                    {history.location.search === "?for=desktop" &&
                      " utilize o navegador Google Chrome para acessar a Flexge."}
                    {history.location.search === "?for=android" &&
                      " utilize o navegador Google Chrome para acessar a Flexge."}
                    {history.location.search === "?for=ios" && " utilize o navegador Safari para acessar a Flexge."}
                  </TranslatedText>
                  <Separator size="xxl" />
                  <Button
                    backgroundColor={primaryColor}
                    onClick={() => {
                      history.push("/");
                      window.location.reload(true);
                    }}
                  >
                    <TranslatedText
                      translateKey="invalidBrowser.button.reload"
                      boldType="extra-bold"
                      size={18}
                      color={secondaryColor}
                    />
                  </Button>
                </Col>
              </Row>
            </Card>
          </Col>
        </ScreenContainer>
      </PublicScreen>
    )}
  </WhitelabelContext.Consumer>
);

export default withRouter(InvalidBrowserScreen);
