import React from "react";
import PropTypes from "prop-types";
import Col from "core/Col";
import { LG_SIZE } from "utils";

const ScreenContainer = ({ children }) => (
  <Col
    flexGrow={1}
    width="100%"
    mediaQueries={{
      md: `(max-width: ${LG_SIZE + 1}px)`,
      lg: `(min-width: ${LG_SIZE + 1}px)`,
    }}
    margin="0 auto"
    mdPadding="0px 15px"
    lgPadding="0px"
    maxWidth={LG_SIZE}
    boxSizing="border-box"
  >
    {children}
  </Col>
);

ScreenContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ScreenContainer;
