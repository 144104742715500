import React from "react";

export default ({ color }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="12">
    <path
      fill={color}
      d="M22.124 1.756C20.874.585 19.366-.001 17.599-.001h-11.2c-1.767 0-3.276.586-4.525 1.757C.623 2.928-.002 4.342-.002 5.998c0 1.657.626 3.07 1.876 4.242 1.249 1.172 2.758 1.758 4.525 1.758 1.6 0 3.008-.501 4.225-1.501h2.75c1.217 1 2.625 1.501 4.225 1.501 1.767 0 3.275-.586 4.525-1.758 1.25-1.172 1.875-2.585 1.875-4.242 0-1.656-.625-3.07-1.875-4.242zM10.399 6.748c0 .11-.037.2-.113.27a.403.403 0 0 1-.287.105h-2.4v2.25a.353.353 0 0 1-.113.269.403.403 0 0 1-.287.105h-1.6a.407.407 0 0 1-.288-.105.352.352 0 0 1-.112-.269v-2.25h-2.4a.405.405 0 0 1-.288-.105.356.356 0 0 1-.112-.27v-1.5a.36.36 0 0 1 .112-.27.405.405 0 0 1 .288-.105h2.4V2.624c0-.11.037-.2.112-.27a.407.407 0 0 1 .288-.105h1.6c.117 0 .212.035.287.105.075.07.113.16.113.27v2.249h2.4c.117 0 .212.035.287.105a.354.354 0 0 1 .113.27v1.5zm6.731 1.811c-.312.292-.69.439-1.131.439-.442 0-.819-.147-1.131-.439a1.4 1.4 0 0 1-.469-1.061c0-.414.156-.767.469-1.06.312-.293.689-.44 1.131-.44.441 0 .819.147 1.131.44.313.293.469.646.469 1.06a1.4 1.4 0 0 1-.469 1.061zm3.2-3a1.597 1.597 0 0 1-1.132.439c-.441 0-.818-.146-1.13-.439a1.4 1.4 0 0 1-.47-1.061 1.4 1.4 0 0 1 .47-1.06 1.592 1.592 0 0 1 1.13-.439c.443 0 .82.146 1.132.439.312.293.469.646.469 1.06a1.4 1.4 0 0 1-.469 1.061z"
    />
  </svg>
);
