import React from "react";
import PropTypes from "prop-types";
import { Block } from "jsxstyle";

const spacingSizes = {
  xxs: 3,
  xs: 5,
  sm: 10,
  md: 15,
  lg: 20,
  xl: 25,
  xxl: 30,
  xxxl: 60,
};

const Separator = ({ size }) => <Block width={spacingSizes[size]} height={spacingSizes[size]} flexShrink={0} />;

Separator.propTypes = {
  size: PropTypes.oneOf(["xxs", "xs", "sm", "md", "lg", "xl", "xxl", "xxxl"]).isRequired,
};

export default Separator;
