import React from "react";
import PropTypes from "prop-types";
import Row from "core/Row";
import Col from "core/Col";
import Text from "core/Text";
import Modal from "core/Modal";
import Separator from "core/Separator";
import Button from "core/Button";
import { BLACK_1, GREEN_1, WHITE } from "consts/colors";
import TranslatedText from "core/TranslatedText";

class ErrorModal extends React.PureComponent {
  static propTypes = {
    isOpen: PropTypes.bool.isRequired,
    message: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired,
    onRetry: PropTypes.func.isRequired,
    retryFlow: PropTypes.shape({
      id: PropTypes.string,
      params: PropTypes.object,
    }),
    attemptCount: PropTypes.number,
  };

  static defaultProps = {
    onRetry: null,
    attemptCount: 0,
  };

  state = {
    seconds: 0,
  };

  componentDidUpdate(prevProps) {
    if (this.props.retryFlow && this.props.attemptCount > prevProps.attemptCount && this.props.attemptCount <= 3) {
      const newTimeout = this.props.attemptCount * 5 + 10;
      if (this.tryAgainInterval) {
        clearInterval(this.tryAgainInterval);
      }
      this.setState(
        {
          seconds: newTimeout,
          isRetrying: false,
        },
        () => {
          this.tryAgainInterval = setInterval(this.handleInterval, 1000);
        }
      );
    }
  }

  componentWillUnmount() {
    if (this.tryAgainInterval) {
      clearInterval(this.tryAgainInterval);
    }
  }

  handleInterval = () => {
    this.setState(
      {
        seconds: this.state.seconds - 1,
      },
      () => {
        if (this.state.seconds === 0) {
          clearInterval(this.tryAgainInterval);
          this.props.onClose();
          if (this.props.retryFlow && this.props.retryFlow.id) {
            this.props.onRetry(this.props.retryFlow.id, this.props.retryFlow.params);
          }
        }
      }
    );
  };

  render() {
    const { isOpen, message, retryFlow, onClose, onRetry, attemptCount } = this.props;
    return (
      <Modal isOpen={isOpen}>
        <Col alignItems="center">
          <Text size={14} color={BLACK_1} boldType="light-bold" align="center">
            {message}
          </Text>
          <Separator size="xl" />
          {retryFlow && retryFlow.id && attemptCount <= 3 && (
            <TranslatedText
              translateKey="tryAgainMessage"
              values={{
                seconds: this.state.seconds,
              }}
              size={14}
              color={BLACK_1}
              boldType="light-bold"
              align="center"
            />
          )}
          {(!retryFlow || attemptCount > 3) && (
            <React.Fragment>
              <Row>
                <Button
                  backgroundColor={WHITE}
                  contentColor={GREEN_1}
                  onClick={() => {
                    onClose();
                    onRetry(retryFlow.id, retryFlow.params);
                  }}
                >
                  <TranslatedText translateKey="tryAgain" size={14} color={GREEN_1} boldType="bold" />
                </Button>
                <Separator size="md" />
                <Button backgroundColor={GREEN_1} contentColor={WHITE} onClick={onClose}>
                  <TranslatedText translateKey="close" size={14} color={WHITE} boldType="bold" />
                </Button>
              </Row>
            </React.Fragment>
          )}
        </Col>
      </Modal>
    );
  }
}

export default ErrorModal;
