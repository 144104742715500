import React from "react";

export default ({ width, height, color }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width || 650} height={height || 500} viewBox="0 0 650 500">
    <defs>
      <radialGradient id="a">
        <stop offset="0%" stopColor={color} />
        <stop offset="100%" stopColor="#F3F5F6" />
      </radialGradient>
    </defs>
    <path
      fill="#607D8B"
      fillRule="evenodd"
      d="M441.817 231.435l492.182 176.817v128.675L441.817 256.459l-82.537-47.033-36.272-20.671-.009-.003h.009l36.272.001 82.537.002 492.182.019v106.324l-492.182-85.667-82.537-14.365-36.272-6.313 36.272 13.032 82.537 29.65zm-82.537-12.637l82.537 68.355L813.531 595H668.376L441.817 328.51l-82.537-97.084-36.272-42.666-.009-.008.009.004 36.272 30.042zm0 31.976l82.537 141.1L560.636 595H472.8l-30.983-84.028-82.537-223.831-36.272-98.372-.009-.017.009.01 36.272 62.012zm0 141.099L395.563 595H322.98l.007-207.448.012-198.8.009.04 36.272 203.081zM250.394 595h-77.243l149.836-406.223.012-.025-.012.055L250.394 595zm-165.085 0H-22.445l345.432-406.238.012-.01-.012.016L85.309 595zm-252.94 0h-120.371v-58.155l610.989-348.089.012-.004-.012.007L-167.631 595zm-120.371-406.251V82.423l610.989 106.326.012.003h-.012l-610.989-.003zm0-219.476v-128.671l610.989 348.144.012.006-.012-.005-610.989-219.474zm0-286.511v-212.657l610.989 718.634.012.013-.012-.011-610.989-505.979zm143.154-293.763H28.065l294.922 799.726.012.027-.012-.02-467.835-799.733zm324.973 0H323.014l-.006 399.876-.009 399.877-.012-.057-142.862-799.696zM359.28-14.291l82.537-461.901 24.087-134.809h152.063l-176.15 477.602-82.537 223.78-36.272 98.35-.009.021.009-.042L359.28-14.291zm0 141.029l82.537-141.079 349.072-596.66h143.11v81.161L441.817 49.01l-82.537 97.07-36.272 42.661-.009.011.009-.014 36.272-62zm0 48.982l82.537-29.645L933.999-30.703V82.445l-492.182 85.633-82.537 14.361-36.272 6.31-.009.003.009-.005 36.272-13.027zm0-7.641l-36.272 20.667 36.272-30.038 82.537-68.346 492.182-407.561v157.831L441.817 121.055l-82.537 47.024zm-647.282 240.146V295.076l610.989-106.323-610.989 219.472z"
      opacity=".078"
    />
    <path
      fill="url(#a)"
      d="M441.817 231.435l492.182 176.817v128.675L441.817 256.459l-82.537-47.033-36.272-20.671-.009-.003h.009l36.272.001 82.537.002 492.182.019v106.324l-492.182-85.667-82.537-14.365-36.272-6.313 36.272 13.032 82.537 29.65zm-82.537-12.637l82.537 68.355L813.531 595H668.376L441.817 328.51l-82.537-97.084-36.272-42.666-.009-.008.009.004 36.272 30.042zm0 31.976l82.537 141.1L560.636 595H472.8l-30.983-84.028-82.537-223.831-36.272-98.372-.009-.017.009.01 36.272 62.012zm0 141.099L395.563 595H322.98l.007-207.448.012-198.8.009.04 36.272 203.081zM250.394 595h-77.243l149.836-406.223.012-.025-.012.055L250.394 595zm-165.085 0H-22.445l345.432-406.238.012-.01-.012.016L85.309 595zm-252.94 0h-120.371v-58.155l610.989-348.089.012-.004-.012.007L-167.631 595zm-120.371-406.251V82.423l610.989 106.326.012.003h-.012l-610.989-.003zm0-219.476v-128.671l610.989 348.144.012.006-.012-.005-610.989-219.474zm0-286.511v-212.657l610.989 718.634.012.013-.012-.011-610.989-505.979zm143.154-293.763H28.065l294.922 799.726.012.027-.012-.02-467.835-799.733zm324.973 0H323.014l-.006 399.876-.009 399.877-.012-.057-142.862-799.696zM359.28-14.291l82.537-461.901 24.087-134.809h152.063l-176.15 477.602-82.537 223.78-36.272 98.35-.009.021.009-.042L359.28-14.291zm0 141.029l82.537-141.079 349.072-596.66h143.11v81.161L441.817 49.01l-82.537 97.07-36.272 42.661-.009.011.009-.014 36.272-62zm0 48.982l82.537-29.645L933.999-30.703V82.445l-492.182 85.633-82.537 14.361-36.272 6.31-.009.003.009-.005 36.272-13.027zm0-7.641l-36.272 20.667 36.272-30.038 82.537-68.346 492.182-407.561v157.831L441.817 121.055l-82.537 47.024zm-647.282 240.146V295.076l610.989-106.323-610.989 219.472z"
    />
  </svg>
);
