import React from "react";
import PropTypes from "prop-types";
import ControlBarButton from "core/ControlBarButton";

function NextButton(props) {
  return (
    <ControlBarButton
      name="next"
      intent="primary"
      onClick={props.onClick}
      isDisabled={props.isDisabled}
      isLoading={props.isLoading}
      isActive={false}
      className={props.shouldShake ? "shake-vertical" : ""}
    />
  );
}

NextButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  shouldShake: PropTypes.bool,
};

NextButton.defaultProps = {
  isDisabled: false,
  isLoading: false,
  shouldShake: false,
};

export default NextButton;
