import React, { useCallback, useState } from "react";
import Popover from "core/Popover";
import ReadButton from "core/ReadButton";
import Text from "core/Text";
import ThemeContext from "core/ThemeContext";
import { useDispatch, useSelector } from "react-redux";
import { getCurrentItemExecutionProp, getSelectedId } from "student-front-commons/src/selectors/itemExecution";
import { readItem } from "student-front-commons/src/actions/itemExecution";

export default function ReadButtonContainer() {
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);

  const selectedId = useSelector(getSelectedId);
  const itemId = useSelector(getCurrentItemExecutionProp("item.id"));
  const itemText = useSelector(getCurrentItemExecutionProp("item.text"));
  const itemPostPhrase = useSelector(getCurrentItemExecutionProp("item.postPhrase"));
  const itemType = useSelector(getCurrentItemExecutionProp("item.type.key"));
  const isRecording = useSelector(getCurrentItemExecutionProp("isRecording"));
  const isDisabled = useSelector(getCurrentItemExecutionProp("isDisabled"));
  const repeatCount = useSelector(getCurrentItemExecutionProp("repeatCount"));

  const handleToggle = useCallback(() => {
    setIsOpen(!isOpen);
    if (!isOpen) {
      dispatch(readItem(itemId));
    }
  }, [isOpen, itemId, dispatch]);

  const isReadDisabled = () => {
    if (["VIDEO_SHORT", "PHONEME", "VOCABULARY"].find((type) => type === itemType)) {
      return (
        isDisabled || isRecording || !selectedId || !repeatCount
        // this.props.isSubmitting ||
      );
    }
    return true;
  };

  return (
    <Popover
      isOpen={isOpen}
      onClose={handleToggle}
      position="bottom"
      content={
        <ThemeContext.Consumer>
          {({ primary }) => (
            <Text boldType="extra-bold" size={22} color={primary}>
              {["PHONEME", "VOCABULARY"].find((type) => type === itemType) ? itemPostPhrase : itemText}
            </Text>
          )}
        </ThemeContext.Consumer>
      }
    >
      <ReadButton isDisabled={isReadDisabled()} onClick={handleToggle} />
    </Popover>
  );
}
