import React from "react";
import UnitListItem from "screens/module-detail/UnitListItem";
import Grid from "core/Grid";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { getUnitIdsByModuleId } from "student-front-commons/src/selectors/entity";

function UnitList() {
  const params = useParams();
  const units = useSelector(getUnitIdsByModuleId(params.moduleId));

  return (
    <Grid columnWidth={220} rowGap={30}>
      {units.map((unit) => (
        <UnitListItem key={unit} unit={unit} />
      ))}
    </Grid>
  );
}

export default UnitList;
