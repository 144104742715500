import React from "react";
import PropTypes from "prop-types";
import Col from "core/Col";

const Clickable = ({ isDisabled, onClick, children, ...rest }) => (
  <Col
    component="button"
    props={{
      onClick,
      disabled: isDisabled,
    }}
    cursor={isDisabled ? "not-allowed" : "pointer"}
    outline="none"
    border="none"
    backgroundColor="transparent"
    position="relative"
    alignItems="center"
    justifyContent="center"
    {...rest}
  >
    {children}
  </Col>
);

Clickable.propTypes = {
  onClick: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
};

export default Clickable;
