import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import shuffle from "lodash/shuffle";
import Col from "core/Col";
import Grid from "core/Grid";
import { useDispatch, useSelector } from "react-redux";
import ItemButton from "../ItemButton";
import { WHITE } from "../../consts/colors";
import AnimatedImage from "../AnimatedImage";
import Icon from "../../assets/memory-game-card-icon.png";
import { getImage } from "../../stores/image-store";
import { useFlow } from "student-front-commons/src/hooks";
import { CHECK_ITEM_ANSWER_FLOW } from "../../consts";
import { getItemExecutionPropById, getSelectedId } from "student-front-commons/src/selectors/itemExecution";
import {
  isInstructionEnableForStep,
  isInstructionHighlighted,
} from "student-front-commons/src/selectors/itemInstruction";
import { addItemExecutionAnswer, selectItem } from "student-front-commons/src/actions/itemExecution";

function MemoryGameItemRender(props) {
  const playingId = useSelector(getItemExecutionPropById(props.itemId, "playingId"));
  const isDisabled = useSelector(getItemExecutionPropById(props.itemId, "isDisabled"));
  const isFinished = useSelector(getItemExecutionPropById(props.itemId, "isFinished"));

  const isInstructionEnabled = useSelector(isInstructionEnableForStep("user-away"));
  const shouldHighlighted = useSelector(isInstructionHighlighted);

  const getItemStatus = () => {
    if (playingId === props.itemId) {
      return "outline-active";
    }
    if (isFinished) {
      return "active";
    }
    if (props.isSelected) {
      return "active";
    }

    return "normal";
  };

  return (
    <ItemButton
      isDisabled={isDisabled || props.isSelected}
      onClick={props.onClick}
      status={getItemStatus() === "outline-active" ? "outline" : getItemStatus()}
      height={200}
      width={150}
      padding={10}
      transition="0.5s"
      className={isInstructionEnabled && shouldHighlighted ? "shake-vertical" : ""}
    >
      {() => (
        <Col
          flexGrow={1}
          width={130}
          borderRadius={10}
          backgroundColor={WHITE}
          boxSizing="border-box"
          justifyContent="center"
          alignItems="center"
          overflow="hidden"
        >
          {(getItemStatus() === "normal" || getItemStatus() === "outline") && (
            <AnimatedImage src={Icon} width={74} height="auto" />
          )}
          {getItemStatus() === "active" && playingId === `post-phrase-${props.itemId}` && (
            <AnimatedImage
              src={
                getImage(`items/${props.itemId}/images/post-phrase-lg.png`) ||
                `${process.env.REACT_APP_FILES_URL}/items/${props.itemId}/images/post-phrase-lg.png`
              }
              width="auto"
              height={180}
              borderRadius={10}
              objectFit="scale-down"
            />
          )}
          {(getItemStatus() === "active" || getItemStatus() === "outline-active") &&
            playingId !== `post-phrase-${props.itemId}` && (
              <AnimatedImage
                src={
                  getImage(`items/${props.itemId}/images/lg.png`) ||
                  `${process.env.REACT_APP_FILES_URL}/items/${props.itemId}/images/lg.png`
                }
                width="auto"
                height={180}
                borderRadius={10}
                objectFit="scale-down"
              />
            )}
        </Col>
      )}
    </ItemButton>
  );
}

MemoryGameItemRender.propTypes = {
  itemId: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  isSelected: PropTypes.bool.isRequired,
};

function MemoryGameItem() {
  const dispatch = useDispatch();

  const [allItems, setAllItems] = useState([]);
  const [userSelection, setUserSelection] = useState([]);

  const [, startCheckFlow] = useFlow(CHECK_ITEM_ANSWER_FLOW);

  const selectedItemId = useSelector(getSelectedId);
  const items = useSelector((state) => state.itemExecutions.allIds);

  useEffect(() => {
    setAllItems(shuffle([...items, ...items]));
  }, [items]);

  useEffect(() => {
    if (!selectedItemId) {
      setUserSelection([]);
    }
  }, [selectedItemId, setUserSelection]);

  const handleClick = useCallback(
    (item, index) => {
      if (!selectedItemId) {
        dispatch(selectItem(item));
        setUserSelection([index]);
      } else {
        dispatch(addItemExecutionAnswer(selectedItemId, { answer: item }));
        setUserSelection([...userSelection, index]);
        startCheckFlow();
      }
    },
    [selectedItemId, userSelection, setUserSelection, dispatch, startCheckFlow]
  );

  return (
    <Col flexGrow={1} justifyContent="center">
      <Grid rowGap={20} columnWidth={160} width="100%">
        {allItems.map((item, index) => (
          <MemoryGameItemRender
            key={`${index}-${item}`}
            itemId={item}
            onClick={() => handleClick(item, index)}
            isSelected={userSelection.some((v) => v === index)}
          />
        ))}
      </Grid>
    </Col>
  );
}

export default MemoryGameItem;
