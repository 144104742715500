import { head, last } from "lodash";
import { call, put, race, select, spawn, take, takeLatest } from "redux-saga/effects";
import { getFlowStart } from "student-front-commons/src/selectors/flow";
import { endFlow, startFlow } from "student-front-commons/src/actions/flow";
import browserHistory from "../browserHistory";
import {
  CLOSE_UNIT_EXECUTION_FLOW,
  GET_NEXT_ITEM_EXECUTION_FLOW,
  SAVE_TASTING_ITEM_EXECUTION_ANSWER_FLOW,
} from "../consts";
import { logError } from "utils";
import { Howl } from "howler";
import sample from "lodash/sample";
import { disableItemExecution, enableItemExecution } from "student-front-commons/src/actions/itemExecution";
import { getItemExecutions } from "student-front-commons/src/selectors/itemExecution";
import { getAssociativeItemOrderByItemId } from "student-front-commons/src/selectors/execution";
import { addExecutionAnswer, setNextExecutionOrder } from "student-front-commons/src/actions/execution";

export default function* () {
  yield takeLatest(getFlowStart(SAVE_TASTING_ITEM_EXECUTION_ANSWER_FLOW), function* () {
    yield race({
      cancel: take(getFlowStart(CLOSE_UNIT_EXECUTION_FLOW)),
      call: call(function* () {
        try {
          yield put(disableItemExecution());

          const itemExecutions = yield select(getItemExecutions);

          yield itemExecutions.reduce(function* (acc, itemExecution) {
            yield acc;

            const associativeItemOrder = yield select(getAssociativeItemOrderByItemId(itemExecution.item.id));
            yield put(
              addExecutionAnswer({
                answer: itemExecution.item.id,
                correct:
                  itemExecution.item.type.key === "VIDEO" ||
                  itemExecution.item.type.key === "MUSIC" ||
                  last(itemExecution.attempts).correct,
                itemOrder: associativeItemOrder,
              })
            );
          }, Promise.resolve());

          const itemTypeKey = head(itemExecutions).item.type.key;
          if (itemTypeKey === "CONNECTING_DOTS") {
            window.jsPlumb.deleteEveryConnection();
          }

          const execution = yield select((state) => state.executions);
          if (execution.answers.length >= execution.associativeItems.length) {
            browserHistory.replace(`${browserHistory.location.pathname}-result`);
          } else {
            // audio effect
            yield spawn(function* () {
              const audio = new Howl({
                src: [
                  sample([
                    require("assets/audio/next/next-audio-3.mp3").default,
                    require("assets/audio/next/next-audio-4.mp3").default,
                    require("assets/audio/next/next-audio-5.mp3").default,
                  ]),
                ],
                autoplay: false,
                loop: false,
                volume: 1,
              });
              yield new Promise((resolve) => {
                audio.once("playerror", () => {
                  audio.unload();
                  resolve();
                });
                audio.once("end", () => {
                  audio.unload();
                  resolve();
                });
                audio.play();
              });
            });

            yield put(setNextExecutionOrder());
            yield put(startFlow(GET_NEXT_ITEM_EXECUTION_FLOW));
          }
        } catch (error) {
          logError({ error, flow: SAVE_TASTING_ITEM_EXECUTION_ANSWER_FLOW });
        } finally {
          yield put(enableItemExecution());
          yield put(endFlow(SAVE_TASTING_ITEM_EXECUTION_ANSWER_FLOW));
        }
      }),
    });
  });
}
