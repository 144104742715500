import React from "react";
import PropTypes from "prop-types";
import Col from "core/Col";
import { GREY_2, WHITE } from "consts/colors";
import withTranslation from "core/withTranslation";
import Spinner from "./Spinner";
import Row from "./Row";

const Button = ({
  isDisabled,
  isLoading,
  isRounded,
  isFullWidth,
  onClick,
  backgroundColor,
  contentColor,
  type,
  roundSize,
  tooltip,
  getTranslation,
  children,
  ...rest
}) => (
  <Col
    component="button"
    props={{
      disabled: isDisabled,
      type,
      onClick,
      ...(tooltip && { "data-tooltip": getTranslation(tooltip) }),
    }}
    cursor={isDisabled ? "not-allowed" : "pointer"}
    backgroundColor={isDisabled ? GREY_2 : backgroundColor}
    color={isDisabled ? WHITE : contentColor}
    width={isFullWidth ? "100%" : isRounded ? roundSize : "auto"}
    height={isRounded ? roundSize : "auto"}
    borderRadius={isRounded ? "100%" : 25}
    padding={isRounded ? "0px" : "10px 35px"}
    border="none"
    justifyContent="center"
    alignItems="center"
    outline="none"
    overflow="hidden"
    boxShadow="0px 1px 3px 0 rgba(0, 0, 0, 0.2)"
    boxSizing="border-box"
    transition="transform 300ms ease, background-color .5s, width .3s, height .3s"
    hoverTransform={isDisabled ? "none" : "scale(1.1)"}
    position="relative"
    {...rest}
  >
    {isLoading && (
      <Row position="absolute" left={25} opacity={isLoading ? 1 : 0} transition="opacity .3s">
        <Spinner size="xxs" />
      </Row>
    )}
    {children}
  </Col>
);

Button.propTypes = {
  isDisabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  isRounded: PropTypes.bool,
  onClick: PropTypes.func,
  roundSize: PropTypes.number,
  contentColor: PropTypes.string,
  backgroundColor: PropTypes.string,
  type: PropTypes.oneOf(["button", "submit"]),
  children: PropTypes.node.isRequired,
};

Button.defaultProps = {
  roundSize: 60,
  isDisabled: false,
  isLoading: false,
  isRounded: false,
  onClick: null,
  type: "button",
  backgroundColor: WHITE,
  contentColor: GREY_2,
};

export default withTranslation(Button);
