import React, { useContext, useEffect } from "react";
import WhitelabelContext from "student-front-commons/src/components/WhitelabelContext";
import { isEmail, isRequired } from "student-front-commons/src/validators";
import HtmlForm from "core/HtmlForm";
import Input from "core/Input";
import Button from "core/Button";
import InBetweenSpacing from "core/InBetweenSpacing";
import TranslatedText from "core/TranslatedText";
import { addBreadcrumb } from "utils";
import { useForm, useService } from "student-front-commons/src/hooks";
import { login } from "student-front-commons/src/services/authenticationService";
import { detect } from "detect-browser";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { hideMessage, showMessage } from "student-front-commons/src/actions/systemMessage";
import { useDispatch } from "react-redux";

function LoginForm() {
  const history = useHistory();
  const dispatch = useDispatch();
  const whitelabelContext = useContext(WhitelabelContext);
  const deviceInfo = detect();

  useEffect(() => {
    dispatch(hideMessage());

    return () => {
      dispatch(hideMessage());
    };
  }, [dispatch]);

  const [isSubmitting, start] = useService(login, {
    onData: (data) => {
      if (data.academicPlan !== "KIDS") {
        sessionStorage.clear();
        dispatch(showMessage({ message: "login.error.academic_user", type: "error" }));
        return;
      }

      sessionStorage.setItem("id", data.id);
      history.replace("/courses");
    },
    onError: (error) => {
      dispatch(showMessage({ message: `login.error.${error.code}`, type: "error" }));
    },
  });

  const { getValue, setValue, getError, submit, isSubmitted } = useForm({
    initialValues: {
      role: "STUDENT",
      distributor: whitelabelContext.distributor,
      company: whitelabelContext.company,
      deviceInfo: {
        browser: `${deviceInfo.name}_${deviceInfo.version}`,
        os: deviceInfo.os,
        screenSize: `${window.screen.width}_x_${window.screen.height}`,
        internetSpeed: "N/A",
      },
    },
    validations: {
      email: [isRequired, isEmail],
      password: [isRequired],
    },
    onSubmit: (values) => {
      dispatch(hideMessage());
      addBreadcrumb({
        category: "ui",
        message: "User login attempt",
      });
      start(values);
    },
  });

  return (
    <HtmlForm onSubmit={submit}>
      <InBetweenSpacing size="lg">
        <Input
          placeholder="login.field.email"
          isDisabled={isSubmitting}
          value={getValue("email")}
          onChange={(value) => setValue("email", value.replace(/\s+/g, ''))}
          errorText={isSubmitted() && getError("email")}
        />
        <Input
          placeholder="login.field.password"
          type="password"
          isDisabled={isSubmitting}
          value={getValue("password")}
          onChange={(value) => setValue("password", value)}
          errorText={isSubmitted() && getError("password")}
        />
        <Button
          intent="primary"
          type="submit"
          isFullWidth={true}
          isDisabled={isSubmitting}
          isLoading={isSubmitting}
          backgroundColor={whitelabelContext.primaryColor}
        >
          <TranslatedText
            translateKey="login.button.access"
            color={whitelabelContext.secondaryColor}
            boldType="bold"
            size={18}
            textTransform="uppercase"
          />
        </Button>
      </InBetweenSpacing>
    </HtmlForm>
  );
}

export default LoginForm;
