import React from "react";
import { connect } from "react-redux";
import get from "lodash/get";
import Flow from "student-front-commons/src/components/Flow";
import { startFlow, endFlow } from "student-front-commons/src/actions/flow";
import ErrorModal from "core/ErrorModal";
import { ERROR_MODAL_FLOW } from "consts";

class ErrorModalContainer extends React.PureComponent {
  render() {
    return (
      <Flow autoStart={false} id={ERROR_MODAL_FLOW}>
        {({ isPending, getParams }) => {
          return (
            <ErrorModal
              isOpen={isPending}
              onRetry={this.props.handleRetry}
              onClose={this.props.handleEndFlow}
              message={get(getParams(), "message", "")}
              retryFlow={get(getParams(), "retryFlow", null)}
              attemptCount={get(getParams(), "attemptCount", 0)}
            />
          );
        }}
      </Flow>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  handleEndFlow: () => dispatch(endFlow(ERROR_MODAL_FLOW)),
  handleRetry: (id, params) => dispatch(startFlow(id, params)),
});

export default connect(null, mapDispatchToProps)(ErrorModalContainer);
