import { all, call, put, select, spawn, takeLatest } from "redux-saga/effects";
import { max, orderBy } from "lodash";
import { endFlow, startFlow } from "student-front-commons/src/actions/flow";
import { getFlow, getFlowStart } from "student-front-commons/src/selectors/flow";
import { startMasteryTestExecution } from "student-front-commons/src/services/masteryTestExecutionService";
import { addBreadcrumb, addImageDataToItems, addSoundToItems, logError } from "utils";
import { GET_NEXT_ITEM_EXECUTION_FLOW, LOAD_CONFIGURATION_FLOW, START_MASTERY_TEST_EXECUTION_FLOW } from "consts";
import { cleanExecution, startExecution } from "student-front-commons/src/actions/execution";

export default function* () {
  yield takeLatest(getFlowStart(START_MASTERY_TEST_EXECUTION_FLOW), function* () {
    const flow = yield select(getFlow(START_MASTERY_TEST_EXECUTION_FLOW));
    try {
      yield put(cleanExecution());

      const result = yield call(startMasteryTestExecution, {
        module: flow.params.module,
        masteryTest: flow.params.masteryTest,
      });

      const configurations = yield select((state) => state.configurations.id);
      if (!configurations) {
        yield put(startFlow(LOAD_CONFIGURATION_FLOW));
        addBreadcrumb({
          category: "flow",
          message: "Start load configuration flow",
        });
      }

      const orderedItems = orderBy(result.items, ["order"], ["asc"]);
      const itemsInOrder = orderedItems.filter((masteryItem) => masteryItem.order === 1).length;
      yield all([
        ...addSoundToItems(orderedItems.slice(0, max([4, itemsInOrder]))),
        ...addImageDataToItems(orderedItems.slice(0, max([4, itemsInOrder]))),
      ]);

      addBreadcrumb({
        category: "flow",
        action: `Added sounds and images to first order Items`,
      });

      yield put(
        startExecution({
          ...result.masteryTestExecution,
          type: "masteryTest",
          items: orderedItems,
          module: flow.params.module,
          masteryTest: flow.params.masteryTest,
          isGame: false,
        })
      );

      yield spawn(function* () {
        yield all([
          ...addSoundToItems(orderedItems.slice(max([4, itemsInOrder]))),
          ...addImageDataToItems(orderedItems.slice(max([4, itemsInOrder]))),
        ]);
        addBreadcrumb({
          category: "flow",
          message: "Finished loading all audios and images",
        });
      });

      yield put(startFlow(GET_NEXT_ITEM_EXECUTION_FLOW));
    } catch (error) {
      logError({ error, flow: START_MASTERY_TEST_EXECUTION_FLOW });
    } finally {
      yield put(endFlow(START_MASTERY_TEST_EXECUTION_FLOW));
    }
  });
}
