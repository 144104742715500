import axios from "axios";
import { Map } from "immutable";
import { Howl, Howler } from "howler";
import LogRocket from "logrocket";
import { addBreadcrumb } from "../utils";

let store = new Map();
let currentPlaying = null;

const downloadAudio = async ({ url, isRetry }) => {
  let audioData = null;
  try {
    const response = await axios.get(
      `${isRetry ? process.env.REACT_APP_FILES_FALLBACK_URL : process.env.REACT_APP_FILES_URL}/${url}`,
      { responseType: "blob" }
    );

    audioData = await new Promise((resolve) => {
      let reader = new FileReader();
      reader.onloadend = () => {
        resolve(reader.result);
        reader = null;
      };
      reader.onerror = () => {
        addBreadcrumb({
          category: "audio-store",
          message: "Error to convert audio to base64",
          data: { error: reader.error },
        });
        reader = null;
        throw new Error("error_creating_base64");
      };
      reader.readAsDataURL(response.data);
    });
  } catch (error) {
    if (error.response) {
      LogRocket.log({ error: `${error.response.status}: ${error.response.statusText}`, flow: "DOWNLOAD_AUDIO_DATA" });
    } else if (error.request) {
      LogRocket.log({ error: `without_response`, flow: "DOWNLOAD_AUDIO_DATA" });
    } else {
      LogRocket.log({ error: error.message, flow: "DOWNLOAD_AUDIO_DATA" });
    }
    return null;
  }

  if (audioData && audioData.indexOf("data:application/octet-stream") > -1) {
    const extension = url.split(".").pop();
    audioData = audioData.replace(
      "data:application/octet-stream",
      { mp3: "data:audio/mpeg", ogg: "data:audio/ogg", wav: "data:audio/x-wav", webm: "data:audio/webm" }[extension] ||
        "data:audio/mpeg"
    );
  }

  return audioData;
};

export const insertSpeechRecognitionAudio = async ({ data, isDeletable }) => {
  store = store.set(data, { data, isDeletable });
};

export const insertAudio = async ({ url, isDeletable }) => {
  const audioData = await downloadAudio({ url, isRetry: false });

  store = store.set(url, {
    data: audioData,
    isDeletable,
  });

  if (!audioData) {
    setTimeout(async () => {
      const audioData = await downloadAudio({ url, isRetry: true });

      store = store.set(url, {
        data: audioData,
        isDeletable,
      });
    }, 500);
  }
};

export const clearAudios = () => {
  store = store.filter((register) => !register.isDeletable);
};

export const getAudio = ({ url }) => {
  const register = store.get(url);
  if (!register) {
    throw new Error("audio_register_not_found");
  }
  if (!register.data) {
    throw new Error("audio_data_not_available");
  }

  return register.data;
};

export const getAudioInsertConfigs = ({ url }) => {
  const register = store.get(url);
  if (!register) {
    throw new Error("audio_register_not_found");
  }
  return register;
};

export const playAudio = async ({ url, rate, resolveOnPlay }) => {
  let audioData = null;
  let attempts = 0;
  let error = null;

  while (!audioData && attempts < 2) {
    try {
      audioData = getAudio({ url });
    } catch (e) {
      error = e;
      const { data, ...configs } = getAudioInsertConfigs({ url });
      addBreadcrumb({
        category: "audio-store",
        message: `Failed to get audio: ${url}. Trying to recreate it`,
        data: {
          url,
          configs,
        },
      });
      await insertAudio({
        url,
        ...configs,
      });
      attempts++;
    }
  }

  if (!audioData) {
    throw error;
  }

  const sound = await new Promise((resolve, reject) => {
    const sound = new Howl({
      src: [audioData],
      autoplay: false,
      loop: false,
      // html5: true,
      onload: () => resolve(sound),
      onloaderror: (id, error) => {
        addBreadcrumb({
          category: "audio-store",
          action: `error_load_audio: ${url}`,
          data: {
            url,
            error,
            hasAudioData: !!audioData,
          },
        });
        reject(`error_load_audio`);
      },
    });
  });

  let playTimeout = null;
  let suspendTimeout = null;
  let onEndTimeout = null;
  return new Promise((resolve, reject) => {
    try {
      currentPlaying = sound;
      currentPlaying.once("end", () => {
        LogRocket.log("audio ended");
        if (suspendTimeout) {
          clearTimeout(suspendTimeout);
          suspendTimeout = null;
        }
        if (playTimeout) {
          clearTimeout(playTimeout);
          playTimeout = null;
        }
        if (onEndTimeout) {
          clearTimeout(onEndTimeout);
          onEndTimeout = null;
        }
        if (currentPlaying) {
          currentPlaying.unload();
          currentPlaying = null;
        }
        resolve();
      });
      currentPlaying.once("playerror", (id, error) => {
        addBreadcrumb({
          category: "audio-flow",
          action: "Error playing audio",
          data: { url },
        });
        if (suspendTimeout) {
          clearTimeout(suspendTimeout);
          suspendTimeout = null;
        }
        if (playTimeout) {
          clearTimeout(playTimeout);
          playTimeout = null;
        }
        if (onEndTimeout) {
          clearTimeout(onEndTimeout);
          onEndTimeout = null;
        }
        if (currentPlaying) {
          currentPlaying.unload();
          currentPlaying = null;
        }
        reject(error);
      });
      currentPlaying.once("play", () => {
        LogRocket.log("audio played");
        let onEndTimeoutMilliseconds = 3000;
        if (currentPlaying && currentPlaying.duration()) {
          //audio duration in seconds * 1000 divided by the rate plus additional 500 milliseconds
          onEndTimeoutMilliseconds = (currentPlaying.duration() * 1000) / (rate || 1) + 500;
        }

        onEndTimeout = setTimeout(() => {
          LogRocket.log("audio end timeout. Howler state " + Howler.state);
          if (suspendTimeout) {
            clearTimeout(suspendTimeout);
            suspendTimeout = null;
          }
          if (playTimeout) {
            clearTimeout(playTimeout);
            playTimeout = null;
          }
          if (onEndTimeout) {
            clearTimeout(onEndTimeout);
            onEndTimeout = null;
          }
          if (currentPlaying) {
            currentPlaying.unload();
            currentPlaying = null;
          }
          resolve();
        }, onEndTimeoutMilliseconds);
        if (suspendTimeout) {
          clearTimeout(suspendTimeout);
          suspendTimeout = null;
        }
        if (playTimeout) {
          clearTimeout(playTimeout);
          playTimeout = null;
        }
        if (resolveOnPlay) {
          if (onEndTimeout) {
            clearTimeout(onEndTimeout);
          }
          resolve(currentPlaying);
        }
      });
      currentPlaying.once("stop", () => {
        if (suspendTimeout) {
          clearTimeout(suspendTimeout);
          suspendTimeout = null;
        }
        if (playTimeout) {
          clearTimeout(playTimeout);
          playTimeout = null;
        }
        if (onEndTimeout) {
          clearTimeout(onEndTimeout);
          onEndTimeout = null;
        }
      });

      currentPlaying.rate(rate || 1);
      suspendTimeout = setTimeout(() => {
        if (Howler.state === "suspended") {
          LogRocket.log(Howler.ctx);
          if (currentPlaying) {
            currentPlaying.unload();
            currentPlaying = null;
          }
          reject("required-user-click");
        }
      }, 3000);
      playTimeout = setTimeout(() => {
        if (currentPlaying) {
          LogRocket.log(`Audio state: ${currentPlaying.state()}`);
          currentPlaying.unload();
          currentPlaying = null;
        }
        reject("not-started");
      }, 15000);
      currentPlaying.play();
    } catch (error) {
      reject(error);
    }
  });
};

export const stopAudio = () => {
  if (currentPlaying) {
    if (currentPlaying.playing()) {
      currentPlaying.stop();
    }
    currentPlaying = null;
  }
};
