import React, { useContext, useEffect } from "react";
import WhitelabelContext from "student-front-commons/src/components/WhitelabelContext";
import { isEmail, isRequired } from "student-front-commons/src/validators";
import HtmlForm from "core/HtmlForm";
import Row from "core/Row";
import Input from "core/Input";
import Button from "core/Button";
import InBetweenSpacing from "core/InBetweenSpacing";
import Separator from "core/Separator";
import Divider from "core/Divider";
import RadioInput from "core/RadioInput";
import { GREY_4, WHITE } from "consts/colors";
import { SM_SIZE } from "utils";
import TranslatedText from "core/TranslatedText";
import { useEntity, useForm, useService } from "student-front-commons/src/hooks";
import { hideMessage, showMessage } from "student-front-commons/src/actions/systemMessage";
import { updateProfile } from "student-front-commons/src/services/profileService";
import { useDispatch } from "react-redux";
import { mergeEntities } from "student-front-commons/src/actions/entity";
import { PROFILE_SCHEMA } from "student-front-commons/src/schemas";
import { pick } from "lodash";

function ProfileForm() {
  const dispatch = useDispatch();
  const whitelabelContext = useContext(WhitelabelContext);

  useEffect(() => {
    dispatch(hideMessage());

    return () => {
      dispatch(hideMessage());
    };
  }, [dispatch]);

  const [isSubmitting, start] = useService(updateProfile, {
    onData: (data) => {
      dispatch(mergeEntities(data.entities));
      dispatch(showMessage({ message: "profile.successMessage", type: "success" }));
    },
    onError: () => {
      dispatch(showMessage({ message: "profile.errorMessage", type: "error" }));
    },
  });

  const profile = useEntity(PROFILE_SCHEMA, sessionStorage.getItem("id"));

  const { getValue, setValue, getError, submit, isSubmitted, isDirty } = useForm({
    initialValues: {
      role: "STUDENT",
      ...pick(profile, ["name", "email", "nickname", "rankingParticipant", "password", "newPassword"]),
    },
    validations: {
      email: [isRequired, isEmail],
      nickname: [isRequired],
      rankingParticipant: [isRequired],
      password: [(value, formValues) => (!value || !value.length) && formValues.newPassword && "Required"],
      newPassword: [(value, formValues) => (!value || !value.length) && formValues.password && "Required"],
    },
    onSubmit: (values) => {
      dispatch(hideMessage());
      start({ ...values, rankingParticipant: values.rankingParticipant === "S" });
    },
  });

  return (
    <HtmlForm onSubmit={submit}>
      <TranslatedText translateKey="profile.title" boldType="extra-bold" size={20} isUpperCase={true} color={GREY_4} />
      <Separator size="md" />
      <InBetweenSpacing size="lg">
        <Input
          placeholder="profile.field.nickname"
          isDisabled={isSubmitting}
          value={getValue("nickname")}
          onChange={(value) => setValue("nickname", value)}
          errorText={isSubmitted() && getError("nickname")}
        />
        <Input
          placeholder="profile.field.email"
          isDisabled={isSubmitting}
          value={getValue("email")}
          onChange={(value) => setValue("email", value)}
          errorText={isSubmitted() && getError("email")}
        />
        <RadioInput
          options={[
            {
              label: "profile.field.rankingParticipant",
              value: "S",
            },
            {
              label: "profile.field.notRankingParticipant",
              value: "N",
            },
          ]}
          name="rankingOption"
          isDisabled={isSubmitting}
          value={getValue("rankingParticipant")}
          onChange={(value) => setValue("rankingParticipant", value)}
          errorText={isSubmitted() && getError("rankingParticipant")}
        />
      </InBetweenSpacing>
      <Separator size="lg" />
      <TranslatedText
        translateKey="profile.passwordSection.title"
        boldType="extra-bold"
        size={20}
        isUpperCase={true}
        color={GREY_4}
      />
      <TranslatedText
        translateKey="profile.passwordSection.description"
        boldType="light-bold"
        size={12}
        isUpperCase={true}
        color={GREY_4}
      />
      <Separator size="md" />
      <Row
        mediaQueries={{
          sm: `(max-width: ${SM_SIZE}px)`,
          md: `(min-width: ${SM_SIZE}px)`,
        }}
        smFlexDirection="column"
        lgFlexDirection="row"
      >
        <InBetweenSpacing size="lg">
          <Input
            placeholder="profile.field.currentPassword"
            isDisabled={isSubmitting}
            value={getValue("password")}
            onChange={(value) => setValue("password", value)}
            errorText={isSubmitted() && getError("password")}
          />
          <Input
            placeholder="profile.field.newPassword"
            isDisabled={isSubmitting || !getValue("password", false)}
            value={getValue("newPassword")}
            onChange={(value) => setValue("newPassword", value)}
            errorText={isSubmitted() && getError("newPassword")}
          />
        </InBetweenSpacing>
      </Row>
      <Separator size="xxl" />
      <Divider />
      <Separator size="xxl" />
      <Row>
        <Button isFullWidth={true} isDisabled={isSubmitting} backgroundColor={WHITE}>
          <TranslatedText
            translateKey="profile.button.cancel"
            color={GREY_4}
            boldType="bold"
            size={16}
            isUpperCase={true}
          />
        </Button>
        <Separator size="lg" />
        <Button
          intent="primary"
          type="submit"
          isFullWidth={true}
          isDisabled={!isDirty || isSubmitting}
          isLoading={isSubmitting}
          backgroundColor={whitelabelContext.primaryColor}
        >
          <TranslatedText
            translateKey="profile.button.save"
            color={whitelabelContext.secondaryColor}
            boldType="bold"
            size={16}
            isUpperCase={true}
          />
        </Button>
      </Row>
    </HtmlForm>
  );
}

export default ProfileForm;
