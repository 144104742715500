import React from "react";

export default ({ color }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="18">
    <path
      fill={color}
      d="M15.581 17.732l7.955-8.099A.988.988 0 0 0 23.803 9a.824.824 0 0 0-.267-.628L15.581.273C15.404.092 15.228 0 14.965 0c-.267 0-.446.092-.622.273l-1.234 1.261a.877.877 0 0 0 0 1.257l3.534 3.599c.264.272.086.813-.354.813H1.707c-.531 0-.885.36-.885.901v1.797c0 .541.354.901.885.901h14.668c.354 0 .623.448.354.809l-3.534 3.603a.878.878 0 0 0 0 1.257l1.238 1.261c.177.18.355.268.618.268.177 0 .353-.088.53-.268z"
    />
  </svg>
);
