import React from "react";
import Flow from "student-front-commons/src/components/Flow";
import { LOGOUT_FLOW } from "consts";
import MenuItem from "core/MenuItem";

const LogoutMenuItemContainer = () => (
  <Flow id={LOGOUT_FLOW}>{({ handleStart }) => <MenuItem label="menu.logout" onClick={handleStart} />}</Flow>
);

export default LogoutMenuItemContainer;
