import React, { useCallback } from "react";
import PropTypes from "prop-types";
import Col from "core/Col";
import Image from "core/Image";
import ItemButton from "core/ItemButton";
import Grid from "core/Grid";
import { getImage } from "stores/image-store";
import { useDispatch, useSelector } from "react-redux";
import { useFlow } from "student-front-commons/src/hooks";
import { CHECK_ITEM_ANSWER_FLOW } from "../../consts";
import { getItemExecutionPropById } from "student-front-commons/src/selectors/itemExecution";
import {
  isInstructionEnableForStep,
  isInstructionHighlighted,
} from "student-front-commons/src/selectors/itemInstruction";
import { addItemExecutionAnswer } from "student-front-commons/src/actions/itemExecution";

function SingleChoiceImageItemRender(props) {
  const dispatch = useDispatch();

  const [, startCheckFlow] = useFlow(CHECK_ITEM_ANSWER_FLOW);

  const itemId = useSelector(getItemExecutionPropById(props.itemId, "item.id"));
  const itemLinkedAnswers = useSelector(getItemExecutionPropById(props.itemId, "item.linkedAnswers"));
  const isDisabled = useSelector(getItemExecutionPropById(props.itemId, "isDisabled"));
  const playingId = useSelector(getItemExecutionPropById(props.itemId, "playingId"));
  const showCorrectOption = useSelector(getItemExecutionPropById(props.itemId, "showCorrectOption"));
  const userAnswer = useSelector(getItemExecutionPropById(props.itemId, "answer"));

  const isInstructionEnabled = useSelector(isInstructionEnableForStep("user-away"));
  const shouldHighlighted = useSelector(isInstructionHighlighted);

  const handleChange = useCallback(
    (answer) => {
      dispatch(addItemExecutionAnswer(itemId, { answer }));
      startCheckFlow();
    },
    [itemId, dispatch, startCheckFlow]
  );

  return (
    <Col flexGrow={1} justifyContent="center" alignItems="center">
      <Grid rowGap={20} columnWidth={220} maxWidth={480} width="100%" flexShrink={0}>
        {itemLinkedAnswers.map((answer) => (
          <ItemButton
            key={answer.id}
            padding={10}
            isDisabled={isDisabled}
            onClick={() => {
              handleChange(answer);
            }}
            status={
              userAnswer && userAnswer.id === answer.id
                ? "active"
                : showCorrectOption && answer.correct
                ? "outline"
                : "normal"
            }
            width={220}
            height={220}
            overflow="visible"
            transition="box-shadow .5s, background-color .5s, transform 300ms ease"
            transform={playingId === answer.id ? "scale(1.15)" : "none"}
            boxShadow={playingId === answer.id ? "0px 1px 59px 10px rgba(250, 220, 96, 0.8)" : "none"}
            className={isInstructionEnabled && shouldHighlighted ? "shake-vertical" : ""}
          >
            {() => (
              <Image
                src={
                  getImage(`items/${props.itemId}/images/${answer.id}.png`) ||
                  `${process.env.REACT_APP_FILES_URL}/items/${props.itemId}/images/${answer.id}.png`
                }
                width="100%"
                height="100%"
                objectFit="cover"
                borderRadius={10}
              />
            )}
          </ItemButton>
        ))}
      </Grid>
    </Col>
  );
}

SingleChoiceImageItemRender.propTypes = {
  itemId: PropTypes.string.isRequired,
};

export default function SingleChoiceImageItem() {
  const items = useSelector((state) => state.itemExecutions.allIds);

  return items.map((item) => <SingleChoiceImageItemRender key={`item-${item}`} itemId={item} />);
}
