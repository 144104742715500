import { delay, put, select, takeLatest } from "redux-saga/effects";
import { getFlowStart, getFlow } from "student-front-commons/src/selectors/flow";
import { endFlow } from "student-front-commons/src/actions/flow";
import { SYSTEM_MESSAGE_FLOW } from "consts";
import { logError } from "utils";

export default function* () {
  yield takeLatest(getFlowStart(SYSTEM_MESSAGE_FLOW), function* () {
    try {
      yield delay(3000);
    } catch (error) {
      logError({ error, flow: SYSTEM_MESSAGE_FLOW });
    } finally {
      const flow = yield select(getFlow(SYSTEM_MESSAGE_FLOW));
      if (flow.params && flow.params.type === "success") {
        yield put(endFlow(SYSTEM_MESSAGE_FLOW));
      }
    }
  });
}
