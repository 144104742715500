import React from "react";
import PropTypes from "prop-types";
import get from "lodash/get";
import snakeCase from "lodash/snakeCase";
import Entity from "student-front-commons/src/components/Entity";
import ThemeContext from "core/ThemeContext";
import ConnectingDotsIcon from "assets/connecting-dots-icon.png";
import MemoryGameIcon from "assets/memory-game-icon.png";
import PresentationIcon from "assets/presentation-icon.png";
import GrammarIcon from "assets/grammar-icon.png";
import MusicIcon from "assets/music-icon.png";
import PhonemeIcon from "assets/phoneme-icon.png";
import SingleChoiceGameIcon from "assets/single-choice-game-icon.png";
import VocabularyGameIcon from "assets/vocabulary-game-icon.png";
import VocabularyIcon from "assets/vocabulary-icon.png";
import TrueFalseIcon from "assets/true-false-icon.png";
import {
  CONNECTING_DOTS_PRIMARY,
  CONNECTING_DOTS_SECONDARY,
  GRAMMAR_PRIMARY,
  GRAMMAR_SECONDARY,
  GREY_3,
  GREY_4,
  MEMORY_GAME_PRIMARY,
  MEMORY_GAME_SECONDARY,
  MUSIC_PRIMARY,
  MUSIC_SECONDARY,
  PHONEME_PRIMARY,
  PRESENTATION_PRIMARY,
  PRESENTATION_SECONDARY,
  SINGLE_CHOICE_GAME_PRIMARY,
  SINGLE_CHOICE_GAME_SECONDARY,
  TRUE_FALSE_PRIMARY,
  TRUE_FALSE_SECONDARY,
  VOCABULARY_GAME_PRIMARY,
  VOCABULARY_GAME_SECONDARY,
  VOCABULARY_PRIMARY,
  VOCABULARY_SECONDARY,
  PHONEME_SECONDARY,
} from "consts/colors";

export default class UnitTypeThemeProvider extends React.PureComponent {
  static propTypes = {
    unitType: PropTypes.string.isRequired,
  };

  theme = {
    connecting_dots_game: {
      primary: CONNECTING_DOTS_PRIMARY,
      secondary: CONNECTING_DOTS_SECONDARY,
      radialGradient: "radial-gradient(circle at 50% 50%, #fbb8cf, #ec407a);",
      icon: ConnectingDotsIcon,
      isGame: true,
    },
    single_choice_game: {
      primary: SINGLE_CHOICE_GAME_PRIMARY,
      secondary: SINGLE_CHOICE_GAME_SECONDARY,
      radialGradient: "radial-gradient(circle at 50% 50%, #a8b5fb, #3f51b5);",
      icon: SingleChoiceGameIcon,
      isGame: true,
    },
    memory_game: {
      primary: MEMORY_GAME_PRIMARY,
      secondary: MEMORY_GAME_SECONDARY,
      radialGradient: "radial-gradient(circle at 50% 50%, #fed8c9, #e87142);",
      icon: MemoryGameIcon,
      isGame: true,
    },
    presentation_kids: {
      primary: PRESENTATION_PRIMARY,
      secondary: PRESENTATION_SECONDARY,
      radialGradient: "radial-gradient(circle at 50% 50%, #e1fecd, #558b2f);",
      icon: PresentationIcon,
      isGame: false,
    },
    grammar_kids: {
      primary: GRAMMAR_PRIMARY,
      secondary: GRAMMAR_SECONDARY,
      radialGradient: "radial-gradient(circle at 50% 50%, #b8dbfa, #3e99e9);",
      icon: GrammarIcon,
      isGame: false,
    },
    vocabulary_kids: {
      primary: VOCABULARY_PRIMARY,
      secondary: VOCABULARY_SECONDARY,
      radialGradient: "radial-gradient(circle at 50% 50%, #ccf4f9, #00bcd4);",
      icon: VocabularyIcon,
      isGame: false,
    },
    vocabulary_game: {
      primary: VOCABULARY_GAME_PRIMARY,
      secondary: VOCABULARY_GAME_SECONDARY,
      radialGradient: "radial-gradient(circle at 50% 50%, rgb(168,181,251), rgb(63,81,181));",
      icon: VocabularyGameIcon,
      isGame: true,
    },
    true_false_kids: {
      primary: TRUE_FALSE_PRIMARY,
      secondary: TRUE_FALSE_SECONDARY,
      radialGradient: "radial-gradient(circle at 50% 50%, #ffc7b3, #925038);",
      icon: TrueFalseIcon,
      isGame: false,
    },
    music_kids: {
      primary: MUSIC_PRIMARY,
      secondary: MUSIC_SECONDARY,
      radialGradient: "radial-gradient(circle at 50% 50%, #9ee6d9, #00897b);",
      icon: MusicIcon,
      isGame: false,
    },
    phoneme_kids: {
      primary: PHONEME_PRIMARY,
      secondary: PHONEME_SECONDARY,
      radialGradient: "radial-gradient(circle at 50% 50%, #fbcec8, #eb6e5d);",
      icon: PhonemeIcon,
      isGame: false,
    },
    default: {
      primary: GREY_3,
      secondary: GREY_4,
      radialGradient: "radial-gradient(circle at 50% 50%, #fee, #bbb);",
      icon: ConnectingDotsIcon,
      isGame: false,
    },
  };

  getTheme = (unitType) => get(this.theme, unitType, this.theme.default);

  normalizeType = (unitType) => snakeCase(unitType.name.toLowerCase());

  render() {
    return (
      <Entity key="unitTypeForProvider" name="unitType">
        {({ getById }) => (
          <ThemeContext.Provider
            value={this.getTheme(this.props.unitType ? this.normalizeType(getById(this.props.unitType)) : "")}
          >
            {this.props.children}
          </ThemeContext.Provider>
        )}
      </Entity>
    );
  }
}
