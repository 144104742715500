import { call, cancelled, put, race, select, take, takeLatest } from "redux-saga/effects";
import { endFlow, startFlow } from "student-front-commons/src/actions/flow";
import { getFlowStart } from "student-front-commons/src/selectors/flow";
import { addBreadcrumb, logError } from "utils";
import {
  CLOSE_MASTERY_TEST_EXECUTION_FLOW,
  CLOSE_UNIT_EXECUTION_FLOW,
  GET_NEXT_ITEM_EXECUTION_FLOW,
  SELECT_ITEM_FLOW,
} from "consts";
import { stopAudio } from "stores/audio-store";
import { getNextItemsToExecute } from "student-front-commons/src/selectors/execution";
import { startItemExecution } from "student-front-commons/src/actions/itemExecution";

export default function* () {
  yield takeLatest(getFlowStart(GET_NEXT_ITEM_EXECUTION_FLOW), function* () {
    yield race({
      closeUnit: take(getFlowStart(CLOSE_UNIT_EXECUTION_FLOW)),
      closeMasteryTest: take(getFlowStart(CLOSE_MASTERY_TEST_EXECUTION_FLOW)),
      call: call(function* () {
        try {
          const nextItems = yield select(getNextItemsToExecute);
          yield put(startItemExecution(nextItems));

          addBreadcrumb({
            category: "flow",
            message: `Got next items to execute`,
          });

          yield put(startFlow(SELECT_ITEM_FLOW, { initialSelect: true }));
        } catch (error) {
          logError({ error, flow: GET_NEXT_ITEM_EXECUTION_FLOW });
        } finally {
          if (yield cancelled()) {
            stopAudio();
          }
          yield put(endFlow(GET_NEXT_ITEM_EXECUTION_FLOW));
        }
      }),
    });
  });
}
