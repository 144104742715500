import React from "react";
import PropTypes from "prop-types";
import ControlBarButton from "core/ControlBarButton";
import { addSentryUserAction } from "../utils";

const sentryUserAction = { mainComponent: "ReadButton" };

const ReadButton = ({ onClick, isDisabled }) => (
  <ControlBarButton
    name="read"
    onClick={() => {
      onClick();
      addSentryUserAction({
        ...sentryUserAction,
        clickedComponent: "ControlBarButton",
        action: `Read Unit Item`,
      });
    }}
    isDisabled={isDisabled}
    isActive={false}
    isLoading={false}
  />
);

ReadButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool.isRequired,
};

export default ReadButton;
