import React from "react";
import PropTypes from "prop-types";
import Button from "core/Button";
import Icon from "core/Icon";
import Spinner from "core/Spinner";
import { GREY_3, GREY_4, WHITE } from "consts/colors";

const IconButton = ({ isDisabled, isLoading, onClick, size, iconSize, backgroundColor, iconColor, name, ...rest }) => (
  <Button
    isRounded={true}
    roundSize={size}
    isDisabled={isDisabled || isLoading}
    onClick={onClick}
    backgroundColor={backgroundColor}
    {...rest}
  >
    {isLoading ? <Spinner size="xs" /> : <Icon size={iconSize} name={name} color={isDisabled ? GREY_3 : iconColor} />}
  </Button>
);

IconButton.propTypes = {
  size: PropTypes.number,
  iconSize: PropTypes.number,
  onClick: PropTypes.func,
  isDisabled: PropTypes.bool,
  iconColor: PropTypes.string,
  backgroundColor: PropTypes.string,
  name: PropTypes.string.isRequired,
};

IconButton.defaultProps = {
  size: 60,
  iconSize: 30,
  isDisabled: false,
  onClick: null,
  backgroundColor: WHITE,
  iconColor: GREY_4,
};

export default IconButton;
