import React from "react";
import PropTypes from "prop-types";
import Col from "core/Col";
import Modal from "core/Modal";
import Separator from "core/Separator";
import IconButton from "core/IconButton";
import { BLACK_1, GREEN_1, WHITE } from "consts/colors";
import TranslatedText from "core/TranslatedText";

class UserPlayModal extends React.PureComponent {
  static propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onConfirm: PropTypes.func.isRequired,
  };

  render() {
    const { isOpen, onConfirm } = this.props;
    return (
      <Modal isOpen={isOpen}>
        <Col alignItems="center">
          <TranslatedText
            size={36}
            color={BLACK_1}
            boldType="extra-bold"
            textAlign="center"
            translateKey="userPlayModal.message"
          />
          <Separator size="lg" />
          <IconButton
            name="play"
            size={100}
            iconColor={WHITE}
            iconSize={64}
            backgroundColor={GREEN_1}
            onClick={onConfirm}
          />
        </Col>
      </Modal>
    );
  }
}

export default UserPlayModal;
