import React from "react";
import get from "lodash/get";
import Row from "core/Row";
import Tabs from "core/Tabs";
import AnimatedCard from "core/AnimatedCard";
import RankingIcon from "core/RankingIcon";
import RankingListContainer from "core/RankingListContainer";
import CurrentSchoolPositionContainer from "core/CurrentSchoolPositionContainer";
import ThemeContext from "core/ThemeContext";

const RankingCard = () => (
  <ThemeContext.Consumer>
    {(data) => (
      <AnimatedCard
        showRopes={true}
        paddingTop={70}
        ropesStyle={{
          backgroundColor: get(data, "primary", ""),
        }}
      >
        <Row flexShrink={0} justifyContent="center" width="100%" position="absolute" top={-20} left={0}>
          <RankingIcon />
        </Row>
        <CurrentSchoolPositionContainer />
        <Tabs tabs={["ranking.regional", "ranking.national"]}>
          {({ selectedTab }) => (
            <React.Fragment>
              {selectedTab === "ranking.regional" && <RankingListContainer level="regional" />}
              {selectedTab === "ranking.national" && <RankingListContainer level="national" />}
            </React.Fragment>
          )}
        </Tabs>
      </AnimatedCard>
    )}
  </ThemeContext.Consumer>
);

export default RankingCard;
