import React, { useState } from "react";
import orderBy from "lodash/orderBy";
import { getGrades } from "student-front-commons/src/services/gradesService";
import GradesList from "screens/grades/GradesList";
import { useEntity, useService } from "student-front-commons/src/hooks";
import { COMPANY_SCHEMA, PROFILE_SCHEMA, SCHOOL_CLASS_SCHEMA, SCHOOL_SCHEMA } from "student-front-commons/src/schemas";

function GradesListContainer() {
  const student = useEntity(PROFILE_SCHEMA, sessionStorage.getItem("id"));
  const schoolClass = useEntity(SCHOOL_CLASS_SCHEMA, student.schoolClass);
  const school = useEntity(SCHOOL_SCHEMA, schoolClass?.school);
  const company = useEntity(COMPANY_SCHEMA, student.company);

  const [data, setData] = useState([]);

  const [isFetching] = useService(getGrades, {
    autoStart: true,
    params: { id: sessionStorage.getItem("id") },
    onData: setData,
  });

  return (
    <GradesList
      isLoading={isFetching}
      periods={orderBy(data || [], ["start"], ["asc"]).filter((period) => period.type === "EVALUATION")}
      school={student.apiVersion === "V2" ? company : school}
    />
  );
}

export default GradesListContainer;
