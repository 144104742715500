import React from "react";
import PropTypes from "prop-types";
import isEqual from "lodash/isEqual";
import Col from "core/Col";
import Row from "core/Row";
import CardRope from "core/CardRope";
import { WHITE } from "consts/colors";

class Card extends React.Component {
  static propTypes = {
    showRopes: PropTypes.bool,
    children: PropTypes.node.isRequired,
  };

  static defaultProps = {
    showRopes: false,
  };

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    return !isEqual(this.props, nextProps);
  }

  render() {
    const { children, showRopes, ropesStyle, betweenRopesStyle, ...rest } = this.props;
    return (
      <Col
        position="relative"
        boxSizing="border-box"
        boxShadow="0px 1px 3px 0 rgba(0, 0, 0, 0.2);"
        backgroundColor={WHITE}
        borderRadius={20}
        flexShrink={0}
        zIndex={1}
        {...rest}
      >
        {showRopes && (
          <Row top={20} left={0} position="absolute" justifyContent="center" width="100%">
            <CardRope {...ropesStyle} />
            <Col width="33%" minWidth={180} {...betweenRopesStyle} />
            <CardRope {...ropesStyle} />
          </Row>
        )}
        {children}
      </Col>
    );
  }
}

export default Card;
