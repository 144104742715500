import React from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import Col from "core/Col";
import Row from "core/Row";
import Text from "core/Text";
import Image from "core/Image";
import Clickable from "core/Clickable";
import MasteryTestIcon from "assets/mastery-test-icon.png";
import MasteryTestIconDisabled from "assets/mastery-test-icon-disabled.png";
import MasteryTestIconFailed from "assets/mastery-test-icon-failed.png";
import MasteryTestIconApproved from "assets/mastery-test-icon-approved.png";
import { GREEN_1, GREY_10, GREY_11, RED_1, RED_2, WHITE } from "consts/colors";
import { useEntity } from "student-front-commons/src/hooks";
import { MASTERY_TEST_SCHEMA } from "student-front-commons/src/schemas";

function MasteryTestItem(props) {
  const history = useHistory();

  const masteryTest = useEntity(MASTERY_TEST_SCHEMA, props.masteryTestId);

  let status = "disabled";
  if (masteryTest.availableAt) {
    status = "available";
  }
  if (masteryTest.failedAt) {
    status = "failed";
  }
  if (masteryTest.approvedAt) {
    status = "approved";
  }

  return (
    <Row position="relative" flexShrink={0} justifyContent="center" height={170} width="100%" overflow="hidden">
      <Clickable
        isDisabled={status !== "available"}
        onClick={() => history.replace(`/modules/${masteryTest.module}/mastery-test/${masteryTest.id}/execution`)}
      >
        <React.Fragment>
          <Col
            position="absolute"
            width={320}
            height={320}
            top={40}
            backgroundColor={
              {
                disabled: GREY_10,
                available: RED_1,
                failed: RED_1,
                approved: WHITE,
              }[status]
            }
            borderRadius="100%"
          />
          <Image
            src={
              {
                disabled: MasteryTestIconDisabled,
                available: MasteryTestIcon,
                failed: MasteryTestIconFailed,
                approved: MasteryTestIconApproved,
              }[status]
            }
            width="auto"
            height={170}
            position="relative"
            bottom={-5}
            left={25}
            zIndex={1}
            transition="all .5s"
          />
          <Col
            backgroundColor={
              {
                disabled: GREY_11,
                available: RED_2,
                failed: RED_2,
                approved: GREEN_1,
              }[status]
            }
            height={30}
            width={120}
            justifyContent="center"
            alignItems="center"
            position="absolute"
            zIndex={2}
            borderRadius={15}
            bottom={15}
            boxShadow="0px 1px 3px 0 rgba(0, 0, 0, 0.2)"
          >
            <Text boldType="bold" size={14} color={WHITE}>
              MT
            </Text>
          </Col>
        </React.Fragment>
      </Clickable>
    </Row>
  );
}

MasteryTestItem.propTypes = {
  masteryTestId: PropTypes.string.isRequired,
};

export default MasteryTestItem;
