import React from "react";
import PropTypes from "prop-types";
import Col from "core/Col";
import Text from "core/Text";
import { GREY_7, WHITE, YELLOW_2 } from "consts/colors";
import TranslatedText from "core/TranslatedText";

const UnitResultGameScoreItem = ({ label, score, isWinner }) => (
  <Col
    justifyContent="center"
    alignItems="center"
    flexShrink={0}
    borderRadius={50}
    width={180}
    height={110}
    backgroundColor={isWinner ? YELLOW_2 : GREY_7}
  >
    <TranslatedText
      translateKey={label}
      boldType="light-bold"
      size={16}
      isUpperCase={true}
      color={WHITE}
      textAlign="center"
    />
    <Text boldType="light-bold" size={45} lineHeight="52px" isUpperCase={true} color={WHITE}>
      {score}
    </Text>
  </Col>
);

UnitResultGameScoreItem.propTypes = {
  label: PropTypes.string.isRequired,
  score: PropTypes.number.isRequired,
  isWinner: PropTypes.bool.isRequired,
};

export default UnitResultGameScoreItem;
