import { Howl } from "howler";
import sample from "lodash/sample";
import snakeCase from "lodash/snakeCase";
import { call, put, select, spawn, takeLatest } from "redux-saga/effects";
import { endFlow, startFlow } from "student-front-commons/src/actions/flow";
import { getFlow, getFlowStart } from "student-front-commons/src/selectors/flow";
import { getEntityById } from "student-front-commons/src/selectors/entity";
import { endUnitExecution } from "student-front-commons/src/services/unitExecutionService";
import { END_UNIT_EXECUTION_FLOW, ERROR_MODAL_FLOW } from "consts";
import { logError } from "utils";
import locales from "locales";
import get from "lodash/get";
import { endExecution } from "student-front-commons/src/actions/execution";
import { getModulesByCourse } from "student-front-commons/src/services/moduleService";
import { mergeEntities } from "student-front-commons/src/actions/entity";
import { MODULE_SCHEMA } from "student-front-commons/src/schemas";

let retryFlowCount = 1;

export default function* () {
  yield takeLatest(getFlowStart(END_UNIT_EXECUTION_FLOW), function* () {
    const flow = yield select(getFlow(END_UNIT_EXECUTION_FLOW));
    try {
      const id = sessionStorage.getItem("id");

      const result = yield call(endUnitExecution, {
        module: flow.params.module,
        unit: flow.params.unit,
        unitExecution: flow.params.execution,
      });

      yield put(endExecution(result));

      const module = yield select(getEntityById(MODULE_SCHEMA, flow.params.module));
      const courseDetails = yield call(getModulesByCourse, {
        id,
        course: module.course,
      });

      yield put(mergeEntities(courseDetails.entities));

      // audio effect
      yield spawn(function* () {
        let audioToPlay = null;
        if (result.points) {
          const unit = yield select(getEntityById("unit", flow.params.unit));
          const unitType = yield select(getEntityById("unitType", unit.type));

          if (
            ["connecting_dots_game", "single_choice_game", "memory_game", "vocabulary_game"].find(
              (type) => type === snakeCase(unitType.name.toLowerCase())
            )
          ) {
            audioToPlay = sample([
              require("assets/audio/game-score-win-1.mp3").default,
              require("assets/audio/game-score-win-2.mp3").default,
            ]);
          } else {
            audioToPlay = sample([
              require("assets/audio/unit-result-1.mp3").default,
              require("assets/audio/unit-result-2.mp3").default,
              require("assets/audio/unit-result-3.mp3").default,
              require("assets/audio/unit-result-4.mp3").default,
              require("assets/audio/unit-result-5.mp3").default,
            ]);
          }
        } else {
          audioToPlay = sample([
            require("assets/audio/game-score-fail-1.mp3").default,
            require("assets/audio/game-score-fail-2.mp3").default,
          ]);
        }

        const audio = new Howl({
          src: [audioToPlay],
          autoplay: false,
          loop: false,
          volume: 1,
        });
        audio.once("end", () => audio.unload());
        audio.play();
      });

      retryFlowCount = 1;
    } catch (error) {
      logError({ error, flow: END_UNIT_EXECUTION_FLOW });
      yield put(
        startFlow(ERROR_MODAL_FLOW, {
          message:
            retryFlowCount > 3
              ? get(locales, "unitExecution.error.endExecution.defaultMessage")
              : get(locales, "unitExecution.error.endExecution.tryAgain"),
          retryFlow: {
            id: END_UNIT_EXECUTION_FLOW,
            params: flow.params,
          },
          attemptCount: retryFlowCount,
        })
      );
      retryFlowCount = retryFlowCount + 1;
    } finally {
      yield put(endFlow(END_UNIT_EXECUTION_FLOW));
    }
  });
}
