import React from "react";

export default ({ color }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="32" viewBox="0 0 20 32">
    <path
      fill={color}
      d="M6.521 18.424c-.72 0-1.304-.651-1.304-1.455v-5.818c0-2.941 2.146-5.333 4.783-5.333 2.637 0 4.782 2.392 4.782 5.333 0 .804-.584 1.455-1.304 1.455-.721 0-1.304-.651-1.304-1.455 0-1.336-.976-2.424-2.174-2.424-1.199 0-2.174 1.088-2.174 2.424v4.614c1.518.601 2.608 2.217 2.608 4.114 0 2.406-1.755 4.363-3.913 4.363-.72 0-1.304-.651-1.304-1.454 0-.804.584-1.455 1.304-1.455.72 0 1.305-.652 1.305-1.454 0-.802-.585-1.455-1.305-1.455zm7.117 8.166a.143.143 0 0 0-.011.015c-.037.051-.905 1.26-1.897 2.489C9.759 31.537 8.99 32 8.261 32H4.782c-.72 0-1.304-.651-1.304-1.455 0-.803.584-1.454 1.304-1.454h3.245c.638-.49 2.299-2.542 3.557-4.295 1.621-2.328 5.807-9.294 5.807-13.645 0-3.89-3.161-8.242-7.391-8.242-4.076 0-7.392 3.697-7.392 8.242 0 .804-.583 1.455-1.304 1.455-.721 0-1.304-.651-1.304-1.455C0 5.002 4.486 0 10 0c2.631 0 5.148 1.234 7.087 3.475C18.938 5.613 20 8.411 20 11.151c0 6.246-6.102 15.066-6.362 15.439z"
    />
  </svg>
);
