import React, { useState } from "react";
import PropType from "prop-types";
import { getCurrentRankingByLevel } from "student-front-commons/src/services/rankingService";
import RankingList from "core/RankingList";
import { useService } from "student-front-commons/src/hooks";

function RankingListContainer({ level }) {
  const [data, setData] = useState([]);

  const [isLoading] = useService(getCurrentRankingByLevel, {
    autoStart: true,
    params: {
      id: sessionStorage.getItem("id"),
      size: 7,
      level: level,
    },
    onData: setData,
    onError: () => setData([]),
  });

  return <RankingList isLoading={isLoading} students={data} />;
}

RankingListContainer.propTypes = {
  level: PropType.oneOf(["regional", "national"]).isRequired,
};

export default RankingListContainer;
