import React from "react";
import PropTypes from "prop-types";
import WhitelabelContext from "student-front-commons/src/components/WhitelabelContext";
import Button from "core/Button";
import Spinner from "core/Spinner";
import Row from "core/Row";
import InBetweenSpacing from "core/InBetweenSpacing";
import TranslatedText from "core/TranslatedText";

export default class FileInput extends React.Component {
  static propTypes = {
    label: PropTypes.string.isRequired,
    onChange: PropTypes.func,
    isDisabled: PropTypes.bool,
    isLoading: PropTypes.bool,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(File)]),
  };

  static defaultProps = {
    onChange: null,
    value: "",
    isDisabled: false,
    isLoading: false,
  };

  render() {
    return (
      <WhitelabelContext.Consumer>
        {({ primaryColor, secondaryColor }) => (
          <Button
            backgroundColor={primaryColor}
            isDisabled={this.props.isDisabled || this.props.isLoading}
            onClick={() => this.fileInput.click()}
          >
            <input
              ref={(node) => {
                this.fileInput = node;
              }}
              type="file"
              accept=".png, .jpeg, .jpg"
              disabled={this.props.isDisabled}
              onChange={(event) => {
                if (event.target.files && event.target.files.length) {
                  this.props.onChange(event.target.files[0]);
                }
              }}
              style={{
                position: "absolute",
                visibility: "hidden",
                opacity: 0,
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
              }}
            />
            <Row alignItems="center" justifyContent="center">
              <InBetweenSpacing size="lg">
                {this.props.isLoading && <Spinner size="xs" />}
                <TranslatedText
                  translateKey={this.props.label}
                  color={secondaryColor}
                  boldType="bold"
                  size={16}
                  isUpperCase={true}
                />
              </InBetweenSpacing>
            </Row>
          </Button>
        )}
      </WhitelabelContext.Consumer>
    );
  }
}
