import React, { useContext, useEffect, useMemo } from "react";
import NavigationPrompt from "react-router-navigation-prompt";
import get from "lodash/get";
import Col from "core/Col";
import Row from "core/Row";
import Modal from "core/Modal";
import Button from "core/Button";
import Separator from "core/Separator";
import { BLACK_1, GREEN_1, RED_1, WHITE } from "consts/colors";
import { CLOSE_UNIT_EXECUTION_FLOW } from "consts";
import TranslatedText from "core/TranslatedText";
import { addSentryUserAction } from "../../utils";
import { useEntity, useFlow } from "student-front-commons/src/hooks";
import { useHistory } from "react-router-dom";
import WhitelabelContext from "student-front-commons/src/components/WhitelabelContext";
import { getCompanyTimeToAllowContinueUnit } from "student-front-commons/src/selectors/entity";
import { useSelector } from "react-redux";
import moment from "moment";
import { PROFILE_SCHEMA } from "student-front-commons/src/schemas";

const sentryUserAction = { mainComponent: "CloseUnitExecutionContainer" };

function CloseUnitExecutionContainer() {
  const history = useHistory();
  const [, start] = useFlow(CLOSE_UNIT_EXECUTION_FLOW, { autoStart: false });
  const whiteLabelContext = useContext(WhitelabelContext);
  const companyTimeToAllowContinueUnit = useSelector(getCompanyTimeToAllowContinueUnit);
  const profile = useEntity(PROFILE_SCHEMA, sessionStorage.getItem("id"));

  const timeToAllowContinueUnit = useMemo(() => {
    return moment
      .duration(companyTimeToAllowContinueUnit ?? whiteLabelContext.timeToAllowContinueUnit, "minutes")
      .locale(profile?.locale || "en")
      .humanize();
  }, [companyTimeToAllowContinueUnit, whiteLabelContext?.timeToAllowContinueUnit, profile?.locale]);

  useEffect(() => {
    const removeListener = history.listen((location, action) => {
      if (action === "POP") {
        start();
      }
    });
    return removeListener;
  }, [history, start]);

  return (
    <NavigationPrompt
      disableNative={true}
      renderIfNotActive={true}
      afterConfirm={start}
      when={(from, to, action) => {
        if (action === "POP") {
          return false;
        }
        if (get(to, "state.systemNavigation", false)) {
          return false;
        }
        return (
          !to ||
          !/\/modules\/[0-9a-fA-F]{24}\/units\/[0-9a-fA-F]{24}\/execution\/[0-9a-fA-F]{24}\/result/.test(to.pathname)
        );
      }}
    >
      {({ isActive, onCancel, onConfirm }) => (
        <Modal isOpen={isActive}>
          <Col alignItems="center">
            <TranslatedText translateKey="unitExecution.leave.title" size={36} color={RED_1} boldType="extra-bold" />
            <Separator size="lg" />
            <TranslatedText
              translateKey={
                timeToAllowContinueUnit
                  ? "unitExecution.leave.description"
                  : "unitExecution.leave.noProgressLossDescription"
              }
              values={{
                timeToAllowContinueUnit,
              }}
              size={14}
              color={BLACK_1}
              boldType="light-bold"
            />
            <Separator size="xxxl" />
            <Row>
              <Button
                backgroundColor={GREEN_1}
                onClick={() => {
                  onCancel();
                  addSentryUserAction({
                    ...sentryUserAction,
                    clickedComponent: "Button",
                    action: `Cancel Unit Execution back navigation`,
                  });
                }}
              >
                <TranslatedText translateKey="no" size={14} color={WHITE} boldType="bold" />
              </Button>
              <Separator size="lg" />
              <Button
                backgroundColor={RED_1}
                contentColor={WHITE}
                onClick={() => {
                  onConfirm();
                  addSentryUserAction({
                    ...sentryUserAction,
                    clickedComponent: "Button",
                    action: `Confirm Unit Execution back navigation`,
                  });
                }}
              >
                <TranslatedText translateKey="yes" size={14} color={WHITE} boldType="bold" />
              </Button>
            </Row>
          </Col>
        </Modal>
      )}
    </NavigationPrompt>
  );
}

export default CloseUnitExecutionContainer;
