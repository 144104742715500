import LogRocket from "logrocket";
import get from "lodash/get";
import { delay, call, cancelled, put, race, select, take, takeLatest } from "redux-saga/effects";
import { endFlow, startFlow } from "student-front-commons/src/actions/flow";
import { getFlowStart, getFlow } from "student-front-commons/src/selectors/flow";
import { playAudio, stopAudio } from "stores/audio-store";
import {
  CLOSE_MASTERY_TEST_EXECUTION_FLOW,
  CLOSE_UNIT_EXECUTION_FLOW,
  ERROR_MODAL_FLOW,
  PLAY_ITEM_AUDIO_FLOW,
  REQUIRE_USER_PLAY_FLOW,
} from "consts";
import { addSoundToItems, logError } from "utils";
import locales from "locales";
import { getItemExecutions, getSelectedId } from "student-front-commons/src/selectors/itemExecution";
import {
  disableItemExecution,
  enableItemExecution,
  finishPlayItem,
  finishPlayItemAccessory,
  finishPlayItemAnswer,
  startPlayItem,
  startPlayItemAccessory,
  startPlayItemAnswer,
} from "student-front-commons/src/actions/itemExecution";

const typesToPlayText = [
  "TRUE_FALSE_KIDS",
  "SINGLE_CHOICE_KIDS",
  "SINGLE_CHOICE_IMAGE",
  "CONNECTING_DOTS",
  "MEMORY_GAME",
  "VOCABULARY_GAME",
  "VOCABULARY",
  "PHONEME",
  "GAP_FILL_LETTER",
];

const typesToPlayAnswers = ["SINGLE_CHOICE_KIDS", "SINGLE_CHOICE_GAME"];

const typesToPlayPostPhrase = ["VOCABULARY", "PHONEME"];

const typesToPlayTitle = ["PHONEME"];

const typesToPlayInstructionBeforePostPhrase = ["VOCABULARY", "PHONEME"];

let retryFlowCount = 1;

export default function* () {
  yield takeLatest(getFlowStart(PLAY_ITEM_AUDIO_FLOW), function* () {
    yield race({
      closeUnit: take(getFlowStart(CLOSE_UNIT_EXECUTION_FLOW)),
      closeMasteryTest: take(getFlowStart(CLOSE_MASTERY_TEST_EXECUTION_FLOW)),
      call: call(function* () {
        const flow = yield select(getFlow(PLAY_ITEM_AUDIO_FLOW));
        try {
          const isInitialPlay = get(flow.params, "initialPlay", false);

          yield put(disableItemExecution());

          const itemExecutions = yield select(getItemExecutions);
          const selectedItem = yield select(getSelectedId);

          yield itemExecutions
            .filter((execution) => !selectedItem || execution.item.id === selectedItem)
            .reduce(function* (acc, execution) {
              yield acc;

              yield put(startPlayItem(execution.item.id, { isInitialPlay }));

              if (typesToPlayTitle.find((typeKey) => typeKey === execution.item.type.key)) {
                yield put(startPlayItemAccessory(execution.item.id, "title"));

                yield call(playAudio, {
                  url: execution.item.titleAudio || execution.item.generatedTitleAudio,
                  rate: 1,
                });

                yield delay(500);
                yield put(finishPlayItemAccessory(execution.item.id));
              }

              if (
                typesToPlayText.find((typeKey) => typeKey === execution.item.type.key) ||
                (!isInitialPlay && execution.item.type.key === "GAP_FILL_IMAGE")
              ) {
                //hack to set the playingId to item id
                yield put(startPlayItem(execution.item.id, { isInitialPlay: true }));

                yield call(playAudio, {
                  url: execution.item.audio || execution.item.generatedAudio,
                  rate: 1,
                });

                if (execution.item.type.key === "MEMORY_GAME") {
                  yield delay(1500);
                }
              }

              if (typesToPlayAnswers.find((typeKey) => typeKey === execution.item.type.key)) {
                yield delay(500);
                yield execution.item.linkedAnswers.reduce(function* (acc, answer, index) {
                  yield acc;
                  if (index) {
                    yield delay(500);
                  }

                  yield put(startPlayItemAnswer(execution.item.id, answer.id));
                  yield call(playAudio, {
                    url: answer.audio || answer.generatedAudio,
                    rate: 1,
                  });
                  yield put(finishPlayItemAnswer(execution.item.id, answer.id));
                }, Promise.resolve(0));
              }

              if (typesToPlayInstructionBeforePostPhrase.find((typeKey) => typeKey === execution.item.type.key)) {
                yield put(startPlayItemAccessory(execution.item.id, "instruction"));

                yield call(playAudio, {
                  url: execution.item.type.itemInstructionSound,
                  rate: 1,
                });

                yield put(finishPlayItemAccessory(execution.item.id));
              }

              if (
                typesToPlayPostPhrase.find((typeKey) => typeKey === execution.item.type.key) ||
                (!isInitialPlay && execution.item.type.key === "CONNECTING_DOTS")
              ) {
                yield put(startPlayItemAccessory(execution.item.id, "post-phrase"));

                yield call(playAudio, {
                  url: execution.item.postPhraseAudio || execution.item.generatedPostPhraseAudio,
                  rate: 1,
                });

                yield put(finishPlayItemAccessory(execution.item.id));
              }

              yield delay(500);
              yield put(finishPlayItem(execution.item.id, { isInitialPlay }));

              if (["CONNECTING_DOTS", "MEMORY_GAME"].find((typeKey) => typeKey === execution.item.type.key)) {
                yield put(disableItemExecution());
              }
            }, Promise.resolve());

          retryFlowCount = 1;
        } catch (error) {
          LogRocket.log(error);
          if (error === "required-user-click") {
            yield put(startFlow(REQUIRE_USER_PLAY_FLOW));
          } else {
            logError({ error, flow: PLAY_ITEM_AUDIO_FLOW });
            yield put(
              startFlow(ERROR_MODAL_FLOW, {
                message:
                  retryFlowCount > 3
                    ? get(locales, "unitExecution.error.playAudio.defaultMessage")
                    : get(locales, "unitExecution.error.playAudio.tryAgain"),
                retryFlow: {
                  id: PLAY_ITEM_AUDIO_FLOW,
                  params: flow.params,
                },
                attemptCount: retryFlowCount,
              })
            );
            retryFlowCount = retryFlowCount + 1;

            const itemExecutions = yield select(getItemExecutions);
            yield addSoundToItems(itemExecutions);
          }
        } finally {
          if (yield cancelled()) {
            stopAudio();
          }
          yield put(enableItemExecution());
          yield put(endFlow(PLAY_ITEM_AUDIO_FLOW));
        }
      }),
    });
  });
}
