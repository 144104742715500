import React from "react";
import { useParams } from "react-router-dom";
import { useForm, useService } from "student-front-commons/src/hooks";
import TranslatedText from "../../core/TranslatedText";
import Icon from "../../core/Icon";
import { GREY_4 } from "../../consts/colors";
import Separator from "../../core/Separator";
import withTranslation from "../../core/withTranslation";
import { saveUnitExecutionRating } from "student-front-commons/src/services/unitExecutionService";
import { isRequired } from "student-front-commons/src/validators";

const startRatingButtonStyle = {
  border: "none",
  background: "transparent",
  width: "40px",
  height: "40px",
  margin: 0,
  padding: 0,
  cursor: "pointer",
};

function RatingButton({ onClick, active }) {
  return (
    <button type="button" style={startRatingButtonStyle} onClick={onClick}>
      {active ? <Icon name="rating-star" /> : <Icon name="rating-star-outline" />}
    </button>
  );
}
const UnitResultRatingCard = ({ getTranslation }) => {
  const params = useParams();

  const [, sendRating] = useService(saveUnitExecutionRating, {
    autoStart: false,
  });

  const { getValue, setValue, submit } = useForm({
    initialValues: { score: 0 },
    validations: { score: [isRequired] },
    onSubmit: (values) => {
      sendRating({
        module: params.moduleId,
        unit: params.unitId,
        unitExecution: params.executionId,
        question: getTranslation("unitRating.question"),
        ...values,
      });
    },
  });

  const handleScoreChange = (score) => {
    setValue("score", score);
    submit();
  };
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <TranslatedText translateKey="unitRating.question" color={GREY_4} size={14} boldType="bold" isUpperCase={true} />
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          margin: "5px 0",
        }}
      >
        <RatingButton onClick={() => handleScoreChange(1)} active={getValue("score") >= 1} />
        <RatingButton onClick={() => handleScoreChange(2)} active={getValue("score") >= 2} />
        <RatingButton onClick={() => handleScoreChange(3)} active={getValue("score") >= 3} />
        <RatingButton onClick={() => handleScoreChange(4)} active={getValue("score") >= 4} />
        <RatingButton onClick={() => handleScoreChange(5)} active={getValue("score") >= 5} />
      </div>
      <Separator size="md" />
      <TranslatedText translateKey="unitRating.comment" color={GREY_4} size={14} boldType="bold" isUpperCase={true} />
      <Separator size="xs" />
      <textarea
        onChange={(event) => setValue("comment", event.target.value)}
        onBlur={submit}
        value={getValue("comment")}
        rows={4}
        style={{
          fontSize: 16,
          width: 320,
          border: "1px solid #d7d7d7",
          borderRadius: 25,
          resize: "none",
          outline: "none",
          boxShadow: "0px 1px 3px 0 rgba(0, 0, 0, 0.2);",
          padding: "12px 30px",
          fontFamily: "Baloo 2",
          color: GREY_4,
        }}
      />
    </div>
  );
};

export default withTranslation(UnitResultRatingCard);
