import React, { useContext } from "react";
import Row from "core/Row";
import Col from "core/Col";
import Text from "core/Text";
import Image from "core/Image";
import Separator from "core/Separator";
import InBetweenSpacing from "core/InBetweenSpacing";
import Card from "core/Card";
import Icon from "core/Icon";
import ItemButton from "core/ItemButton";
import UnitTypeThemeContext from "core/ThemeContext";
import { YELLOW_4 } from "consts/colors";
import { Flex } from "jsxstyle";
import MediaQuery from "react-responsive";
import { getImage } from "stores/image-store";
import { playAudio } from "stores/audio-store";
import { useSelector } from "react-redux";
import { getItemExecutionPropById } from "student-front-commons/src/selectors/itemExecution";

function SingleChoiceGameItemRender(props) {
  const unitTypeThemeContext = useContext(UnitTypeThemeContext);

  const itemId = useSelector(getItemExecutionPropById(props.itemId, "item.id"));
  const itemLinkedAnswers = useSelector(getItemExecutionPropById(props.itemId, "item.linkedAnswers"));
  const isDisabled = useSelector(getItemExecutionPropById(props.itemId, "isDisabled"));
  const playingId = useSelector(getItemExecutionPropById(props.itemId, "playingId"));
  const showCorrectOption = useSelector(getItemExecutionPropById(props.itemId, "showCorrectOption"));
  const userAnswer = useSelector(getItemExecutionPropById(props.itemId, "answer"));

  const getAnswerStatus = (answer) => {
    if (playingId === answer.id) {
      return "outline";
    }
    if (showCorrectOption && answer.correct) {
      return "outline";
    }
    if (userAnswer === answer.text) {
      return "active";
    }
    return "normal";
  };

  return (
    <Col alignItems="center">
      <Card
        padding={20}
        showRopes={true}
        ropesStyle={{
          backgroundColor: unitTypeThemeContext.secondary,
        }}
        betweenRopesStyle={{
          width: "70%",
        }}
      >
        <Image
          src={
            getImage(`items/${itemId}/images/lg.png`) ||
            `${process.env.REACT_APP_FILES_URL}/items/${itemId}/images/lg.png`
          }
          width="100%"
          height="auto"
          borderRadius={10}
        />
      </Card>
      <Separator size="xl" />
      <Separator size="xxl" />
      <MediaQuery orientation="landscape">
        {(matches) => (
          <Flex flexDirection={matches ? "row" : "column"} flexWrap="wrap" justifyContent="center">
            <InBetweenSpacing size={matches ? "xxl" : "xl"}>
              {itemLinkedAnswers.map((answer) => (
                <ItemButton
                  key={`answer-${answer.id}`}
                  status={getAnswerStatus(answer)}
                  isDisabled={isDisabled}
                  onClick={() =>
                    playAudio({
                      url: answer.audio || answer.generatedAudio,
                      rate: 1,
                    })
                  }
                  height={50}
                  borderRadius={25}
                  padding="0px 20px"
                  transition="all .3s"
                  marginBottom={10}
                  hoverTransform={isDisabled ? "none" : "scale(1.2)"}
                >
                  {({ status }) => (
                    <Row alignItems="center">
                      <Icon
                        name="repeat"
                        color={
                          {
                            active: YELLOW_4,
                            outline: unitTypeThemeContext.primary,
                            normal: unitTypeThemeContext.primary,
                          }[status]
                        }
                      />
                      <Separator size="md" />
                      <Text
                        boldType="bold"
                        size={18}
                        color={
                          {
                            active: YELLOW_4,
                            outline: unitTypeThemeContext.primary,
                            normal: unitTypeThemeContext.primary,
                          }[status]
                        }
                      >
                        {answer.text}
                      </Text>
                    </Row>
                  )}
                </ItemButton>
              ))}
            </InBetweenSpacing>
          </Flex>
        )}
      </MediaQuery>
      <Separator size="xxl" />
    </Col>
  );
}

export default function SingleChoiceGameItem() {
  const items = useSelector((state) => state.itemExecutions.allIds);

  return items.map((item) => <SingleChoiceGameItemRender key={`item-${item}`} itemId={item} />);
}
