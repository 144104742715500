import React from "react";
import { withRouter } from "react-router-dom";
import WhitelabelContext from "student-front-commons/src/components/WhitelabelContext";
import Lottie from "react-lottie";
import PublicScreen from "core/PublicScreen";
import Card from "core/Card";
import Col from "core/Col";
import Row from "core/Row";
import NotFoundIcon from "assets/lottie/404-icon";
import Separator from "core/Separator";
import { GREY_4 } from "consts/colors";
import Button from "core/Button";
import ScreenContainer from "core/ScreenContainer";
import { MD_SIZE } from "utils";
import TranslatedText from "core/TranslatedText";

const NotFoundScreen = ({ history }) => (
  <WhitelabelContext.Consumer>
    {({ primaryColor, secondaryColor }) => (
      <PublicScreen>
        <ScreenContainer>
          <Col height="100vh" justifyContent="center" alignItems="center">
            <Card showRopes={true} padding={50}>
              <Row
                alignItems="center"
                mediaQueries={{
                  sm: `(max-width: ${MD_SIZE}px)`,
                  md: `(min-width: ${MD_SIZE}px)`,
                }}
                smFlexDirection="column"
                mdFlexDirection="row"
              >
                <Lottie
                  options={{
                    loop: true,
                    autoplay: true,
                    animationData: NotFoundIcon,
                    rendererSettings: {
                      preserveAspectRatio: "xMidYMid slice",
                    },
                  }}
                  height={360}
                  width={360}
                />
                <Separator size="xxxl" />
                <Col alignItems="center">
                  <TranslatedText
                    translateKey={
                      history.location.pathname === "/no-course-found" ? "notFound.noCourse" : "notFound.default"
                    }
                    boldType="extra-bold"
                    size={44}
                    color={GREY_4}
                    align="center"
                  />
                  <Separator size="xl" />
                  <Button backgroundColor={primaryColor} onClick={() => history.push("/")}>
                    <TranslatedText
                      translateKey="notFound.button.back"
                      boldType="extra-bold"
                      size={18}
                      color={secondaryColor}
                    />
                  </Button>
                </Col>
              </Row>
            </Card>
          </Col>
        </ScreenContainer>
      </PublicScreen>
    )}
  </WhitelabelContext.Consumer>
);

export default withRouter(NotFoundScreen);
