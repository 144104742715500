import React from "react";
import PropTypes from "prop-types";
import Icon from "core/Icon";
import AvailableIcon from "assets/unit-status-available-icon.png";
import CompletedIcon from "assets/unit-status-completed-icon.png";
import { GREY_7 } from "consts/colors";
import Image from "core/Image";

const UnitStatus = ({ status }) => {
  if (status === "BLOCKED") {
    return <Icon name="lock" color={GREY_7} size={18} />;
  }
  return (
    <Image
      src={
        {
          AVAILABLE: AvailableIcon,
          COMPLETED: CompletedIcon,
        }[status]
      }
      width="auto"
      height={17}
    />
  );
};

UnitStatus.propTypes = {
  status: PropTypes.oneOf(["BLOCKED", "AVAILABLE", "COMPLETED"]).isRequired,
};

export default UnitStatus;
