import React from "react";
import PropTypes from "prop-types";
import Row from "core/Row";
import Col from "core/Col";
import Text from "core/Text";
import Modal from "core/Modal";
import Separator from "core/Separator";
import Button from "core/Button";
import { BLACK_1, GREEN_1, RED_1, WHITE } from "consts/colors";
import TranslatedText from "core/TranslatedText";

class ConfirmModal extends React.PureComponent {
  static propTypes = {
    isOpen: PropTypes.bool.isRequired,
    message: PropTypes.string.isRequired,
    onCancel: PropTypes.func.isRequired,
    onConfirm: PropTypes.func.isRequired,
    type: PropTypes.oneOf(["confirm", "alert"]).isRequired,
  };

  render() {
    const { isOpen, message, type, onConfirm, onCancel } = this.props;
    return (
      <Modal isOpen={isOpen}>
        <Col alignItems="center">
          <TranslatedText translateKey="attention" size={36} color={RED_1} boldType="extra-bold" />
          <Separator size="lg" />
          <Text size={14} color={BLACK_1} boldType="light-bold" align="center">
            {message}
          </Text>
          <Separator size="xxxl" />
          {type === "confirm" && (
            <Row>
              <Button backgroundColor={GREEN_1} onClick={onCancel}>
                <TranslatedText translateKey="no" size={14} color={WHITE} boldType="bold" />
              </Button>
              <Separator size="lg" />
              <Button backgroundColor={RED_1} contentColor={WHITE} onClick={onConfirm}>
                <TranslatedText translateKey="yes" size={14} color={WHITE} boldType="bold" />
              </Button>
            </Row>
          )}
          {type === "alert" && (
            <Row>
              <Button backgroundColor={GREEN_1} contentColor={WHITE} onClick={onConfirm}>
                <Text size={14} color={WHITE} boldType="bold">
                  Ok
                </Text>
              </Button>
            </Row>
          )}
        </Col>
      </Modal>
    );
  }
}

export default ConfirmModal;
