import React, { useContext } from "react";
import Col from "core/Col";
import Logo from "core/Logo";
import Card from "core/Card";
import Separator from "core/Separator";
import PublicScreen from "core/PublicScreen";
import ScreenContainer from "core/ScreenContainer";
import LoginForm from "./LoginForm";
import { SM_SIZE } from "utils";
import LoginMessageHandler from "./LoginMessageHandler";
import WhitelabelContext from "student-front-commons/src/components/WhitelabelContext";

function LoginScreen() {
  const whitelabelContext = useContext(WhitelabelContext);

  return (
    <PublicScreen>
      <ScreenContainer>
        <Col height="100vh" justifyContent="center" margin="0 auto">
          <Card
            mediaQueries={{
              sm: `(max-width: ${SM_SIZE}px)`,
              md: `(min-width: ${SM_SIZE}px)`,
            }}
            smPadding="80px 40px"
            mdPadding={80}
            showRopes={true}
            maxWidth={520}
            width="auto"
            animation="bounce-in-top 0.8s both"
            zIndex={2}
            ropesStyle={{
              backgroundColor: whitelabelContext.primaryColor,
              opacity: 0.5,
            }}
            className="user-report-error-catcher"
          >
            <Col width="75%" margin="0 auto">
              <Logo />
            </Col>
            <Separator size="xxl" />
            <Separator size="xxl" />
            <LoginForm />
          </Card>
          <Separator size="xxl" />
          <LoginMessageHandler />
        </Col>
      </ScreenContainer>
    </PublicScreen>
  );
}

export default LoginScreen;
