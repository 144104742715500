import { takeLatest, call, put } from "redux-saga/effects";
import { Howler } from "howler";
import LogRocket from "logrocket";
import { endFlow } from "student-front-commons/src/actions/flow";
import { cleanEntities } from "student-front-commons/src/actions/entity";
import { getFlowStart } from "student-front-commons/src/selectors/flow";
import browserHistory from "browserHistory";
import { LOGOUT_FLOW } from "consts";
import { hideMessage } from "student-front-commons/src/actions/systemMessage";

export default function* () {
  yield takeLatest(getFlowStart(LOGOUT_FLOW), function* () {
    try {
      LogRocket.startNewSession();
      sessionStorage.clear();
      Howler.unload();
      yield call(browserHistory.push, "/login");
      yield put(cleanEntities());
      yield put(hideMessage());
    } finally {
      yield put(endFlow(LOGOUT_FLOW));
    }
  });
}
