import React, { useCallback, useContext, useMemo } from "react";
import get from "lodash/get";
import head from "lodash/head";
import omitBy from "lodash/omitBy";
import orderBy from "lodash/orderBy";
import values from "lodash/values";
import { Flex } from "jsxstyle";
import MediaQuery from "react-responsive";
import Col from "core/Col";
import Row from "core/Row";
import Card from "core/Card";
import Text from "core/Text";
import Separator from "core/Separator";
import InBetweenSpacing from "core/InBetweenSpacing";
import UnitTypeThemeContext from "core/ThemeContext";
import { GREY_2, WHITE } from "consts/colors";
import { useDispatch, useSelector } from "react-redux";
import { useFlow } from "student-front-commons/src/hooks";
import { CHECK_ITEM_ANSWER_FLOW } from "../../consts";
import { getItemExecutionPropById } from "student-front-commons/src/selectors/itemExecution";
import {
  isInstructionEnableForStep,
  isInstructionHighlighted,
} from "student-front-commons/src/selectors/itemInstruction";
import { addItemExecutionAnswer } from "student-front-commons/src/actions/itemExecution";
import Icon from "../Icon";

function UnscrambleItemRender(props) {
  const dispatch = useDispatch();
  const unitTypeThemeContext = useContext(UnitTypeThemeContext);

  const [, startCheckFlow] = useFlow(CHECK_ITEM_ANSWER_FLOW);

  const itemText = useSelector(getItemExecutionPropById(props.itemId, "item.text"));
  const itemAnswers = useSelector(getItemExecutionPropById(props.itemId, "item.answers"));
  const itemLinkedAnswers = useSelector(getItemExecutionPropById(props.itemId, "item.linkedAnswers"));
  const isDisabled = useSelector(getItemExecutionPropById(props.itemId, "isDisabled"));
  const showCorrectOption = useSelector(getItemExecutionPropById(props.itemId, "showCorrectOption"));
  const userAnswerChunks = useSelector(getItemExecutionPropById(props.itemId, "extraData.chunks"));

  const isInstructionEnabled = useSelector(isInstructionEnableForStep("user-away"));
  const shouldHighlighted = useSelector(isInstructionHighlighted);

  const availableChunks = useMemo(
    () => itemLinkedAnswers.filter((answer) => answer && answer.index >= 0).map((answer) => answer.index),
    [itemLinkedAnswers]
  );

  const getUserAnswerByChunks = useCallback(
    (newChunks) => {
      return itemText
        .split(" ")
        .reduce((acc, slice, sliceIndex) => {
          const isAnswerIndex = itemLinkedAnswers.some((answer) => answer.index === sliceIndex);
          if (isAnswerIndex) {
            const chunk = get(newChunks, sliceIndex, { text: "-" });
            return acc.concat(chunk.text).concat(" ");
          }
          if (!itemAnswers.some((answer) => answer.index === sliceIndex)) {
            return acc.concat(slice).concat(" ");
          }
          return acc;
        }, "")
        .trim();
    },
    [itemText, itemLinkedAnswers, itemAnswers]
  );

  const handleAddAnswer = useCallback(
    (answer) => {
      const firstAvailableChunk = head(
        orderBy(availableChunks.filter((index) => !get(userAnswerChunks, index.toString(), false)))
      );

      if (firstAvailableChunk === null || firstAvailableChunk === undefined) {
        return;
      }

      const chunks = {
        ...(userAnswerChunks || {}),
        [firstAvailableChunk.toString()]: answer,
      };
      const newAnswer = getUserAnswerByChunks(chunks);

      dispatch(addItemExecutionAnswer(props.itemId, { answer: newAnswer, extraData: { chunks } }));
      if (availableChunks.length === Object.keys(chunks).length) {
        setTimeout(() => {
          startCheckFlow();
        }, 0);
      }
    },
    [props.itemId, userAnswerChunks, availableChunks, getUserAnswerByChunks, dispatch, startCheckFlow]
  );

  const handleRemoveAnswer = useCallback(
    (answerIndex) => {
      const chunks = omitBy(userAnswerChunks, (value, key) => key.toString() === answerIndex.toString());
      const newAnswer = getUserAnswerByChunks(chunks);

      dispatch(addItemExecutionAnswer(props.itemId, { answer: newAnswer, extraData: { chunks } }));
    },
    [props.itemId, userAnswerChunks, getUserAnswerByChunks, dispatch]
  );

  const isSelected = (value) => values(userAnswerChunks || {}).some((x) => x.id === value.id);

  return (
    <Col alignItems="center">
      <Card
        alignItems="center"
        padding="50px 40px"
        showRopes={true}
        ropesStyle={{
          backgroundColor: unitTypeThemeContext.secondary,
        }}
        betweenRopesStyle={{
          width: "70%",
        }}
      >
        <Row alignItems="center" flexWrap="wrap">
          <InBetweenSpacing size="sm">
            {itemText.split(" ").map((slice, index) => {
              const foundAnswer = itemLinkedAnswers.find((answer) => answer.index === index);
              if (foundAnswer) {
                const indexAnswer = get(userAnswerChunks, index, false);
                return (
                  <React.Fragment key={`drop-${index}`}>
                    <Col
                      backgroundColor={isDisabled ? GREY_2 : WHITE}
                      padding="0px 15px"
                      width="auto"
                      minWidth={100}
                      height={60}
                      borderWidth={2}
                      borderStyle={indexAnswer ? "solid" : "dashed"}
                      borderColor={unitTypeThemeContext.primary}
                      borderRadius={5}
                      boxSizing="border-box"
                      transition="all .3s"
                      hoverTransform={!isDisabled && indexAnswer !== null ? "scale(1.1)" : "none"}
                      cursor={isDisabled ? "not-allowed" : "pointer"}
                      role="button"
                      props={{
                        disabled: isDisabled,
                        onClick:
                          indexAnswer !== null && !isDisabled ? () => handleRemoveAnswer(foundAnswer.index) : null,
                      }}
                    >
                      {(indexAnswer || showCorrectOption) && (
                        <Row alignItems="center">
                          <Text boldType="light-bold" align="center" size={36} color={unitTypeThemeContext.primary}>
                            {(showCorrectOption ? foundAnswer : indexAnswer).text.replace(/[!.?,]$/, "")}
                          </Text>
                          <Separator size="md" />
                          <Icon name="close" size={24} color={unitTypeThemeContext.primary} />
                        </Row>
                      )}
                    </Col>
                    {/[!.?,]$/.test(slice) && (
                      <Text boldType="light-bold" size={42} color={unitTypeThemeContext.primary} align="center">
                        {slice.substring(slice.length - 1)}
                      </Text>
                    )}
                  </React.Fragment>
                );
              }
              return (
                <Text
                  key={`${slice}-${index}`}
                  boldType="light-bold"
                  size={36}
                  color={unitTypeThemeContext.primary}
                  align="center"
                >
                  {slice}
                </Text>
              );
            })}
          </InBetweenSpacing>
        </Row>
      </Card>
      <Separator size="xxl" />
      <Separator size="xxl" />
      <MediaQuery orientation="landscape">
        {(matches) => (
          <Flex
            flexDirection={matches ? "row" : "column"}
            alignItems="center"
            boxSizing="border-box"
            padding="40px 20px"
            borderRadius={10}
            transition="all .3s"
          >
            <InBetweenSpacing size="xl">
              {itemLinkedAnswers.map((availableAnswer) => {
                if (isSelected(availableAnswer)) {
                  return (
                    <Col
                      key={`answered-${availableAnswer.id}`}
                      backgroundColor="transparent"
                      padding="0px 15px"
                      minWidth={100}
                    >
                      <Text boldType="light-bold" align="center" size={36} color="transparent">
                        {availableAnswer.text.replace(/[!.?,]$/, "")}
                      </Text>
                    </Col>
                  );
                }
                return (
                  <Col
                    key={`answer-${availableAnswer.id}`}
                    backgroundColor={isDisabled ? GREY_2 : WHITE}
                    padding="0px 15px"
                    minWidth={100}
                    borderWidth={2}
                    borderRadius={5}
                    boxSizing="border-box"
                    transition="all .3s"
                    hoverTransform={isDisabled ? "none" : "scale(1.1)"}
                    cursor={isDisabled ? "not-allowed" : "pointer"}
                    role="button"
                    props={{
                      disabled: isDisabled,
                      onClick: () => handleAddAnswer(availableAnswer),
                    }}
                    className={isInstructionEnabled && shouldHighlighted ? "shake-vertical" : ""}
                  >
                    <Text boldType="light-bold" align="center" size={36} color={unitTypeThemeContext.primary}>
                      {availableAnswer.text.replace(/[!.?,]$/, "")}
                    </Text>
                  </Col>
                );
              })}
            </InBetweenSpacing>
          </Flex>
        )}
      </MediaQuery>
      <Separator size="xxl" />
    </Col>
  );
}

function UnscrambleItem() {
  const items = useSelector((state) => state.itemExecutions.allIds);

  return items.map((item) => <UnscrambleItemRender key={`item-${item}`} itemId={item} />);
}

export default UnscrambleItem;
