import React from "react";
import PropTypes from "prop-types";
import platform from "platform";
import Lottie from "react-lottie";
import Row from "core/Row";
import Col from "core/Col";
import Text from "core/Text";
import Modal from "core/Modal";
import Separator from "core/Separator";
import Button from "core/Button";
import MicIcon from "assets/lottie/mic";
import { BLACK_1, GREEN_1, WHITE, LINK } from "consts/colors";
import TranslatedText from "core/TranslatedText";

const MicrophoneErrorModal = ({ isOpen, message, type }) => (
  <Modal isOpen={isOpen}>
    <Row alignItems="center" justifyContent="center" flexWrap="wrap">
      <Lottie
        options={{
          loop: true,
          autoplay: true,
          animationData: MicIcon,
          rendererSettings: {
            preserveAspectRatio: "xMidYMid slice",
          },
        }}
        width={240}
        height={240}
      />
      <Col alignItems="center" justifyContent="center">
        <TranslatedText translateKey="microphoneErrorModal.title" size={18} color={BLACK_1} boldType="extra-bold" />
        <Separator size="md" />
        <Text size={14} color={BLACK_1} boldType="light-bold" align="center">
          {message}
        </Text>
        <Separator size="xs" />
        {type === "permission" && (
          <React.Fragment>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={
                {
                  "Chrome Mobile":
                    "https://support.google.com/chrome/answer/114662?co=GENIE.Platform%3DAndroid&hl=pt-BR",
                  Chrome: "https://support.google.com/chrome/answer/114662?co=GENIE.Platform%3DAndroid&hl=pt-BR",
                  Safari: "https://support.apple.com/pt-br/guide/safari/ibrw7f78f7fe/12.0/mac/10.14",
                }[platform.name]
              }
              style={{
                color: LINK,
              }}
            >
              <TranslatedText
                translateKey="microphoneErrorModal.permission.message"
                size={14}
                color={LINK}
                boldType="light-bold"
                align="center"
              />
            </a>
            <Separator size="xxl" />
            <Button backgroundColor={GREEN_1} contentColor={WHITE} onClick={() => window.location.reload(true)}>
              <TranslatedText
                translateKey="microphoneErrorModal.permission.button"
                size={14}
                color={WHITE}
                boldType="bold"
              />
            </Button>
          </React.Fragment>
        )}
        {type === "update" && (
          <React.Fragment>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={
                {
                  "Chrome Mobile":
                    "https://support.google.com/chrome/answer/95414?co=GENIE.Platform%3DDesktop&hl=pt-BR",
                  Chrome: "https://support.google.com/chrome/answer/95414?co=GENIE.Platform%3DDesktop&hl=pt-BR",
                  Safari: "https://support.apple.com/pt-br/HT204204",
                }[platform.name]
              }
              style={{
                color: LINK,
              }}
            >
              <TranslatedText
                translateKey="microphoneErrorModal.update.message"
                size={14}
                color={LINK}
                boldType="light-bold"
                align="center"
              />
            </a>
            <Separator size="xxl" />
            <Button backgroundColor={GREEN_1} contentColor={WHITE} onClick={() => window.location.reload(true)}>
              <TranslatedText
                translateKey="microphoneErrorModal.update.button"
                size={14}
                color={WHITE}
                boldType="bold"
              />
            </Button>
          </React.Fragment>
        )}
        {type === "general" && (
          <React.Fragment>
            <Separator size="lg" />
            <Button backgroundColor={GREEN_1} contentColor={WHITE} onClick={() => window.location.reload(true)}>
              <TranslatedText
                translateKey="microphoneErrorModal.general.button"
                size={14}
                color={WHITE}
                boldType="bold"
              />
            </Button>
          </React.Fragment>
        )}
      </Col>
    </Row>
    <Separator size="md" />
  </Modal>
);

MicrophoneErrorModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  message: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
};

export default MicrophoneErrorModal;
