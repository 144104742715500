import React from "react";
import PropTypes from "prop-types";
import { Howl } from "howler";
import sample from "lodash/sample";
import Clickable from "core/Clickable";

const ClickableSound = ({ onClick, ...rest }) => (
  <Clickable
    {...rest}
    onClick={async () => {
      onClick();
      const audio = new Howl({
        src: [
          sample([
            require("assets/audio/transition/transition-1.mp3").default,
            require("assets/audio/transition/transition-2.mp3").default,
            require("assets/audio/transition/transition-3.mp3").default,
          ]),
        ],
        autoplay: false,
        loop: false,
        volume: 1,
      });
      await new Promise((resolve) => {
        audio.once("end", () => {
          audio.unload();
          resolve();
        });
        audio.play();
      });
    }}
  />
);

ClickableSound.propTypes = {
  onClick: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
};

export default ClickableSound;
