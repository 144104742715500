import { put, takeLatest } from "redux-saga/effects";
import { endFlow } from "student-front-commons/src/actions/flow";
import { getFlowStart } from "student-front-commons/src/selectors/flow";
import { clearAudios } from "../stores/audio-store";
import { clearImages } from "../stores/image-store";
import { CLOSE_MASTERY_TEST_EXECUTION_FLOW, ERROR_MODAL_FLOW, REQUIRE_USER_PLAY_FLOW } from "../consts";
import { cleanExecution } from "student-front-commons/src/actions/execution";
import { logError } from "../utils";

export default function* () {
  yield takeLatest(getFlowStart(CLOSE_MASTERY_TEST_EXECUTION_FLOW), function* () {
    try {
      // const masteryTestExecutionForm = yield select(getForm(MASTERY_TEST_EXECUTION_FORM));
      // if (masteryTestExecutionForm && masteryTestExecutionForm.values) {
      //   yield call(setMasteryTestExecutionAsFailed, {
      //     module: masteryTestExecutionForm.values.module,
      //     masteryTest: masteryTestExecutionForm.values.masteryTest,
      //     masteryTestExecution: masteryTestExecutionForm.values.execution,
      //   });
      //
      //   const masteryTest = yield select(getEntityById("masteryTest", masteryTestExecutionForm.values.masteryTest));
      //   masteryTest.failedAt = moment().format();
      //   yield put(addEntity("masteryTest", masteryTest));
      // }

      clearAudios();
      clearImages();

      yield put(cleanExecution());

      if (window.jsPlumb) {
        window.jsPlumb.deleteEveryConnection();
      }
      yield put(endFlow(REQUIRE_USER_PLAY_FLOW));
      yield put(endFlow(ERROR_MODAL_FLOW));
    } catch (error) {
      logError({ error, flow: CLOSE_MASTERY_TEST_EXECUTION_FLOW });
    } finally {
      yield put(endFlow(CLOSE_MASTERY_TEST_EXECUTION_FLOW));
    }
  });
}
