import { all, call, put, select, takeLatest } from "redux-saga/effects";
import { endFlow } from "student-front-commons/src/actions/flow";
import { mergeEntities } from "student-front-commons/src/actions/entity";
import { getFlowStart, getFlow } from "student-front-commons/src/selectors/flow";
import { getModulesByCourse } from "student-front-commons/src/services/moduleService";
import { LOAD_MODULES_FLOW } from "consts";
import { insertImage } from "stores/image-store";
import { logError } from "utils";

export default function* () {
  yield takeLatest(getFlowStart(LOAD_MODULES_FLOW), function* () {
    try {
      const id = sessionStorage.getItem("id");
      const flow = yield select(getFlow(LOAD_MODULES_FLOW));

      const modules = yield call(getModulesByCourse, {
        id,
        course: flow.params.course,
      });

      // pre loading transition icon
      yield all(
        modules.result.map(function* (entityId) {
          const moduleEntity = modules.entities.module[entityId];
          if (moduleEntity && moduleEntity.backgroundUrl) {
            yield insertImage({ url: moduleEntity.backgroundUrl });
          }
        })
      );

      yield put(mergeEntities(modules.entities));
    } catch (error) {
      logError({ error, flow: LOAD_MODULES_FLOW });
    } finally {
      yield put(endFlow(LOAD_MODULES_FLOW));
    }
  });
}
