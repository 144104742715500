import React from "react";
import PropTypes from "prop-types";
import WhitelabelContext from "student-front-commons/src/components/WhitelabelContext";
import Row from "core/Row";
import Modal from "core/Modal";
import Divider from "core/Divider";
import Separator from "core/Separator";
import IconButton from "core/IconButton";
import StudyQualityDetailRequirement from "core/StudyQualityDetailRequirement";
import TranslatedText from "core/TranslatedText";

export default class StudyQualityDetailModal extends React.PureComponent {
  static propTypes = {
    children: PropTypes.func.isRequired,
    studyQuality: PropTypes.shape({
      score: PropTypes.number,
      requirements: PropTypes.arrayOf(PropTypes.shape({})),
    }).isRequired,
  };

  state = {
    isOpen: false,
  };

  handleToggle = () => {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  };

  render() {
    return (
      <WhitelabelContext.Consumer>
        {({ primaryColor }) => (
          <React.Fragment>
            <Modal isOpen={this.state.isOpen}>
              <Row alignItems="center" justifyContent="space-between">
                <TranslatedText translateKey="studyQuality.modalTitle" color={primaryColor} size={32} boldType="bold" />
                <Separator size="lg" />
                <IconButton name="close" onClick={this.handleToggle} />
              </Row>
              <Separator size="lg" />
              <Divider />
              {this.props.studyQuality.requirements.map((item) => (
                <React.Fragment key={item.requirement}>
                  <StudyQualityDetailRequirement item={item} />
                  <Divider />
                </React.Fragment>
              ))}
            </Modal>
            {this.props.children({ toggleModal: this.handleToggle })}
          </React.Fragment>
        )}
      </WhitelabelContext.Consumer>
    );
  }
}
