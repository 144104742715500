import React, { useEffect, useState } from "react";
import InBetweenSpacing from "core/InBetweenSpacing";
import Avatar from "core/Avatar";
import FileInput from "core/FileInput";
import Col from "core/Col";
import { useDispatch } from "react-redux";
import { useEntity, useService } from "student-front-commons/src/hooks";
import { updateProfilePicture } from "student-front-commons/src/services/profileService";
import { mergeEntities } from "student-front-commons/src/actions/entity";
import { hideMessage, showMessage } from "student-front-commons/src/actions/systemMessage";
import { PROFILE_SCHEMA } from "student-front-commons/src/schemas";

function ProfilePictureForm() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(hideMessage());

    return () => {
      dispatch(hideMessage());
    };
  }, [dispatch]);

  const [image, setImage] = useState(null);

  const profile = useEntity(PROFILE_SCHEMA, sessionStorage.getItem("id"));

  const [isSubmitting, submitProfilePicture] = useService(updateProfilePicture, {
    onData: (data) => {
      dispatch(mergeEntities(data.entities));
      dispatch(showMessage({ message: "profile.successMessage", type: "success" }));
    },
    onError: () => {
      dispatch(showMessage({ message: "profile.errorMessage", type: "error" }));
    },
  });

  return (
    <Col alignItems="center">
      <InBetweenSpacing size="lg">
        <Avatar size="xxxl" src={profile.profilePicture} hasShadow={true} />
        <FileInput
          label="profile.field.changeAvatar"
          isDisabled={isSubmitting}
          isLoading={isSubmitting}
          value={image}
          onChange={(value) => {
            setImage(value);
            submitProfilePicture({ image: value });
          }}
        />
      </InBetweenSpacing>
    </Col>
  );
}

export default ProfilePictureForm;
