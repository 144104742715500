import React from "react";
import { useParams } from "react-router-dom";
import ControlBar from "core/ControlBar";
import UnitIcon from "core/UnitIcon";
import Separator from "core/Separator";
import UnitName from "core/UnitName";
import Col from "core/Col";
import { useEntity } from "student-front-commons/src/hooks";
import { UNIT_SCHEMA } from "student-front-commons/src/schemas";

function UnitExecutionControlBar() {
  const { unitId } = useParams();

  const unit = useEntity(UNIT_SCHEMA, unitId);
  return (
    <ControlBar type="unit">
      <Col position="relative" alignItems="center" width={140} bottom={10} zIndex={1}>
        <UnitIcon size="xs" showGameIcon={false} isDisabled={false} />
        <Separator size="xxl" />
        <Separator size="sm" />
        <UnitName name={unit.name} intent="primary" />
      </Col>
    </ControlBar>
  );
}

export default UnitExecutionControlBar;
