import React, { useContext } from "react";
import { useHistory, useParams } from "react-router-dom";
import Card from "core/Card";
import UnitIcon from "core/UnitIcon";
import Col from "core/Col";
import Separator from "core/Separator";
import UnitTypeThemeContext from "core/ThemeContext";
import UnitResultGameScore from "screens/unit-result/UnitResultGameScore";
import { useSelector } from "react-redux";
import ShineBackground from "../../core/ShineBackground";
import TranslatedText from "../../core/TranslatedText";
import { GREEN_1, GREY_4, GREY_7, YELLOW_4 } from "../../consts/colors";
import Row from "../../core/Row";
import Text from "../../core/Text";
import Icon from "../../core/Icon";
import InBetweenSpacing from "../../core/InBetweenSpacing";
import IconButton from "../../core/IconButton";
import UnitResultScore from "./UnitResultScore";
import UnitResultRatingCard from "./UnitResultRatingCard";

function UnitResultCard() {
  const params = useParams();
  const history = useHistory();
  const unitTypeThemeContext = useContext(UnitTypeThemeContext);

  const executionPoints = useSelector((state) => state.executions?.result?.points);
  const executionScore = useSelector((state) => state.executions?.result?.score);

  return (
    <Card
      showRopes={true}
      padding="100px 50px 100px 50px"
      alignItems="center"
      betweenRopesStyle={{
        width: "70%",
      }}
      ropesStyle={{
        backgroundColor: unitTypeThemeContext.primary,
      }}
    >
      {!!executionPoints && (
        <Col position="absolute" top={0} left={0} zIndex={-1} opacity={0.08} width="100%" height="100%">
          <ShineBackground width="100%" height="100%" color={unitTypeThemeContext.primary} />
        </Col>
      )}
      <Col position="absolute" top={-50}>
        <UnitIcon size="sm" />
      </Col>
      <Separator size="md" />
      <TranslatedText
        translateKey="unitExecution.pointsReceived"
        color={GREY_4}
        size={16}
        boldType="bold"
        isUpperCase={true}
      />
      <Separator size="sm" />
      <Row flexShrink={0} alignItems="flex-start">
        <Text
          color={executionPoints ? unitTypeThemeContext.primary : GREY_7}
          size={110}
          boldType="bold"
          isUpperCase={true}
          lineHeight="84px"
        >
          {executionPoints}
        </Text>
        {!!executionPoints && <Icon name="arrow-up" color={GREEN_1} />}
      </Row>
      <Separator size="xl" />
      <UnitResultRatingCard />
      <Separator size="xxxl" />
      {unitTypeThemeContext.isGame ? <UnitResultGameScore /> : <UnitResultScore score={executionScore} />}
      {sessionStorage.getItem("id") !== "tasting_user" && (
        <Row position="absolute" bottom={-40}>
          <InBetweenSpacing size="lg">
            {!executionPoints && (
              <IconButton
                name="repeat"
                size={80}
                onClick={() => history.replace(`/modules/${params.moduleId}/units/${params.unitId}/execution`)}
                backgroundColor={YELLOW_4}
                iconColor={unitTypeThemeContext.secondary}
              />
            )}
            <IconButton
              name="next"
              size={80}
              onClick={() => history.replace(`/modules/${params.moduleId}/detail`)}
              backgroundColor={YELLOW_4}
              iconColor={unitTypeThemeContext.secondary}
            />
          </InBetweenSpacing>
        </Row>
      )}
    </Card>
  );
}

export default UnitResultCard;
