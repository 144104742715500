import React, { useCallback, useState } from "react";
import Popover from "core/Popover";
import TranslateButton from "core/TranslateButton";
import UnitTypeThemeContext from "core/ThemeContext";
import Text from "core/Text";
import useTranslation from "student-front-commons/src/hooks/useTranslation";
import { useEntity } from "student-front-commons/src/hooks";
import { PROFILE_SCHEMA } from "student-front-commons/src/schemas";
import { useDispatch, useSelector } from "react-redux";
import { getCurrentItemExecutionProp, getSelectedId } from "student-front-commons/src/selectors/itemExecution";
import { translateItem } from "student-front-commons/src/actions/itemExecution";

const TranslateButtonContainer = () => {
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);

  const profile = useEntity(PROFILE_SCHEMA, sessionStorage.getItem("id"));

  const selectedId = useSelector(getSelectedId);
  const itemId = useSelector(getCurrentItemExecutionProp("item.id"));
  const itemType = useSelector(getCurrentItemExecutionProp("item.type.key"));
  const isRecording = useSelector(getCurrentItemExecutionProp("isRecording"));
  const isDisabled = useSelector(getCurrentItemExecutionProp("isDisabled"));
  const readCount = useSelector(getCurrentItemExecutionProp("readCount"));

  const [, translation] = useTranslation(itemId, profile.locale);

  const handleToggle = useCallback(() => {
    setIsOpen(!isOpen);
    if (!isOpen) {
      dispatch(translateItem(itemId));
    }
  }, [isOpen, itemId, dispatch]);

  const isTranslateDisabled = () => {
    if (["VIDEO_SHORT", "PHONEME", "VOCABULARY"].find((type) => type === itemType)) {
      return (
        isDisabled || isRecording || !selectedId || !readCount
        // this.props.isSubmitting ||
      );
    }
    return true;
  };

  return (
    <Popover
      isOpen={isOpen}
      onClose={handleToggle}
      position="bottom"
      content={
        <UnitTypeThemeContext.Consumer>
          {({ primary }) => (
            <Text boldType="extra-bold" size={22} color={primary}>
              {translation}
            </Text>
          )}
        </UnitTypeThemeContext.Consumer>
      }
    >
      <TranslateButton isDisabled={isTranslateDisabled()} onClick={handleToggle} />
    </Popover>
  );
};

export default TranslateButtonContainer;
