import React from "react";
import ModuleList from "screens/module-list/ModuleList";
import { LOAD_MODULES_FLOW } from "consts";
import { useFlow } from "student-front-commons/src/hooks";
import Spinner from "../../core/Spinner";
import Col from "../../core/Col";
import { useSelector } from "react-redux";
import { getModuleIdsByCourseId } from "student-front-commons/src/selectors/entity";

function ModuleListContainer() {
  const moduleIds = useSelector(getModuleIdsByCourseId(sessionStorage.getItem("course")));
  const [isLoading] = useFlow(LOAD_MODULES_FLOW);

  if (!moduleIds || isLoading) {
    return (
      <Col flexGrow={1} justifyContent="center" alignItems="center">
        <Spinner />
      </Col>
    );
  }

  return <ModuleList modules={moduleIds} />;
}

export default ModuleListContainer;
