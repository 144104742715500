import React from "react";
import PropTypes from "prop-types";
import numeral from "numeral";
import Col from "core/Col";
import Text from "core/Text";
import { GREY_4 } from "consts/colors";
import Row from "core/Row";
import Spinner from "core/Spinner";
import TranslatedText from "core/TranslatedText";

const CurrentSchoolPosition = ({ position, isLoading }) => (
  <Col alignItems="center" flexShrink={0}>
    <TranslatedText
      translateKey="ranking.mySchoolPosition"
      boldType="bold"
      size={14}
      color={GREY_4}
      isUpperCase={true}
    />
    {isLoading && (
      <Col padding="24px 0px" justifyContent="center" alignItems="center">
        <Spinner size="xs" />
      </Col>
    )}
    {!isLoading && !position && (
      <Text boldType="bold" size={34} color={GREY_4} isUpperCase={true}>
        N/A
      </Text>
    )}
    {!isLoading && !!position && (
      <Row alignItems="baseline">
        <Text boldType="bold" size={52} color={GREY_4} isUpperCase={true}>
          {position}
        </Text>
        <Text boldType="bold" size={32} color={GREY_4} isUpperCase={true}>
          {numeral(position).format("Oo").substring(position.toString().length)}
        </Text>
      </Row>
    )}
  </Col>
);

CurrentSchoolPosition.propTypes = {
  position: PropTypes.number,
};

CurrentSchoolPosition.defaultProps = {
  position: null,
};

export default CurrentSchoolPosition;
