import React from "react";
import Button from "core/Button";
import { GREY_4 } from "consts/colors";
import TranslatedText from "core/TranslatedText";
import { useUserReport } from "student-front-commons/src/components/UserReportContext";

const BugReporterButton = () => {
  const { handleStartReport, isDeactivate } = useUserReport();
  if (isDeactivate) {
    return null;
  }

  return (
    <Button onClick={handleStartReport}>
      <TranslatedText translateKey="reportError" color={GREY_4} size={14} boldType="extra-bold" isUpperCase={true} />
    </Button>
  );
}

export default BugReporterButton;
