import React from "react";
import PropTypes from "prop-types";
import omit from "lodash/omit";
import get from "lodash/get";
import parse5 from "parse5";
import translations from "../locales";
import Text from "core/Text";

class TranslatedText extends React.PureComponent {
  static propTypes = {
    as: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    values: PropTypes.shape({}),
    translateKey: PropTypes.string.isRequired,
  };

  static defaultProps = {
    as: Text,
    values: {},
  };

  parseStyleString = (s) =>
    s.split(";").reduce(function (ruleMap, ruleString) {
      const rulePair = ruleString.split(":");
      ruleMap[rulePair[0].trim()] = rulePair[1].trim();

      return ruleMap;
    }, {});

  render() {
    const textProps = omit(this.props, ["as", "translateKey", "values"]);
    const text = translations.formatString(get(translations, this.props.translateKey), this.props.values);

    if (!text) {
      return null;
    }
    return React.createElement(
      this.props.as,
      textProps,
      <React.Fragment>
        {parse5.parseFragment(text).childNodes.map((node) => {
          if (!node.childNodes) {
            return node.value;
          }
          const nodeStyleString = get(
            node.attrs.find((attr) => attr.name === "style"),
            "value",
            null
          );
          return React.createElement(
            node.tagName || "span",
            {
              style: nodeStyleString ? this.parseStyleString(nodeStyleString) : {},
            },
            node.childNodes.map((child) => child.value)
          );
        })}
      </React.Fragment>
    );
  }
}

export default TranslatedText;
