import React from "react";
import { withRouter } from "react-router-dom";
import Col from "core/Col";
import Card from "core/Card";
import Separator from "core/Separator";
import PrivateScreen from "core/PrivateScreen";
import IconButton from "core/IconButton";
import { LG_SIZE, SM_SIZE } from "utils";
import ProfileForm from "./ProfileForm";
import FormMessageHandler from "../../core/FormMessageHandler";
import ProfilePictureForm from "./ProfilePictureForm";

const ProfileScreen = ({ history }) => (
  <PrivateScreen>
    <Col flexShrink={0} margin="100px auto 40px auto">
      <Card
        showRopes={true}
        mediaQueries={{
          sm: `(max-width: ${SM_SIZE}px)`,
          md: `(min-width: ${SM_SIZE}px)`,
          lg: `(min-width: ${LG_SIZE}px)`,
        }}
        smPadding={40}
        mdPadding={60}
        lgPadding={80}
        smPaddingTop={220}
        mdPaddingTop={220}
        lgPaddingTop={220}
        paddingBottom={50}
        smMaxWidth={540}
        mdMaxWidth={620}
        lgMaxWidth={840}
        width="100%"
        flexShrink={0}
        animation="bounce-in-top 0.8s both"
        zIndex={2}
        betweenRopesStyle={{
          width: "70%",
        }}
        className="user-report-error-catcher"
      >
        <Col position="absolute" left={0} top={-80}>
          <IconButton name="back" onClick={() => history.push("/")} />
        </Col>
        <Col position="absolute" top={-80} alignSelf="center">
          <ProfilePictureForm />
        </Col>
        <ProfileForm />
      </Card>
      <Separator size="xxl" />
      <FormMessageHandler />
    </Col>
  </PrivateScreen>
);

export default withRouter(ProfileScreen);
