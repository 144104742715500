import get from "lodash/get";
import { actionChannel, call, delay, fork, put, race, select, take, takeLatest, cancel } from "redux-saga/effects";
import { buffers, eventChannel } from "redux-saga";
import { endFlow, startFlow } from "student-front-commons/src/actions/flow";
import { getFlow, getFlowEnd, getFlowStart } from "student-front-commons/src/selectors/flow";
import {
  CHECK_ITEM_ANSWER_FLOW,
  CLOSE_UNIT_EXECUTION_FLOW,
  END_UNIT_EXECUTION_FLOW,
  ERROR_MODAL_FLOW,
  GET_NEXT_ITEM_EXECUTION_FLOW,
  PLAY_ITEM_AUDIO_FLOW,
  PLAY_ITEM_VIDEO_FLOW,
  PLAY_RECORD_AUDIO_FLOW,
  SAVE_UNIT_ITEM_EXECUTION_ANSWER_FLOW,
  SELECT_ITEM_FLOW,
  START_RECORD_FLOW,
  USER_AWAY_FLOW,
  USER_AWAY_TIMEOUT_FLOW,
} from "consts";
import { logError } from "utils";
import {
  ADD_ITEM_EXECUTION_ANSWER,
  ADD_ITEM_EXECUTION_ATTEMPT,
  FINISH_RECORD_ITEM,
  LISTEN_ITEM,
  READ_ITEM,
  SAVE_RECORD_ITEM_RESULT,
  START_RECORD_ITEM,
  SUBMIT_RECORD_ITEM,
  TRANSLATE_ITEM,
  UPDATE_ITEM_MEDIA_PROGRESS,
} from "student-front-commons/src/actions/itemExecution";
import { getEntityById } from "student-front-commons/src/selectors/entity";
import { UserReportEvents } from "student-front-commons/src/components/UserReportContext";
import { COMPANY_SCHEMA, PROFILE_SCHEMA } from "student-front-commons/src/schemas";

function userReportEvents() {
  return eventChannel((emitter) => {
    const openListener = () => {
      emitter({ status: "OPEN" });
    };
    UserReportEvents.subscribe(UserReportEvents.OPEN, openListener);

    const closeListener = () => {
      emitter({ status: "CLOSE" });
    };
    UserReportEvents.subscribe(UserReportEvents.CLOSE, closeListener);

    return () => {
      UserReportEvents.unsubscribe(UserReportEvents.OPEN, openListener);
      UserReportEvents.unsubscribe(UserReportEvents.CLOSE, closeListener);
    };
  });
}

export default function* () {
  yield takeLatest(getFlowStart(USER_AWAY_TIMEOUT_FLOW), function* () {
    yield race({
      cancel: take(getFlowStart(CLOSE_UNIT_EXECUTION_FLOW)),
      endUnitExecution: take(getFlowStart(END_UNIT_EXECUTION_FLOW)),
      saveItemExecution: take(getFlowStart(SAVE_UNIT_ITEM_EXECUTION_ANSWER_FLOW)),
      stopForRecord: take(getFlowStart(START_RECORD_FLOW)),
      call: call(function* () {
        let userReportFork;
        let pauseForUserReport = false;

        try {
          userReportFork = yield fork(function* () {
            const channel = yield call(userReportEvents);
            try {
              while (true) {
                const { status } = yield take(channel);
                if (status === "OPEN") {
                  pauseForUserReport = true;
                }
                if (status === "CLOSE") {
                  pauseForUserReport = false;
                }
              }
            } finally {
              channel.close();
            }
          });

          const flow = yield select(getFlow(USER_AWAY_TIMEOUT_FLOW));
          let pendingActionCount = get(flow.params, "pendingActionCount", 0);

          const userAwayChannel = yield actionChannel(
            (action) =>
              getFlowStart(GET_NEXT_ITEM_EXECUTION_FLOW)(action) ||
              getFlowEnd(GET_NEXT_ITEM_EXECUTION_FLOW)(action) ||
              getFlowStart(SELECT_ITEM_FLOW)(action) ||
              getFlowEnd(SELECT_ITEM_FLOW)(action) ||
              getFlowStart(PLAY_ITEM_AUDIO_FLOW)(action) ||
              getFlowEnd(PLAY_ITEM_AUDIO_FLOW)(action) ||
              getFlowStart(PLAY_RECORD_AUDIO_FLOW)(action) ||
              getFlowEnd(PLAY_RECORD_AUDIO_FLOW)(action) ||
              getFlowStart(PLAY_ITEM_VIDEO_FLOW)(action) ||
              getFlowEnd(PLAY_ITEM_VIDEO_FLOW)(action) ||
              getFlowStart(CHECK_ITEM_ANSWER_FLOW)(action) ||
              getFlowEnd(CHECK_ITEM_ANSWER_FLOW)(action) ||
              getFlowStart(SAVE_UNIT_ITEM_EXECUTION_ANSWER_FLOW)(action) ||
              getFlowEnd(SAVE_UNIT_ITEM_EXECUTION_ANSWER_FLOW)(action) ||
              action.type === READ_ITEM ||
              action.type === TRANSLATE_ITEM ||
              action.type === LISTEN_ITEM ||
              action.type === ADD_ITEM_EXECUTION_ANSWER ||
              action.type === ADD_ITEM_EXECUTION_ATTEMPT ||
              action.type === START_RECORD_ITEM ||
              action.type === SUBMIT_RECORD_ITEM ||
              action.type === SAVE_RECORD_ITEM_RESULT ||
              action.type === FINISH_RECORD_ITEM ||
              action.type === UPDATE_ITEM_MEDIA_PROGRESS,
            buffers.sliding(2)
          );

          const id = sessionStorage.getItem("id");
          const profile = yield select(getEntityById(PROFILE_SCHEMA, id));
          const company = yield select(getEntityById(COMPANY_SCHEMA, profile.company));

          const allowedInactiveTime =
            (company.allowedStudentInactiveTime || 1) *
            {
              0: 1,
              1: 1.5,
              2: 2,
            }[pendingActionCount] *
            60 *
            1000;

          let isUserAway = false;
          while (!isUserAway && !profile.skipInstructions) {
            const { timeout } = yield race({
              timeout: delay(allowedInactiveTime),
              newAction: take(userAwayChannel),
            });

            if (timeout && !pauseForUserReport) {
              const errorFlow = yield select(getFlow(ERROR_MODAL_FLOW));
              if (!get(errorFlow, "isPending", false)) {
                isUserAway = true;
                pendingActionCount++;
                yield put(startFlow(USER_AWAY_FLOW, { pendingActionCount }));
              }
            } else {
              pendingActionCount = 0;
            }
          }
        } catch (error) {
          logError({ error, flow: USER_AWAY_TIMEOUT_FLOW });
        } finally {
          if (userReportFork) {
            cancel(userReportFork);
          }
          pauseForUserReport = false;
          yield put(endFlow(USER_AWAY_TIMEOUT_FLOW));
        }
      }),
    });
  });
}
