import React from "react";
import PropTypes from "prop-types";
import hexToRgba from "hex-rgba";
import Col from "core/Col";
import ThemeContext from "core/ThemeContext";
import { GREY_2, WHITE, YELLOW_4 } from "consts/colors";

const ItemButton = ({ id, isDisabled, status, onClick, children, ...rest }) => (
  <ThemeContext.Consumer>
    {({ secondary }) => (
      <Col
        component="button"
        props={{
          disabled: isDisabled,
          type: "button",
          onClick,
          id,
        }}
        cursor={isDisabled ? "not-allowed" : "pointer"}
        backgroundColor={
          status === "normal" && isDisabled
            ? GREY_2
            : {
                active: secondary,
                outline: YELLOW_4,
                normal: WHITE,
              }[status]
        }
        border={
          {
            active: secondary,
            outline: YELLOW_4,
            normal: WHITE,
          }[status]
        }
        boxShadow={
          {
            active: `0px 1px 59px 0 ${hexToRgba(secondary, 80)};`,
            outline: `0px 1px 59px 0 ${hexToRgba(YELLOW_4, 80)};`,
            normal: "none",
          }[status]
        }
        borderRadius={20}
        flexShrink={0}
        padding={0}
        position="relative"
        boxSizing="border-box"
        justifyContent="center"
        alignItems="center"
        outline="none"
        overflow="hidden"
        transition="background-color .5s, box-shadow .5s"
        {...rest}
      >
        {children({ status })}
      </Col>
    )}
  </ThemeContext.Consumer>
);

ItemButton.propTypes = {
  id: PropTypes.string,
  isDisabled: PropTypes.bool,
  onClick: PropTypes.func,
  status: PropTypes.oneOf(["outline", "active", "normal"]).isRequired,
  children: PropTypes.func.isRequired,
};

ItemButton.defaultProps = {
  id: null,
  isPlaying: false,
  isDisabled: false,
  onClick: null,
};

export default ItemButton;
