import React from "react";
import PropTypes from "prop-types";
import Card from "core/Card";

export default class AnimatedCard extends React.Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
  };

  state = {
    className: "slide-in-top",
  };

  componentDidMount() {
    this.setState({
      className: "slide-in-top",
    });
  }

  componentWillUnmount() {
    this.setState({
      className: "slide-out-bottom",
    });
  }

  render() {
    return <Card className={this.state.className} {...this.props} />;
  }
}
