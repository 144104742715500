import React, { useCallback, useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import shuffle from "lodash/shuffle";
import "jsplumb/dist/js/jsplumb.min";
import Row from "core/Row";
import Col from "core/Col";
import Text from "core/Text";
import InBetweenSpacing from "core/InBetweenSpacing";
import ItemButton from "core/ItemButton";
import { GREY_2, WHITE, YELLOW_4 } from "consts/colors";
import UnitTypeThemeContext from "core/ThemeContext";
import Separator from "core/Separator";
import AnimatedImage from "core/AnimatedImage";
import RepeatButtonContainer from "core/RepeatButtonContainer";
import { getImage } from "stores/image-store";
import { useDispatch, useSelector } from "react-redux";
import { getItemExecutionPropById, getSelectedId } from "student-front-commons/src/selectors/itemExecution";
import { addItemExecutionAnswer } from "student-front-commons/src/actions/itemExecution";
import { useFlow } from "student-front-commons/src/hooks";
import { CHECK_ITEM_ANSWER_FLOW, SELECT_ITEM_FLOW } from "../../consts";
import {
  isInstructionEnableForStep,
  isInstructionHighlighted,
} from "student-front-commons/src/selectors/itemInstruction";
import { values, get } from "lodash";

const commonJsPlumbSettings = {
  connector: ["Straight", { gap: 0 }],
  paintStyle: {
    strokeWidth: 4,
    stroke: YELLOW_4,
    outlineWidth: 0,
  },
  endpointStyle: {
    fill: "transparent",
  },
};

const ConnectingDotRegion = ({ status, side }) => (
  <UnitTypeThemeContext.Consumer>
    {({ secondary }) => (
      <Col
        width={16}
        height={16}
        marginLeft={side === "right" ? -8 : 0}
        marginRight={side === "left" ? -8 : 0}
        borderRadius="100%"
        backgroundColor={
          {
            outline: YELLOW_4,
            active: secondary,
            normal: WHITE,
            disabled: GREY_2,
          }[status]
        }
      />
    )}
  </UnitTypeThemeContext.Consumer>
);

ConnectingDotTextRender.propTypes = {
  itemId: PropTypes.string.isRequired,
  side: PropTypes.oneOf(["left", "right"]).isRequired,
};

function ConnectingDotTextRender(props) {
  const unitTypeThemeContext = useContext(UnitTypeThemeContext);

  const [, startSelectFlow] = useFlow(SELECT_ITEM_FLOW);

  const selectedItemId = useSelector(getSelectedId);
  const itemText = useSelector(getItemExecutionPropById(props.itemId, "item.text"));
  const playingId = useSelector(getItemExecutionPropById(props.itemId, "playingId"));
  const isDisabled = useSelector(getItemExecutionPropById(props.itemId, "isDisabled"));
  const isFinished = useSelector(getItemExecutionPropById(props.itemId, "isFinished"));
  const answer = useSelector(getItemExecutionPropById(props.itemId, "answer"));

  const isInstructionEnabled = useSelector(isInstructionEnableForStep("user-away"));
  const shouldHighlighted = useSelector(isInstructionHighlighted);

  const handleClick = useCallback(() => {
    startSelectFlow({ item: props.itemId });
  }, [props.itemId, startSelectFlow]);

  const isItemDisabled = () => isDisabled || !!selectedItemId;

  const getItemStatus = () => {
    if (selectedItemId && props.itemId === selectedItemId) {
      return "active";
    }
    if (isFinished) {
      return "active";
    }
    if (playingId === props.itemId) {
      return "outline";
    }
    return "normal";
  };

  return (
    <Row key={`text-${props.itemId}`} alignItems="center" justifyContent="center" minWidth={200}>
      {!isFinished || (isFinished && answer === props.itemId && props.side === "left") ? (
        <ConnectingDotRegion status={getItemStatus()} side="left" />
      ) : (
        <Separator size="lg" />
      )}
      <ItemButton
        id={`item-${props.itemId}`}
        onClick={handleClick}
        isDisabled={isItemDisabled() || getItemStatus() !== "normal"}
        status={getItemStatus()}
        minWidth={160}
        height={40}
        flexGrow={1}
        padding="0px 15px"
        className={isInstructionEnabled && shouldHighlighted && !selectedItemId ? "shake-vertical" : ""}
      >
        {({ status }) => (
          <Text
            boldType="extra-bold"
            size={22}
            color={
              {
                outline: WHITE,
                active: WHITE,
                normal: unitTypeThemeContext.secondary,
              }[status]
            }
          >
            {itemText}
          </Text>
        )}
      </ItemButton>
      {!isFinished || (isFinished && answer === props.itemId && props.side === "right") ? (
        <ConnectingDotRegion status={getItemStatus()} side="right" />
      ) : (
        <Separator size="lg" />
      )}
    </Row>
  );
}

ConnectingDotImageRender.propTypes = {
  itemId: PropTypes.string.isRequired,
  side: PropTypes.oneOf(["left", "right"]).isRequired,
};

function ConnectingDotImageRender(props) {
  const dispatch = useDispatch();

  const [, startCheckFlow] = useFlow(CHECK_ITEM_ANSWER_FLOW);

  const selectedItemId = useSelector(getSelectedId);
  const playingId = useSelector(getItemExecutionPropById(props.itemId, "playingId"));
  const isDisabled = useSelector(getItemExecutionPropById(props.itemId, "isDisabled"));
  const isFinished = useSelector(getItemExecutionPropById(props.itemId, "isFinished"));
  const showCorrectOption = useSelector(getItemExecutionPropById(props.itemId, "showCorrectOption"));
  const isSpeechRecognitionRequired = useSelector(
    getItemExecutionPropById(props.itemId, "extraData.isSpeechRecognitionRequired")
  );
  const isAnySpeechRecognitionRequired = useSelector((state) =>
    values(state.itemExecutions.byId).find((item) => get(item, "extraData.isSpeechRecognitionRequired", false))
  );

  const isAnswerDisabled = () =>
    isDisabled || !selectedItemId || isFinished || (!!selectedItemId && isAnySpeechRecognitionRequired);

  const getAnswerStatus = () => {
    if (isFinished) {
      return "active";
    }
    if (showCorrectOption && selectedItemId === props.itemId) {
      return "outline";
    }
    return "normal";
  };

  const handleClick = useCallback(() => {
    window.jsPlumb.connect(
      {
        source: `item-${selectedItemId}`,
        target: `answer-${props.itemId}`,
        anchors: props.side === "left" ? ["Left", "Right"] : ["Right", "Left"],
      },
      commonJsPlumbSettings
    );

    dispatch(addItemExecutionAnswer(selectedItemId, { answer: props.itemId }));
    startCheckFlow();
  }, [selectedItemId, props.itemId, props.side, dispatch, startCheckFlow]);

  return (
    <Row alignItems="center" justifyContent="center" position="relative">
      {props.side === "right" && <ConnectingDotRegion status={getAnswerStatus()} side="left" />}
      <ItemButton
        id={`answer-${props.itemId}`}
        onClick={handleClick}
        status={getAnswerStatus()}
        isDisabled={isAnswerDisabled()}
        padding={10}
      >
        {() => (
          <React.Fragment>
            {playingId === `post-phrase-${props.itemId}` ? (
              <AnimatedImage
                key={`${props.itemId}-post-phrase`}
                src={
                  getImage(`items/${props.itemId}/images/post-phrase-lg.png`) ||
                  `${process.env.REACT_APP_FILES_URL}/items/${props.itemId}/images/post-phrase-lg.png`
                }
                width="auto"
                height={100}
                borderRadius={10}
                objectFit="scale-down"
              />
            ) : (
              <AnimatedImage
                key={`${props.itemId}-item`}
                src={
                  getImage(`items/${props.itemId}/images/lg.png`) ||
                  `${process.env.REACT_APP_FILES_URL}/items/${props.itemId}/images/lg.png`
                }
                width="auto"
                height={100}
                objectFit="scale-down"
                borderRadius={10}
              />
            )}
          </React.Fragment>
        )}
      </ItemButton>
      {props.side === "left" && <ConnectingDotRegion status={getAnswerStatus()} side="right" />}
      {isFinished && isSpeechRecognitionRequired && (
        <Col position="absolute" right={-5} bottom={-15}>
          <RepeatButtonContainer />
        </Col>
      )}
    </Row>
  );
}

export default function ConnectingDotsItem() {
  const [textItems, setTextItems] = useState([]);
  const [imageItems, setImageItems] = useState([]);

  const items = useSelector((state) => state.itemExecutions.allIds);

  useEffect(() => {
    let windowWidth = window.innerWidth;

    const handleResize = () => {
      const newWindowWidth = window.innerWidth;
      if (newWindowWidth !== windowWidth) {
        window.jsPlumb.repaintEverything();
        windowWidth = newWindowWidth;
      }
    };

    window.addEventListener("resize", handleResize);

    setTextItems(items);
    setImageItems(shuffle(items));

    window.jsPlumb.setContainer("root");
    window.jsPlumb.reset();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [items]);

  return (
    <Row flexGrow={1} justifyContent="space-between" alignItems="center">
      <Col>
        <InBetweenSpacing size="lg">
          {imageItems.slice(0, imageItems.length / 2).map((item) => (
            <ConnectingDotImageRender key={`left-${item}`} side="left" itemId={item} />
          ))}
        </InBetweenSpacing>
      </Col>
      <Col>
        <InBetweenSpacing size="lg">
          {textItems.map((item) => (
            <ConnectingDotTextRender
              key={`text-${item}`}
              itemId={item}
              side={imageItems.findIndex((imageItem) => imageItem === item) <= 2 ? "left" : "right"}
            />
          ))}
        </InBetweenSpacing>
      </Col>
      <Col>
        <InBetweenSpacing size="lg">
          {imageItems.slice(imageItems.length / 2).map((item) => (
            <ConnectingDotImageRender key={`right-${item}`} side="right" itemId={item} />
          ))}
        </InBetweenSpacing>
      </Col>
    </Row>
  );
}
