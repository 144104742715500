import React, { useState } from "react";
import get from "lodash/get";
import { getCurrentPositions } from "student-front-commons/src/services/positionsService";
import CurrentSchoolPosition from "core/CurrentSchoolPosition";
import { useService } from "student-front-commons/src/hooks";

function CurrentSchoolPositionContainer() {
  const [position, setPosition] = useState(0);

  const [isLoading] = useService(getCurrentPositions, {
    autoStart: true,
    params: {
      id: sessionStorage.getItem("id"),
    },
    onData: (data) => setPosition(get(data, "school", 0)),
    onError: () => setPosition(0),
  });

  return <CurrentSchoolPosition isLoading={isLoading} position={position} />;
}

export default CurrentSchoolPositionContainer;
