import React from "react";
import PropTypes from "prop-types";
import ActiveIcon from "assets/unit-result-star-active.png";
import DisabledIcon from "assets/unit-result-star-disable.png";
import Image from "core/Image";

const UnitStarIcon = ({ isActive, size, ...rest }) => (
  <Image
    src={isActive ? ActiveIcon : DisabledIcon}
    opacity={isActive ? 1 : 0.7}
    width="auto"
    height={
      {
        xxs: 18,
        xs: 32,
        sm: 48,
        md: 56,
        lg: 64,
        xl: 72,
      }[size]
    }
    {...rest}
  />
);

UnitStarIcon.propTypes = {
  isActive: PropTypes.bool.isRequired,
  size: PropTypes.oneOf(["xxs", "xs", "sm", "md", "lg", "xl"]).isRequired,
};

export default UnitStarIcon;
