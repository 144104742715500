import React from "react";
import PropTypes from "prop-types";
import Col from "core/Col";
import Row from "core/Row";
import Icon from "core/Icon";
import Text from "core/Text";
import Image from "core/Image";
import InBetweenSpacing from "core/InBetweenSpacing";
import ClickableSound from "core/ClickableSound";
import { ADVENTURES, DISCOVERY, GREY_8, GREY_9, WHITE } from "consts/colors";
import { addBreadcrumb, SM_SIZE } from "utils";
import DiscoveryIcon from "assets/discovery.png";
import AdventuresIcon from "assets/adventures.png";
import { useHistory } from "react-router-dom/cjs/react-router-dom";

function CourseListItem({ course }) {
  const history = useHistory();

  return (
    <ClickableSound
      onClick={() => {
        sessionStorage.setItem("course", course.id);
        history.replace("/");
        addBreadcrumb({
          category: "ui",
          message: "Student selected course",
          data: { course },
        });
      }}
      isDisabled={course.disabled}
    >
      <Col
        mediaQueries={{
          sm: `(max-width: ${SM_SIZE}px)`,
          md: `(min-width: ${SM_SIZE}px)`,
        }}
        transition="width .3s, height .3s"
        alignItems="center"
        justifyContent="flex-end"
        smWidth={360}
        mdWidth={390}
        smHeight={360}
        mdHeight={390}
        boxSizing="border-box"
        position="relative"
      >
        <Image
          src={course.name.toLowerCase() === "discovery" ? DiscoveryIcon : AdventuresIcon}
          filter={course.disabled ? "grayscale(100%)" : "none"}
          opacity={course.disabled ? 0.8 : 1}
          position="absolute"
          transition="transform 300ms ease"
          hoverTransform={course.disabled ? "none" : "scale(1.1)"}
        />
        <Col
          alignItems="center"
          justifyContent="center"
          backgroundColor={
            course.disabled
              ? GREY_9
              : {
                  DISCOVERY,
                  ADVENTURES,
                }[
                  course.name
                    .toUpperCase()
                    .replace(/[.!?,`'"]/g, "")
                    .replace(/ /g, "_")
                ]
          }
          borderRadius={20}
          zIndex={2}
          height={40}
          padding="0px 35px"
          boxSizing="border-box"
          boxShadow="0px 10px 20px 0 rgba(0, 0, 0, 0.2);"
          marginBottom={15}
        >
          <Row alignItems="center" justifyContent="center">
            <InBetweenSpacing size="xs">
              {course.disabled && <Icon size={16} name="lock" color={GREY_8} />}
              <Text boldType="extra-bold" size={18} color={course.disabled ? GREY_8 : WHITE}>
                {course.name}
              </Text>
            </InBetweenSpacing>
          </Row>
        </Col>
      </Col>
    </ClickableSound>
  );
}

CourseListItem.propTypes = {
  course: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }).isRequired,
};

export default CourseListItem;
