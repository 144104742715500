import { Howl } from "howler";
import { call, put, race, select, spawn, take, takeLatest } from "redux-saga/effects";
import { getFlow, getFlowEnd, getFlowStart } from "student-front-commons/src/selectors/flow";
import { endFlow, startFlow } from "student-front-commons/src/actions/flow";
import { saveUnitItemExecution } from "student-front-commons/src/services/unitExecutionService";
import browserHistory from "browserHistory";
import {
  CHECK_ITEM_ANSWER_FLOW,
  CLOSE_UNIT_EXECUTION_FLOW,
  ERROR_MODAL_FLOW,
  GET_NEXT_ITEM_EXECUTION_FLOW,
  SAVE_UNIT_ITEM_EXECUTION_ANSWER_FLOW,
} from "consts";
import { logError } from "utils";
import locales from "locales";
import { get, head, last, sample } from "lodash";
import { disableItemExecution, enableItemExecution } from "student-front-commons/src/actions/itemExecution";
import { getItemExecutions } from "student-front-commons/src/selectors/itemExecution";
import { getAssociativeItemOrderByItemId } from "student-front-commons/src/selectors/execution";
import { addExecutionAnswer, setNextExecutionOrder } from "student-front-commons/src/actions/execution";

let retryFlowCount = 1;

export default function* () {
  yield takeLatest(getFlowStart(SAVE_UNIT_ITEM_EXECUTION_ANSWER_FLOW), function* () {
    yield race({
      cancel: take(getFlowStart(CLOSE_UNIT_EXECUTION_FLOW)),
      call: call(function* () {
        const flow = yield select(getFlow(SAVE_UNIT_ITEM_EXECUTION_ANSWER_FLOW));
        try {
          yield put(disableItemExecution());

          let execution = yield select((state) => state.executions);
          const itemExecutions = yield select(getItemExecutions);

          // we must use reduce to be sure the allow the api sum the counts
          yield itemExecutions.reduce(function* (acc, itemExecution) {
            yield acc;

            const answer = yield call(saveUnitItemExecution, {
              module: execution.module,
              unit: execution.unit,
              unitExecution: execution.id,
              item: itemExecution.item.id,
              correct:
                itemExecution.item.type.key === "VIDEO" ||
                itemExecution.item.type.key === "MUSIC" ||
                last(itemExecution.attempts).correct,
              userAwayCount: itemExecution.userAwayCount,
              errorCount: itemExecution.errorCount,
              repeatCount: itemExecution.repeatCount,
              recordCount: itemExecution.recordCount,
              listenCount: itemExecution.listenCount,
              readCount: itemExecution.readCount,
              translateCount: itemExecution.translateCount,
              timeReportingError: 0,
              attempts: itemExecution.attempts,
              audio: itemExecution.recordFile,
            });

            const associativeItemOrder = yield select(getAssociativeItemOrderByItemId(itemExecution.item.id));
            yield put(addExecutionAnswer({ answer: answer.id, itemOrder: associativeItemOrder }));

            return Promise.resolve();
          }, Promise.resolve());

          const checkAnswerFlow = yield select(getFlow(CHECK_ITEM_ANSWER_FLOW));
          if (checkAnswerFlow && checkAnswerFlow.isPending) {
            yield take(getFlowEnd(CHECK_ITEM_ANSWER_FLOW));
          }

          const itemTypeKey = head(itemExecutions).item.type.key;
          if (itemTypeKey === "CONNECTING_DOTS") {
            window.jsPlumb.deleteEveryConnection();
          }

          execution = yield select((state) => state.executions);
          if (execution.answers.length >= execution.associativeItems.length) {
            browserHistory.replace(`${browserHistory.location.pathname}/${execution.id}/result`);
          } else {
            // audio effect
            yield spawn(function* () {
              const audio = new Howl({
                src: [
                  sample([
                    require("assets/audio/next/next-audio-3.mp3").default,
                    require("assets/audio/next/next-audio-4.mp3").default,
                    require("assets/audio/next/next-audio-5.mp3").default,
                  ]),
                ],
                autoplay: false,
                loop: false,
                volume: 1,
              });
              yield new Promise((resolve) => {
                audio.once("playerror", () => {
                  audio.unload();
                  resolve();
                });
                audio.once("end", () => {
                  audio.unload();
                  resolve();
                });
                audio.play();
              });
            });

            yield put(setNextExecutionOrder());
            yield put(startFlow(GET_NEXT_ITEM_EXECUTION_FLOW));
          }
          retryFlowCount = 1;
        } catch (error) {
          logError({ error, flow: SAVE_UNIT_ITEM_EXECUTION_ANSWER_FLOW });

          yield put(
            startFlow(ERROR_MODAL_FLOW, {
              message:
                retryFlowCount > 3
                  ? get(locales, "unitExecution.error.saveItem.defaultMessage")
                  : get(locales, "unitExecution.error.saveItem.tryAgain"),
              retryFlow: {
                id: SAVE_UNIT_ITEM_EXECUTION_ANSWER_FLOW,
                params: flow.params,
              },
              attemptCount: retryFlowCount,
            })
          );
          retryFlowCount = retryFlowCount + 1;

          yield put(enableItemExecution());
        } finally {
          yield put(endFlow(SAVE_UNIT_ITEM_EXECUTION_ANSWER_FLOW));
        }
      }),
    });
  });
}
