import React from "react";
import PropTypes from "prop-types";
import Text from "core/Text";
import Col from "core/Col";
import { BLACK_1, GREY_3, GREY_4 } from "consts/colors";
import TranslatedText from "core/TranslatedText";

const GradesListFinalGradeColumn = ({ grade, schoolGrade, maximumSchoolGrade, isPreview }) => (
  <Col width="22%" justifyContent="center" alignItems="center">
    <Text size={32} color={isPreview ? GREY_3 : BLACK_1} boldType="bold">
      {grade || "N/A"}
    </Text>
    {isPreview && <TranslatedText translateKey="grades.preview" size={13} color={GREY_3} boldType="light-bold" />}
    <TranslatedText
      translateKey="grades.yourSchoolGrade"
      values={{
        grade: schoolGrade || "N/A",
      }}
      size={13}
      color={isPreview ? GREY_3 : GREY_4}
      boldType="light-bold"
    />
    <TranslatedText
      translateKey="grades.maximumSchoolGrade"
      values={{
        grade: maximumSchoolGrade || "N/A",
      }}
      size={13}
      color={isPreview ? GREY_3 : GREY_4}
      boldType="light-bold"
    />
  </Col>
);

GradesListFinalGradeColumn.propTypes = {
  grade: PropTypes.number,
  schoolGrade: PropTypes.number,
  maximumSchoolGrade: PropTypes.number,
  isPreview: PropTypes.bool.isRequired,
};

export default GradesListFinalGradeColumn;
