import React from "react";
import ThemeContext from "core/ThemeContext";
import { MASTERY_TEST_PRIMARY, MASTERY_TEST_SECONDARY } from "consts/colors";

export default class MasteryTestThemeProvider extends React.Component {
  render() {
    return (
      <ThemeContext.Provider
        value={{
          primary: MASTERY_TEST_PRIMARY,
          secondary: MASTERY_TEST_SECONDARY,
          radialGradient: "radial-gradient(circle at 50% 50%, #9ee6d9, #00897b);",
        }}
      >
        {this.props.children}
      </ThemeContext.Provider>
    );
  }
}
