import { head, toLower, orderBy, max, snakeCase } from "lodash";
import { all, call, put, select, spawn, take, takeLatest } from "redux-saga/effects";
import { endFlow, startFlow } from "student-front-commons/src/actions/flow";
import { mergeEntities } from "student-front-commons/src/actions/entity";
import { getFlow, getFlowEnd, getFlowStart } from "student-front-commons/src/selectors/flow";
import { startTastingExecution } from "student-front-commons/src/services/tastingService";
import { addBreadcrumb, addImageDataToItems, addSoundToItems, getInstructionSound, logError } from "utils";
import { GET_NEXT_ITEM_EXECUTION_FLOW, LOAD_CONFIGURATION_FLOW, START_TASTING_EXECUTION_FLOW } from "consts";
import locales from "locales";
import { getEntityById } from "student-front-commons/src/selectors/entity";
import { startExecution } from "student-front-commons/src/actions/execution";

export default function* () {
  yield takeLatest(getFlowStart(START_TASTING_EXECUTION_FLOW), function* () {
    const flow = yield select(getFlow(START_TASTING_EXECUTION_FLOW));
    try {
      sessionStorage.setItem("id", "tasting_user");
      sessionStorage.setItem("accessToken", flow.params.token);
      const locale = head(toLower(flow.params.locale || navigator.language || head(navigator.languages)).split("-"));
      sessionStorage.setItem("locale", locale);

      locales.setLanguage(sessionStorage.getItem("locale"));

      const profileResult = {
        result: "tasting_user",
        entities: {
          profile: {
            tasting_user: {
              id: "tasting_user",
              initialEnglishLevel: 0,
              schoolClass: "tasting-class-room",
              locale: locale,
              skipInstructions: flow.params.disableInstructions === "true",
              collectContact: flow.params.collectContact === "true",
              pagesAccessed: [],
            },
          },
          schoolClass: {
            "tasting-class-room": {
              id: "tasting-class-room",
              // isPlacementTestClass: true,
              school: "tasting-school",
            },
          },
          school: {
            "tasting-school": {
              id: "tasting-school",
              allowLevelSelection: false,
            },
          },
        },
      };
      yield put(mergeEntities(profileResult.entities));

      yield put(startFlow(LOAD_CONFIGURATION_FLOW));
      yield take(getFlowEnd(LOAD_CONFIGURATION_FLOW));

      const result = yield call(startTastingExecution, {
        unit: flow.params.unit,
      });

      yield put(mergeEntities(result.unit.entities));
      const orderedItems = orderBy(result.items, ["order"], ["asc"]).map((unitItem) => ({
        ...unitItem,
        item: {
          ...unitItem.item,
          type: {
            ...unitItem.item.type,
            itemInstructionSound: getInstructionSound(unitItem.item.type, "BETWEEN_ITEMS", locale),
            initialInstructionSound: getInstructionSound(unitItem.item.type, "INITIAL", locale),
          },
        },
      }));

      const itemsInOrder = orderedItems.filter((unitItem) => unitItem.order === 1).length;
      yield all([
        ...addSoundToItems(orderedItems.slice(0, max([4, itemsInOrder]))),
        ...addImageDataToItems(orderedItems.slice(0, max([4, itemsInOrder]))),
      ]);

      addBreadcrumb({
        category: "flow",
        action: `Added sounds and images to first order Items`,
      });

      const unit = yield select(getEntityById("unit", flow.params.unit));
      const unitType = yield select(getEntityById("unitType", unit.type));
      const isGame = ["connecting_dots_game", "single_choice_game", "memory_game", "vocabulary_game"].some(
        (type) => type === snakeCase(unitType.name.toLowerCase())
      );

      yield put(
        startExecution({
          id: "tasting",
          answers: [],
          startedAt: new Date(),
          items: orderedItems,
          module: unit.module,
          unit: unit.id,
          isGame,
        })
      );

      yield spawn(function* () {
        yield all([
          ...addSoundToItems(orderedItems.slice(max([4, itemsInOrder]))),
          ...addImageDataToItems(orderedItems.slice(max([4, itemsInOrder]))),
        ]);
        addBreadcrumb({
          category: "flow",
          message: "Finished loading all audios and images",
        });
      });

      yield put(startFlow(GET_NEXT_ITEM_EXECUTION_FLOW));
    } catch (error) {
      logError({ error, flow: START_TASTING_EXECUTION_FLOW });
    } finally {
      yield put(endFlow(START_TASTING_EXECUTION_FLOW));
    }
  });
}
