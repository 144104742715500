import * as Sentry from "@sentry/react";
import { END_FLOW, START_FLOW } from "../actions/flow";

export default (store) => (next) => (action) => {
  if (action.type && (action.type === START_FLOW || action.type === END_FLOW)) {
    Sentry.addBreadcrumb({
      type: "info",
      category: "flow",
      level: "info",
      message: `${action.type} ${action.payload.flowId}`,
      data: {
        flowId: action.payload.flowId,
        flowStatus: action.type,
        flowParams: action.payload.params || null,
      },
    });
  }
  return next(action);
};
