import React from "react";
import { createPortal } from "react-dom";
import hexToRgba from "hex-rgba";
import Col from "core/Col";
import Card from "core/Card";
import { WHITE, BLACK_1 } from "consts/colors";
import Separator from "core/Separator";

const Modal = ({ children, isOpen }) =>
  createPortal(
    <Col
      position="absolute"
      top={0}
      left={0}
      width="100%"
      height="100vh"
      zIndex={isOpen ? 1000 : -1}
      opacity={isOpen ? 1 : 0}
      transition="opacity .5s"
      alignItems="center"
      padding={20}
      boxSizing="border-box"
    >
      <Col position="fixed" backgroundColor={hexToRgba(WHITE, 70)} top={0} left={0} width="100%" height="100vh" />
      <Separator size="xxxl" />
      <Separator size="xxxl" />
      <Card
        showRopes={true}
        ropesStyle={{
          backgroundColor: hexToRgba(BLACK_1, 50),
        }}
        padding={50}
      >
        {children}
      </Card>
    </Col>,
    document.getElementById("modal-root")
  );

export default Modal;
