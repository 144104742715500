export const WHITE = "#fff";

export const BLACK = "#000";
export const BLACK_1 = "#455a64";

export const LINK = "#1488cc";

export const GREY_1 = "#f6f6f6";
export const GREY_2 = "#d7d7d7";
export const GREY_3 = "rgba(96, 125, 139, 0.5)";
export const GREY_4 = "#607d8b";
export const GREY_5 = "#ededed";
export const GREY_6 = "#cfd8dc";
export const GREY_7 = "#b6d3e1";
export const GREY_8 = "#a5a5a5";
export const GREY_9 = "#727272";
export const GREY_10 = "#5b7886";
export const GREY_11 = "#456270";

export const GREEN_1 = "#00786A";

export const YELLOW_1 = "#fbf2d6";
export const YELLOW_2 = "#ffcd38";
export const YELLOW_3 = "#ebaf03";
export const YELLOW_4 = "#fadc60";

export const RED_1 = "#e23b5b";
export const RED_2 = "#cd2545";

export const GOLD = "#f4bc13";
export const SILVER = "#9d999a";
export const BRONZE = "#d69b23";

export const SPEECH_EXCELLENT = "#00661A";
export const SPEECH_GOOD = "#69b81d";
export const SPEECH_MODERATE = "#FFE10D";
export const SPEECH_LOW = "#FF6600";
export const SPEECH_WEAK = "#D20000";

export const DISCOVERY = "#028478";
export const ADVENTURES = "#dc8117";

export const CONNECTING_DOTS_PRIMARY = "#ec407a";
export const CONNECTING_DOTS_SECONDARY = "#d81b60";
export const SINGLE_CHOICE_GAME_PRIMARY = "#3f51b5";
export const SINGLE_CHOICE_GAME_SECONDARY = "#303f9f";
export const MEMORY_GAME_PRIMARY = "#e87142";
export const MEMORY_GAME_SECONDARY = "#c55022";
export const PRESENTATION_PRIMARY = "#558b2f";
export const PRESENTATION_SECONDARY = "#33691e";
export const GRAMMAR_PRIMARY = "#1e88e5";
export const GRAMMAR_SECONDARY = "#1565c0";
export const VOCABULARY_PRIMARY = "#00bcd4";
export const VOCABULARY_SECONDARY = "#018391";
export const VOCABULARY_GAME_PRIMARY = "#7b1fa2";
export const VOCABULARY_GAME_SECONDARY = "#4a148c";
export const TRUE_FALSE_PRIMARY = "#925038";
export const TRUE_FALSE_SECONDARY = "#5d4037";
export const MUSIC_PRIMARY = "#00897b";
export const MUSIC_SECONDARY = "#00695c";
export const PHONEME_PRIMARY = "#d34f3d";
export const PHONEME_SECONDARY = "#ac3e2f";

export const MODULE_LEVEL_1 = "#e64365";
export const MODULE_LEVEL_2 = "#2d89ae";
export const MODULE_LEVEL_3 = "#73a840";
export const MODULE_LEVEL_4 = "#fc9600";
export const MODULE_LEVEL_5 = "#4eb3f4";
export const MODULE_LEVEL_6 = "#835dc4";

export const LETS_GO_CAMPING = "#6da048";
export const THE_MAGIC_CLOSET = "#2082cf";
export const A_DAY_AT_THE_ZOO = "#2eac5e";
export const A_DAY_AT_THE_MUSEUM = "#a1be46";
export const SPACE_TIME = "#213a84";
export const THE_TIME_MACHINE = "#f9aa34";
export const WE_ARE_THE_CHAMPIONS = "#7856b9";
export const WE_LOVE_TRAVELING = "#1a8e94";
export const WERE_AT_DISNEY = "#1781b2";
export const FAMILY_ALBUM = "#d40c5c";
export const PARTY_TIME = "#da990d";
export const WELCOME_TO_OUR_CITY = "#7fe4f0";
export const THIS_IS_OUR_SCHOOL = "#c88bf6";

export const MASTERY_TEST_PRIMARY = "#00897b";
export const MASTERY_TEST_SECONDARY = "#00695c";
