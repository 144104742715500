import React from "react";
import PropTypes from "prop-types";
import Col from "core/Col";
import Row from "core/Row";
import Icon from "core/Icon";
import Text from "core/Text";
import Image from "core/Image";
import InBetweenSpacing from "core/InBetweenSpacing";
import ClickableSound from "core/ClickableSound";
import {
  A_DAY_AT_THE_MUSEUM,
  A_DAY_AT_THE_ZOO,
  FAMILY_ALBUM,
  GREY_8,
  GREY_9,
  LETS_GO_CAMPING,
  MODULE_LEVEL_1,
  MODULE_LEVEL_2,
  MODULE_LEVEL_3,
  MODULE_LEVEL_4,
  MODULE_LEVEL_5,
  MODULE_LEVEL_6,
  PARTY_TIME,
  SPACE_TIME,
  THE_MAGIC_CLOSET,
  THE_TIME_MACHINE,
  THIS_IS_OUR_SCHOOL,
  WE_ARE_THE_CHAMPIONS,
  WE_LOVE_TRAVELING,
  WELCOME_TO_OUR_CITY,
  WERE_AT_DISNEY,
  WHITE,
} from "consts/colors";
import { addBreadcrumb, SM_SIZE } from "utils";
import { getImage } from "stores/image-store";
import { useEntity } from "student-front-commons/src/hooks";
import { MODULE_SCHEMA } from "student-front-commons/src/schemas";
import { useHistory } from "react-router-dom/cjs/react-router-dom";

function ModuleListItem(props) {
  const history = useHistory();
  const module = useEntity(MODULE_SCHEMA, props.module);

  return (
    <ClickableSound
      onClick={() => {
        history.push(`/modules/${module.id}/detail`);
        addBreadcrumb({
          category: "ui",
          message: `Navigate to /modules/${module.id}/detail`,
        });
      }}
      isDisabled={module.disabled}
    >
      <Col
        mediaQueries={{
          sm: `(max-width: ${SM_SIZE}px)`,
          md: `(min-width: ${SM_SIZE}px)`,
        }}
        transition="width .3s, height .3s"
        alignItems="center"
        justifyContent="flex-end"
        smWidth={220}
        mdWidth={280}
        smHeight={220}
        mdHeight={280}
        boxSizing="border-box"
        position="relative"
      >
        <Image
          src={getImage(module.backgroundUrl) || `${process.env.REACT_APP_FILES_URL}/${module.backgroundUrl}`}
          filter={module.disabled ? "grayscale(100%)" : "none"}
          opacity={module.disabled ? 0.8 : 1}
          position="absolute"
          transition="transform 300ms ease"
          hoverTransform={module.disabled ? "none" : "scale(1.1)"}
        />
        <Col
          alignItems="center"
          justifyContent="center"
          backgroundColor={
            module.disabled
              ? GREY_9
              : {
                  LEVEL_1: MODULE_LEVEL_1,
                  LEVEL_2: MODULE_LEVEL_2,
                  LEVEL_3: MODULE_LEVEL_3,
                  LEVEL_4: MODULE_LEVEL_4,
                  LEVEL_5: MODULE_LEVEL_5,
                  LEVEL_6: MODULE_LEVEL_6,
                  LETS_GO_CAMPING,
                  THE_MAGIC_CLOSET,
                  A_DAY_AT_THE_ZOO,
                  A_DAY_AT_THE_MUSEUM,
                  SPACE_TIME,
                  THE_TIME_MACHINE,
                  WE_ARE_THE_CHAMPIONS,
                  WE_LOVE_TRAVELING,
                  WERE_AT_DISNEY,
                  FAMILY_ALBUM,
                  THIS_IS_OUR_SCHOOL,
                  PARTY_TIME,
                  WELCOME_TO_OUR_CITY,
                }[
                  module.name
                    .toUpperCase()
                    .replace(/[.!?,`'"]/g, "")
                    .replace(/ /g, "_")
                ]
          }
          borderRadius={15}
          zIndex={2}
          height={30}
          padding="0px 25px"
          boxSizing="border-box"
          boxShadow="0px 10px 20px 0 rgba(0, 0, 0, 0.2);"
          marginBottom={20}
        >
          <Row alignItems="center" justifyContent="center">
            <InBetweenSpacing size="xs">
              {module.disabled && <Icon size={16} name="lock" color={GREY_8} />}
              <Text boldType="extra-bold" size={16} color={module.disabled ? GREY_8 : WHITE}>
                {module.name}
              </Text>
            </InBetweenSpacing>
          </Row>
        </Col>
      </Col>
    </ClickableSound>
  );
}

ModuleListItem.propTypes = {
  module: PropTypes.string.isRequired,
};

export default ModuleListItem;
