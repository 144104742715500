import { call, cancelled, put, race, select, take, takeLatest } from "redux-saga/effects";
import { endFlow } from "student-front-commons/src/actions/flow";
import { getFlowStart } from "student-front-commons/src/selectors/flow";
import { CLOSE_MASTERY_TEST_EXECUTION_FLOW, CLOSE_UNIT_EXECUTION_FLOW, PLAY_RECORD_AUDIO_FLOW } from "consts";
import { playAudio, stopAudio } from "stores/audio-store";
import { logError } from "utils";
import { disableItemExecution, enableItemExecution, listenItem } from "student-front-commons/src/actions/itemExecution";
import { getCurrentItemExecutionProp, getSelectedId } from "student-front-commons/src/selectors/itemExecution";

export default function* () {
  yield takeLatest(getFlowStart(PLAY_RECORD_AUDIO_FLOW), function* () {
    yield race({
      cancel: take(getFlowStart(CLOSE_UNIT_EXECUTION_FLOW)),
      closeMasteryTest: take(getFlowStart(CLOSE_MASTERY_TEST_EXECUTION_FLOW)),
      call: call(function* () {
        try {
          yield put(disableItemExecution());

          const selectedItem = yield select(getSelectedId);
          const url = yield select(getCurrentItemExecutionProp("recordUrl"));

          yield call(playAudio, {
            url,
            rate: 1,
          });

          yield put(listenItem(selectedItem));
        } catch (error) {
          logError({ error, flow: PLAY_RECORD_AUDIO_FLOW });
        } finally {
          if (yield cancelled()) {
            stopAudio();
          }
          yield put(enableItemExecution());
          yield put(endFlow(PLAY_RECORD_AUDIO_FLOW));
        }
      }),
    });
  });
}
