import React from "react";
import Col from "core/Col";
import Card from "core/Card";
import { GREY_1, RED_1, WHITE } from "consts/colors";
import TranslatedText from "core/TranslatedText";

const ModuleHeaderItem = ({ title, children }) => (
  <Card
    transition="width .3s"
    showRopes={true}
    alignItems="center"
    justifyContent="center"
    backgroundColor={RED_1}
    borderRadius={20}
    width="auto"
    minWidth={200}
    padding="0px 40px"
    height={60}
    ropesStyle={{
      backgroundColor: GREY_1,
    }}
    betweenRopesStyle={{
      width: "80%",
      minWidth: 0,
    }}
  >
    <Col
      position="absolute"
      top={-10}
      borderRadius={20}
      backgroundColor={RED_1}
      transition="width .3s"
      width="fit-content"
      maxWidth={150}
      minWidth={90}
      padding="0 10px"
      height={20}
      alignItems="center"
      justifyContent="center"
      boxShadow="0px 1px 3px 0 rgba(0, 0, 0, 0.2);"
    >
      <TranslatedText translateKey={title} size={10} color={WHITE} boldType="extra-bold" isUpperCase={true} />
    </Col>
    {children}
  </Card>
);

export default ModuleHeaderItem;
