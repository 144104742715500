import { eventChannel } from "redux-saga";
import { takeLatest, put, call, take } from "redux-saga/effects";
import { endFlow } from "student-front-commons/src/actions/flow";
import { getFlowStart } from "student-front-commons/src/selectors/flow";
import { MONITOR_NETWORK_CONNECTION_FLOW } from "consts";
import { logError, addBreadcrumb } from "utils";

function networkEvents() {
  return eventChannel((emitter) => {
    const listenerStatus = (status) => emitter({ status });

    window.addEventListener("online", () => listenerStatus("online"));
    window.addEventListener("offline", () => listenerStatus("offline"));

    return () => {
      window.removeEventListener("online", () => listenerStatus("online"));
      window.removeEventListener("offline", () => listenerStatus("offline"));
    };
  });
}

export default function* () {
  yield takeLatest(getFlowStart(MONITOR_NETWORK_CONNECTION_FLOW), function* () {
    try {
      addBreadcrumb({
        category: "flow",
        message: `User network ${navigator.onLine ? "connected" : "disconnected"}`,
      });

      const channel = yield call(networkEvents);
      while (true) {
        const { status } = yield take(channel);
        addBreadcrumb({
          category: "flow",
          message: `User network status changed: ${status}`,
        });
      }
    } catch (error) {
      logError({ flow: MONITOR_NETWORK_CONNECTION_FLOW, error });
    } finally {
      yield put(endFlow(MONITOR_NETWORK_CONNECTION_FLOW));
    }
  });
}
