import React from "react";
import PropTypes from "prop-types";
import PrivateScreen from "core/PrivateScreen";
import Col from "core/Col";
import Row from "core/Row";
import Spinner from "core/Spinner";
import Separator from "core/Separator";
import RankingCard from "core/RankingCard";
import UnitTypeThemeContext from "core/ThemeContext";
import RankingParticipantContainer from "core/RankingParticipantContainer";
import UnitResultCard from "screens/unit-result/UnitResultCard";
import { MD_SIZE } from "utils";

const UnitResultScreen = ({ isLoading }) => (
  <UnitTypeThemeContext.Consumer>
    {({ radialGradient }) => (
      <PrivateScreen
        backgroundStyle={{
          background: radialGradient,
        }}
      >
        {isLoading ? (
          <Col height="100vh" justifyContent="center" alignItems="center">
            <Spinner />
          </Col>
        ) : (
          <RankingParticipantContainer>
            {({ isRankingParticipant }) => (
              <Row justifyContent="space-between">
                <Col
                  mediaQueries={{
                    sm: `(max-width: ${MD_SIZE}px)`,
                    md: `(min-width: ${MD_SIZE}px)`,
                  }}
                  smWidth="100%"
                  mdWidth={!isRankingParticipant ? "100%" : "65%"}
                >
                  <Separator size="xl" />
                  <Separator size="xl" />
                  <UnitResultCard />
                </Col>
                {isRankingParticipant && (
                  <Col
                    mediaQueries={{
                      sm: `(max-width: ${MD_SIZE}px)`,
                      md: `(min-width: ${MD_SIZE}px)`,
                    }}
                    smDisplay="none"
                    mdDisplay="flex"
                    smWidth="0%"
                    mdWidth="30%"
                  >
                    <RankingCard />
                  </Col>
                )}
              </Row>
            )}
          </RankingParticipantContainer>
        )}
      </PrivateScreen>
    )}
  </UnitTypeThemeContext.Consumer>
);

UnitResultScreen.propTypes = {
  isLoading: PropTypes.bool.isRequired,
};

export default UnitResultScreen;
