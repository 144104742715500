import React from "react";

export default ({ color }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="31">
    <path
      fill={color}
      d="M17.439 22.901c-1.708 1.966-3.817 3.093-6.329 3.38v2.381h4.444c.301 0 .562.115.781.342.221.23.33.5.33.812 0 .312-.109.584-.33.811-.219.228-.48.343-.781.343H4.444a1.05 1.05 0 0 1-.782-.343 1.128 1.128 0 0 1-.33-.811c0-.312.11-.582.33-.812.22-.227.48-.342.782-.342h4.444v-2.381c-2.512-.287-4.621-1.414-6.329-3.38-1.707-1.964-2.56-4.276-2.56-6.932v-2.308c0-.312.11-.583.329-.811.22-.228.481-.343.782-.343.301 0 .561.115.781.343.22.228.33.499.33.811v2.308c0 2.224.76 4.126 2.283 5.707 1.522 1.58 3.353 2.371 5.495 2.371 2.141 0 3.974-.791 5.495-2.371 1.522-1.581 2.283-3.483 2.283-5.707v-2.308c0-.312.11-.583.329-.811.221-.228.481-.343.782-.343.301 0 .562.115.781.343.22.228.33.499.33.811v2.308c0 2.656-.853 4.968-2.56 6.932zm-3.516-2.857c-1.089 1.129-2.397 1.695-3.924 1.695-1.527 0-2.835-.566-3.924-1.695-1.088-1.13-1.631-2.488-1.631-4.075V6.738c0-1.587.543-2.946 1.631-4.075C7.164 1.533 8.472.968 9.999.968c1.527 0 2.835.565 3.924 1.695 1.088 1.129 1.631 2.488 1.631 4.075v9.231c0 1.587-.544 2.945-1.631 4.075z"
    />
  </svg>
);
