import LogRocket from "logrocket";
import moment from "moment";
import { endsWith, get, min } from "lodash";
import Player from "@vimeo/player";
import { call, cancelled, delay, put, race, select, take, takeLatest } from "redux-saga/effects";
import { eventChannel } from "redux-saga";
import { endFlow, startFlow } from "student-front-commons/src/actions/flow";
import { getFlow, getFlowEnd, getFlowStart } from "student-front-commons/src/selectors/flow";
import {
  CLOSE_MASTERY_TEST_EXECUTION_FLOW,
  CLOSE_UNIT_EXECUTION_FLOW,
  PLAY_ITEM_VIDEO_FLOW,
  REQUIRE_USER_PLAY_FLOW,
} from "consts";
import { addBreadcrumb, logError } from "utils";
import {
  disableItemExecution,
  enableItemExecution,
  finishPlayItem,
  startPlayItem,
  updateItemMediaLoad,
  updateItemMediaProgress,
} from "student-front-commons/src/actions/itemExecution";
import { getCurrentItemExecutionProp } from "student-front-commons/src/selectors/itemExecution";

function playerEvents(player, listenTo) {
  return eventChannel((emitter) => {
    listenTo.map((event) => {
      LogRocket.log(`add event handler ${event}`);
      return player.on(event, (data) => {
        LogRocket.log({ event, data });
        emitter({ event, data });
      });
    });
    return () => {
      LogRocket.log("remove event handlers");
      listenTo.map((event) => player.off(event));
    };
  });
}

let player = null;

export default function* () {
  yield takeLatest(getFlowStart(PLAY_ITEM_VIDEO_FLOW), function* () {
    yield race({
      closeUnit: take(getFlowStart(CLOSE_UNIT_EXECUTION_FLOW)),
      closeMasteryTest: take(getFlowStart(CLOSE_MASTERY_TEST_EXECUTION_FLOW)),
      call: call(function* () {
        const flow = yield select(getFlow(PLAY_ITEM_VIDEO_FLOW));
        const isInitialPlay = get(flow.params, "initialPlay", false);

        try {
          yield put(disableItemExecution());

          // this is required, because we need the dom ready to start the player
          while (!document.getElementById("video-container")) {
            yield delay(100);
          }

          const item = yield select(getCurrentItemExecutionProp("item"));

          let videoId = "";
          if (!player || !videoId || !endsWith(item.videoLink, videoId)) {
            player = new Player("video-container", {
              url: item.videoLink,
              width: min([640, window.screen.availWidth - 120]),
              loop: false,
              title: false,
              keyboard: false,
              autoplay: false,
              controls: false,
              muted: false,
            });
            addBreadcrumb({
              category: "flow",
              message: `Video player loaded for ${item.videoLink}`,
            });
          }

          if (item.type.key === "VIDEO_SHORT") {
            // await video load, because we can not set cuepoints before video has a defined duration
            while (!(yield player.getDuration())) {
              yield delay(50);
            }

            const fixedStart = item.videoStartTime
              .match(/.{2}/g)
              .reduce((acc, value, index) => acc.concat(index ? ":" : "").concat(value), "");
            const fixedEnd = item.videoEndTime
              .match(/.{2}/g)
              .reduce((acc, value, index) => acc.concat(index ? ":" : "").concat(value), "");

            const startSeconds = moment.duration(fixedStart).asSeconds();
            const endSeconds = moment.duration(fixedEnd).asSeconds();

            const totalDuration = yield player.getDuration();
            yield player.addCuePoint(min([endSeconds, totalDuration - 0.5]));
            player.setCurrentTime(startSeconds);

            let currentTime = null;
            do {
              currentTime = yield player.getCurrentTime();
            } while (currentTime === null || currentTime > startSeconds);
          }

          try {
            yield new Promise((resolve, reject) => {
              const playHandler = ({ percent }) => {
                player.setMuted(false);
                player.setVolume(1);
                player.off("timeupdate", playHandler);
                resolve();
              };
              player.on("timeupdate", playHandler);
              player.play().catch((e) => {
                player.off("timeupdate", playHandler);
                reject(e);
              });
            });
          } catch (e) {
            LogRocket.log(e);
            yield put(startFlow(REQUIRE_USER_PLAY_FLOW));
            yield take(getFlowEnd(REQUIRE_USER_PLAY_FLOW));
            player.play().then(() => {
              player.setMuted(false);
              player.setVolume(1);
            });
          }

          yield put(startPlayItem(item.id, { isInitialPlay }));

          const events = ["cuepoint", "bufferend", "ended", "timeupdate", "progress", "play", "bufferstart", "error"];
          const channel = yield call(playerEvents, player, events);
          while (true) {
            const { event, data } = yield take(channel);

            addBreadcrumb({
              category: "flow",
              message: `Video ${event} for ${item.videoLink}`,
              data: {
                event,
                link: item.videoLink,
              },
            });

            if (event === "cuepoint") {
              yield put(finishPlayItem(item.id, { isInitialPlay }));
              yield player.pause();
              channel.close();
              return;
            }
            if (event === "error") {
              addBreadcrumb({
                category: "flow",
                message: `Video error ${data.name} on method ${data.method} with message: ${data.message}`,
              });
              if ((data.name === "NotAllowedError" || data.name === "AbortError") && data.method === "play") {
                yield put(startFlow(REQUIRE_USER_PLAY_FLOW));
                yield take(getFlowEnd(REQUIRE_USER_PLAY_FLOW));
                if (player) {
                  LogRocket.log("show play modal after error");
                  yield player.play();
                  yield player.setMuted(false);
                  yield player.setVolume(1);
                }
              }
            }
            if (event === "timeupdate" && item.type.key !== "VIDEO_SHORT") {
              yield put(updateItemMediaProgress(item.id, { duration: data.duration, currentTime: data.seconds }));
            }
            if (event === "progress" && item.type.key !== "VIDEO_SHORT") {
              yield put(updateItemMediaLoad(item.id, { percent: data.percent * 100 }));
            }
            if (event === "ended") {
              yield put(finishPlayItem(item.id, { isInitialPlay }));
              channel.close();
              return;
            }
          }
        } catch (error) {
          logError({ error, flow: PLAY_ITEM_VIDEO_FLOW });
        } finally {
          if (yield cancelled()) {
            if (player) {
              yield player.destroy();
              addBreadcrumb({
                category: "flow",
                message: "Player destroyed",
              });
              player = null;
            }
          } else {
            yield put(enableItemExecution());
          }
          yield put(endFlow(PLAY_ITEM_VIDEO_FLOW));
        }
      }),
    });
  });
}
