import head from "lodash/head";
import shuffle from "lodash/shuffle";
import LogRocket from "logrocket";
import { call, cancelled, delay, put, race, select, take, takeLatest } from "redux-saga/effects";
import { endFlow, startFlow } from "student-front-commons/src/actions/flow";
import { getFlow, getFlowEnd, getFlowStart } from "student-front-commons/src/selectors/flow";
import {
  CLOSE_MASTERY_TEST_EXECUTION_FLOW,
  CLOSE_UNIT_EXECUTION_FLOW,
  ERROR_MODAL_FLOW,
  PLAY_ITEM_AUDIO_FLOW,
  PLAY_ITEM_VIDEO_FLOW,
  REQUIRE_USER_PLAY_FLOW,
  SELECT_ITEM_FLOW,
} from "consts";
import { playAudio, stopAudio } from "stores/audio-store";
import { addBreadcrumb, logError } from "utils";
import locales from "locales";
import get from "lodash/get";
import { getItemExecutions } from "student-front-commons/src/selectors/itemExecution";
import { enableItemExecution, selectItem } from "student-front-commons/src/actions/itemExecution";
import { playExecutionInstruction } from "student-front-commons/src/actions/execution";

const typesToAutoSelectItem = [
  "VOCABULARY_GAME",
  "SINGLE_CHOICE_KIDS",
  "SINGLE_CHOICE_IMAGE",
  "SINGLE_CHOICE_GAME",
  "GAP_FILL_IMAGE",
  "GAP_FILL_LETTER",
  "TRUE_FALSE_KIDS",
  "VIDEO",
  "VIDEO_SHORT",
  "MUSIC",
  "GAP_FILL_MULTIPLE",
  "UNSCRAMBLE_DRAG_AND_DROP",
];

const typeToAutoPlay = [
  "VOCABULARY_GAME",
  "SINGLE_CHOICE_KIDS",
  "SINGLE_CHOICE_IMAGE",
  "SINGLE_CHOICE_GAME",
  "GAP_FILL_IMAGE",
  "GAP_FILL_LETTER",
  "TRUE_FALSE_KIDS",
  "VIDEO",
  "VIDEO_SHORT",
  "MUSIC",
  "MEMORY_GAME",
  "CONNECTING_DOTS",
];

let retryFlowCount = 1;

export default function* () {
  yield takeLatest(getFlowStart(SELECT_ITEM_FLOW), function* () {
    yield race({
      closeUnit: take(getFlowStart(CLOSE_UNIT_EXECUTION_FLOW)),
      closeMasteryTest: take(getFlowStart(CLOSE_MASTERY_TEST_EXECUTION_FLOW)),
      call: call(function* () {
        const flow = yield select(getFlow(SELECT_ITEM_FLOW));
        try {
          const isInitialSelect = get(flow.params, "initialSelect", false);
          const onlySelectFromAvailable = get(flow.params, "onlySelectFromAvailable", false);

          const itemExecutions = yield select(getItemExecutions);
          const itemType = head(itemExecutions).item.type;

          if (isInitialSelect && typesToAutoSelectItem.find((type) => type === itemType.key)) {
            const currentItem = head(
              shuffle(
                itemExecutions
                  .filter(
                    (itemExecution) =>
                      !onlySelectFromAvailable || (onlySelectFromAvailable && !itemExecution.isFinished)
                  )
                  .map((itemExecution) => itemExecution.item.id)
              )
            );
            yield put(selectItem(currentItem));
            addBreadcrumb({
              category: "flow",
              message: `Auto select item: ${currentItem}`,
            });
          }

          const initialInstructionsPlayed = yield select((state) => state.executions.initialInstructionsPlayed);
          if (
            isInitialSelect &&
            itemType.initialInstructionSound &&
            !initialInstructionsPlayed.some((type) => type === itemType.key)
          ) {
            yield delay(250);

            yield call(playAudio, {
              url: itemType.initialInstructionSound,
              rate: 1,
            });
            addBreadcrumb({
              category: "flow",
              message: `Played initial instruction for ${itemType.key}`,
            });
            yield put(playExecutionInstruction(itemType.key));
          }

          if (isInitialSelect && typeToAutoPlay.find((type) => type === itemType.key)) {
            yield delay(250);

            if (itemType.key === "VIDEO" || itemType.key === "VIDEO_SHORT" || itemType.key === "MUSIC") {
              yield put(startFlow(PLAY_ITEM_VIDEO_FLOW, { initialPlay: true }));
            } else {
              yield put(startFlow(PLAY_ITEM_AUDIO_FLOW, { initialPlay: true }));
            }
          } else {
            yield put(enableItemExecution());
          }

          if (!isInitialSelect && flow.params.item) {
            yield put(selectItem(flow.params.item));

            if (itemType.key === "VIDEO" || itemType.key === "VIDEO_SHORT" || itemType.key === "MUSIC") {
              yield put(startFlow(PLAY_ITEM_VIDEO_FLOW, { initialPlay: true }));
            } else {
              yield put(startFlow(PLAY_ITEM_AUDIO_FLOW, { initialPlay: true }));
            }
          }

          retryFlowCount = 1;
          yield put(endFlow(ERROR_MODAL_FLOW));
        } catch (error) {
          LogRocket.log(error);
          if (error === "required-user-click") {
            yield put(startFlow(REQUIRE_USER_PLAY_FLOW));
            yield take(getFlowEnd(REQUIRE_USER_PLAY_FLOW));
            stopAudio();

            yield delay(500);
            yield put(startFlow(SELECT_ITEM_FLOW, flow.params));
          } else {
            logError({ error, flow: SELECT_ITEM_FLOW });
            yield put(
              startFlow(ERROR_MODAL_FLOW, {
                message:
                  retryFlowCount > 3
                    ? get(locales, "unitExecution.error.selectItem.defaultMessage")
                    : get(locales, "unitExecution.error.selectItem.tryAgain"),
                retryFlow: {
                  id: SELECT_ITEM_FLOW,
                  params: flow.params,
                },
                attemptCount: retryFlowCount,
              })
            );
            retryFlowCount = retryFlowCount + 1;
          }
        } finally {
          if (yield cancelled()) {
            stopAudio();
          }
          yield put(endFlow(SELECT_ITEM_FLOW));
        }
      }),
    });
  });
}
