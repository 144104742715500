import React, { useContext } from "react";
import PropTypes from "prop-types";
import MediaQuery from "react-responsive";
import Row from "core/Row";
import Col from "core/Col";
import RecordButtonContainer from "core/RecordButtonContainer";
import ListenButtonContainer from "core/ListenButtonContainer";
import RepeatButtonContainer from "core/RepeatButtonContainer";
import ThemeContext from "core/ThemeContext";
import ControlBarGameScore from "core/ControlBarGameScore";
import ReadButtonContainer from "core/ReadButtonContainer";
import TranslateButtonContainer from "core/TranslateButtonContainer";
import { SM_SIZE } from "utils";
import { getItemsExecutionsType } from "student-front-commons/src/selectors/itemExecution";
import { useSelector } from "react-redux";
import NextButtonContainer from "./NextButtonContainer";
import ItemTimer from "./ItemTimer";

ControlBar.propTypes = {
  type: PropTypes.oneOf(["unit", "mastery-test"]).isRequired,
};

export default function ControlBar(props) {
  const themeContext = useContext(ThemeContext);

  const itemsExecutionsType = useSelector(getItemsExecutionsType);

  return (
    <Row
      justifyContent="center"
      alignItems="flex-end"
      flexShrink={0}
      position="relative"
      marginBottom={15}
      mediaQueries={{
        sm: `(max-width: ${SM_SIZE}px)`,
        md: `(min-width: ${SM_SIZE}px)`,
      }}
      smHeight={110}
      mdHeight={140}
    >
      <Col
        position="absolute"
        mediaQueries={{
          sm: `(max-width: ${SM_SIZE}px)`,
          md: `(min-width: ${SM_SIZE}px)`,
        }}
        smHeight={80}
        mdHeight={110}
        transition="height .3s"
        maxWidth={540}
        width="100%"
        borderRadius={55}
        marginBottom={5}
        backgroundColor={themeContext.secondary}
        zIndex={1}
        className="user-report-error-catcher"
      />
      <Row
        alignItems="center"
        justifyContent="space-between"
        maxWidth={580}
        width="100%"
        mediaQueries={{
          sm: `(max-width: ${SM_SIZE}px)`,
          md: `(min-width: ${SM_SIZE}px)`,
        }}
        smHeight={80}
        mdHeight={110}
        marginBottom={5}
        transition="height .3s"
        zIndex={2}
      >
        <Row
          mediaQueries={{
            sm: `(max-width: ${SM_SIZE}px)`,
            md: `(min-width: ${SM_SIZE}px)`,
          }}
          smWidth="calc(50% - 5px)"
          mdWidth="calc(50% - 80px)"
          justifyContent={["MEMORY_GAME"].find((type) => type === itemsExecutionsType) ? "flex-end" : "space-between"}
        >
          {!["MEMORY_GAME", "VOCABULARY_GAME"].find((type) => type === itemsExecutionsType) && (
            <RecordButtonContainer />
          )}
          {!["SINGLE_CHOICE_GAME", "CONNECTING_DOTS", "MEMORY_GAME", "VOCABULARY_GAME"].find(
            (type) => type === itemsExecutionsType
          ) && <ListenButtonContainer />}
          {!["SINGLE_CHOICE_GAME", "CONNECTING_DOTS", "MEMORY_GAME"].find((type) => type === itemsExecutionsType) && (
            <RepeatButtonContainer />
          )}
          {props.type === "unit" && themeContext.isGame && <ControlBarGameScore type="student" />}
        </Row>
        <MediaQuery minWidth={SM_SIZE}>{props.children}</MediaQuery>
        <Row
          mediaQueries={{
            sm: `(max-width: ${SM_SIZE}px)`,
            md: `(min-width: ${SM_SIZE}px)`,
          }}
          smWidth="calc(50% - 5px)"
          mdWidth="calc(50% - 80px)"
          alignItems="center"
          justifyContent="space-between"
        >
          {props.type === "unit" && themeContext.isGame && <ControlBarGameScore type="computer" />}
          {props.type === "unit" &&
            !["SINGLE_CHOICE_GAME", "CONNECTING_DOTS", "MEMORY_GAME", "VOCABULARY_GAME"].find(
              (type) => type === itemsExecutionsType
            ) && <ReadButtonContainer />}
          {props.type === "unit" &&
            !["SINGLE_CHOICE_GAME", "CONNECTING_DOTS", "MEMORY_GAME", "VOCABULARY_GAME"].find(
              (type) => type === itemsExecutionsType
            ) && <TranslateButtonContainer />}
          {props.type === "mastery-test" && <ItemTimer />}
          <NextButtonContainer />
        </Row>
      </Row>
    </Row>
  );
}
