import { call, cancelled, put, race, select, take, takeLatest } from "redux-saga/effects";
import sample from "lodash/sample";
import get from "lodash/get";
import last from "lodash/last";
import { getFlowStart } from "student-front-commons/src/selectors/flow";
import { endFlow, startFlow } from "student-front-commons/src/actions/flow";
import { checkAnswer } from "student-front-commons/src/services/itemService";
import {
  CHECK_ITEM_ANSWER_FLOW,
  CLOSE_MASTERY_TEST_EXECUTION_FLOW,
  CLOSE_UNIT_EXECUTION_FLOW,
  SAVE_MASTERY_TEST_ITEM_EXECUTION_ANSWER_FLOW,
  SAVE_TASTING_ITEM_EXECUTION_ANSWER_FLOW,
  SAVE_UNIT_ITEM_EXECUTION_ANSWER_FLOW,
  SELECT_ITEM_FLOW,
} from "consts";
import { playAudio, stopAudio } from "stores/audio-store";
import { addBreadcrumb, logError } from "utils";
import {
  addItemExecutionAnswer,
  addItemExecutionAttempt,
  disableItemExecution,
  enableItemExecution,
  finishItemExecution,
  finishPlayItemAccessory,
  hideItemCorrectOption,
  incrementItemExecutionError,
  showItemCorrectOption,
  startPlayItemAccessory,
  unselectItem,
} from "student-front-commons/src/actions/itemExecution";
import {
  getItemExecutionPropById,
  getItemExecutions,
  getSelectedId,
} from "student-front-commons/src/selectors/itemExecution";
import { incrementGameScoreType } from "student-front-commons/src/actions/execution";
import { getExecutionType } from "student-front-commons/src/selectors/execution";

const typesToGameCheck = ["CONNECTING_DOTS", "MEMORY_GAME", "VOCABULARY_GAME"];

const typesToCheckAnswer = [
  "SINGLE_CHOICE_IMAGE",
  "SINGLE_CHOICE_KIDS",
  "SINGLE_CHOICE_GAME",
  "GAP_FILL_IMAGE",
  "GAP_FILL_LETTER",
  "GAP_FILL_MULTIPLE",
  "TRUE_FALSE_KIDS",
];

const typesToShowCorrectAnswer = [
  "CONNECTING_DOTS",
  "SINGLE_CHOICE_GAME",
  "VOCABULARY_GAME",
  "GAP_FILL_IMAGE",
  "GAP_FILL_LETTER",
  "TRUE_FALSE_KIDS",
  "SINGLE_CHOICE_KIDS",
  "GAP_FILL_MULTIPLE",
  "SINGLE_CHOICE_IMAGE",
];

const typesToPlayPostPhraseAudio = ["CONNECTING_DOTS", "MEMORY_GAME", "TRUE_FALSE_KIDS", "VOCABULARY_GAME"];

const typesToPlayItemAudio = ["GAP_FILL_IMAGE", "GAP_FILL_LETTER", "GAP_FILL_MULTIPLE"];

const typesToAutoSave = [
  "SINGLE_CHOICE_IMAGE",
  "SINGLE_CHOICE_KIDS",
  "SINGLE_CHOICE_GAME",
  "GAP_FILL_LETTER",
  "TRUE_FALSE_KIDS",
  "GAP_FILL_MULTIPLE",
];

const typesToAutoSelectItem = ["VOCABULARY_GAME"];

const typeToChangeGameScore = ["SINGLE_CHOICE_GAME", "CONNECTING_DOTS", "MEMORY_GAME", "VOCABULARY_GAME"];

const typesToCleanWrongAnswer = [
  "SINGLE_CHOICE_IMAGE",
  "SINGLE_CHOICE_GAME",
  "GAP_FILL_LETTER",
  "GAP_FILL_IMAGE",
  "TRUE_FALSE_KIDS",
];

export default function* () {
  yield takeLatest(getFlowStart(CHECK_ITEM_ANSWER_FLOW), function* () {
    yield race({
      cancel: take(getFlowStart(CLOSE_UNIT_EXECUTION_FLOW)),
      closeMasteryTest: take(getFlowStart(CLOSE_MASTERY_TEST_EXECUTION_FLOW)),
      call: call(function* () {
        try {
          yield put(disableItemExecution());

          const executionType = yield select(getExecutionType);

          let saveFlow = {
            unit: SAVE_UNIT_ITEM_EXECUTION_ANSWER_FLOW,
            masteryTest: SAVE_MASTERY_TEST_ITEM_EXECUTION_ANSWER_FLOW,
          }[executionType];
          if (sessionStorage.getItem("id") === "tasting_user") {
            saveFlow = SAVE_TASTING_ITEM_EXECUTION_ANSWER_FLOW;
          }

          const selectedId = yield select(getSelectedId);

          let itemExecutions = yield select(getItemExecutions);
          let currentExecution = itemExecutions.find((itemExecution) => itemExecution.item.id === selectedId);

          if (typesToShowCorrectAnswer.find((type) => type === currentExecution.item.type.key)) {
            yield put(hideItemCorrectOption(currentExecution.item.id));
          }

          if (typesToCheckAnswer.find((type) => type === currentExecution.item.type.key)) {
            const answerResult = yield call(checkAnswer, {
              item: currentExecution.item,
              answer: currentExecution.answer,
            });
            const currentAnswer = {
              answer: answerResult.answer,
              correct: answerResult.status === "CORRECT",
            };
            yield put(addItemExecutionAttempt(currentExecution.item.id, { answer: currentAnswer }));
          } else if (typesToGameCheck.find((type) => type === currentExecution.item.type.key)) {
            const currentAnswer = {
              answer: currentExecution.answer,
              correct: currentExecution.item.id === currentExecution.answer,
            };
            yield put(addItemExecutionAttempt(currentExecution.item.id, { answer: currentAnswer }));
          }

          // all other flows (mastery, certification) will handle error in his own way
          if (![SAVE_UNIT_ITEM_EXECUTION_ANSWER_FLOW, SAVE_TASTING_ITEM_EXECUTION_ANSWER_FLOW].includes(saveFlow)) {
            yield put(startFlow(saveFlow));
            return;
          }

          itemExecutions = yield select(getItemExecutions);
          currentExecution = itemExecutions.find((itemExecution) => itemExecution.item.id === selectedId);

          if (last(currentExecution.attempts).correct) {
            yield put(finishItemExecution(currentExecution.item.id));

            addBreadcrumb({
              category: "flow",
              action: `Correct answer on Item ${currentExecution.item.id}`,
            });

            const correctAudios = yield select((state) => state.configurations.correctAudios);
            const randomCorrectAudio = sample(correctAudios);
            if (randomCorrectAudio) {
              yield call(playAudio, {
                rate: 1,
                url: randomCorrectAudio.path || randomCorrectAudio.generatedAudio,
              });
            }

            if (typesToPlayPostPhraseAudio.find((type) => type === currentExecution.item.type.key)) {
              yield put(startPlayItemAccessory(currentExecution.item.id, "post-phrase"));
              yield call(playAudio, {
                url: currentExecution.item.postPhraseAudio || currentExecution.item.generatedPostPhraseAudio,
                rate: 1,
              });
              yield put(finishPlayItemAccessory(currentExecution.item.id));
            }

            if (typesToPlayItemAudio.find((type) => type === currentExecution.item.type.key)) {
              yield call(playAudio, {
                rate: 1,
                url: currentExecution.item.audio || currentExecution.item.generatedAudio,
              });
            }

            if (["SINGLE_CHOICE_GAME", "SINGLE_CHOICE_KIDS"].find((type) => type === currentExecution.item.type.key)) {
              const correctAnswer = currentExecution.item.linkedAnswers.find((answer) => answer.correct);
              yield call(playAudio, {
                rate: 1,
                url: correctAnswer.audio || correctAnswer.generatedAudio,
              });
            }

            if (typeToChangeGameScore.find((type) => type === currentExecution.item.type.key)) {
              yield put(incrementGameScoreType("student"));
            }

            if (typesToAutoSave.find((type) => type === currentExecution.item.type.key)) {
              yield put(startFlow(saveFlow));
            }

            if (["GAP_FILL_IMAGE", "CONNECTING_DOTS"].find((type) => type === currentExecution.item.type.key)) {
              const answer = yield select(getItemExecutionPropById(currentExecution.item.id, "answer"));
              yield put(
                addItemExecutionAnswer(currentExecution.item.id, {
                  answer,
                  extraData: { isSpeechRecognitionRequired: true },
                })
              );
            }
            if (
              ["GAP_FILL_IMAGE", "CONNECTING_DOTS", "MEMORY_GAME"].find(
                (type) => type === currentExecution.item.type.key
              )
            ) {
              yield put(enableItemExecution());
            }

            if (typesToAutoSelectItem.find((type) => type === currentExecution.item.type.key)) {
              itemExecutions = yield select(getItemExecutions);
              const availableItems = itemExecutions.filter((execution) => !execution.isFinished);
              if (availableItems.length) {
                yield put(
                  startFlow(SELECT_ITEM_FLOW, {
                    initialSelect: true,
                    onlySelectFromAvailable: true,
                  })
                );
              } else {
                yield put(enableItemExecution());
              }
            } else {
              if (!["CONNECTING_DOTS", "GAP_FILL_IMAGE"].some((type) => type === currentExecution.item.type.key)) {
                yield put(unselectItem());
              }
            }
          } else {
            const errorCount = currentExecution.errorCount + 1;
            yield put(incrementItemExecutionError(currentExecution.item.id));

            addBreadcrumb({
              category: "flow",
              message: `Incorrect answer on Item ${currentExecution.item.id}`,
              data: {
                errorCount,
              },
            });

            if (typeToChangeGameScore.find((type) => type === currentExecution.item.type.key)) {
              yield put(incrementGameScoreType("computer"));
            }

            const errorAudios = yield select((state) => state.configurations.errorAudios);
            const randomErrorAudio = sample(errorAudios);
            if (randomErrorAudio) {
              yield call(playAudio, {
                rate: 1,
                url: randomErrorAudio.path || randomErrorAudio.generatedAudio,
              });
            }

            if (["MEMORY_GAME"].some((type) => type === currentExecution.item.type.key)) {
              const firstTryAudios = yield select((state) => state.configurations.firstTryAudios);
              const randomAudio = sample(firstTryAudios);
              if (randomAudio) {
                yield call(playAudio, {
                  rate: 1,
                  url: randomAudio.path || randomAudio.generatedAudio,
                });
              }

              yield put(addItemExecutionAnswer(currentExecution.item.id, { answer: "", extraData: {} }));
              yield put(unselectItem());
              yield put(enableItemExecution());
            } else if (errorCount === 1) {
              const firstTryAudios = yield select((state) => state.configurations.firstTryAudios);
              const randomAudio = sample(firstTryAudios);
              if (randomAudio) {
                yield call(playAudio, {
                  rate: 1,
                  url: randomAudio.path || randomAudio.generatedAudio,
                });
              }

              if (typesToCleanWrongAnswer.find((type) => type === currentExecution.item.type.key)) {
                yield put(addItemExecutionAnswer(currentExecution.item.id, { answer: "", extraData: {} }));
              }
              yield put(enableItemExecution());
            } else if (errorCount === 2) {
              if (typesToShowCorrectAnswer.find((type) => type === currentExecution.item.type.key)) {
                yield put(showItemCorrectOption(currentExecution.item.id));
              }

              const firstTryAudios = yield select((state) => state.configurations.firstTryAudios);
              const randomAudio = sample(firstTryAudios);
              if (randomAudio) {
                yield call(playAudio, {
                  rate: 1,
                  url: randomAudio.path || randomAudio.generatedAudio,
                });
              }

              if (typesToCleanWrongAnswer.find((type) => type === currentExecution.item.type.key)) {
                yield put(addItemExecutionAnswer(currentExecution.item.id, { answer: "", extraData: {} }));
              }
              yield put(enableItemExecution());
            } else {
              const secondTryAudios = yield select((state) => state.configurations.secondTryAudios);
              const randomAudio = sample(secondTryAudios);
              if (randomAudio) {
                yield call(playAudio, {
                  rate: 1,
                  url: randomAudio.path || randomAudio.generatedAudio,
                });
              }

              if (typesToCleanWrongAnswer.find((type) => type === currentExecution.item.type.key)) {
                yield put(addItemExecutionAnswer(currentExecution.item.id, { answer: "", extraData: {} }));
              }

              if (typesToShowCorrectAnswer.find((type) => type === currentExecution.item.type.key)) {
                yield put(showItemCorrectOption(currentExecution.item.id));
              }

              if (
                ["CONNECTING_DOTS", "TRUE_FALSE_KIDS", "VOCABULARY_GAME"].some(
                  (type) => currentExecution.item.type.key === type
                )
              ) {
                yield call(playAudio, {
                  rate: 1,
                  isCompleteUrl: false,
                  url: currentExecution.item.postPhraseAudio || currentExecution.item.generatedPostPhraseAudio,
                });
              }

              if (typesToPlayItemAudio.find((type) => type === currentExecution.item.type.key)) {
                yield call(playAudio, {
                  rate: 1,
                  isCompleteUrl: false,
                  url: currentExecution.item.audio || currentExecution.item.generatedAudio,
                });
              }

              if (
                ["SINGLE_CHOICE_GAME", "SINGLE_CHOICE_KIDS", "SINGLE_CHOICE_IMAGE"].find(
                  (type) => type === currentExecution.item.type.key
                )
              ) {
                const correctAnswer = currentExecution.item.linkedAnswers.find((answer) => answer.correct);
                yield call(playAudio, {
                  rate: 1,
                  isCompleteUrl: false,
                  url: correctAnswer.audio || correctAnswer.generatedAudio,
                });
              }

              if (typesToShowCorrectAnswer.find((type) => type === currentExecution.item.type.key)) {
                yield put(hideItemCorrectOption(currentExecution.item.id));
              }

              yield put(finishItemExecution(currentExecution.item.id));

              if (typesToAutoSave.find((type) => type === currentExecution.item.type.key)) {
                yield put(startFlow(saveFlow));
              }

              if (["GAP_FILL_IMAGE", "CONNECTING_DOTS"].find((type) => type === currentExecution.item.type.key)) {
                const answer = yield select(getItemExecutionPropById(currentExecution.item.id, "answer"));
                yield put(
                  addItemExecutionAnswer(currentExecution.item.id, {
                    answer,
                    extraData: { isSpeechRecognitionRequired: true },
                  })
                );
                yield put(enableItemExecution());
              }

              if (typesToAutoSelectItem.find((type) => type === currentExecution.item.type.key)) {
                itemExecutions = yield select(getItemExecutions);
                const availableItems = itemExecutions.filter((execution) => !execution.isFinished);
                if (availableItems.length) {
                  yield put(
                    startFlow(SELECT_ITEM_FLOW, {
                      initialSelect: true,
                      onlySelectFromAvailable: true,
                    })
                  );
                } else {
                  yield put(enableItemExecution());
                }
              } else {
                if (!["CONNECTING_DOTS", "GAP_FILL_IMAGE"].some((type) => type === currentExecution.item.type.key)) {
                  yield put(unselectItem());
                }
              }
            }

            if (currentExecution.item.type.key === "CONNECTING_DOTS") {
              window.jsPlumb.deleteConnectionsForElement(`item-${get(currentExecution.item, "id", false)}`);
              window.jsPlumb.deleteConnectionsForElement(`answer-${get(currentExecution.item, "id", false)}`);
            }
          }
        } catch (error) {
          logError({ error, flow: CHECK_ITEM_ANSWER_FLOW });
        } finally {
          if (yield cancelled()) {
            stopAudio();
          }
          yield put(endFlow(CHECK_ITEM_ANSWER_FLOW));
        }
      }),
    });
  });
}
