import React, { useCallback, useMemo } from "react";
import { addBreadcrumb } from "../utils";
import { useSelector } from "react-redux";
import {
  getCurrentItemExecutionProp,
  getItemsExecutionsType,
  getSelectedId,
} from "student-front-commons/src/selectors/itemExecution";
import { useFlow } from "student-front-commons/src/hooks";
import {
  CHECK_ITEM_ANSWER_FLOW,
  SAVE_MASTERY_TEST_ITEM_EXECUTION_ANSWER_FLOW,
  SAVE_TASTING_ITEM_EXECUTION_ANSWER_FLOW,
  SAVE_UNIT_ITEM_EXECUTION_ANSWER_FLOW,
} from "../consts";
import last from "lodash/last";
import { values } from "lodash";
import {
  isInstructionEnableForStep,
  isInstructionHighlighted,
} from "student-front-commons/src/selectors/itemInstruction";
import NextButton from "./NextButton";
import { getExecutionType } from "student-front-commons/src/selectors/execution";

function NextButtonContainer() {
  const executionType = useSelector(getExecutionType);

  const selectedId = useSelector(getSelectedId);
  const itemType = useSelector(getItemsExecutionsType);
  const isRecording = useSelector(getCurrentItemExecutionProp("isRecording"));
  const isSubmittingRecord = useSelector(getCurrentItemExecutionProp("isSubmittingRecord"));
  const isDisabled = useSelector(getCurrentItemExecutionProp("isDisabled"));
  const isFinished = useSelector(getCurrentItemExecutionProp("isFinished"));
  const attempts = useSelector(getCurrentItemExecutionProp("attempts"));
  const isSpeechRecognitionRequired = useSelector(getCurrentItemExecutionProp("extraData.isSpeechRecognitionRequired"));
  const isAllItemsAnswered = useSelector((state) =>
    values(state.itemExecutions.byId).every((item) => item.attempts?.length > 0)
  );

  const isInstructionEnabled = useSelector(isInstructionEnableForStep("user-away"));
  const shouldHighlighted = useSelector(isInstructionHighlighted);

  const [isSubmitting, startCheck] = useFlow(CHECK_ITEM_ANSWER_FLOW);
  const [isSaving, startSaving] = useFlow(
    { unit: SAVE_UNIT_ITEM_EXECUTION_ANSWER_FLOW, masteryTest: SAVE_MASTERY_TEST_ITEM_EXECUTION_ANSWER_FLOW }[
      executionType
    ]
  );
  const [isSavingTasting, startSavingTasting] = useFlow(SAVE_TASTING_ITEM_EXECUTION_ANSWER_FLOW);

  const isNextDisabled = () => {
    if (["MEMORY_GAME", "VOCABULARY_GAME"].find((type) => type === itemType)) {
      return isDisabled || !isAllItemsAnswered;
    }
    if ("CONNECTING_DOTS" === itemType) {
      return isDisabled || !isAllItemsAnswered || isSpeechRecognitionRequired;
    }

    if (["PHONEME", "VOCABULARY"].find((type) => type === itemType)) {
      return isDisabled || isRecording || isSubmittingRecord || !isAllItemsAnswered;
    }
    if (["VIDEO_SHORT"].find((type) => type === itemType)) {
      return isDisabled || isRecording || isSubmittingRecord || (!last(attempts)?.correct && attempts?.length < 3);
    }
    if (["VIDEO", "MUSIC"].find((type) => type === itemType)) {
      return isDisabled;
    }
    if (["GAP_FILL_IMAGE"].find((type) => type === itemType)) {
      return isDisabled || !selectedId || !isFinished || isSpeechRecognitionRequired;
    }
    return true;
  };

  const typeToOnlySave = useMemo(
    () => [
      "PHONEME",
      "VOCABULARY",
      "VIDEO_SHORT",
      "CONNECTING_DOTS",
      "MEMORY_GAME",
      "VOCABULARY_GAME",
      "GAP_FILL_IMAGE",
      "VIDEO",
      "MUSIC",
    ],
    []
  );

  const handleClick = useCallback(() => {
    const isTasting = sessionStorage.getItem("id") === "tasting_user";

    if (typeToOnlySave.some((type) => type === itemType)) {
      isTasting ? startSavingTasting() : startSaving();
    } else {
      startCheck();
    }
    addBreadcrumb({
      category: "ui",
      message: `Click to check and save unit items`,
    });
  }, [itemType, typeToOnlySave, startCheck, startSavingTasting, startSaving]);

  return (
    <NextButton
      onClick={handleClick}
      isDisabled={isNextDisabled()}
      isLoading={
        typeToOnlySave.some((type) => type === itemType)
          ? isSaving || isSavingTasting
          : isSubmitting || isSaving || isSavingTasting
      }
      shouldShake={!isNextDisabled() && isInstructionEnabled && shouldHighlighted}
    />
  );
}

export default NextButtonContainer;
