import React from "react";
import GradesHeader from "screens/grades/GradesHeader";
import { useEntity } from "student-front-commons/src/hooks";
import { COMPANY_SCHEMA, PROFILE_SCHEMA, SCHOOL_CLASS_SCHEMA, SCHOOL_SCHEMA } from "student-front-commons/src/schemas";

function GradesHeaderContainer() {
  const student = useEntity(PROFILE_SCHEMA, sessionStorage.getItem("id"));
  const schoolClass = useEntity(SCHOOL_CLASS_SCHEMA, student.schoolClass);
  const school = useEntity(SCHOOL_SCHEMA, schoolClass?.school);
  const company = useEntity(COMPANY_SCHEMA, student.company);

  return (
    <GradesHeader
      student={student.name}
      teacher={student.apiVersion === "V2" ? student.techer?.name : schoolClass.teacher?.name}
      classroom={schoolClass?.name}
      school={student.apiVersion === "V2" ? company.name : school?.name}
      maximumGrade={student.apiVersion === "V2" ? company.maximumGrade : school?.maximumGrade}
      weeklyHoursRequired={student.apiVersion === "V2" ? student.weeklyHoursRequired : schoolClass.weeklyHoursRequired}
    />
  );
}

export default GradesHeaderContainer;
