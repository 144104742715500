import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import "moment-duration-format";
import Text from "core/Text";
import Col from "core/Col";
import { BLACK_1, GREY_3, GREY_4 } from "consts/colors";
import TranslatedText from "core/TranslatedText";

const GradesListHourColumn = ({ grade, requiredHours, studiedHours, isPreview }) => (
  <Col width="22%" justifyContent="center" alignItems="center">
    <Text size={32} color={isPreview ? GREY_3 : BLACK_1} boldType="bold">
      {grade || "N/A"}
    </Text>
    <TranslatedText
      translateKey="grades.totalStudyTimePeriod"
      values={{
        time: moment.duration(studiedHours, "hours").format("hh:mm", { trim: false }),
      }}
      size={13}
      color={isPreview ? GREY_3 : GREY_4}
      boldType="light-bold"
    />
    <TranslatedText
      translateKey="grades.requiredStudyTimePeriod"
      values={{
        time: moment.duration(requiredHours, "hours").format("hh:mm", { trim: false }),
      }}
      size={13}
      color={isPreview ? GREY_3 : GREY_4}
      boldType="light-bold"
    />
  </Col>
);

GradesListHourColumn.propTypes = {
  grade: PropTypes.number,
  requiredHours: PropTypes.number,
  studiedHours: PropTypes.number,
  isPreview: PropTypes.bool.isRequired,
};

export default GradesListHourColumn;
