import React, { useContext } from "react";
import PropTypes from "prop-types";
import get from "lodash/get";
import inRange from "lodash/inRange";
import toNumber from "lodash/toNumber";
import Col from "core/Col";
import Text from "core/Text";
import Separator from "core/Separator";
import Card from "core/Card";
import Spinner from "core/Spinner";
import UnitTypeThemeContext from "core/ThemeContext";
import { WHITE, YELLOW_4 } from "consts/colors";
import Row from "core/Row";
import { SM_SIZE } from "utils";
import { useSelector } from "react-redux";
import { getItemExecutionPropById } from "student-front-commons/src/selectors/itemExecution";
import { head } from "lodash";

function VideoItemSubtitles(props) {
  const unitTypeThemeContext = useContext(UnitTypeThemeContext);

  const subtitles = useSelector(getItemExecutionPropById(props.itemId, "item.subtitles"));
  const currentTime = useSelector(getItemExecutionPropById(props.itemId, "playingStats.currentTime"));

  if (!subtitles || !subtitles.length) {
    return null;
  }

  return (
    <React.Fragment>
      <Separator size="lg" />
      <Row
        bottom={-20}
        position="absolute"
        alignItems="center"
        justifyContent="center"
        alignSelf="center"
        backgroundColor={unitTypeThemeContext.secondary}
        borderRadius={20}
        padding="0px 30px"
        minHeight={40}
        minWidth="50%"
        width="auto"
        transition="width .3s"
      >
        {get(
          subtitles.find((subtitleItem) =>
            inRange(currentTime, toNumber(subtitleItem.start), toNumber(subtitleItem.end) + 0.1)
          ),
          "slices",
          []
        ).map((slice, index) => (
          <React.Fragment key={slice.start}>
            {!!index && <Separator size="xs" />}
            <Text boldType="light-bold" size={22} color={currentTime >= toNumber(slice.start) ? YELLOW_4 : WHITE}>
              {slice.text}
            </Text>
          </React.Fragment>
        ))}
      </Row>
    </React.Fragment>
  );
}

function VideoItemProgressBar(props) {
  const unitTypeThemeContext = useContext(UnitTypeThemeContext);

  const loadedPercent = useSelector(getItemExecutionPropById(props.itemId, "loadingStats.percent"));
  const duration = useSelector(getItemExecutionPropById(props.itemId, "playingStats.duration"));
  const currentTime = useSelector(getItemExecutionPropById(props.itemId, "playingStats.currentTime"));

  return (
    <Col position="relative" width="100%" height={4} backgroundColor="rgba(0, 0, 0, 0.3)" overflow="hidden">
      <Col
        position="absolute"
        backgroundColor={unitTypeThemeContext.primary}
        width={`${loadedPercent}%`}
        height="100%"
        transition="width .5s"
        opacity={0.8}
      />
      <Col
        position="absolute"
        backgroundColor={unitTypeThemeContext.secondary}
        width={`${(currentTime / duration) * 100}%`}
        height="100%"
        transition="width .5s"
      />
    </Col>
  );
}

function VideoItemRender(props) {
  const unitTypeThemeContext = useContext(UnitTypeThemeContext);

  const itemTitle = useSelector(getItemExecutionPropById(props.itemId, "item.title"));
  const itemTypeKey = useSelector(getItemExecutionPropById(props.itemId, "item.type.key"));
  const isDisabled = useSelector(getItemExecutionPropById(props.itemId, "isDisabled"));
  const playingId = useSelector(getItemExecutionPropById(props.itemId, "playingId"));

  const showProgressBar = itemTypeKey !== "VIDEO_SHORT";

  return (
    <Col alignItems="center">
      <Card
        mediaQueries={{
          sm: `(max-width: ${SM_SIZE}px)`,
          md: `(min-width: ${SM_SIZE}px)`,
        }}
        width="90%"
        smMaxWidth={SM_SIZE}
        mdMaxWidth={680}
        alignItems="center"
        padding={20}
        showRopes={true}
        ropesStyle={{
          backgroundColor: unitTypeThemeContext.secondary,
        }}
        betweenRopesStyle={{
          width: "70%",
        }}
      >
        <Col
          alignItems="center"
          justifyContent="center"
          backgroundColor={unitTypeThemeContext.secondary}
          borderRadius={20}
          minHeight={40}
          minWidth="65%"
          maxWidth="80%"
          padding="0px 20px"
          boxSizing="border-box"
        >
          <Text boldType="light-bold" size={22} color={WHITE} align="center">
            {itemTitle}
          </Text>
        </Col>
        <Separator size="xxl" />
        <Col
          alignItems="center"
          justifyContent="center"
          mediaQueries={{
            sm: `(max-width: ${SM_SIZE}px)`,
            md: `(min-width: ${SM_SIZE}px)`,
          }}
          smWidth="80%"
          mdWidth={640}
          position="relative"
        >
          {!playingId && isDisabled && (
            <Col position="absolute">
              <Spinner size="xs" />
            </Col>
          )}
          <Col
            props={{
              id: "video-container",
            }}
            key="video-container"
          />
          {showProgressBar && <VideoItemProgressBar itemId={props.itemId} />}
        </Col>
        <VideoItemSubtitles itemId={props.itemId} />
      </Card>
      <Separator size="xl" />
    </Col>
  );
}

VideoItemRender.propTypes = {
  itemId: PropTypes.string.isRequired,
};

function VideoItem() {
  const items = useSelector((state) => state.itemExecutions.allIds);
  const itemVideoLink = useSelector(
    (state) => state.itemExecutions.byId[head(state.itemExecutions.allIds)]?.item?.videoLink
  );

  return items.map((item) => <VideoItemRender key={`item-${itemVideoLink}`} itemId={item} />);
}

export default VideoItem;
