import React from "react";
import PropTypes from "prop-types";
import range from "lodash/range";
import toInteger from "lodash/toInteger";
import isFinite from "lodash/isFinite";
import Row from "core/Row";
import Col from "core/Col";
import StudyQualityStarIcon from "core/StudyQualityStarIcon";
import InBetweenSpacing from "core/InBetweenSpacing";
import { GREY_4 } from "consts/colors";
import TranslatedText from "core/TranslatedText";

export default class StudyQualityDetailRequirement extends React.Component {
  static propTypes = {
    item: PropTypes.shape({
      requirement: PropTypes.string,
      firstScore: PropTypes.number,
      secondScore: PropTypes.number,
      thirdScore: PropTypes.number,
    }).isRequired,
  };

  getScoreByType = (item) => {
    const getScore = (divisor) => {
      if (isFinite(item.secondScore) && isFinite(item.thirdScore)) {
        return toInteger(
          (item.firstScore * 2.5) / divisor +
            ((item.secondScore || 0) * 1.6) / divisor +
            ((item.thirdScore || 0) * 0.9) / divisor
        );
      }
      return toInteger((item.firstScore / divisor) * 5);
    };

    if (
      ["DAYS_STUDIED", "DAYS_STUDIED_AT_LEAST_30_MINUTES", "UNIT_AVERAGE_SCORE", "TIME_STUDIED"].find(
        (type) => type === item.requirement
      )
    ) {
      return getScore(3);
    } else if (
      ["SINGLE_CHOICE_AVERAGE_SCORE", "SPEECH_RECOGNITION_AVERAGE_SCORE", "LISTEN_USAGE"].find(
        (type) => type === item.requirement
      )
    ) {
      return getScore(2);
    } else if (item.requirement === "REPEAT_USAGE") {
      return getScore(4);
    }
    return null;
  };

  render() {
    return (
      <Row alignItems="center" minHeight={40} padding="5px 0px">
        <Col flexGrow={1} width="45%" maxWidth={470}>
          <TranslatedText
            translateKey={`studyQuality.requirements.${this.props.item.requirement}`}
            size={14}
            boldType="light-bold"
            color={GREY_4}
          />
        </Col>
        <InBetweenSpacing size="sm">
          {range(5).map((index) => (
            <StudyQualityStarIcon
              key={`${this.props.item.requirement}-${index}`}
              isActive={this.getScoreByType(this.props.item) > index}
              size="xs"
            />
          ))}
        </InBetweenSpacing>
      </Row>
    );
  }
}
