import { call, put, select, takeLatest } from "redux-saga/effects";
import { mergeEntities } from "student-front-commons/src/actions/entity";
import { endFlow } from "student-front-commons/src/actions/flow";
import { getFlowStart } from "student-front-commons/src/selectors/flow";
import { CLOSE_UNIT_EXECUTION_FLOW, ERROR_MODAL_FLOW, REQUIRE_USER_PLAY_FLOW } from "consts";
import { clearAudios } from "stores/audio-store";
import { clearImages } from "stores/image-store";
import { logError } from "utils";
import { getModulesByCourse } from "student-front-commons/src/services/moduleService";
import { cleanExecution } from "student-front-commons/src/actions/execution";

export default function* () {
  yield takeLatest(getFlowStart(CLOSE_UNIT_EXECUTION_FLOW), function* () {
    try {
      const unitExecution = yield select((state) => state.executions);
      if (unitExecution && unitExecution.id) {
        const modules = yield call(getModulesByCourse, {
          id: sessionStorage.getItem("id"),
          course: sessionStorage.getItem("course"),
        });
        yield put(mergeEntities(modules.entities));
      }

      clearAudios();
      clearImages();

      yield put(cleanExecution());

      if (window.jsPlumb) {
        window.jsPlumb.deleteEveryConnection();
      }
      yield put(endFlow(REQUIRE_USER_PLAY_FLOW));
      yield put(endFlow(ERROR_MODAL_FLOW));
    } catch (error) {
      logError({ error, flow: CLOSE_UNIT_EXECUTION_FLOW });
    } finally {
      yield put(endFlow(CLOSE_UNIT_EXECUTION_FLOW));
    }
  });
}
