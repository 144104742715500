import { call, cancelled, put, race, select, take, takeLatest } from "redux-saga/effects";
import sample from "lodash/sample";
import last from "lodash/last";
import { getFlowStart } from "student-front-commons/src/selectors/flow";
import { endFlow, startFlow } from "student-front-commons/src/actions/flow";
import {
  CLOSE_MASTERY_TEST_EXECUTION_FLOW,
  GET_NEXT_ITEM_EXECUTION_FLOW,
  SAVE_MASTERY_TEST_ITEM_EXECUTION_ANSWER_FLOW,
} from "consts";
import browserHistory from "browserHistory";
import { playAudio, stopAudio } from "stores/audio-store";
import { logError } from "utils";
import {
  disableItemExecution,
  enableItemExecution,
  incrementItemExecutionError,
} from "student-front-commons/src/actions/itemExecution";
import { getItemExecutions } from "student-front-commons/src/selectors/itemExecution";
import { getAssociativeItemOrderByItemId } from "student-front-commons/src/selectors/execution";
import { addExecutionAnswer, setNextExecutionOrder } from "student-front-commons/src/actions/execution";
import { saveMasteryTestItemExecution } from "student-front-commons/src/services/masteryTestExecutionService";

const typesWithExtraAttempts = [];

export default function* () {
  yield takeLatest(getFlowStart(SAVE_MASTERY_TEST_ITEM_EXECUTION_ANSWER_FLOW), function* () {
    yield race({
      cancel: take(getFlowStart(CLOSE_MASTERY_TEST_EXECUTION_FLOW)),
      call: call(function* () {
        try {
          yield put(disableItemExecution());

          let execution = yield select((state) => state.executions);
          const itemExecutions = yield select(getItemExecutions);

          const hsWrongItems = yield itemExecutions.reduce(function* (acc, itemExecution) {
            const shouldSkip = yield acc;

            if (
              typesWithExtraAttempts.some((type) => type === itemExecution.item.type.key) &&
              !last(itemExecution.attempts).correct &&
              itemExecution.errorCount < 3
            ) {
              yield put(incrementItemExecutionError(itemExecution.item.id));

              const firstTryAudios = yield select((state) => state.configurations.firstTryAudios);
              const randomAudio = sample(firstTryAudios);
              yield call(playAudio, {
                rate: 1,
                url: randomAudio.path || randomAudio.generatedAudio,
              });

              return true;
            }

            return shouldSkip;
          }, Promise.resolve(false));
          if (hsWrongItems) {
            yield put(enableItemExecution());
            return true;
          }

          yield itemExecutions.reduce(function* (acc, itemExecution) {
            yield acc;

            const answer = yield call(saveMasteryTestItemExecution, {
              module: execution.module,
              masteryTest: execution.masteryTest,
              masteryTestExecution: execution.id,
              item: itemExecution.item.id,
              correct:
                itemExecution.item.type.key === "VIDEO" ||
                itemExecution.item.type.key === "MUSIC" ||
                last(itemExecution.attempts).correct,
              userAwayCount: itemExecution.userAwayCount,
              errorCount: itemExecution.errorCount,
              repeatCount: itemExecution.repeatCount,
              recordCount: itemExecution.recordCount,
              listenCount: itemExecution.listenCount,
              readCount: itemExecution.readCount,
              translateCount: itemExecution.translateCount,
              timeReportingError: 0,
              attempts: itemExecution.attempts,
              audio: itemExecution.recordFile,
            });

            const associativeItemOrder = yield select(getAssociativeItemOrderByItemId(itemExecution.item.id));
            yield put(addExecutionAnswer({ answer: answer.id, itemOrder: associativeItemOrder }));

            return Promise.resolve();
          }, Promise.resolve());

          execution = yield select((state) => state.executions);
          if (execution.answers.length >= execution.associativeItems.length) {
            browserHistory.replace(`${browserHistory.location.pathname}/${execution.id}/result`);
          } else {
            yield put(setNextExecutionOrder());
            yield put(startFlow(GET_NEXT_ITEM_EXECUTION_FLOW));
          }
        } catch (error) {
          logError({
            error,
            flow: SAVE_MASTERY_TEST_ITEM_EXECUTION_ANSWER_FLOW,
          });
        } finally {
          if (yield cancelled()) {
            stopAudio();
          }
          yield put(endFlow(SAVE_MASTERY_TEST_ITEM_EXECUTION_ANSWER_FLOW));
        }
      }),
    });
  });
}
