import React from "react";
import PropTypes from "prop-types";
import WhitelabelContext from "student-front-commons/src/components/WhitelabelContext";
import Col from "core/Col";
import { BLACK_1 } from "consts/colors";
import TranslatedText from "core/TranslatedText";

const GradesListHeaderItem = ({ label, values, ...rest }) => (
  <WhitelabelContext.Consumer>
    {({ primaryColor }) => (
      <Col
        alignItems="center"
        justifyContent="center"
        borderBottomColor={primaryColor}
        borderBottomStyle="solid"
        borderBottomWidth={2}
        padding="5px 0px"
        {...rest}
      >
        <TranslatedText translateKey={label} values={values} size={16} boldType="bold" color={BLACK_1} />
      </Col>
    )}
  </WhitelabelContext.Consumer>
);

GradesListHeaderItem.propTypes = {
  label: PropTypes.string.isRequired,
  values: PropTypes.object,
};

export default GradesListHeaderItem;
