import React from "react";
import PropTypes from "prop-types";
import { Grid as GridComponent } from "jsxstyle";

const Grid = ({ columnWidth, rowGap, children, ...rest }) => (
  <GridComponent
    gridTemplateColumns={`repeat(auto-fill, minmax(${columnWidth}px, 1fr));`}
    gridRowGap={rowGap}
    justifyItems="center"
    {...rest}
  >
    {children}
  </GridComponent>
);

Grid.propTypes = {
  columnWidth: PropTypes.number.isRequired,
  rowGap: PropTypes.number.isRequired,
  children: PropTypes.node.isRequired,
};

export default Grid;
