import React from "react";
import PropTypes from "prop-types";
import { Block } from "jsxstyle";
import { GREY_1, GREY_2, GREY_3, GREY_4, RED_2 } from "consts/colors";
import Col from "core/Col";
import withTranslation from "core/withTranslation";
import TranslatedText from "core/TranslatedText";

const Input = ({ placeholder, value, type, errorText, isDisabled, onChange, onKeyDown, getTranslation }) => (
  <Col>
    <Col
      component="input"
      props={{
        disabled: isDisabled,
        placeholder: getTranslation(placeholder),
        value,
        onChange: (event) => onChange(event.target.value),
        onKeyDown,
        type,
      }}
      borderRadius={25}
      fontSize={16}
      border={`1px solid ${!!errorText ? RED_2 : GREY_2}`}
      backgroundColor={GREY_1}
      color={GREY_4}
      placeholderColor={GREY_3}
      fontFamily="Baloo 2"
      placeholderFontFamily="Baloo 2"
      cursor={isDisabled ? "not-allowed" : "auto"}
      opacity={isDisabled ? 0.5 : 1}
      transition="opacity .5s"
      padding="12px 30px"
      outline="none"
      boxShadow="0px 1px 3px 0 rgba(0, 0, 0, 0.2);"
      autoComplete="false"
    />
    {errorText && (
      <Block marginTop={5} marginLeft={30}>
        <TranslatedText translateKey={`error.${errorText.toLowerCase().replace(/ /g, "_")}`} color={RED_2} size={11} />
      </Block>
    )}
  </Col>
);

Input.propTypes = {
  placeholder: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  onKeyDown: PropTypes.func,
  isDisabled: PropTypes.bool,
  errorText: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  value: PropTypes.oneOfType([PropTypes.string]),
  type: PropTypes.oneOf(["text", "password"]),
};

Input.defaultProps = {
  errorText: null,
  onChange: null,
  onKeyDown: null,
  value: "",
  type: "text",
  isDisabled: false,
};

export default withTranslation(Input);
