import React from "react";
import { Link } from "react-router-dom";
import MediaQuery from "react-responsive";
import Row from "core/Row";
import Logo from "core/Logo";
import Separator from "core/Separator";
import ScreenContainer from "core/ScreenContainer";
import MenuContainer from "core/MenuContainer";
import RankingIcon from "core/RankingIcon";
import WeekTimeContainer from "core/WeekTimeContainer";
import StudentInfoContainer from "core/StudentInfoContainer";
import RankingParticipantContainer from "core/RankingParticipantContainer";
import { WHITE } from "consts/colors";
import { MD_SIZE } from "utils";

class Header extends React.PureComponent {
  render() {
    return (
      <Row
        width="100%"
        height={79}
        minHeight={79}
        boxShadow="0px 1px 3px 0 rgba(0, 0, 0, 0.2);"
        backgroundColor={WHITE}
        zIndex={10}
        boxSizing="border-box"
        position="absolute"
      >
        <ScreenContainer>
          <Row justifyContent="space-between" alignItems="center" flexGrow={1}>
            <Link to="/">
              <Logo height={60} width="auto" />
            </Link>
            {sessionStorage.getItem("id") !== "tasting_user" && (
              <Row alignItems="center">
                <WeekTimeContainer />
                <StudentInfoContainer />
                <RankingParticipantContainer>
                  {({ isRankingParticipant }) =>
                    isRankingParticipant && (
                      <MediaQuery maxWidth={MD_SIZE}>
                        <Link to="/ranking">
                          <RankingIcon size={60} />
                        </Link>
                        <Separator size="sm" />
                      </MediaQuery>
                    )
                  }
                </RankingParticipantContainer>
                <MenuContainer />
              </Row>
            )}
          </Row>
        </ScreenContainer>
      </Row>
    );
  }
}

export default Header;
