import React from "react";
import PropTypes from "prop-types";
import { Block } from "jsxstyle";
import Separator from "core/Separator";
import RankingListItem from "core/RankingListItem";
import CircularDivider from "core/CircularDivider";
import Col from "core/Col";
import Spinner from "core/Spinner";
import { GREY_4 } from "consts/colors";
import TranslatedText from "core/TranslatedText";

const RankingList = ({ students, isLoading }) => (
  <React.Fragment>
    {isLoading && (
      <Col flexGrow={1} justifyContent="center" alignItems="center" padding={20}>
        <Spinner size="xs" />
      </Col>
    )}
    {!isLoading && !students.length && (
      <Col flexGrow={1} justifyContent="center" alignItems="center" padding={20}>
        <TranslatedText translateKey="ranking.noDataMessage" color={GREY_4} />
      </Col>
    )}
    {!isLoading && !!students.length && (
      <Block component="ul" margin={0} padding={20}>
        {students.map((student, index) => (
          <Block key={`ranking-${student.id}`} component="li">
            {!!index && <Separator size="sm" />}
            <RankingListItem student={student} />
            {index === 2 && (
              <React.Fragment>
                <Separator size="sm" />
                <CircularDivider />
              </React.Fragment>
            )}
          </Block>
        ))}
      </Block>
    )}
  </React.Fragment>
);

RankingList.propTypes = {
  students: PropTypes.arrayOf(
    PropTypes.shape({
      position: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      nickname: PropTypes.string,
      points: PropTypes.number.isRequired,
      pictureUrl: PropTypes.string,
    })
  ).isRequired,
};

export default RankingList;
