const labels = { 
'attention': 'Attention!', 
'checkIntroduction': 'Listen to the instructions', 
'close': 'Close', 
'courseScreen.chooseCourse': 'Choose your course', 
'courseScreen.greetings': 'Hello, {name} ;)', 
'error.invalid_date': 'Invalid data', 
'error.invalid_email': 'Invalid email', 
'error.invalid_format': 'Invalid format', 
'error.microphoneNotFound': 'Unable to access your microphone. Please check your browser settings.', 
'error.noMicrophonePermission': 'The permission to access the microphone has not been accepted, please check your browser settings.', 
'error.noSpeechDetected': 'Your speech could not be detected. Check your microphone, it may be muted or disconnected.', 
'error.required': 'Required', 
'error.required_date': 'Required', 
'error.startRecord': 'Unable to start recording, please make sure your device and/or browser are up to date.', 
'grades.averageSq': 'Average: {score}', 
'grades.classRoom': 'Class:', 
'grades.finalGradeColumn': 'Final Grade', 
'grades.hoursColumn': 'Hours ({weight}%)', 
'grades.maximumGrade': 'Highest Grade:', 
'grades.maximumSchoolGrade': 'School\'s Highest Grade: {grade}', 
'grades.noData': 'Your grades aren\'t available yet', 
'grades.preview': 'Preview', 
'grades.requiredStudyTime': 'Required study time', 
'grades.requiredStudyTimePeriod': 'Required study time: {time}', 
'grades.school': 'School', 
'grades.student': 'Student', 
'grades.studyQualityColumn': 'Study Quality ({weight}%)', 
'grades.teacher': 'Teacher', 
'grades.title': 'Your Grades - {year}', 
'grades.totalStudyTimePeriod': 'Total study time: {time}', 
'grades.yourSchoolGrade': 'Your grades at school: {grade}', 
'invalidBrowser.android': 'Please, use Google Chrome to access the site.', 
'invalidBrowser.button.reload': 'Refresh', 
'invalidBrowser.desktop': 'Please, use Google Chrome to access the site', 
'invalidBrowser.ios': 'Please, use Safari to access the site', 
'login.button.access': 'Access', 
'login.error.academic_user': 'Go to {name} - {url}', 
'login.error.classroom_not_found': 'Your user is not associated with any classroom', 
'login.error.email_not_found': 'Email address not found', 
'login.error.inactive_classroom': 'Inactive classroom', 
'login.error.invalid_password': 'Incorrect password', 
'login.error.out_of_access_period': 'Invalid access period', 
'login.error.payment_required': 'There is a pending payment. Please check your email.', 
'login.error.student_contract_blocked': 'Oops, it appears that your access has been blocked. Contact your teacher for more information.', 
'login.error.unexpected_error': 'Failed to log in. Please try again later.', 
'login.error.user_disabled': 'Your account has been disabled, please contact your school.', 
'login.error.user_not_found_in_company': 'Email address not found', 
'login.error.user_not_found_in_distributor': 'Email address not found', 
'login.field.email': 'Email', 
'login.field.password': 'Password', 
'menu.grades': 'Grades', 
'menu.logout': 'Log out', 
'menu.profile': 'Profile', 
'microphoneErrorModal.general.button': 'Ok, I got it', 
'microphoneErrorModal.permission.button': 'Ok, I enabled the microphone', 
'microphoneErrorModal.permission.message': 'Click here to see how to change your browser settings.', 
'microphoneErrorModal.title': 'Action required', 
'microphoneErrorModal.update.button': 'Ok, I have updated it', 
'microphoneErrorModal.update.message': 'Click here and see how to change and update your device.', 
'module': 'Module', 
'no': 'No', 
'notFound.button.back': 'Back', 
'notFound.default': 'This page isn\'t available.', 
'notFound.noCourse': 'You\'re not enrolled in any course, please contact your teacher.', 
'profile.button.cancel': 'Cancel', 
'profile.button.save': 'Save', 
'profile.errorMessage': 'Cannot update information.', 
'profile.field.birthDate': 'Birth Date', 
'profile.field.changeAvatar': 'Update Avatar', 
'profile.field.currentPassword': 'Current Password', 
'profile.field.email': 'Email', 
'profile.field.female': 'Female', 
'profile.field.male': 'Male', 
'profile.field.newPassword': 'New Password', 
'profile.field.nickname': 'Username', 
'profile.field.notRankingParticipant': 'I don\'t want to be a part of the Ranking.', 
'profile.field.rankingParticipant': 'I want to be a part of the Ranking.', 
'profile.passwordSection.description': 'To change your password, write your current password and the new one.', 
'profile.passwordSection.title': 'Password', 
'profile.successMessage': 'Your information has been updated.', 
'profile.title': 'Student\'s Info', 
'ranking.mySchoolPosition': 'My class rank', 
'ranking.national': 'National', 
'ranking.noDataMessage': 'Complete 1 exercise to unlock the ranking!', 
'ranking.regional': 'Regional', 
'ranking.school': 'School', 
'reportError': 'Report Error', 
'studyQuality.modalTitle': 'How to improve your Study Quality', 
'studyQuality.noEvaluationPeriod': 'You are not in an evaluation period.', 
'studyQuality.oneHourRequired': 'Your Study Quality will be generated after 1 hour of study.', 
'studyQuality.requirements.DAYS_STUDIED': 'Study at least 7 days every 15 days.', 
'studyQuality.requirements.DAYS_STUDIED_AT_LEAST_30_MINUTES': 'Study at least 30 minutes per day', 
'studyQuality.requirements.LISTEN_USAGE': 'Use of the Listen button', 
'studyQuality.requirements.REPEAT_USAGE': 'Use of the Repeat button', 
'studyQuality.requirements.SINGLE_CHOICE_AVERAGE_SCORE': 'Answers to the questions', 
'studyQuality.requirements.SPEECH_RECOGNITION_AVERAGE_SCORE': 'Average Score - Pronunciation', 
'studyQuality.requirements.TIME_STUDIED': 'Study the required weekly time', 
'studyQuality.requirements.UNIT_AVERAGE_SCORE': 'Unit Average Score', 
'studyQuality.title': 'Study Quality', 
'tryAgain': 'Try Again', 
'tryAgainMessage': 'Try again in {seconds} seconds.', 
'unitExecution.confirmModal.executedToday': 'You already completed this exercise. If you do it again, you won\'t earn any new stars. Are you sure you want to continue?', 
'unitExecution.confirmModal.noPoints': 'You already completed this exercise. If you do it again, you won\'t earn any new stars. Are you sure you want to continue?', 
'unitExecution.confirmModal.unitCompleted': 'You already earned all the stars. If you do the exercise again, you won\'t earn any new stars. Do you want to continue?', 
'unitExecution.error.endExecution.defaultMessage': 'The result of the activity could not be calculated. You can try again or move on to another activity.', 
'unitExecution.error.endExecution.tryAgain': 'There was a problem calculating your result. Please wait a few seconds and we will try again.', 
'unitExecution.error.playAudio.defaultMessage': 'Unable to play the native speaker\'s audio. You can try again or move on to another activity.', 
'unitExecution.error.playAudio.tryAgain': 'Unable to play the native speaker\'s audio. Please wait a few seconds and we will try again.', 
'unitExecution.error.saveItem.defaultMessage': 'Unable to save answer. You can try again or move on to another activity.', 
'unitExecution.error.saveItem.tryAgain': 'There was a problem saving the answer. Please wait a few seconds and we will try again.', 
'unitExecution.error.selectItem.defaultMessage': 'Unable to play the activity\'s instruction. You can try again or move to another activity.', 
'unitExecution.error.selectItem.tryAgain': 'Unable to play the activity\'s instruction. Please wait a few seconds and we will try again.', 
'unitExecution.error.startExecution.defaultMessage': 'Unable to start activity. You can try again or move on to another activity.', 
'unitExecution.error.startExecution.tryAgain': 'An error occurred. Please try again later.', 
'unitExecution.itemType.text.false': 'No, it is not true.', 
'unitExecution.itemType.text.true': 'Yes, it is true.', 
'unitExecution.leave.description': 'If you leave now, your progress will be lost after {timeToAllowContinueUnit}. Are you sure want to leave?', 
'unitExecution.leave.noProgressLossDescription': 'Are you sure want to leave?', 
'unitExecution.leave.title': 'Attention', 
'unitExecution.pointsReceived': 'Points Earned', 
'unitExecution.score.computer': 'Computer Score', 
'unitExecution.score.my': 'My Score', 
'unitExecution.score.yours': 'Your Score', 
'userAway.message': 'Seems like you are having trouble doing the exercise. Try listening to the instruction audio again or talk to your teacher.', 
'userErrorReport.buttonsText.discardChanges': 'Discard', 
'userErrorReport.buttonsText.submit': 'Submit', 
'userErrorReport.errorMessages.KIDSNotFound': 'The email address is not registered in the platform', 
'userErrorReport.errorMessages.apiError': 'We were unable to submit your report. Please wait a moment and try again', 
'userErrorReport.labels.content': 'Tell us what happened', 
'userErrorReport.labels.email': 'Email', 
'userErrorReport.labels.subject': 'Subject', 
'userErrorReport.placeholders.content': 'Feel free to send us any suggestions and feedback', 
'userErrorReport.placeholders.email': 'Enter your email address registered on the platform', 
'userErrorReport.placeholders.subject': 'Type here the reason for your report', 
'userErrorReport.title': 'Report an error', 
'userPlayModal.message': 'Let\'s start!', 
'weekTime': 'Time of the week', 
'yes': 'Yes', 
}; 
export default labels;