import { Howl } from "howler";
import round from "lodash/round";
import sample from "lodash/sample";
import snakeCase from "lodash/snakeCase";
import { put, select, spawn, takeLatest } from "redux-saga/effects";
import { endFlow, startFlow } from "student-front-commons/src/actions/flow";
import { getFlow, getFlowStart } from "student-front-commons/src/selectors/flow";
import { getEntityById } from "student-front-commons/src/selectors/entity";
import { END_TASTING_EXECUTION_FLOW, ERROR_MODAL_FLOW } from "consts";
import { logError } from "utils";
import { UNIT_SCHEMA } from "student-front-commons/src/schemas";
import { endExecution } from "student-front-commons/src/actions/execution";
import browserHistory from "../browserHistory";

let retryFlowCount = 1;

export default function* () {
  yield takeLatest(getFlowStart(END_TASTING_EXECUTION_FLOW), function* () {
    const flow = yield select(getFlow(END_TASTING_EXECUTION_FLOW));
    try {
      const execution = yield select((state) => state.executions);
      if (!execution || !execution.unit) {
        browserHistory.replace("/login");
      }

      const unit = yield select(getEntityById(UNIT_SCHEMA, execution.unit));

      const total = execution.answers.length;
      const correct = execution.answers.filter((answer) => answer.correct).length;

      const result = {
        score: round((correct / total) * 100),
        points: unit.defaultPoints,
      };

      yield put(endExecution(result));

      // audio effect
      yield spawn(function* () {
        let audioToPlay = null;
        if (result.points) {
          const unit = yield select(getEntityById("unit", flow.params.unit));
          const unitType = yield select(getEntityById("unitType", unit.type));

          if (
            ["connecting_dots_game", "single_choice_game", "memory_game", "vocabulary_game"].find(
              (type) => type === snakeCase(unitType.name.toLowerCase())
            )
          ) {
            audioToPlay = sample([
              require("assets/audio/game-score-win-1.mp3").default,
              require("assets/audio/game-score-win-2.mp3").default,
            ]);
          } else {
            audioToPlay = sample([
              require("assets/audio/unit-result-1.mp3").default,
              require("assets/audio/unit-result-2.mp3").default,
              require("assets/audio/unit-result-3.mp3").default,
              require("assets/audio/unit-result-4.mp3").default,
              require("assets/audio/unit-result-5.mp3").default,
            ]);
          }
        } else {
          audioToPlay = sample([
            require("assets/audio/game-score-fail-1.mp3").default,
            require("assets/audio/game-score-fail-2.mp3").default,
          ]);
        }

        const audio = new Howl({
          src: [audioToPlay],
          autoplay: false,
          loop: false,
          volume: 1,
        });
        audio.once("end", () => audio.unload());
        audio.play();
      });

      retryFlowCount = 1;
    } catch (error) {
      logError({ error, flow: END_TASTING_EXECUTION_FLOW });
      yield put(
        startFlow(ERROR_MODAL_FLOW, {
          message:
            retryFlowCount > 3
              ? "Não foi possível calcular o resultado da atividade. Você pode tentar novamente ou ir para outra atividade."
              : "Ocorreu um problema para calcular seu resultado. Aguarde alguns instantes e iremos tentar novamente.",
          retryFlow: {
            id: END_TASTING_EXECUTION_FLOW,
            params: flow.params,
          },
          attemptCount: retryFlowCount,
        })
      );
      retryFlowCount = retryFlowCount + 1;
    } finally {
      yield put(endFlow(END_TASTING_EXECUTION_FLOW));
    }
  });
}
