import React from "react";
import Col from "core/Col";
import Image from "core/Image";
import ControlBar from "core/ControlBar";
import MasteryTestFlag from "assets/mastery-test-icon.png";

function MasteryTestExecutionControlBar() {
  return (
    <ControlBar type="mastery-test">
      <Col height="100%" overflow="hidden">
        <Image
          src={MasteryTestFlag}
          position="relative"
          width="auto"
          height={120}
          top={20}
          zIndex={1}
          transition="all .5s"
        />
      </Col>
    </ControlBar>
  );
}

export default MasteryTestExecutionControlBar;
