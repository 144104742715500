import React from "react";
import PropTypes from "prop-types";
import Col from "core/Col";
import Row from "core/Row";
import Spinner from "core/Spinner";
import InBetweenSpacing from "core/InBetweenSpacing";
import GradesListHeaderItem from "screens/grades/GradesListHeaderItem";
import GradesListPeriodColumn from "screens/grades/GradesListPeriodColumn";
import GradesListStudyQualityColumn from "screens/grades/GradesListStudyQualityColumn";
import GradesListHourColumn from "screens/grades/GradesListHourColumn";
import GradesListFinalGradeColumn from "screens/grades/GradesListFinalGradeColumn";
import { GREY_4 } from "consts/colors";
import TranslatedText from "core/TranslatedText";

const GradesList = ({ isLoading, periods, school }) => (
  <Col>
    {isLoading && <Spinner size="xs" />}
    {!isLoading && !periods.length && (
      <TranslatedText translateKey="grades.noData" size={14} color={GREY_4} boldType="light-bold" align="center" />
    )}
    {!isLoading && !!periods.length && (
      <InBetweenSpacing size="xs">
        <Row alignItems="flex-end" justifyContent="space-between">
          <GradesListHeaderItem label="" flexGrow={1} />
          <GradesListHeaderItem
            width="22%"
            label="grades.studyQualityColumn"
            values={{
              weight: school.percentStudyQualityRelevanceInGrade,
            }}
          />
          <GradesListHeaderItem
            width="22%"
            label="grades.hoursColumn"
            values={{
              weight: school.percentHoursRelevanceInGrade,
            }}
          />
          <GradesListHeaderItem width="22%" label="grades.finalGradeColumn" />
        </Row>
        {periods.map((period) => (
          <Row key={period.id} alignItems="center" justifyContent="space-between" padding="10px 0px">
            <GradesListPeriodColumn name={period.name} start={period.start} end={period.end} />
            {period.grade && (
              <React.Fragment>
                <GradesListStudyQualityColumn
                  grade={period.grade.studyQualityGrade}
                  averageScore={period.grade.averageStudyQuality}
                  isPreview={!period.grade.id}
                />
                <GradesListHourColumn
                  grade={period.grade.hoursGrade}
                  requiredHours={period.grade.hoursRequired}
                  studiedHours={period.grade.hoursStudied}
                  isPreview={!period.grade.id}
                />
                <GradesListFinalGradeColumn
                  grade={period.grade.finalGrade}
                  schoolGrade={period.grade.formattedFinalGrade}
                  maximumSchoolGrade={school.maximumGrade}
                  isPreview={!period.grade.id}
                />
              </React.Fragment>
            )}
          </Row>
        ))}
      </InBetweenSpacing>
    )}
  </Col>
);

GradesList.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  periods: PropTypes.arrayOf(
    PropTypes.shape({
      grade: PropTypes.shape({
        studyQualityGrade: PropTypes.number,
        averageStudyQuality: PropTypes.number,
        hoursGrade: PropTypes.number,
        hoursRequired: PropTypes.number,
        hoursStudied: PropTypes.number,
        finalGrade: PropTypes.number,
        formattedFinalGrade: PropTypes.string,
      }),
    })
  ).isRequired,
  school: PropTypes.shape({
    percentHoursRelevanceInGrade: PropTypes.number,
    percentStudyQualityRelevanceInGrade: PropTypes.number,
  }).isRequired,
};

export default GradesList;
