import React from "react";
import PropTypes from "prop-types";
import Image from "core/Image";
import Col from "core/Col";
import DefaultBg from "assets/default-bg.png";

function PublicScreen({ children, background, backgroundStyle }) {
  return (
    <Col>
      <Image
        src={background || DefaultBg}
        position="fixed"
        top={0}
        objectFit="cover"
        className="user-report-app-background"
        {...backgroundStyle}
      />
      {children}
    </Col>
  );
}

PublicScreen.propTypes = {
  background: PropTypes.string,
  backgroundStyle: PropTypes.object,
  children: PropTypes.node.isRequired,
};

PublicScreen.defaultProps = {
  background: null,
  backgroundStyle: {
    backgroundImage: "radial-gradient(circle at 50% 50%, #fffad7, #B9D011)",
  },
};

export default PublicScreen;
