import React from "react";
import { withRouter } from "react-router-dom";
import PrivateScreen from "core/PrivateScreen";
import RankingCard from "core/RankingCard";
import IconButton from "core/IconButton";
import Separator from "core/Separator";

const RankingScreen = ({ history }) => (
  <PrivateScreen>
    <IconButton name="back" onClick={() => history.push("/")} />
    <Separator size="sm" />
    <RankingCard />
  </PrivateScreen>
);

export default withRouter(RankingScreen);
