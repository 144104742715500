import React, { useContext } from "react";
import Col from "core/Col";
import Row from "core/Row";
import RoundedText from "core/RoundedText";
import InBetweenSpacing from "core/InBetweenSpacing";
import UnitTypeThemeContext from "core/ThemeContext";
import Image from "core/Image";
import LoseIcon from "assets/unit-result-game-lose.png";
import GameWinnerIcon from "assets/lottie/game-winner";
import UnitResultGameScoreItem from "screens/unit-result/UnitResultGameScoreItem";
import Lottie from "react-lottie";
import { useSelector } from "react-redux";

function UnitResultGameScore() {
  const unitTypeThemeContext = useContext(UnitTypeThemeContext);

  const studentGameScore = useSelector((state) => state.executions?.studentGameScore);
  const computerGameScore = useSelector((state) => state.executions?.computerGameScore);

  return (
    <Col marginTop={-50}>
      <RoundedText radius={300} color={unitTypeThemeContext.primary} size={62} boldType="bold">
        {studentGameScore > computerGameScore ? "You won!" : "Oh no!"}
      </RoundedText>
      <Row flexShrink={0} alignItems="center" height={200}>
        <InBetweenSpacing size={studentGameScore > computerGameScore ? "lg" : "xxxl"}>
          <UnitResultGameScoreItem
            label="unitExecution.score.my"
            score={studentGameScore}
            isWinner={studentGameScore > computerGameScore}
          />
          {studentGameScore > computerGameScore ? (
            <Col margin="0 -70px 70px">
              <Lottie
                options={{
                  loop: false,
                  autoplay: true,
                  animationData: GameWinnerIcon,
                  rendererSettings: {
                    preserveAspectRatio: "xMidYMid slice",
                  },
                }}
                width={350}
                height="auto"
              />
            </Col>
          ) : (
            <Image src={LoseIcon} width="auto" height={70} opacity={0.7} />
          )}
          <UnitResultGameScoreItem
            label="unitExecution.score.computer"
            score={computerGameScore}
            isWinner={computerGameScore > studentGameScore}
          />
        </InBetweenSpacing>
      </Row>
    </Col>
  );
}

export default UnitResultGameScore;
