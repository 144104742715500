import React from "react";
import PropTypes from "prop-types";
import WhitelabelContext from "student-front-commons/src/components/WhitelabelContext";
import moment from "moment";
import "moment-duration-format";
import Row from "core/Row";
import Col from "core/Col";
import GradesHeaderItem from "screens/grades/GradesHeaderItem";
import InBetweenSpacing from "core/InBetweenSpacing";
import { WHITE } from "consts/colors";
import TranslatedText from "core/TranslatedText";

const GradesHeader = ({ student, teacher, classroom, school, weeklyHoursRequired, maximumGrade }) => (
  <WhitelabelContext.Consumer>
    {({ secondaryColor }) => (
      <Col>
        <Col
          alignItems="center"
          justifyContent="center"
          backgroundColor={secondaryColor}
          width="100%"
          height={55}
          borderTopLeftRadius={20}
          borderTopRightRadius={20}
        >
          <TranslatedText
            translateKey="grades.title"
            values={{
              year: moment().format("YYYY"),
            }}
            size={22}
            color={WHITE}
            boldType="bold"
          />
        </Col>
        <Row justifyContent="space-between" alignItems="center" padding={25}>
          <Col>
            <InBetweenSpacing size="sm">
              <GradesHeaderItem label="grades.student" value={student} />
              {teacher && <GradesHeaderItem label="grades.teacher" value={teacher} />}
              {weeklyHoursRequired && (
                <GradesHeaderItem
                  label="grades.requiredStudyTime"
                  value={`${moment.duration(weeklyHoursRequired, "hours").format("hh:mm", { trim: false })}hrs / week`}
                />
              )}
            </InBetweenSpacing>
          </Col>
          <Col>
            <InBetweenSpacing size="sm">
              {classroom && <GradesHeaderItem label="grades.classRoom" value={classroom} />}
              <GradesHeaderItem label="grades.school" value={school} />
              {maximumGrade && <GradesHeaderItem label="grades.maximumGrade" value={maximumGrade} />}
            </InBetweenSpacing>
          </Col>
        </Row>
      </Col>
    )}
  </WhitelabelContext.Consumer>
);

GradesHeader.propTypes = {
  student: PropTypes.string,
  teacher: PropTypes.string,
  classroom: PropTypes.string,
  weeklyHoursRequired: PropTypes.number,
  school: PropTypes.string,
  maximumGrade: PropTypes.number,
};

export default GradesHeader;
