import React from "react";
import PropTypes from "prop-types";
import * as d3 from "d3";
import { SimpleGauge } from "d3-simple-gauge";
import sample from "lodash/sample";
import Col from "core/Col";
import Text from "core/Text";
import Separator from "core/Separator";
import { SPEECH_EXCELLENT, SPEECH_MODERATE, SPEECH_LOW, SPEECH_WEAK, SPEECH_GOOD } from "consts/colors";

export default class SpeechRecognitionResult extends React.Component {
  static propTypes = {
    score: PropTypes.number.isRequired,
    scoreToPassOfSpeechRecognition: PropTypes.number.isRequired,
  };

  state = {
    showAnimation: false,
    legendText: "",
  };

  componentDidMount() {
    const svg = d3.select(this.gaugeNode).append("svg").attr("width", 110).attr("height", 55);

    this.simpleGauge = new SimpleGauge({
      el: svg.append("g"),
      height: 50,
      interval: [0, 100],
      sectionsCount: 5,
      sectionsColors: [SPEECH_WEAK, SPEECH_LOW, SPEECH_MODERATE, SPEECH_GOOD, SPEECH_EXCELLENT],
      width: 110,
      barWidth: 10,
      needleRadius: 5,
      needleColor: "#a5a5a5",
      chartInset: 0,
    });

    if (this.props.score) {
      this.setState({
        legendText: this.getLegendText(),
      });
      this.simpleGauge.value = this.props.score;
    }
  }

  getLegendColor = () => {
    if (this.props.score >= 80) {
      return SPEECH_EXCELLENT;
    } else if (this.props.score >= this.props.scoreToPassOfSpeechRecognition && this.props.score < 80) {
      return SPEECH_GOOD;
    } else if (this.props.score >= 40 && this.props.score < this.props.scoreToPassOfSpeechRecognition) {
      return SPEECH_MODERATE;
    } else if (this.props.score >= 20 && this.props.score < 40) {
      return SPEECH_LOW;
    } else {
      return SPEECH_WEAK;
    }
  };

  getLegendText = () => {
    const excellent = [
      "Excellent!",
      "Amazing!",
      "Superb!",
      "Impecable!",
      "Outstanding!",
      "That's great!",
      "Sensational!",
    ];
    const moderate = ["Try again", "Do it once more", "Do it again", "You can do better", "One more time"];
    const weak = ["Not enough", "Weak", "That's not it", "No, do it again!"];

    if (this.props.score >= this.props.scoreToPassOfSpeechRecognition) {
      return sample(excellent);
    } else if (this.props.score >= 20 && this.props.score < this.props.scoreToPassOfSpeechRecognition) {
      return sample(moderate);
    } else {
      return sample(weak);
    }
  };

  render() {
    return (
      <Col alignItems="center" position="relative">
        <Text size={14} boldType="light-bold" color={this.getLegendColor()}>
          {this.state.legendText}
        </Text>
        <Separator size="xs" />
        <div
          ref={(node) => {
            this.gaugeNode = node;
          }}
        />
      </Col>
    );
  }
}
