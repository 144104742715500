import React from "react";
import PropTypes from "prop-types";
import Text from "core/Text";
import Row from "core/Row";
import Icon from "core/Icon";
import UnitTypeThemeContext from "core/ThemeContext";
import { GREY_8, GREY_9 } from "consts/colors";
import Separator from "core/Separator";

class UnitName extends React.PureComponent {
  static propTypes = {
    name: PropTypes.string.isRequired,
    isDisabled: PropTypes.bool,
    intent: PropTypes.oneOf(["primary", "secondary"]).isRequired,
  };

  static defaultProps = {
    isDisabled: false,
  };

  render() {
    const { name, intent, isDisabled } = this.props;
    return (
      <UnitTypeThemeContext.Consumer>
        {({ primary, secondary }) => (
          <Row
            justifyContent="center"
            alignItems="center"
            backgroundColor={
              isDisabled
                ? GREY_9
                : {
                    primary: primary,
                    secondary: secondary,
                  }[intent]
            }
            borderRadius={15}
            height={30}
            minWidth={120}
            width="max-content"
            padding="0px 25px"
            boxSizing="border-box"
            boxShadow="0px 1px 3px 0 rgba(0, 0, 0, 0.2);"
          >
            {isDisabled && (
              <React.Fragment>
                <Icon size={16} name="lock" color={GREY_8} />
                <Separator size="xs" />
              </React.Fragment>
            )}
            <Text boldType="bold" size={14}>
              {name}
            </Text>
          </Row>
        )}
      </UnitTypeThemeContext.Consumer>
    );
  }
}

export default UnitName;
