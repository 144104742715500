import React, { useCallback, useContext } from "react";
import Col from "core/Col";
import Image from "core/Image";
import ItemButton from "core/ItemButton";
import Grid from "core/Grid";
import Text from "core/Text";
import UnitTypeThemeContext from "core/ThemeContext";
import { SELECT_ITEM_FLOW } from "consts";
import { GREY_2, SPEECH_EXCELLENT, SPEECH_GOOD, SPEECH_LOW, SPEECH_MODERATE, SPEECH_WEAK, WHITE } from "consts/colors";
import { getImage } from "stores/image-store";
import { useSelector } from "react-redux";
import { isNil } from "lodash";
import { useFlow } from "student-front-commons/src/hooks";
import {
  getCurrentItemExecutionProp,
  getItemExecutionPropById,
  getSelectedId,
} from "student-front-commons/src/selectors/itemExecution";
import {
  isInstructionEnableForStep,
  isInstructionHighlighted,
} from "student-front-commons/src/selectors/itemInstruction";

function VocabularyItemRender(props) {
  const unitTypeContext = useContext(UnitTypeThemeContext);

  const [, startSelectItemFlow] = useFlow(SELECT_ITEM_FLOW);

  const selectedItemId = useSelector(getSelectedId);
  const itemId = useSelector(getItemExecutionPropById(props.itemId, "item.id"));
  const itemText = useSelector(getItemExecutionPropById(props.itemId, "item.text"));
  const playingId = useSelector(getItemExecutionPropById(props.itemId, "playingId"));
  const isDisabled = useSelector(getItemExecutionPropById(props.itemId, "isDisabled"));
  const isRecording = useSelector(getItemExecutionPropById(props.itemId, "isRecording"));
  const qualityScore = useSelector(getItemExecutionPropById(props.itemId, "speechRecognitionResult.qualityScore"));
  const isRecordingAnyItem = useSelector(getCurrentItemExecutionProp("isRecording"));

  const isInstructionEnabled = useSelector(isInstructionEnableForStep("user-away"));
  const shouldHighlighted = useSelector(isInstructionHighlighted);

  const speechRecognitionMinimumScore = useSelector((state) => state.configurations.scoreToPassOfSpeechRecognition);

  const getItemBackground = () => {
    if (isNil(qualityScore)) {
      return isDisabled ? GREY_2 : WHITE;
    }

    if (qualityScore >= 80) {
      return SPEECH_EXCELLENT;
    } else if (qualityScore >= speechRecognitionMinimumScore && qualityScore < 80) {
      return SPEECH_GOOD;
    } else if (qualityScore >= 40 && qualityScore < speechRecognitionMinimumScore) {
      return SPEECH_MODERATE;
    } else if (qualityScore >= 20 && qualityScore < 40) {
      return SPEECH_LOW;
    } else {
      return SPEECH_WEAK;
    }
  };

  const handleSelect = useCallback(() => {
    startSelectItemFlow({ item: itemId });
  }, [itemId, startSelectItemFlow]);

  return (
    <ItemButton
      padding={10}
      isDisabled={isDisabled || isRecording || isRecordingAnyItem || selectedItemId === itemId}
      onClick={handleSelect}
      status={selectedItemId === itemId || !isNil(qualityScore) ? "active" : "normal"}
      backgroundColor={getItemBackground()}
      width={180}
      height={180}
      overflow="visible"
      transition="box-shadow .5s, background-color .5s, transform 300ms ease"
      transform={playingId === itemId ? "scale(1.15)" : "none"}
      boxShadow={playingId === itemId ? "0px 1px 59px 10px rgba(250, 220, 96, 0.8)" : "none"}
      className={isInstructionEnabled && shouldHighlighted && !selectedItemId ? "shake-vertical" : ""}
    >
      {({ status }) => (
        <React.Fragment>
          <Col position="relative" overflow="hidden" borderRadius={10}>
            <Image
              src={
                getImage(`items/${itemId}/images/post-phrase-lg.png`) ||
                `${process.env.REACT_APP_FILES_URL}/items/${itemId}/images/post-phrase-lg.png`
              }
              width="100%"
              height="auto"
              position="absolute"
              borderRadius={10}
              className={playingId === `post-phrase-${itemId}` ? "slide-in-top" : "slide-out-bottom"}
            />
            <Image
              src={
                getImage(`items/${itemId}/images/lg.png`) ||
                `${process.env.REACT_APP_FILES_URL}/items/${itemId}/images/lg.png`
              }
              width="100%"
              height="auto"
              borderRadius={10}
              className={playingId === `post-phrase-${itemId}` ? "slide-out-bottom" : "slide-in-top"}
            />
          </Col>
          {status === "active" && (
            <Col
              zIndex={2}
              bottom={-20}
              position="absolute"
              alignItems="center"
              justifyContent="center"
              backgroundColor={unitTypeContext.primary}
              borderRadius={20}
              minHeight={40}
              padding="0px 10px"
              transition="all .5s"
              transform={playingId === itemId ? "scale(1.15)" : "none"}
              boxShadow={playingId === itemId ? "0px 1px 59px 0 rgba(250, 220, 96, 0.8)" : "none"}
            >
              <Text boldType="light-bold" size={15} color={WHITE}>
                {itemText}
              </Text>
            </Col>
          )}
        </React.Fragment>
      )}
    </ItemButton>
  );
}

function VocabularyItem() {
  let items = useSelector((state) => state.itemExecutions.allIds);

  return (
    <Col flexGrow={1} justifyContent="center" alignItems="center">
      <Grid
        rowGap={40}
        columnWidth={200}
        maxWidth={210 * (items.length > 4 ? items.length / 2 : items.length)}
        width="100%"
        flexShrink={0}
      >
        {items.map((item) => (
          <VocabularyItemRender key={item} itemId={item} />
        ))}
      </Grid>
    </Col>
  );
}

export default VocabularyItem;
