import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import Col from "core/Col";
import Row from "core/Row";
import Spinner from "core/Spinner";
import Separator from "core/Separator";
import PublicScreen from "core/PublicScreen";
import ScreenContainer from "core/ScreenContainer";
import UnitTypeThemeContext from "core/ThemeContext";
import ExecutionHelpContainer from "core/ExecutionHelpContainer";
import UnitExecutionProgressBar from "screens/unit-execution/UnitExecutionProgressBar";
import { XL_SIZE } from "utils";
import ConfirmModalContainer from "core/ConfirmModalContainer";
import MicrophoneErrorModalContainer from "core/MicrophoneErrorModalContainer";
import UserPlayModalContainer from "core/UserPlayModalContainer";
import ErrorModalContainer from "core/ErrorModalContainer";
import ItemExecution from "../../../core/items";
import UnitExecutionControlBar from "../UnitExecutionControlBar";

class TastingExecutionScreen extends React.Component {
  static propTypes = {
    isLoading: PropTypes.bool.isRequired,
  };

  render() {
    const { isLoading } = this.props;
    return (
      <UnitTypeThemeContext.Consumer>
        {({ radialGradient }) => (
          <React.Fragment>
            <PublicScreen
              backgroundStyle={{
                background: radialGradient,
              }}
            >
              {!isLoading && <UnitExecutionProgressBar />}
              <Separator size="lg" />
              {isLoading ? (
                <Col alignItems="center" justifyContent="center" height="100vh">
                  <Spinner />
                </Col>
              ) : (
                <Col alignItems="center" height="97vh" position="relative">
                  <Row
                    width="100%"
                    maxWidth={XL_SIZE}
                    mediaQueries={{
                      md: `(max-width: ${XL_SIZE + 1}px)`,
                      lg: `(min-width: ${XL_SIZE + 1}px)`,
                    }}
                    mdPadding="0px 15px"
                    lgPadding="0px"
                    boxSizing="border-box"
                    position="absolute"
                    justifyContent="flex-end"
                    zIndex={2}
                  >
                    <ExecutionHelpContainer />
                  </Row>
                  <Separator size="xxxl" />
                  <Separator size="sm" />
                  <Col width="100%" flexGrow={1} flexShrink={0} justifyContent="space-between">
                    <ScreenContainer>
                      <ItemExecution />
                    </ScreenContainer>
                    <UnitExecutionControlBar />
                  </Col>
                </Col>
              )}
            </PublicScreen>
            <ConfirmModalContainer />
            <MicrophoneErrorModalContainer />
            <UserPlayModalContainer />
            <ErrorModalContainer />
          </React.Fragment>
        )}
      </UnitTypeThemeContext.Consumer>
    );
  }
}

export default withRouter(TastingExecutionScreen);
