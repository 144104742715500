import React from "react";
import { useParams } from "react-router-dom";
import UnitResultScreen from "screens/unit-result/UnitResultScreen";
import { END_UNIT_EXECUTION_FLOW } from "consts";
import UnitTypeThemeProvider from "core/UnitTypeThemeProvider";
import { useEntity, useFlow } from "student-front-commons/src/hooks";
import { UNIT_SCHEMA } from "student-front-commons/src/schemas";

function UnitResultScreenContainer() {
  const params = useParams();

  const unit = useEntity(UNIT_SCHEMA, params.unitId);

  const [isPending] = useFlow(END_UNIT_EXECUTION_FLOW, {
    autoStart: true,
    params: {
      module: params.moduleId,
      unit: params.unitId,
      execution: params.executionId,
    },
  });

  return (
    <UnitTypeThemeProvider unitType={unit.type}>
      <UnitResultScreen isLoading={isPending} />
    </UnitTypeThemeProvider>
  );
}

export default UnitResultScreenContainer;
