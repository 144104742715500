import React from "react";
import { Block } from "jsxstyle";
import { useLocation } from "react-router-dom";
import { useUserReport } from "student-front-commons/src/components/UserReportContext";

const UserReportButton = () => {
  const { handleStartReport, isDeactivate } = useUserReport();
  const location = useLocation();

  if (isDeactivate || /\/modules\/[0-9a-fA-F]{24}\/units\/[0-9a-fA-F]{24}\/execution/.test(location.pathname)) {
    return null;
  }

  return (
    <Block
      component="button"
      props={{
        type: "button",
        onClick: handleStartReport,
        className: "start-report-button",
        dataHtml2canvasIgnore: true,
      }}
      position="fixed"
      bottom={0}
      right={10}
      color="#eceff1"
      backgroundColor="#013666"
      fontSize={16}
      margin="0 1% 0 0"
      width={120}
      height={30}
      borderRadius="5px 5px 0 0"
      boxShadow="0px 2px 8px 0px rgba(0, 0, 0, 0.4)"
      zIndex={100}
      border="none"
      cursor="pointer"
      transition="transform 300ms ease, background-color .5s, width .3s, height .3s"
      transform="scale(1)"
      hoverTransform="scale(1.1)"
    >
      Report error
    </Block>
  );
};

export default UserReportButton;
