import { call, cancelled, put, race, select, take, takeLatest } from "redux-saga/effects";
import { endFlow } from "student-front-commons/src/actions/flow";
import { getFlowStart } from "student-front-commons/src/selectors/flow";
import { CLOSE_MASTERY_TEST_EXECUTION_FLOW, CLOSE_UNIT_EXECUTION_FLOW, PLAY_ITEM_INSTRUCTION_FLOW } from "consts";
import { playAudio, stopAudio } from "stores/audio-store";
import { logError } from "utils";
import { disableItemExecution, enableItemExecution } from "student-front-commons/src/actions/itemExecution";
import { getItemExecutions } from "student-front-commons/src/selectors/itemExecution";
import head from "lodash/head";

export default function* () {
  yield takeLatest(getFlowStart(PLAY_ITEM_INSTRUCTION_FLOW), function* () {
    yield race({
      closeUnit: take(getFlowStart(CLOSE_UNIT_EXECUTION_FLOW)),
      closeMasteryTest: take(getFlowStart(CLOSE_MASTERY_TEST_EXECUTION_FLOW)),
      call: call(function* () {
        try {
          yield put(disableItemExecution());

          const itemExecutions = yield select(getItemExecutions);
          const itemType = head(itemExecutions).item.type;

          if (itemType.initialInstructionSound) {
            yield call(playAudio, {
              url: itemType.initialInstructionSound,
              rate: 1,
            });
          }
        } catch (error) {
          logError({ error, flow: PLAY_ITEM_INSTRUCTION_FLOW });
        } finally {
          if (yield cancelled()) {
            stopAudio();
          }
          yield put(enableItemExecution());
          yield put(endFlow(PLAY_ITEM_INSTRUCTION_FLOW));
        }
      }),
    });
  });
}
