import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import "moment-duration-format";
import WhitelabelContext from "student-front-commons/src/components/WhitelabelContext";
import Text from "core/Text";
import { GREY_3, GREY_4, GREY_5 } from "consts/colors";
import Col from "core/Col";
import Separator from "core/Separator";
import ProgressBar from "core/ProgressBar";
import Spinner from "core/Spinner";
import Row from "core/Row";
import { SM_SIZE } from "utils";
import TranslatedText from "core/TranslatedText";

class WeekTime extends React.PureComponent {
  static propTypes = {
    hours: PropTypes.number,
    requiredHours: PropTypes.number,
    isLoading: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    hours: 0,
    requiredHours: 0,
  };

  render() {
    const { hours, requiredHours, isLoading } = this.props;
    return (
      <WhitelabelContext.Consumer>
        {({ primaryColor }) => (
          <Col
            mediaQueries={{
              sm: `(max-width: ${SM_SIZE}px)`,
              md: `(min-width: ${SM_SIZE}px)`,
            }}
            smWidth={180}
            mdWidth={230}
            transition="width .3s"
            height={60}
            smMarginRight={10}
            mdMarginRight={20}
            borderRadius={20}
            borderWidth={1}
            borderStyle="solid"
            borderColor={GREY_5}
            boxSizing="border-box"
            padding="0px 15px"
            justifyContent="center"
          >
            {isLoading && <Spinner size="xs" />}
            {!isLoading && (
              <React.Fragment>
                <TranslatedText translateKey="weekTime" color={GREY_4} size={14} />
                <Separator size="xs" />
                <ProgressBar total={requiredHours} contentColor={primaryColor} backgroundColor={GREY_3} value={hours} />
                <Separator size="xxs" />
                <Row justifyContent="space-between">
                  <Text color={GREY_4} size={10}>
                    {moment.duration(hours, "hours").format("hh:mm", { trim: false })} hrs de{" "}
                    {moment.duration(requiredHours, "hours").format("hh:mm", { trim: false })} hrs
                  </Text>
                </Row>
              </React.Fragment>
            )}
          </Col>
        )}
      </WhitelabelContext.Consumer>
    );
  }
}

export default WeekTime;
