import React, { useCallback, useContext, useState } from "react";
import WhitelabelContext from "student-front-commons/src/components/WhitelabelContext";
import Popover from "core/Popover";
import Col from "core/Col";
import MenuItem from "core/MenuItem";
import IconButton from "core/IconButton";
import InBetweenSpacing from "core/InBetweenSpacing";
import Divider from "core/Divider";
import LogoutMenuItemContainer from "core/LogoutMenuItemContainer";
import { LG_SIZE } from "utils";
import { useEntity } from "student-front-commons/src/hooks";
import { PROFILE_SCHEMA, SCHOOL_CLASS_SCHEMA } from "student-front-commons/src/schemas";
import { useHistory } from "react-router-dom";

function MenuContainer() {
  const history = useHistory();
  const whitelabelContext = useContext(WhitelabelContext);
  const [isOpen, setIsOpen] = useState(false);

  const profile = useEntity(PROFILE_SCHEMA, sessionStorage.getItem("id"));
  const schoolClass = useEntity(SCHOOL_CLASS_SCHEMA, profile.schoolClass);

  const handleToggle = useCallback(() => setIsOpen(!isOpen), [isOpen]);

  return (
    <Popover
      isOpen={isOpen}
      onClose={handleToggle}
      position="top"
      content={
        <Col>
          <InBetweenSpacing size="xs">
            <MenuItem label="menu.profile" onClick={() => history.push("/profile")} />
            {profile.apiVersion === "V2"
              ? profile.evaluationTemplate
              : schoolClass.evaluationTemplate && (
                  <React.Fragment>
                    <Divider />
                    <MenuItem label="menu.grades" onClick={() => history.push("/grades")} />
                  </React.Fragment>
                )}
            <Divider />
            <LogoutMenuItemContainer />
          </InBetweenSpacing>
        </Col>
      }
      mediaQueries={{
        md: `(max-width: ${LG_SIZE}px)`,
        lg: `(min-width: ${LG_SIZE}px)`,
      }}
      mdAlignSelf="flex-end"
      lgAlignSelf="center"
    >
      <IconButton
        onClick={handleToggle}
        backgroundColor={whitelabelContext.primaryColor}
        iconColor={whitelabelContext.secondaryColor}
        name="settings"
      />
    </Popover>
  );
}

export default MenuContainer;
