import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import SpeechRecognitionResult from "core/SpeechRecognitionResult";

const SpeechRecognitionResultContainer = ({ score, scoreToPassOfSpeechRecognition }) => (
  <SpeechRecognitionResult score={score} scoreToPassOfSpeechRecognition={scoreToPassOfSpeechRecognition} />
);

SpeechRecognitionResultContainer.propTypes = {
  score: PropTypes.number.isRequired,
};

const mapStateToProps = (state) => ({
  scoreToPassOfSpeechRecognition: state.configurations.scoreToPassOfSpeechRecognition,
});

export default connect(mapStateToProps)(SpeechRecognitionResultContainer);
