const labels = { 
'attention': 'Atenção!', 
'checkIntroduction': 'Escutar instrução', 
'close': 'Fechar', 
'courseScreen.chooseCourse': 'Escolha seu curso', 
'courseScreen.greetings': 'Olá, {name} ;)', 
'error.invalid_date': 'Data inválida', 
'error.invalid_email': 'E-mail inválido', 
'error.invalid_format': 'Formato inválido', 
'error.microphoneNotFound': 'Não foi possível acessar seu microfone. Verifique as configurações do seu navegador.', 
'error.noMicrophonePermission': 'A permissão para acessar o microfone não foi aceita, verifique as configurações do seu navegador.', 
'error.noSpeechDetected': 'Não foi possível detectar sua fala. Verifique seu microfone, ele pode estar mudo ou desconectado.', 
'error.required': 'Obrigatório', 
'error.required_date': 'Obrigatório', 
'error.startRecord': 'Não foi posível iniciar a gravação, verifique se seu dispositivo e/ou navegador estão atualizados.', 
'grades.averageSq': 'Média: {score}', 
'grades.classRoom': 'Turma:', 
'grades.finalGradeColumn': 'Nota Final', 
'grades.hoursColumn': 'Horas ({weight}%)', 
'grades.maximumGrade': 'Nota Máxima:', 
'grades.maximumSchoolGrade': 'Nota máxima da escola: {grade}', 
'grades.noData': 'Suas notas ainda não estão disponíveis.', 
'grades.preview': 'Prévia', 
'grades.requiredStudyTime': 'Tempo de estudo exigido', 
'grades.requiredStudyTimePeriod': 'Tempo de estudo exigido: {time}', 
'grades.school': 'Escola:', 
'grades.student': 'Aluno:', 
'grades.studyQualityColumn': 'Qualidade de Estudo ({weight}%)', 
'grades.teacher': 'Professor:', 
'grades.title': 'Suas Notas - {year}', 
'grades.totalStudyTimePeriod': 'Tempo total de estudo: {time}', 
'grades.yourSchoolGrade': 'Sua nota na escola: {grade}', 
'invalidBrowser.android': 'Por favor, utilize o navegador Google Chrome para acessar a plataforma.', 
'invalidBrowser.button.reload': 'Recarregar', 
'invalidBrowser.desktop': 'Por favor, utilize o navegador Google Chrome para acessar a plataforma.', 
'invalidBrowser.ios': 'Por favor, utilize o navegador Safari para acessar a plataforma.', 
'login.button.access': 'Acessar', 
'login.error.academic_user': 'Acesse a {name} - {url}', 
'login.error.classroom_not_found': 'Seu usuário não está associado a uma sala.', 
'login.error.email_not_found': 'E-mail não encontrado.', 
'login.error.inactive_classroom': 'Sala em período inativo.', 
'login.error.invalid_password': 'Senha inválida.', 
'login.error.out_of_access_period': 'Periodo de acceso no válido', 
'login.error.payment_required': 'Existe um pagamento em aberto. Verifique seu e-mail ou entre em contato conosco.', 
'login.error.student_contract_blocked': 'Ops, parece que seu acesso foi bloqueado. Entre em contato com seu professor para mais informações.', 
'login.error.unexpected_error': 'Não foi possível realizar o login. Tente novamente em alguns instantes.', 
'login.error.user_disabled': 'Seu usuário esta desabilitado, verifique com sua instituição.', 
'login.error.user_not_found_in_company': 'E-mail não encontrado.', 
'login.error.user_not_found_in_distributor': 'E-mail não encontrado.', 
'login.field.email': 'E-mail', 
'login.field.password': 'Senha', 
'menu.grades': 'Boletim', 
'menu.logout': 'Sair', 
'menu.profile': 'Perfil', 
'microphoneErrorModal.general.button': 'Ok, entendi', 
'microphoneErrorModal.permission.button': 'Ok, já habilitei o microfone', 
'microphoneErrorModal.permission.message': 'Clique aqui e veja como mudar as configurações do seu navegador.', 
'microphoneErrorModal.title': 'Ação necessária', 
'microphoneErrorModal.update.button': 'Ok, já atualizei', 
'microphoneErrorModal.update.message': 'Clique aqui e veja como mudar atualizar seu dispositivo.', 
'module': 'Módulo', 
'no': 'Não', 
'notFound.button.back': 'Voltar', 
'notFound.default': 'Página indisponível.', 
'notFound.noCourse': 'Você não possui um curso, verifique com seu professor.', 
'profile.button.cancel': 'Cancelar', 
'profile.button.save': 'Salvar', 
'profile.errorMessage': 'Erro para atulizar os dados.', 
'profile.field.birthDate': 'Data de Nascimento', 
'profile.field.changeAvatar': 'Alterar Avatar', 
'profile.field.currentPassword': 'Senha Atual', 
'profile.field.email': 'E-mail', 
'profile.field.female': 'Feminino', 
'profile.field.male': 'Masculino', 
'profile.field.newPassword': 'Nova Senha', 
'profile.field.nickname': 'Nick', 
'profile.field.notRankingParticipant': 'Eu não quero participar do Ranking.', 
'profile.field.rankingParticipant': 'Eu quero participar do Ranking.', 
'profile.passwordSection.description': 'Para alterar sua senha, informe sua senha atual e a nova senha desejada.', 
'profile.passwordSection.title': 'Password', 
'profile.successMessage': 'Dados atualizados.', 
'profile.title': 'Dados do Aluno', 
'ranking.mySchoolPosition': 'Minha posição na escola', 
'ranking.national': 'Nacional', 
'ranking.noDataMessage': 'Complete um exercício para desbloquear o ranking!', 
'ranking.regional': 'Regional', 
'ranking.school': 'School', 
'reportError': 'Reportar Erro', 
'studyQuality.modalTitle': 'Como melhorar meu Study Quality?', 
'studyQuality.noEvaluationPeriod': 'Você não esta em um período de avaliação.', 
'studyQuality.oneHourRequired': 'Seu Study Quality será gerado após 1 hora de estudo.', 
'studyQuality.requirements.DAYS_STUDIED': 'Estudar pelo menos 7 dias a cada 15 dias', 
'studyQuality.requirements.DAYS_STUDIED_AT_LEAST_30_MINUTES': 'Quando estudar, fazer pelo menos 30 minutos', 
'studyQuality.requirements.LISTEN_USAGE': 'Uso do botão Escutar', 
'studyQuality.requirements.REPEAT_USAGE': 'Uso do botão Repetir', 
'studyQuality.requirements.SINGLE_CHOICE_AVERAGE_SCORE': 'Acerto das questions', 
'studyQuality.requirements.SPEECH_RECOGNITION_AVERAGE_SCORE': 'Nota média no Speech Recognition', 
'studyQuality.requirements.TIME_STUDIED': 'Estudar o tempo semanal exigido', 
'studyQuality.requirements.UNIT_AVERAGE_SCORE': 'Nota média nas units', 
'studyQuality.title': 'Qualidade de Estudo', 
'tryAgain': 'Tentar Novamente', 
'tryAgainMessage': 'Tentando novamente em {seconds} segundos.', 
'unitExecution.confirmModal.executedToday': 'Você já fez este exercício hoje. Se refizer ele agora, você não ganhará uma nova estrelinha. Deseja continuar?', 
'unitExecution.confirmModal.noPoints': 'Você já fez este exercício. Se refizer, não irá ganhar novas estrelas. Você realmente deseja continuar?', 
'unitExecution.confirmModal.unitCompleted': 'Você já completou todas as estrelinhas. Se refizer este exercício, não ganhará novas estrelas. Você deseja continuar?', 
'unitExecution.error.endExecution.defaultMessage': 'Não foi possível calcular o resultado da atividade. Você pode tentar novamente ou ir para outra atividade.', 
'unitExecution.error.endExecution.tryAgain': 'Ocorreu um problema para calcular seu resultado. Aguarde alguns instantes e iremos tentar novamente.', 
'unitExecution.error.playAudio.defaultMessage': 'Não foi possível tocar o áudio do nativo. Você pode tentar novamente ou ir para outra atividade.', 
'unitExecution.error.playAudio.tryAgain': 'Não foi possível tocar o áudio do nativo. Aguarde alguns instantes e iremos tentar novamente.', 
'unitExecution.error.saveItem.defaultMessage': 'Não foi possível salvar a resposta. Você pode tentar novamente ou ir para outra atividade.', 
'unitExecution.error.saveItem.tryAgain': 'Ocorreu um problema para salvar a resposta. Aguarde alguns instantes e iremos tentar novamente.', 
'unitExecution.error.selectItem.defaultMessage': 'Não foi possível iniciar a instrução da atividade. Você pode tentar novamente ou ir para outra atividade.', 
'unitExecution.error.selectItem.tryAgain': 'Não foi possível iniciar a instrução da atividade. Aguarde alguns instantes e iremos tentar novamente.', 
'unitExecution.error.startExecution.defaultMessage': 'Não foi possível iniciar a atividade. Você pode tentar novamente ou ir para outra atividade.', 
'unitExecution.error.startExecution.tryAgain': 'Ocorreu um problema para inciar a atividade. Aguarde alguns instantes e iremos tentar novamente.', 
'unitExecution.itemType.text.false': 'No, it is not true.', 
'unitExecution.itemType.text.true': 'Yes, it is true.', 
'unitExecution.leave.description': 'Se você sair agora, o progresso do seu estudo será perdido após {timeToAllowContinueUnit}. Tem certeza que deseja sair ?', 
'unitExecution.leave.noProgressLossDescription': 'Tem certeza que deseja sair?', 
'unitExecution.leave.title': 'Atenção', 
'unitExecution.pointsReceived': 'Pontos Conquistados', 
'unitExecution.score.computer': 'Pontos do computador', 
'unitExecution.score.my': 'Meus Pontos', 
'unitExecution.score.yours': 'Seu Resultado', 
'userAway.message': 'Parece que você está com dificuldade para fazer o exercício. Tente escutar novamente o áudio de instrução ou fale com seu professor.', 
'userErrorReport.buttonsText.discardChanges': 'Descartar', 
'userErrorReport.buttonsText.submit': 'Enviar', 
'userErrorReport.errorMessages.KIDSNotFound': 'E-mail não cadastrado na plataforma', 
'userErrorReport.errorMessages.apiError': 'Não foi possível enviar seu reporte no momento. Aguarde um momento e tente novamente', 
'userErrorReport.labels.content': 'Nos conte o que aconteceu', 
'userErrorReport.labels.email': 'E-mail', 
'userErrorReport.labels.subject': 'Assunto', 
'userErrorReport.placeholders.content': 'Sinta-se à vontade para nos enviar suas sugestões e feedbacks', 
'userErrorReport.placeholders.email': 'Coloque o seu e-mail cadastrado na plataforma', 
'userErrorReport.placeholders.subject': 'Coloque aqui o assunto do seu reporte', 
'userErrorReport.title': 'Reportar um erro', 
'userPlayModal.message': 'Vamos começar!', 
'weekTime': 'Tempo da semana', 
'yes': 'Sim', 
}; 
export default labels;