import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import platform from "platform";
import Spinner from "core/Spinner";
import Col from "core/Col";

class ValidateBrowser extends React.Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
  };

  state = {
    isLoading: true,
    error: false,
  };

  componentDidMount() {
    if (platform.os.family === "Android" && platform.name !== "Chrome Mobile" && platform.name !== "Chrome") {
      this.props.history.push("/invalid-browser?for=android");
    } else if (platform.os.family === "iOS" && platform.name !== "Safari") {
      this.props.history.push("/invalid-browser?for=ios");
    } else if (
      platform.os.family !== "Android" &&
      platform.os.family !== "iOS" &&
      platform.name !== "Chrome" &&
      platform.name !== "Safari" &&
      platform.name !== "Firefox"
    ) {
      this.props.history.push("/invalid-browser?for=desktop");
    }
    this.setState({
      isLoading: false,
    });
  }

  render() {
    if (this.state.isLoading) {
      return (
        <Col justifyContent="center" alignItems="center" height="100vh">
          <Spinner size="sm" />
        </Col>
      );
    }
    return this.props.children;
  }
}

export default withRouter(ValidateBrowser);
