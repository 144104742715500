import React from "react";
import PropTypes from "prop-types";
import Entity from "student-front-commons/src/components/Entity";

const RankingParticipantContainer = ({ children }) => (
  <Entity name="profile">
    {({ getById }) => {
      const profile = getById(sessionStorage.getItem("id"));
      return children({
        isRankingParticipant: profile.rankingParticipant === "S",
      });
    }}
  </Entity>
);

RankingParticipantContainer.propTypes = {
  children: PropTypes.func.isRequired,
};

export default RankingParticipantContainer;
