import React from "react";
import CourseList from "screens/course/CourseList";
import { useEntity } from "student-front-commons/src/hooks";
import { PROFILE_SCHEMA } from "student-front-commons/src/schemas";

function CourseListContainer() {
  const defaultCourses = [
    {
      id: "discovery",
      name: "Discovery",
      disabled: true,
    },
    {
      id: "adventures",
      name: "Adventures",
      disabled: true,
    },
  ];
  const profile = useEntity(PROFILE_SCHEMA, sessionStorage.getItem("id"));

  return (
    <CourseList
      isLoading={false}
      courses={defaultCourses.map((staticCourse) => {
        const studentCourse = profile.activeCourses.find((c) => c.name === staticCourse.name);
        if (studentCourse) {
          return {
            ...studentCourse,
            disabled: false,
          };
        }
        return staticCourse;
      })}
    />
  );
}

export default CourseListContainer;
