import React from "react";
import PropTypes from "prop-types";
import Col from "core/Col";
import Image from "core/Image";
import UnitTypeThemeContext from "core/ThemeContext";
import Icon from "core/Icon";
import { GREEN_1, GREY_8, GREY_9, WHITE, YELLOW_4 } from "consts/colors";

class UnitIcon extends React.PureComponent {
  static propTypes = {
    isDisabled: PropTypes.bool,
    isCompleted: PropTypes.bool,
    showGameIcon: PropTypes.bool,
    size: PropTypes.oneOf(["xs", "sm", "md"]).isRequired,
  };

  static defaultProps = {
    isDisabled: false,
    isCompleted: false,
    showGameIcon: false,
  };

  render() {
    const { size, showGameIcon, isDisabled, isCompleted } = this.props;
    return (
      <UnitTypeThemeContext.Consumer>
        {({ primary, secondary, icon, isGame }) => (
          <Col
            position="relative"
            alignItems="center"
            justifyContent="center"
            width={
              {
                xs: 80,
                sm: 120,
                md: 180,
              }[size]
            }
            height={
              {
                xs: 80,
                sm: 120,
                md: 180,
              }[size]
            }
            borderRadius="100%"
            boxSizing="border-box"
            boxShadow="0px 10px 20px 0 rgba(0, 0, 0, 0.2);"
            backgroundColor={isDisabled ? GREY_8 : primary}
            border={isCompleted ? `2px solid ${GREEN_1}` : "none"}
          >
            {isGame && showGameIcon && (
              <Col
                position="absolute"
                top={5}
                right={10}
                width={40}
                height={40}
                boxShadow="0px 1px 3px 0 rgba(0, 0, 0, 0.2);"
                borderRadius="100%"
                boxSize="border-box"
                alignItems="center"
                justifyContent="center"
                backgroundColor={isDisabled ? GREY_9 : secondary}
              >
                <Icon name="game" color={isDisabled ? GREY_8 : YELLOW_4} />
              </Col>
            )}
            {isCompleted && (
              <Col
                alignSelf="center"
                position="absolute"
                top={-20}
                width={40}
                height={40}
                boxShadow="0px 1px 3px 0 rgba(0, 0, 0, 0.2);"
                borderRadius="100%"
                boxSize="border-box"
                alignItems="center"
                justifyContent="center"
                backgroundColor={GREEN_1}
              >
                <Icon name="check" color={WHITE} />
              </Col>
            )}
            <Image
              src={icon}
              width={
                {
                  xs: "60%",
                  sm: "60%",
                  md: "40%",
                }[size]
              }
              height="auto"
              objectFit="scale-down"
              filter={isDisabled ? "grayscale(100%)" : "none"}
              marginBottom={
                {
                  xs: 0,
                  sm: 0,
                  md: 20,
                }[size]
              }
            />
          </Col>
        )}
      </UnitTypeThemeContext.Consumer>
    );
  }
}

export default UnitIcon;
