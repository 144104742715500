import React from "react";
import { useParams } from "react-router-dom";
import MasteryTestExecutionScreen from "screens/mastery-test-execution/MasteryTestExecutionScreen";
import MasteryTestThemeProvider from "core/MasteryTestThemeProvider";
import { LOAD_CONFIGURATION_FLOW, START_MASTERY_TEST_EXECUTION_FLOW } from "consts";
import { useFlow } from "student-front-commons/src/hooks";

function MasteryTestExecutionScreenContainer() {
  const { moduleId, masteryTestId } = useParams();

  const [isLoadingConfig] = useFlow(LOAD_CONFIGURATION_FLOW);
  const [isStartingExecution] = useFlow(START_MASTERY_TEST_EXECUTION_FLOW, {
    autoStart: true,
    params: {
      module: moduleId,
      masteryTest: masteryTestId,
    },
  });

  return (
    <MasteryTestThemeProvider>
      <MasteryTestExecutionScreen isLoading={isLoadingConfig || isStartingExecution} />
    </MasteryTestThemeProvider>
  );
}

export default MasteryTestExecutionScreenContainer;
