import React from "react";
import NavigationPrompt from "react-router-navigation-prompt";
import Flow from "student-front-commons/src/components/Flow";
import get from "lodash/get";
import Col from "core/Col";
import Row from "core/Row";
import Modal from "core/Modal";
import Button from "core/Button";
import Separator from "core/Separator";
import { BLACK_1, GREEN_1, RED_1, WHITE } from "consts/colors";
import { CLOSE_MASTERY_TEST_EXECUTION_FLOW } from "consts";
import TranslatedText from "../../core/TranslatedText";

const CloseMasteryTestExecutionContainer = () => (
  <NavigationPrompt
    disableNative={true}
    renderIfNotActive={true}
    when={(from, to) => {
      if (get(to, "state.systemNavigation", false)) {
        return false;
      }
      return (
        !to ||
        !/\/modules\/[0-9a-fA-F]{24}\/mastery-test\/[0-9a-fA-F]{24}\/execution\/[0-9a-fA-F]{24}\/result/.test(
          to.pathname
        )
      );
    }}
  >
    {({ isActive, onCancel, onConfirm }) => (
      <Flow autoStart={false} id={CLOSE_MASTERY_TEST_EXECUTION_FLOW}>
        {({ handleStart }) => (
          <Modal isOpen={isActive}>
            <Col alignItems="center">
              <TranslatedText
                translateKey="masteryTestExecution.leave.title"
                size={36}
                color={RED_1}
                boldType="extra-bold"
              />
              <Separator size="lg" />
              <TranslatedText
                translateKey="masteryTestExecution.leave.description"
                size={14}
                color={BLACK_1}
                boldType="light-bold"
              />
              <Separator size="xxxl" />
              <Row>
                <Button backgroundColor={GREEN_1} onClick={onCancel}>
                  <TranslatedText translateKey="no" size={14} color={WHITE} boldType="bold" />
                </Button>
                <Separator size="lg" />
                <Button
                  backgroundColor={RED_1}
                  contentColor={WHITE}
                  onClick={() => {
                    handleStart();
                    onConfirm();
                  }}
                >
                  <TranslatedText translateKey="yes" size={14} color={WHITE} boldType="bold" />
                </Button>
              </Row>
            </Col>
          </Modal>
        )}
      </Flow>
    )}
  </NavigationPrompt>
);

export default CloseMasteryTestExecutionContainer;
