import React, { Component } from "react";
import { Router, Route, Switch } from "react-router-dom";
import html2canvas from "html2canvas";
import LogRocket from "logrocket";
import { detect } from "detect-browser";
import browserHistory from "browserHistory";
import WhitelabelAppProvider from "student-front-commons/src/components/WhitelabelAppProvider";
import Col from "core/Col";
import Spinner from "core/Spinner";
import ValidateBrowser from "core/ValidateBrowser";
import UserRoutesContainer from "core/UserRoutesContainer";
import LoginScreen from "screens/login/LoginScreen";
import InvalidBrowserScreen from "screens/InvalidBrowserScreen";
import TastingExecutionScreenContainer from "screens/unit-execution/tasting/TastingExecutionScreenContainer";
import TastingResultScreenContainer from "screens/unit-result/tasting/TastingResultScreenContainer";
import FlexgeLogo from "assets/logo-flexge.png";
import { logError } from "utils";
import AuthSceneContainer from "auth/AuthSceneContainer";
import WhitelabelConfig from "./wl.config.json";
import UserReportContext from "student-front-commons/src/components/UserReportContext";
import UserErrorReportForm from "./core/UserErrorReportForm";
import UserReportPrintEditor from "./core/UserReportPrintEditor";
import UserReportButton from "./core/UserReportButton";
import { useSelector } from "react-redux";

function Routes() {
  const executionId = useSelector((state) => state.executions.id);

  const handleStartScreenShot = async (onComplete) => {
    const deviceInfo = detect();

    const canvas = await html2canvas(document.body, {
      allowTaint: true,
      useCORS: true,
      foreignObjectRendering: false,
      scrollX: 0,
      scrollY: -window.scrollY,
      scale: 1.0,
      onclone: (document) => {
        const elementsWithAnimation = document.querySelectorAll(".user-report-error-catcher");
        if (elementsWithAnimation.length) {
          elementsWithAnimation.forEach((elem) => {
            elem.style.animation = "none";
            elem.style.zIndex = "initial";
          });
        }
        const withSlideInTopClass = document.querySelectorAll(".slide-in-top");
        if (withSlideInTopClass.length) {
          withSlideInTopClass.forEach((elem) => {
            elem.classList.remove("slide-in-top");
          });
        }
        const appBackgroundImage = document.querySelector(".user-report-app-background");
        if (appBackgroundImage) {
          appBackgroundImage.style.position = "absolute";
          appBackgroundImage.style.top = `${window.scrollY}px`;
        }
      },
    });

    let sessionURL = LogRocket.sessionURL;
    if (!(sessionURL || "").includes("app.logrocket.com")) {
      sessionURL = "Unavailable session link";
    }
    const imageBase64 = canvas.toDataURL("image/png");
    onComplete(imageBase64, {
      currentSessionURL: sessionURL,
      device: `${deviceInfo.name}_${deviceInfo.version}`,
      deviceSystem: deviceInfo.os,
      executionId,
    });

    const noscript = document.querySelector("noscript");
    if (noscript) {
      noscript.remove();
    }
  };

  return (
    <UserReportContext
      platform="kids_web"
      imageEditorType={UserReportPrintEditor}
      reportFormType={UserErrorReportForm}
      onTakeScreenshot={handleStartScreenShot}
    >
      <Switch>
        <Route exact path="/login" component={LoginScreen} />
        <Route path="/invalid-browser" component={InvalidBrowserScreen} />
        <Route path="/auth-callback" component={AuthSceneContainer} />
        <Route path="/units/:unitId/tasting" exact={true} component={TastingExecutionScreenContainer} />
        <Route path="/units/:unitId/tasting-result" exact={true} component={TastingResultScreenContainer} />
        <Route path="/" component={UserRoutesContainer} />
      </Switch>
      <UserReportButton />
    </UserReportContext>
  );
}

class App extends Component {
  componentDidCatch(error, errorInfo) {
    logError({ flow: "APP_DID_CATCH", error });
  }

  render() {
    return (
      <WhitelabelAppProvider
        defaultLogo={FlexgeLogo}
        whitelabelConfigs={WhitelabelConfig}
        host={process.env.REACT_APP_API_URL}
      >
        {({ isLoading }) =>
          isLoading ? (
            <Col justifyContent="center" alignItems="center" height="100vh">
              <Spinner size="sm" />
            </Col>
          ) : (
            <Router history={browserHistory}>
              <ValidateBrowser>
                <Routes />
              </ValidateBrowser>
            </Router>
          )
        }
      </WhitelabelAppProvider>
    );
  }
}

export default App;
