import { put, take, takeLatest } from "redux-saga/effects";
import { getFlowStart } from "student-front-commons/src/selectors/flow";
import { endFlow } from "student-front-commons/src/actions/flow";
import { FINISH_USER_PLAY_FLOW, REQUIRE_USER_PLAY_FLOW } from "consts";
import { logError } from "utils";

export default function* () {
  yield takeLatest(getFlowStart(REQUIRE_USER_PLAY_FLOW), function* () {
    try {
      yield take(getFlowStart(FINISH_USER_PLAY_FLOW));
    } catch (error) {
      logError({ error, flow: REQUIRE_USER_PLAY_FLOW });
    } finally {
      yield put(endFlow(REQUIRE_USER_PLAY_FLOW));
    }
  });
}
