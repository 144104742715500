import React from "react";
import { adopt } from "react-adopt";
import Flow from "student-front-commons/src/components/Flow";
import UserPlayModal from "core/UserPlayModal";
import { FINISH_USER_PLAY_FLOW, REQUIRE_USER_PLAY_FLOW } from "consts";

class UserPlayModalContainer extends React.PureComponent {
  Composed = adopt({
    modalFlow: <Flow autoStart={false} id={REQUIRE_USER_PLAY_FLOW} />,
    confirmFlow: <Flow autoStart={false} id={FINISH_USER_PLAY_FLOW} />,
  });

  render() {
    return (
      <this.Composed>
        {({ modalFlow, confirmFlow }) => (
          <UserPlayModal isOpen={modalFlow.isPending} onConfirm={confirmFlow.handleStart} />
        )}
      </this.Composed>
    );
  }
}

export default UserPlayModalContainer;
