import React, { useCallback } from "react";
import PropTypes from "prop-types";
import Image from "core/Image";
import ItemButton from "core/ItemButton";
import Col from "core/Col";
import Grid from "core/Grid";
import { getImage } from "stores/image-store";
import { useDispatch, useSelector } from "react-redux";
import { getItemExecutionPropById, getSelectedId } from "student-front-commons/src/selectors/itemExecution";
import {
  isInstructionEnableForStep,
  isInstructionHighlighted,
} from "student-front-commons/src/selectors/itemInstruction";
import { useFlow } from "student-front-commons/src/hooks";
import { CHECK_ITEM_ANSWER_FLOW } from "../../consts";
import { addItemExecutionAnswer } from "student-front-commons/src/actions/itemExecution";

function VocabularyGameItemRender(props) {
  const dispatch = useDispatch();

  const [, startCheckFlow] = useFlow(CHECK_ITEM_ANSWER_FLOW);

  const selectedItemId = useSelector(getSelectedId);
  const playingId = useSelector(getItemExecutionPropById(props.itemId, "playingId"));
  const isDisabled = useSelector(getItemExecutionPropById(props.itemId, "isDisabled"));
  const isFinished = useSelector(getItemExecutionPropById(props.itemId, "isFinished"));
  const showCorrectOption = useSelector(getItemExecutionPropById(props.itemId, "showCorrectOption"));

  const isInstructionEnabled = useSelector(isInstructionEnableForStep("user-away"));
  const shouldHighlighted = useSelector(isInstructionHighlighted);

  const handleClick = useCallback(() => {
    dispatch(addItemExecutionAnswer(selectedItemId, { answer: props.itemId }));
    startCheckFlow();
  }, [selectedItemId, dispatch, props.itemId, startCheckFlow]);

  return (
    <ItemButton
      padding={10}
      isDisabled={isDisabled || isFinished}
      onClick={handleClick}
      width={180}
      height={180}
      status={isFinished ? "active" : showCorrectOption && props.itemId === selectedItemId ? "outline" : "normal"}
      className={isInstructionEnabled && shouldHighlighted && !isFinished ? "shake-vertical" : ""}
    >
      {() => (
        <Col position="relative" overflow="hidden" borderRadius={10}>
          <Image
            src={
              getImage(`items/${props.itemId}/images/post-phrase-lg.png`) ||
              `${process.env.REACT_APP_FILES_URL}/items/${props.itemId}/images/post-phrase-lg.png`
            }
            width="100%"
            height="auto"
            position="absolute"
            className={playingId === `post-phrase-${props.itemId}` ? "slide-in-top" : "slide-out-bottom"}
          />
          <Image
            src={
              getImage(`items/${props.itemId}/images/lg.png`) ||
              `${process.env.REACT_APP_FILES_URL}/items/${props.itemId}/images/lg.png`
            }
            width="100%"
            height="auto"
            className={playingId === `post-phrase-${props.itemId}` ? "slide-out-bottom" : "slide-in-top"}
          />
        </Col>
      )}
    </ItemButton>
  );
}

VocabularyGameItemRender.propTypes = {
  itemId: PropTypes.string.isRequired,
};

function VocabularyGameItem() {
  const items = useSelector((state) => state.itemExecutions.allIds);

  return (
    <Col flexGrow={1} justifyContent="center" alignItems="center">
      <Grid rowGap={20} columnWidth={200} maxWidth={720} width="100%" flexShrink={0}>
        {items.map((item) => (
          <VocabularyGameItemRender key={item} itemId={item} />
        ))}
      </Grid>
    </Col>
  );
}

export default VocabularyGameItem;
