import React, { Fragment, Children, isValidElement } from "react";
import PropTypes from "prop-types";
import Separator from "core/Separator";

const InBetweenSpacing = ({ size, children }) => {
  const flattenedChildren = Children.toArray(children);
  return Children.map(flattenedChildren, (child, childIndex) => (
    <Fragment>
      {child}
      {isValidElement(flattenedChildren[childIndex + 1]) && <Separator size={size} />}
    </Fragment>
  ));
};

InBetweenSpacing.propTypes = {
  size: Separator.propTypes.size,
  children: PropTypes.node,
};

export default InBetweenSpacing;
