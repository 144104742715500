import React, { useCallback, useContext } from "react";
import Col from "core/Col";
import Row from "core/Row";
import Text from "core/Text";
import Separator from "core/Separator";
import InBetweenSpacing from "core/InBetweenSpacing";
import UnitTypeThemeContext from "core/ThemeContext";
import Card from "core/Card";
import Image from "core/Image";
import ItemButton from "core/ItemButton";
import { WHITE, YELLOW_4 } from "consts/colors";
import { getImage } from "stores/image-store";
import { useDispatch, useSelector } from "react-redux";
import { getItemExecutionPropById } from "student-front-commons/src/selectors/itemExecution";
import { useFlow } from "student-front-commons/src/hooks";
import { CHECK_ITEM_ANSWER_FLOW } from "../../consts";
import { addItemExecutionAnswer } from "student-front-commons/src/actions/itemExecution";
import {
  isInstructionEnableForStep,
  isInstructionHighlighted,
} from "student-front-commons/src/selectors/itemInstruction";

function GapFillLetterItemRender(props) {
  const dispatch = useDispatch();
  const unitTypeThemeContext = useContext(UnitTypeThemeContext);

  const [, startCheckFlow] = useFlow(CHECK_ITEM_ANSWER_FLOW);

  const itemId = useSelector(getItemExecutionPropById(props.itemId, "item.id"));
  const itemText = useSelector(getItemExecutionPropById(props.itemId, "item.text"));
  const itemLinkedAnswers = useSelector(getItemExecutionPropById(props.itemId, "item.linkedAnswers"));
  const isDisabled = useSelector(getItemExecutionPropById(props.itemId, "isDisabled"));
  const showCorrectOption = useSelector(getItemExecutionPropById(props.itemId, "showCorrectOption"));
  const userAnswer = useSelector(getItemExecutionPropById(props.itemId, "answer"));

  const isInstructionEnabled = useSelector(isInstructionEnableForStep("user-away"));
  const shouldHighlighted = useSelector(isInstructionHighlighted);

  const handleChange = useCallback(
    (answer) => {
      dispatch(addItemExecutionAnswer(itemId, { answer }));
      startCheckFlow();
    },
    [itemId, startCheckFlow, dispatch]
  );

  return (
    <Col alignItems="center">
      <Card
        alignItems="center"
        padding={20}
        showRopes={true}
        ropesStyle={{
          backgroundColor: unitTypeThemeContext.secondary,
        }}
        betweenRopesStyle={{
          width: "70%",
        }}
      >
        <Image
          src={
            getImage(`items/${itemId}/images/lg.png`) ||
            `${process.env.REACT_APP_FILES_URL}/items/${itemId}/images/lg.png`
          }
          width="100%"
          height="auto"
          borderRadius={10}
        />
        <Separator size="md" />
      </Card>
      <Col
        zIndex={1}
        marginTop={-25}
        alignItems="center"
        justifyContent="center"
        backgroundColor={unitTypeThemeContext.secondary}
        borderRadius={25}
        minHeight={50}
        width="65%"
      >
        <Row>
          {[...itemText].map((slice, index) => {
            if (itemLinkedAnswers.find((answer) => answer.index === index)) {
              return (
                <Text boldType="light-bold" size={36} color={userAnswer && userAnswer.correct ? YELLOW_4 : WHITE}>
                  {userAnswer && userAnswer.correct ? userAnswer.text : "_"}
                </Text>
              );
            }
            return (
              <Text boldType="light-bold" size={36} color={WHITE}>
                {slice}
              </Text>
            );
          }, "")}
        </Row>
      </Col>
      <Separator size="xxl" />
      <Row>
        <InBetweenSpacing size="xxl">
          {itemLinkedAnswers.map((answer) => (
            <ItemButton
              key={`answer-${answer.id}`}
              isDisabled={isDisabled}
              onClick={() => handleChange(answer)}
              status={
                showCorrectOption && answer.correct
                  ? "outline"
                  : userAnswer && userAnswer.id === answer.id
                  ? "active"
                  : "normal"
              }
              height={60}
              width={60}
              borderRadius={60}
              transition="all .3s"
              hoverTransform={isDisabled ? "none" : "scale(1.2)"}
              className={isInstructionEnabled && shouldHighlighted ? "shake-vertical" : ""}
            >
              {({ status }) => (
                <Text
                  boldType="light-bold"
                  size={36}
                  color={
                    {
                      active: YELLOW_4,
                      outline: unitTypeThemeContext.primary,
                      normal: unitTypeThemeContext.primary,
                    }[status]
                  }
                >
                  {answer.text}
                </Text>
              )}
            </ItemButton>
          ))}
        </InBetweenSpacing>
      </Row>
      <Separator size="xxl" />
    </Col>
  );
}

function GapFillLetterItem() {
  const items = useSelector((state) => state.itemExecutions.allIds);

  return items.map((item) => <GapFillLetterItemRender key={`item-${item}`} itemId={item} />);
}

export default GapFillLetterItem;
