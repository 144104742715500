import React from "react";

export default ({ color, size }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={size || 12} height={size || 12} viewBox="0 0 12 12">
    <path
      fill={color}
      d="M5.999 5.998a3 3 0 1 0 0-6 3 3 0 1 0 0 6zm0 1.5c-2.003 0-6 1.006-6 3v1.5h12v-1.5c0-1.994-3.998-3-6-3z"
    />
  </svg>
);
