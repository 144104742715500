import React, { useCallback, useContext } from "react";
import { isNil } from "lodash";
import Card from "core/Card";
import Row from "core/Row";
import Col from "core/Col";
import Image from "core/Image";
import Text from "core/Text";
import Clickable from "core/Clickable";
import Separator from "core/Separator";
import InBetweenSpacing from "core/InBetweenSpacing";
import UnitTypeThemeContext from "core/ThemeContext";
import { SELECT_ITEM_FLOW } from "consts";
import {
  GREY_2,
  SPEECH_EXCELLENT,
  SPEECH_GOOD,
  SPEECH_LOW,
  SPEECH_MODERATE,
  SPEECH_WEAK,
  WHITE,
  YELLOW_4,
} from "consts/colors";
import { getImage } from "stores/image-store";
import { useSelector } from "react-redux";
import { useFlow } from "student-front-commons/src/hooks";
import {
  getCurrentItemExecutionProp,
  getItemExecutionPropById,
  getSelectedId,
} from "student-front-commons/src/selectors/itemExecution";
import {
  isInstructionEnableForStep,
  isInstructionHighlighted,
} from "student-front-commons/src/selectors/itemInstruction";
import { Block } from "jsxstyle";

function PhonemeItemRender(props) {
  const unitTypeContext = useContext(UnitTypeThemeContext);

  const [, startSelectItemFlow] = useFlow(SELECT_ITEM_FLOW);

  const selectedItemId = useSelector(getSelectedId);
  const itemId = useSelector(getItemExecutionPropById(props.itemId, "item.id"));
  const itemText = useSelector(getItemExecutionPropById(props.itemId, "item.text"));
  const itemTitle = useSelector(getItemExecutionPropById(props.itemId, "item.title"));
  const playingId = useSelector(getItemExecutionPropById(props.itemId, "playingId"));
  const isDisabled = useSelector(getItemExecutionPropById(props.itemId, "isDisabled"));
  const isRecording = useSelector(getItemExecutionPropById(props.itemId, "isRecording"));
  const qualityScore = useSelector(getItemExecutionPropById(props.itemId, "speechRecognitionResult.qualityScore"));
  const isRecordingAnyItem = useSelector(getCurrentItemExecutionProp("isRecording"));

  const isInstructionEnabled = useSelector(isInstructionEnableForStep("user-away"));
  const shouldHighlighted = useSelector(isInstructionHighlighted);

  const speechRecognitionMinimumScore = useSelector((state) => state.configurations.scoreToPassOfSpeechRecognition);

  const getSpeechScoreColor = () => {
    if (isNil(qualityScore)) {
      return isDisabled ? GREY_2 : WHITE;
    }

    if (qualityScore >= 80) {
      return SPEECH_EXCELLENT;
    } else if (qualityScore >= speechRecognitionMinimumScore && qualityScore < 80) {
      return SPEECH_GOOD;
    } else if (qualityScore >= 40 && qualityScore < speechRecognitionMinimumScore) {
      return SPEECH_MODERATE;
    } else if (qualityScore >= 20 && qualityScore < 40) {
      return SPEECH_LOW;
    } else {
      return SPEECH_WEAK;
    }
  };

  const handleSelect = useCallback(() => {
    startSelectItemFlow({ item: itemId });
  }, [itemId, startSelectItemFlow]);

  return (
    <Col alignItems="center" width={260}>
      <Clickable
        isDisabled={isDisabled || isRecording || isRecordingAnyItem || selectedItemId === itemId}
        onClick={handleSelect}
      >
        <Row
          justifyContent="center"
          alignItems="center"
          backgroundColor={unitTypeContext.primary}
          width={200}
          height={100}
          borderRadius={25}
          transition="box-shadow .5s, transform 300ms ease"
          transform={playingId === `title-${itemId}` ? "scale(1.15)" : "none"}
          boxShadow={playingId === `title-${itemId}` ? "0px 1px 59px 10px rgba(250, 220, 96, 0.8)" : "none"}
          className={isInstructionEnabled && shouldHighlighted && !selectedItemId ? "shake-vertical" : ""}
        >
          <Block
            component="label"
            fontWeight={800}
            fontFamily="Baloo 2"
            color={YELLOW_4}
            fontSize={56}
            pointerEvents="none"
          >
            {itemTitle.toUpperCase()}
          </Block>
          <Separator size="sm" />
          <Block
            component="label"
            fontWeight={800}
            fontFamily="Baloo 2"
            color={YELLOW_4}
            fontSize={56}
            pointerEvents="none"
          >
            {itemTitle.toLowerCase()}
          </Block>
        </Row>
      </Clickable>
      <Separator size="xxl" />
      <Separator size="xxl" />
      <Col
        position="relative"
        alignItems="center"
        transition="opacity .5s"
        opacity={selectedItemId === itemId || !isNil(qualityScore) ? 1 : 0}
      >
        <Card
          padding={10}
          backgroundColor={getSpeechScoreColor()}
          width={260}
          height={260}
          overflow="hidden"
          transition="box-shadow .5s, transform 300ms ease"
          transform={playingId === `post-phrase-${itemId}` ? "scale(1.15)" : "none"}
          boxShadow={playingId === `post-phrase-${itemId}` ? "0px 1px 59px 10px rgba(250, 220, 96, 0.8)" : "none"}
        >
          <Image
            src={
              getImage(`items/${itemId}/images/lg.png`) ||
              `${process.env.REACT_APP_FILES_URL}/items/${itemId}/images/lg.png`
            }
            width="100%"
            height="auto"
            borderRadius={10}
          />
        </Card>
        <Col
          position="absolute"
          top={playingId === `post-phrase-${itemId}` ? -45 : -25}
          justifyContent="center"
          alignItems="center"
          backgroundColor={unitTypeContext.primary}
          padding="0px 20px"
          height={50}
          borderRadius={25}
          zIndex={1}
          transition="top 300ms, box-shadow .5s, transform 300ms ease"
          transform={playingId === itemId ? "scale(1.15)" : "none"}
          boxShadow={playingId === itemId ? "0px 1px 59px 0 rgba(250, 220, 96, 0.8)" : "none"}
        >
          <Row>
            {[...itemText].map((slice, index) => (
              <Text
                key={`item-${itemId}-text-${slice}-${index}`}
                boldType="extra-bold"
                size={22}
                color={slice.toLowerCase() === itemTitle.toLowerCase() ? YELLOW_4 : WHITE}
              >
                {slice}
              </Text>
            ))}
          </Row>
        </Col>
      </Col>
    </Col>
  );
}

export default function PhonemeItem() {
  const items = useSelector((state) => state.itemExecutions.allIds);

  return (
    <Row justifyContent="center" alignItems="flex-start" flexWrap="wrap" minWidth={items.length * 30}>
      <InBetweenSpacing size="xxl">
        {items.map((item) => (
          <PhonemeItemRender key={item} itemId={item} />
        ))}
      </InBetweenSpacing>
    </Row>
  );
}
