import React from "react";
import PropTypes from "prop-types";
import ControlBarButton from "core/ControlBarButton";
import { addSentryUserAction } from "../utils";

const sentryUserAction = { mainComponent: "RepeatButton" };

const RepeatButton = ({ onClick, isDisabled }) => (
  <ControlBarButton
    name="repeat"
    onClick={() => {
      onClick();
      addSentryUserAction({
        ...sentryUserAction,
        clickedComponent: "ControlBarButton",
        action: `Repeat Unit Item`,
      });
    }}
    isDisabled={isDisabled}
    isLoading={false}
    isActive={false}
  />
);

RepeatButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool.isRequired,
};

export default RepeatButton;
