import React from "react";
import PropTypes from "prop-types";
import Lottie from "react-lottie";
import Col from "core/Col";
import Row from "core/Row";
import InBetweenSpacing from "core/InBetweenSpacing";
import FiveStarsIcon from "assets/lottie/unit-result-star";
import FourStarsIcon from "assets/lottie/unit-result-confetti";
import UnitStarIcon from "core/UnitStarIcon";
import { YELLOW_3 } from "consts/colors";
import TranslatedText from "core/TranslatedText";

export default class UnitResultScore extends React.Component {
  static propTypes = {
    score: PropTypes.number.isRequired,
  };

  state = { animationLoopCount: 0 };

  handleAnimationLoop = () => {
    this.setState({
      animationLoopCount: this.state.animationLoopCount + 1,
    });
  };

  render() {
    const { score } = this.props;
    return (
      <Col alignItems="center">
        <TranslatedText translateKey="unitExecution.score.yours" color={YELLOW_3} size={42} boldType="bold" />
        <Row flexShrink={0} alignItems="center">
          <InBetweenSpacing size="md">
            <UnitStarIcon size="xs" isActive={score > 0} />
            <UnitStarIcon size="sm" isActive={score > 30 || (score > 15 && score <= 30)} />
            <UnitStarIcon size="md" isActive={score > 50 || (score > 30 && score <= 50)} />
            <UnitStarIcon size="lg" isActive={score > 70 || (score > 50 && score <= 70)} />
            <UnitStarIcon size="xl" isActive={score >= 70} />
          </InBetweenSpacing>
        </Row>
        {score > 50 && (
          <Col position="absolute">
            <Lottie
              options={{
                loop: true,
                autoplay: true,
                animationData: score >= 70 ? FiveStarsIcon : FourStarsIcon,
                rendererSettings: {
                  preserveAspectRatio: "xMidYMid slice",
                },
              }}
              width={400}
              height="auto"
              backgroundColor="transparent"
              isStopped={this.state.animationLoopCount >= 3}
              eventListeners={[
                {
                  eventName: "loopComplete",
                  callback: this.handleAnimationLoop,
                },
              ]}
            />
          </Col>
        )}
      </Col>
    );
  }
}
