import React from "react";
import queryString from "query-string";
import { useLocation, useParams } from "react-router-dom";
import UnitTypeThemeProvider from "core/UnitTypeThemeProvider";
import TastingExecutionScreen from "screens/unit-execution/tasting/TastingExecutionScreen";
import { LOAD_CONFIGURATION_FLOW, START_TASTING_EXECUTION_FLOW } from "consts";
import { useEntity, useFlow } from "student-front-commons/src/hooks";
import { UNIT_SCHEMA } from "student-front-commons/src/schemas";

function TastingExecutionScreenContainer() {
  const location = useLocation();
  const { unitId } = useParams();

  const unit = useEntity(UNIT_SCHEMA, unitId);

  const [isLoadingConfig] = useFlow(LOAD_CONFIGURATION_FLOW);
  const [isStartingExecution] = useFlow(START_TASTING_EXECUTION_FLOW, {
    autoStart: true,
    params: {
      unit: unitId,
      ...queryString.parse(location.search),
    },
  });

  return (
    <UnitTypeThemeProvider unitType={unit?.type}>
      <TastingExecutionScreen isLoading={isStartingExecution || isLoadingConfig} />
    </UnitTypeThemeProvider>
  );
}

export default TastingExecutionScreenContainer;
