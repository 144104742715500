import React from "react";
import { adopt } from "react-adopt";
import get from "lodash/get";
import Flow from "student-front-commons/src/components/Flow";
import ConfirmModal from "core/ConfirmModal";
import { CONFIRM_MODAL_FLOW, CONFIRM_MODAL_YES_FLOW, CONFIRM_MODAL_NO_FLOW } from "consts";

class ConfirmModalContainer extends React.PureComponent {
  Composed = adopt({
    modalFlow: <Flow autoStart={false} id={CONFIRM_MODAL_FLOW} />,
    confirmFlow: <Flow autoStart={false} id={CONFIRM_MODAL_YES_FLOW} />,
    cancelFlow: <Flow autoStart={false} id={CONFIRM_MODAL_NO_FLOW} />,
  });

  render() {
    return (
      <this.Composed>
        {({ modalFlow, confirmFlow, cancelFlow }) => (
          <ConfirmModal
            isOpen={modalFlow.isPending}
            onConfirm={confirmFlow.handleStart}
            onCancel={cancelFlow.handleStart}
            message={get(modalFlow.getParams(), "message", "")}
            type={get(modalFlow.getParams(), "type", "confirm")}
          />
        )}
      </this.Composed>
    );
  }
}

export default ConfirmModalContainer;
