import React from "react";
import PropTypes from "prop-types";
import Col from "core/Col";
import Clickable from "core/Clickable";
import { GREY_4 } from "consts/colors";
import TranslatedText from "core/TranslatedText";

const MenuItem = ({ label, onClick }) => (
  <Clickable isDisabled={false} onClick={onClick}>
    <Col alingItems="center" justifyContent="center">
      <TranslatedText translateKey={label} boldType="light-bold" size={18} color={GREY_4} />
    </Col>
  </Clickable>
);

MenuItem.propTypes = {
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default MenuItem;
