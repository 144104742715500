import LogRocket from "logrocket";
import jwtDecode from "jwt-decode";
import { head } from "lodash";
import * as Sentry from "@sentry/react";
import { call, put, takeLatest, select } from "redux-saga/effects";
import { endFlow, startFlow } from "student-front-commons/src/actions/flow";
import { mergeEntities } from "student-front-commons/src/actions/entity";
import { getFlowStart } from "student-front-commons/src/selectors/flow";
import { getEntityById } from "student-front-commons/src/selectors/entity";
import { getProfile } from "student-front-commons/src/services/profileService";
import { getCourses } from "student-front-commons/src/services/courseService";
import browserHistory from "browserHistory";
import { LOAD_CONFIGURATION_FLOW, LOAD_STUDENT_FLOW, LOAD_LOCALIZED_LABELS_FLOW } from "consts";
import mapValues from "lodash/mapValues";

export default function* () {
  yield takeLatest(getFlowStart(LOAD_STUDENT_FLOW), function* () {
    try {
      if (!sessionStorage.getItem("accessToken")) {
        sessionStorage.clear();
        browserHistory.replace("/login");
      }

      const id = sessionStorage.getItem("id");
      const decodedToken = jwtDecode(sessionStorage.getItem("accessToken"));
      sessionStorage.setItem("locale", decodedToken.locale);

      yield put(
        startFlow(LOAD_LOCALIZED_LABELS_FLOW, {
          locale: head(decodedToken.locale.split("-")),
        })
      );

      const profileResult = yield call(getProfile);
      yield put(mergeEntities(profileResult.entities));

      const courses = yield call(getCourses, {
        id,
      });

      // only keep it until bett educar
      courses.entities.course = mapValues(courses.entities.course, (value) => ({
        ...value,
        name: value.name === "Bett Kids" ? "Adventures" : value.name,
      }));

      yield put(mergeEntities(courses.entities));
      if (!courses.result.length) {
        yield call(browserHistory.replace, "/no-course-found");
      }

      yield put(startFlow(LOAD_CONFIGURATION_FLOW));

      const profile = yield select(getEntityById("profile", sessionStorage.getItem("id")));
      const schoolClass = yield select(getEntityById("schoolClass", profile.schoolClass));
      const school = yield select(getEntityById("school", schoolClass?.school));
      const company = yield select(getEntityById("company", profile.company));

      if (process.env.REACT_APP_LOG_ROCKET_KEY) {
        LogRocket.init(process.env.REACT_APP_LOG_ROCKET_KEY);
        LogRocket.identify(sessionStorage.getItem("id"), {
          name: profile.name,
          email: profile.email,
          company: company.name,
          school: school?.name,
          classroom: schoolClass?.name,
        });
      }

      Sentry.configureScope((scope) => {
        scope.setUser({
          id: sessionStorage.getItem("id"),
          email: profile.email,
          company: company.name,
        });
      });

      if (!sessionStorage.getItem("course") || sessionStorage.getItem("course") === "null") {
        browserHistory.replace("/courses");
      }
    } catch (error) {
      Sentry.captureException(error);
    } finally {
      yield put(endFlow(LOAD_STUDENT_FLOW));
    }
  });
}
