import React from "react";

export default ({ color }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30">
    <path
      fill={color}
      d="M24.667 26.484V30l-4.687-3.516h-9.375V15.879h-.586l-4.687 3.515v-3.515H0V0h19.394v10.605H30v15.879h-5.333zM17.636 1.758H1.757v12.363h5.332v1.758l2.344-1.758h1.172v-3.484H8.658c-.079 0-.123.032-.131.095l-.203.919a.792.792 0 0 1-.292.472.831.831 0 0 1-.531.185H6.379a.473.473 0 0 1-.4-.203.479.479 0 0 1-.077-.442L8.241 4.24a.867.867 0 0 1 .328-.466.918.918 0 0 1 .555-.179h1.146a.9.9 0 0 1 .543.179c.163.12.276.275.34.466l2.006 6.365h4.477V1.758zm-7.259 6.993l-.692-3.115c0-.008-.004-.012-.012-.012s-.012.004-.012.012l-.692 3.115c-.008.024-.002.046.018.066a.09.09 0 0 0 .065.029h1.241a.089.089 0 0 0 .066-.029c.02-.02.026-.042.018-.066zm17.865 3.612H12.363v12.363h8.203l2.344 1.758v-1.758h5.332V12.363zm-8.409 10.581a23.14 23.14 0 0 1-2.029-.094.695.695 0 0 1-.475-.24.74.74 0 0 1-.194-.511V14.99c0-.188.062-.356.188-.504a.663.663 0 0 1 .469-.247 20.728 20.728 0 0 1 1.924-.094c2.346 0 3.519.743 3.519 2.229 0 .501-.158.925-.475 1.273-.317.348-.741.565-1.273.651-.008 0-.012.004-.012.012s.004.012.012.012c.602.078 1.083.309 1.443.692.36.383.54.833.54 1.349 0 .884-.288 1.535-.863 1.953-.574.419-1.499.628-2.774.628zm.117-7.039c-.148 0-.293.012-.434.035-.063.016-.094.059-.094.129V17.5c0 .071.036.106.106.106h.246c.9 0 1.349-.293 1.349-.88 0-.547-.391-.821-1.173-.821zm.059 3.343h-.481c-.07 0-.106.036-.106.106v1.666c0 .07.031.114.094.129.141.024.286.035.434.035.469 0 .812-.086 1.027-.258.215-.172.323-.438.323-.798 0-.586-.431-.88-1.291-.88z"
    />
  </svg>
);
