import React from "react";
import PropTypes from "prop-types";
import { Block } from "jsxstyle";
import { WHITE } from "consts/colors";
import SettingsContext from "./SettingsContext";

class Text extends React.PureComponent {
  static propTypes = {
    boldType: PropTypes.oneOf(["regular", "bold", "extra-bold", "light-bold"]),
    align: PropTypes.oneOf(["left", "center", "right"]),
    isUpperCase: PropTypes.bool,
    id: PropTypes.string,
    color: PropTypes.string,
    lineHeight: PropTypes.string,
    size: PropTypes.number,
    children: PropTypes.node.isRequired,
  };

  static defaultProps = {
    id: undefined,
    boldType: "regular",
    align: "left",
    isUpperCase: false,
    color: WHITE,
    size: 12,
    lineHeight: null,
  };

  render() {
    const { id, boldType, align, isUpperCase, color, size, type, lineHeight, children, ...rest } = this.props;
    return (
      <SettingsContext.Consumer>
        {({ forceUpperCase }) => (
          <Block
            component="label"
            props={{
              id,
            }}
            fontWeight={
              {
                regular: 400,
                "light-bold": 600,
                bold: 700,
                "extra-bold": 800,
              }[boldType]
            }
            textAlign={align}
            fontFamily="Baloo 2"
            color={color}
            fontSize={size}
            textTransform={isUpperCase || forceUpperCase ? "uppercase" : "none"}
            lineHeight={lineHeight}
            transition="color .5s"
            cursor="inherit"
            pointerEvents="none"
            {...rest}
          >
            {children}
          </Block>
        )}
      </SettingsContext.Consumer>
    );
  }
}

export default Text;
