import React from "react";
import PropTypes from "prop-types";
import Row from "core/Row";
import Col from "core/Col";
import Text from "core/Text";
import Separator from "core/Separator";
import Avatar from "core/Avatar";
import { GREY_4, GREY_5 } from "consts/colors";
import { SM_SIZE } from "utils";

const StudentInfo = ({ nickname, name, profilePicture, course }) => (
  <Row
    mediaQueries={{
      none: `(max-width: ${SM_SIZE}px)`,
      sm: `(min-width: ${SM_SIZE}px)`,
    }}
    noneDisplay="none"
    smDisplay="flex"
    transition="display .3s"
    height={60}
    borderRadius={20}
    borderWidth={1}
    borderStyle="solid"
    borderColor={GREY_5}
    alignItems="center"
    boxSizing="border-box"
    marginRight={20}
  >
    <Separator size="sm" />
    <Avatar src={profilePicture} size="sm" />
    <Separator size="sm" />
    <Col>
      <Text
        boldType="bold"
        size={16}
        color={GREY_4}
        textOverflow="ellipsis"
        whiteSpace="nowrap"
        maxWidth={165}
        overflow="hidden"
      >
        {nickname || name}
      </Text>
      <Text size={12} color={GREY_4}>
        {course}
      </Text>
    </Col>
    <Separator size="xxl" />
  </Row>
);

StudentInfo.propTypes = {
  name: PropTypes.string.isRequired,
  course: PropTypes.string.isRequired,
  profilePicture: PropTypes.string,
};

StudentInfo.defaultProps = {
  profilePicture: null,
};

export default StudentInfo;
