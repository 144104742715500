import React from "react";
import CircleType from "circletype";
import Text from "core/Text";

export default class RoundedText extends React.Component {
  componentDidMount() {
    const circleType = new CircleType(document.getElementById("rounded-text"));
    circleType.radius(this.props.radius).dir(1);
  }

  render() {
    const { children, ...rest } = this.props;
    return (
      <Text id="rounded-text" {...rest}>
        {children}
      </Text>
    );
  }
}
