import React from "react";
import { useParams } from "react-router-dom";
import MasteryTestResultScreen from "screens/mastery-test-result/MasteryTestResultScreen";
import MasteryTestThemeProvider from "core/MasteryTestThemeProvider";
import { END_MASTERY_TEST_EXECUTION_FLOW } from "consts";
import { useFlow } from "student-front-commons/src/hooks";

function MasteryTestResultScreenContainer() {
  const { moduleId, masteryTestId, executionId } = useParams();

  const [isPending] = useFlow(END_MASTERY_TEST_EXECUTION_FLOW, {
    autoStart: true,
    params: {
      module: moduleId,
      masteryTest: masteryTestId,
      masteryTestExecution: executionId,
    },
  });

  return (
    <MasteryTestThemeProvider>
      <MasteryTestResultScreen isLoading={isPending} />
    </MasteryTestThemeProvider>
  );
}

export default MasteryTestResultScreenContainer;
