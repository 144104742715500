import React, { useCallback } from "react";
import PrivateScreen from "core/PrivateScreen";
import Col from "core/Col";
import ModuleHeader from "screens/module-detail/ModuleHeader";
import Separator from "core/Separator";
import { useEntity, useService } from "student-front-commons/src/hooks";
import { COURSE_SCHEMA, MODULE_SCHEMA } from "student-front-commons/src/schemas";
import { useParams } from "react-router-dom";
import UnitList from "./UnitList";
import MasteryTestList from "./MasteryTestList";
import { setModuleAccess } from "student-front-commons/src/services/moduleService";

function ModuleDetailScreen() {
  const params = useParams();

  const course = useEntity(COURSE_SCHEMA, sessionStorage.getItem("course"));
  const module = useEntity(MODULE_SCHEMA, params.moduleId);

  useService(setModuleAccess, {
    autoStart: true,
    params: {
      id: sessionStorage.getItem("id"),
      course: sessionStorage.getItem("course"),
      module: params.moduleId,
    },
  });

  const getModuleBackground = useCallback(() => {
    try {
      if (module && module.name && course && course.name) {
        return require(`assets/backgrounds/${course.name.toLowerCase()}/${module.name
          .toLowerCase()
          .replace(/[.!?,`'"]/g, "")
          .replace(/ /g, "-")}.png`).default;
      }
    } catch (error) {
      return null;
    }
  }, [course, module]);

  return (
    <PrivateScreen background={getModuleBackground()}>
      <ModuleHeader />
      <Separator size="lg" />
      <Separator size="lg" />
      <Col flexGrow={1} flexShrink={0}>
        <UnitList />
      </Col>
      <Separator size="xxl" />
      <MasteryTestList />
    </PrivateScreen>
  );
}

export default ModuleDetailScreen;
