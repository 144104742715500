import React, { useContext } from "react";
import WhitelabelContext from "student-front-commons/src/components/WhitelabelContext";
import Card from "core/Card";
import PrivateScreen from "core/PrivateScreen";
import CourseListContainer from "screens/course/CourseListContainer";
import Separator from "core/Separator";
import Col from "core/Col";
import ColorfulBg from "assets/default-colorful-bg.png";
import { GREY_4 } from "consts/colors";
import TranslatedText from "core/TranslatedText";
import { useEntity } from "student-front-commons/src/hooks";
import { PROFILE_SCHEMA } from "student-front-commons/src/schemas";

function CourseScreen() {
  const whitelabelContext = useContext(WhitelabelContext);
  const profile = useEntity(PROFILE_SCHEMA, sessionStorage.getItem("id"));

  return (
    <PrivateScreen background={ColorfulBg}>
      <Col alignItems="center">
        <Card
          showRopes={true}
          padding="30px 15px 15px 15px"
          alignItems="center"
          width="100%"
          maxWidth={520}
          betweenRopesStyle={{
            width: "70%",
          }}
        >
          <TranslatedText
            translateKey="courseScreen.greetings"
            values={{
              name: profile.nickname || profile.name,
            }}
            size={26}
            color={whitelabelContext.primaryColor}
            boldType="bold"
          />
          <Separator size="xs" />
          <TranslatedText translateKey="courseScreen.chooseCourse" size={18} color={GREY_4} boldType="light-bold" />
        </Card>
        <Separator size="sm" />
        <Separator size="xxxl" />
        <CourseListContainer />
      </Col>
    </PrivateScreen>
  );
}

export default CourseScreen;
