import React from "react";
import { useParams } from "react-router-dom";
import UnitExecutionScreen from "screens/unit-execution/UnitExecutionScreen";
import { LOAD_CONFIGURATION_FLOW, START_UNIT_EXECUTION_FLOW } from "consts";
import UnitTypeThemeProvider from "core/UnitTypeThemeProvider";
import { useEntity, useFlow } from "student-front-commons/src/hooks";
import { UNIT_SCHEMA } from "student-front-commons/src/schemas";

function UnitExecutionScreenContainer() {
  const { moduleId, unitId } = useParams();

  const unit = useEntity(UNIT_SCHEMA, unitId);

  const [isLoadingConfig] = useFlow(LOAD_CONFIGURATION_FLOW);
  const [isStartingExecution] = useFlow(START_UNIT_EXECUTION_FLOW, {
    autoStart: true,
    params: {
      module: moduleId,
      unit: unitId,
    },
  });

  return (
    <UnitTypeThemeProvider unitType={unit.type}>
      <UnitExecutionScreen isLoading={isLoadingConfig || isStartingExecution} />
    </UnitTypeThemeProvider>
  );
}

export default UnitExecutionScreenContainer;
