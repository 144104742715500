import React, { useContext } from "react";
import PropTypes from "prop-types";
import { useHistory, useParams } from "react-router-dom";
import Col from "core/Col";
import Row from "core/Row";
import Spinner from "core/Spinner";
import Separator from "core/Separator";
import IconButton from "core/IconButton";
import UnitTypeThemeContext from "core/ThemeContext";
import UnitExecutionControlBar from "screens/unit-execution/UnitExecutionControlBar";
import CloseUnitExecutionContainer from "screens/unit-execution/CloseUnitExecutionContainer";
import PublicScreen from "core/PublicScreen";
import ScreenContainer from "core/ScreenContainer";
import ExecutionHelpContainer from "core/ExecutionHelpContainer";
import InBetweenSpacing from "core/InBetweenSpacing";
import BugReporterButton from "core/BugReporterButton";
import { addBreadcrumb, XL_SIZE } from "utils";
import UnitExecutionProgressBar from "./UnitExecutionProgressBar";
import ItemExecution from "../../core/items";

function UnitExecutionScreen(props) {
  const params = useParams();
  const history = useHistory();
  const unitTypeContext = useContext(UnitTypeThemeContext);

  return (
    <PublicScreen
      backgroundStyle={{
        background: unitTypeContext.radialGradient,
      }}
    >
      {!props.isLoading && <UnitExecutionProgressBar />}
      <Separator size="lg" />
      <CloseUnitExecutionContainer />
      {props.isLoading ? (
        <Col alignItems="center" justifyContent="center" height="100vh">
          <Spinner />
        </Col>
      ) : (
        <Col alignItems="center" height="97vh" position="relative">
          <Row
            width="100%"
            maxWidth={XL_SIZE}
            mediaQueries={{
              md: `(max-width: ${XL_SIZE + 1}px)`,
              lg: `(min-width: ${XL_SIZE + 1}px)`,
            }}
            mdPadding="0px 15px"
            lgPadding="0px"
            boxSizing="border-box"
            position="absolute"
            justifyContent="space-between"
            zIndex={2}
          >
            <IconButton
              name="back"
              onClick={() => {
                history.push(`/modules/${params.moduleId}/detail`);
                addBreadcrumb({
                  category: "ui",
                  action: `Navigate to /modules/${params.moduleId}/detail`,
                });
              }}
            />
            <Row>
              <InBetweenSpacing size="sm">
                <BugReporterButton />
                <ExecutionHelpContainer />
              </InBetweenSpacing>
            </Row>
          </Row>
          <Separator size="xxxl" />
          <Separator size="sm" />
          <Col width="100%" flexGrow={1} flexShrink={0} justifyContent="space-between">
            <ScreenContainer>
              <ItemExecution />
            </ScreenContainer>
            <UnitExecutionControlBar />
          </Col>
        </Col>
      )}
    </PublicScreen>
  );
}

UnitExecutionScreen.propTypes = {
  isLoading: PropTypes.bool.isRequired,
};

export default UnitExecutionScreen;
