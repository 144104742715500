import React from "react";
import PrivateScreen from "core/PrivateScreen";
import Card from "core/Card";
import Separator from "core/Separator";
import GradesHeaderContainer from "screens/grades/GradesHeaderContainer";
import GradesListContainer from "screens/grades/GradesListContainer";
import { WHITE } from "consts/colors";

const GradesScreen = () => (
  <PrivateScreen>
    <Card
      showRopes={true}
      flexShrink={0}
      animation="bounce-in-top 0.8s both"
      zIndex={2}
      betweenRopesStyle={{
        width: "70%",
      }}
      ropesStyle={{
        backgroundColor: WHITE,
      }}
    >
      <GradesHeaderContainer />
    </Card>
    <Separator size="xl" />
    <Card
      showRopes={true}
      padding="60px 40px 40px"
      flexShrink={0}
      animation="bounce-in-top 0.8s both"
      betweenRopesStyle={{
        width: "70%",
      }}
    >
      <GradesListContainer />
    </Card>
  </PrivateScreen>
);

export default GradesScreen;
