import React from "react";
import PropTypes from "prop-types";
import Lottie from "react-lottie";
import SpinnerOne from "assets/lottie/spinner-1";

const Spinner = ({ size }) => (
  <Lottie
    options={{
      loop: true,
      autoplay: true,
      animationData: SpinnerOne,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
      },
    }}
    height={
      {
        xxs: 20,
        xs: 30,
        sm: 120,
      }[size]
    }
    width={
      {
        xxs: 20,
        xs: 30,
        sm: 120,
      }[size]
    }
  />
);

Spinner.propTypes = {
  size: PropTypes.oneOf(["xxs","xs", "sm"]),
};

Spinner.defaultProps = {
  size: "sm",
};

export default Spinner;
