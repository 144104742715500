import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import PublicScreen from "core/PublicScreen";
import Header from "core/Header";
import ScreenContainer from "core/ScreenContainer";
import Col from "core/Col";

class PrivateScreen extends React.Component {
  static propTypes = {
    background: PropTypes.string,
    backgroundStyle: PropTypes.object,
    children: PropTypes.node.isRequired,
  };

  componentDidMount() {
    if (!sessionStorage.getItem("accessToken")) {
      sessionStorage.clear();
      this.props.history.push("/login");
    }
  }

  render() {
    return (
      <PublicScreen background={this.props.background} backgroundStyle={this.props.backgroundStyle}>
        <Header />
        <Col height="100vh" zIndex={1} paddingTop={120}>
          <ScreenContainer>{this.props.children}</ScreenContainer>
        </Col>
      </PublicScreen>
    );
  }
}

export default withRouter(PrivateScreen);
