import React, { useCallback, useContext } from "react";
import PropTypes from "prop-types";
import Text from "core/Text";
import Image from "core/Image";
import InBetweenSpacing from "core/InBetweenSpacing";
import ItemButton from "core/ItemButton";
import Col from "core/Col";
import Separator from "core/Separator";
import Card from "core/Card";
import { YELLOW_4 } from "consts/colors";
import { Flex } from "jsxstyle";
import MediaQuery from "react-responsive";
import { getImage } from "stores/image-store";
import { useDispatch, useSelector } from "react-redux";
import UnitTypeThemeContext from "../ThemeContext";
import { useFlow } from "student-front-commons/src/hooks";
import { CHECK_ITEM_ANSWER_FLOW } from "../../consts";
import { getItemExecutionPropById } from "student-front-commons/src/selectors/itemExecution";
import {
  isInstructionEnableForStep,
  isInstructionHighlighted,
} from "student-front-commons/src/selectors/itemInstruction";
import { addItemExecutionAnswer } from "student-front-commons/src/actions/itemExecution";

function SingleChoiceItemRender(props) {
  const dispatch = useDispatch();
  const unitTypeThemeContext = useContext(UnitTypeThemeContext);

  const [, startCheckFlow] = useFlow(CHECK_ITEM_ANSWER_FLOW);

  const itemLinkedAnswers = useSelector(getItemExecutionPropById(props.itemId, "item.linkedAnswers"));
  const isDisabled = useSelector(getItemExecutionPropById(props.itemId, "isDisabled"));
  const playingId = useSelector(getItemExecutionPropById(props.itemId, "playingId"));
  const showCorrectOption = useSelector(getItemExecutionPropById(props.itemId, "showCorrectOption"));

  const isInstructionEnabled = useSelector(isInstructionEnableForStep("user-away"));
  const shouldHighlighted = useSelector(isInstructionHighlighted);

  const handleChange = useCallback(
    (answer) => {
      dispatch(addItemExecutionAnswer(props.itemId, { answer }));
      startCheckFlow();
    },
    [props.itemId, startCheckFlow, dispatch]
  );

  return (
    <Col alignItems="center">
      <Card
        alignItems="center"
        padding={20}
        showRopes={true}
        ropesStyle={{
          backgroundColor: unitTypeThemeContext.secondary,
        }}
        betweenRopesStyle={{
          width: "70%",
        }}
      >
        <Image
          src={
            getImage(`items/${props.itemId}/images/lg.png`) ||
            `${process.env.REACT_APP_FILES_URL}/items/${props.itemId}/images/lg.png`
          }
          width="100%"
          height="auto"
          borderRadius={10}
        />
      </Card>
      <Separator size="xxl" />
      <MediaQuery orientation="landscape">
        {(matches) => (
          <Flex flexDirection={matches ? "row" : "column"} flexWrap="wrap" justifyContent="center">
            <InBetweenSpacing size="xxl">
              {itemLinkedAnswers.map((answer) => (
                <ItemButton
                  key={`answer-${answer.id}`}
                  isDisabled={isDisabled}
                  status={(showCorrectOption && answer.correct) || playingId === answer.id ? "outline" : "normal"}
                  onClick={() => {
                    handleChange(answer);
                  }}
                  height="auto"
                  borderRadius={25}
                  marginBottom={matches ? 15 : 0}
                  padding="0px 20px"
                  transition="all .3s"
                  hoverTransform={isDisabled ? "none" : "scale(1.2)"}
                  className={isInstructionEnabled && shouldHighlighted ? "shake-vertical" : ""}
                >
                  {({ status }) => (
                    <Text
                      boldType="light-bold"
                      size={36}
                      color={
                        {
                          active: YELLOW_4,
                          outline: unitTypeThemeContext.primary,
                          normal: unitTypeThemeContext.primary,
                        }[status]
                      }
                      textAlign="center"
                    >
                      {answer.text}
                    </Text>
                  )}
                </ItemButton>
              ))}
            </InBetweenSpacing>
          </Flex>
        )}
      </MediaQuery>
      <Separator size="xxl" />
    </Col>
  );
}

SingleChoiceItemRender.propTypes = {
  itemId: PropTypes.string.isRequired,
};

function SingleChoiceItem() {
  const items = useSelector((state) => state.itemExecutions.allIds);

  return items.map((item) => <SingleChoiceItemRender key={`item-${item}`} itemId={item} />);
}

export default SingleChoiceItem;
