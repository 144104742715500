import React from "react";
import PropTypes from "prop-types";
import Row from "core/Row";
import Text from "core/Text";
import Separator from "core/Separator";
import { BLACK_1, GREY_4 } from "consts/colors";
import TranslatedText from "core/TranslatedText";

const GradesHeaderItem = ({ label, value }) => (
  <Row alignItems="center" justifyContent="baseline">
    <TranslatedText translateKey={label} size={14} color={GREY_4} boldType="light-bold" />
    <Separator size="xxs" />
    <Text size={16} color={BLACK_1} boldType="bold">
      {value}
    </Text>
  </Row>
);

GradesHeaderItem.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

export default GradesHeaderItem;
