import React from "react";
import PropTypes from "prop-types";
import ModuleListItem from "screens/module-list/ModuleListItem";
import Grid from "core/Grid";
import { SM_SIZE } from "utils";

const ModuleList = ({ modules }) => (
  <Grid
    mediaQueries={{
      sm: `(max-width: ${SM_SIZE}px)`,
      md: `(min-width: ${SM_SIZE}px)`,
    }}
    smGridTemplateColumns={`repeat(auto-fill, minmax(${210}px, 1fr));`}
    mdGridTemplateColumns={`repeat(auto-fill, minmax(${280}px, 1fr));`}
    columnWidth={280}
    rowGap={0}
  >
    {modules.map((module) => (
      <ModuleListItem key={module} module={module} />
    ))}
  </Grid>
);

ModuleList.propTypes = {
  modules: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default ModuleList;
