import { call, put, select, takeLatest } from "redux-saga/effects";
import { endFlow } from "student-front-commons/src/actions/flow";
import { mergeEntities } from "student-front-commons/src/actions/entity";
import { getFlow, getFlowStart } from "student-front-commons/src/selectors/flow";
import { getEntityById } from "student-front-commons/src/selectors/entity";
import { endMasteryTestExecution } from "student-front-commons/src/services/masteryTestExecutionService";
import { getModulesByCourse } from "student-front-commons/src/services/moduleService";
import { getProfile } from "student-front-commons/src/services/profileService";
import { getCourses } from "student-front-commons/src/services/courseService";
import { END_MASTERY_TEST_EXECUTION_FLOW } from "consts";
import { logError } from "utils";
import { endExecution } from "student-front-commons/src/actions/execution";
import { MODULE_SCHEMA } from "student-front-commons/src/schemas";

export default function* () {
  yield takeLatest(getFlowStart(END_MASTERY_TEST_EXECUTION_FLOW), function* () {
    const flow = yield select(getFlow(END_MASTERY_TEST_EXECUTION_FLOW));
    try {
      const id = sessionStorage.getItem("id");

      const result = yield call(endMasteryTestExecution, {
        module: flow.params.module,
        masteryTest: flow.params.masteryTest,
        masteryTestExecution: flow.params.masteryTestExecution,
      });
      yield put(endExecution(result));

      const module = yield select(getEntityById(MODULE_SCHEMA, flow.params.module));
      const courseDetails = yield call(getModulesByCourse, {
        id,
        course: module.course,
      });

      yield put(mergeEntities(courseDetails.entities));

      // in case of the student ended de course we need to load the courses again the get the new course
      if (result.courseStatus === "COURSE_ENDED") {
        const profile = yield call(getProfile, { id });
        yield put(mergeEntities(profile.entities));

        const result = yield call(getCourses, { id });
        yield put(mergeEntities(result.entities));

        const courseDetails = yield call(getModulesByCourse, {
          id,
          course: sessionStorage.getItem("course"),
        });
        yield put(mergeEntities(courseDetails.entities));
      }
    } catch (error) {
      logError({ error, flow: END_MASTERY_TEST_EXECUTION_FLOW });
    } finally {
      yield put(endFlow(END_MASTERY_TEST_EXECUTION_FLOW));
    }
  });
}
