import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import Text from "core/Text";
import Col from "core/Col";
import { BLACK_1, GREY_4 } from "consts/colors";

const GradesListPeriodColumn = ({ name, start, end }) => (
  <Col flexGrow={1}>
    <Text size={16} color={BLACK_1} boldType="bold">
      {name}
    </Text>
    <Text size={12} color={GREY_4} boldType="light-bold">
      {moment(start).format("DD/MM/YYYY")} - {moment(end).format("DD/MM/YYYY")}
    </Text>
  </Col>
);

GradesListPeriodColumn.propTypes = {
  name: PropTypes.string.isRequired,
  start: PropTypes.string.isRequired,
  end: PropTypes.string.isRequired,
};

export default GradesListPeriodColumn;
