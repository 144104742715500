import React from "react";
import PropTypes from "prop-types";
import Spinner from "core/Spinner";
import Text from "core/Text";
import Col from "core/Col";
import Clickable from "core/Clickable";
import StudyQualityDetailModal from "core/StudyQualityDetailModal";
import { WHITE } from "consts/colors";
import Row from "core/Row";
import withTranslation from "core/withTranslation";
import { addSentryUserAction } from "../utils";

const sentryUserAction = { mainComponent: "CurrentStudyQuality" };

const CurrentStudyQuality = ({ studyQuality, isLoading, hasGrades, isInEvaluationPeriod, getTranslation }) => (
  <Col flexGrow={1} justifyContent={isLoading ? "center" : "flex-end"}>
    {isLoading && <Spinner size="xs" />}
    {!isLoading && studyQuality === null && (
      <Row
        alignItems="baseline"
        props={{
          "data-tooltip": hasGrades
            ? isInEvaluationPeriod
              ? getTranslation("studyQuality.oneHourRequired")
              : getTranslation("studyQuality.noEvaluationPeriod")
            : getTranslation("studyQuality.oneHourRequired"),
        }}
      >
        <Text color={WHITE} boldType="extra-bold" size={26} lineHeight="50px">
          N/A
        </Text>
      </Row>
    )}
    {!isLoading && studyQuality != null && (
      <StudyQualityDetailModal studyQuality={studyQuality}>
        {({ toggleModal }) => (
          <Clickable
            isDisabled={isLoading}
            onClick={() => {
              toggleModal();
              addSentryUserAction({
                ...sentryUserAction,
                clickedComponent: "Clickable",
                action: `Toggle StudyQualityDetailModal`,
              });
            }}
            hoverTransform="scale(1.2)"
          >
            <Row alignItems="baseline">
              <Text color={WHITE} boldType="extra-bold" size={32} lineHeight="50px">
                {studyQuality.score}
              </Text>
            </Row>
          </Clickable>
        )}
      </StudyQualityDetailModal>
    )}
  </Col>
);

CurrentStudyQuality.propTypes = {
  score: PropTypes.number,
  isLoading: PropTypes.bool.isRequired,
  hasGrades: PropTypes.bool.isRequired,
  isInEvaluationPeriod: PropTypes.bool.isRequired,
};

CurrentStudyQuality.defaultProps = {
  score: null,
};

export default withTranslation(CurrentStudyQuality);
