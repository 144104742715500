import React from "react";
import PropTypes from "prop-types";
import ThemeContext from "core/ThemeContext";
import IconButton from "core/IconButton";
import { RED_1, WHITE, YELLOW_4 } from "consts/colors";

const ControlBarButton = ({ name, onClick, isLoading, isDisabled, isActive, ...rest }) => (
  <ThemeContext.Consumer>
    {({ secondary }) => (
      <IconButton
        name={name}
        className={isActive ? "record-animation" : ""}
        backgroundColor={isActive ? RED_1 : YELLOW_4}
        iconColor={isActive ? WHITE : secondary}
        onClick={onClick}
        isLoading={isLoading}
        isDisabled={isDisabled}
        {...rest}
      />
    )}
  </ThemeContext.Consumer>
);

ControlBarButton.propTypes = {
  name: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isActive: PropTypes.bool,
};

ControlBarButton.defaultProps = {
  isLoading: false,
  isDisabled: false,
  isActive: false,
};

export default ControlBarButton;
