import React from "react";
import PropTypes from "prop-types";
import WhitelabelContext from "student-front-commons/src/components/WhitelabelContext";
import head from "lodash/head";
import Col from "core/Col";
import Divider from "core/Divider";
import Row from "core/Row";
import { GREY_3 } from "consts/colors";
import TranslatedText from "core/TranslatedText";
import { addSentryUserAction } from "utils";

const sentryUserAction = { mainComponent: "Tabs" };

export default class Tabs extends React.Component {
  static propTypes = {
    tabs: PropTypes.arrayOf(PropTypes.string).isRequired,
    children: PropTypes.func.isRequired,
  };

  state = {
    selectedTab: null,
  };

  componentDidMount() {
    this.setState({
      selectedTab: head(this.props.tabs),
    });
  }

  handleChangeTab = (tab) => {
    this.setState({
      selectedTab: tab,
    });
  };

  render() {
    const { tabs, children } = this.props;
    return (
      <WhitelabelContext.Consumer>
        {({ primaryColor }) => (
          <React.Fragment>
            <Col flexShrink={0} padding="0px 20px">
              <Divider />
              <Row padding={0} alignItems="center" justifyContent="center">
                {tabs.map((tab) => (
                  <Col
                    key={tab}
                    component="button"
                    props={{
                      onClick: () => {
                        this.handleChangeTab(tab);
                        addSentryUserAction({
                          ...sentryUserAction,
                          clickedComponent: "Col",
                          action: `Switch tab to ${tab}`,
                        });
                      },
                    }}
                    outline="none"
                    overflow="hidden"
                    backgroundColor="transparent"
                    border="none"
                    padding={20}
                    cursor="pointer"
                  >
                    <TranslatedText
                      translateKey={tab}
                      boldType="bold"
                      size={14}
                      isUpperCase={true}
                      color={tab === this.state.selectedTab ? primaryColor : GREY_3}
                    />
                  </Col>
                ))}
              </Row>
              <Divider />
            </Col>
            {!!this.state.selectedTab && (
              <Col overflowY="auto">{children({ selectedTab: this.state.selectedTab })}</Col>
            )}
          </React.Fragment>
        )}
      </WhitelabelContext.Consumer>
    );
  }
}
