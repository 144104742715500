import React from "react";
import { useParams } from "react-router-dom";
import MasteryTestItem from "screens/module-detail/MasteryTestItem";
import { useSelector } from "react-redux";
import { getMasteryTestIdsByModuleId } from "student-front-commons/src/selectors/entity";
import Row from "../../core/Row";

function MasteryTestList() {
  const params = useParams();
  const masteryTests = useSelector(getMasteryTestIdsByModuleId(params.moduleId));

  return (
    <Row>
      {masteryTests.map((id) => (
        <MasteryTestItem key={id} masteryTestId={id} />
      ))}
    </Row>
  );
}

export default MasteryTestList;
