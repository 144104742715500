import React, { useEffect, useState } from "react";
import useCountDown from "react-countdown-hook";
import moment from "moment";
import "moment-duration-format";
import Text from "core/Text";
import Icon from "core/Icon";
import InBetweenSpacing from "core/InBetweenSpacing";
import Row from "core/Row";
import { BLACK_1 } from "consts/colors";
import { useSelector } from "react-redux";
import { getCurrentItemExecutionProp, getSelectedId } from "student-front-commons/src/selectors/itemExecution";
import { useFlow } from "student-front-commons/src/hooks";
import { CHECK_ITEM_ANSWER_FLOW } from "../consts";

function ItemTimer() {
  const [isPaused, setIsPaused] = useState(true);
  const [isRunning, setIsRunning] = useState(false);

  const [timeLeft, { start, pause, resume }] = useCountDown(60000);

  const [isSubmitting, startCheck] = useFlow(CHECK_ITEM_ANSWER_FLOW);

  const itemId = useSelector(getSelectedId);
  const itemTime = useSelector(getCurrentItemExecutionProp("item.type.defaultMasteryTestTime"));
  const isRecording = useSelector(getCurrentItemExecutionProp("isRecording"));
  const isDisabled = useSelector(getCurrentItemExecutionProp("isDisabled"));
  const isSubmittingRecord = useSelector(getCurrentItemExecutionProp("isSubmittingRecord"));

  useEffect(() => {
    pause();
    setIsPaused(true);
    setIsRunning(false);
  }, [itemId, pause, setIsPaused, setIsRunning]);

  useEffect(() => {
    if (!!itemTime) {
      setTimeout(() => {
        start(itemTime * 1000);
        setIsRunning(true);
      }, 1000);
    }
  }, [itemTime, start]);

  useEffect(() => {
    if (timeLeft <= 0 && isRunning && !isPaused) {
      startCheck();
    }
  }, [timeLeft, isPaused, isRunning, startCheck]);

  useEffect(() => {
    const isPaused = !!isDisabled || !!isRecording || !!isSubmittingRecord || !!isSubmitting;
    setIsPaused(isPaused);
    if (isPaused) {
      pause();
    } else {
      resume();
    }
  }, [isDisabled, isRecording, isSubmittingRecord, isSubmitting, pause, resume]);

  return (
    <Row
      height="100%"
      alignItems="center"
      justifyContent="center"
      backgroundColor="#fff"
      borderRadius={15}
      padding="5px 15px"
    >
      <InBetweenSpacing size="sm">
        <Row className={isRunning && timeLeft <= 5000 ? "shake-vertical" : ""}>
          <Icon size={28} name={isPaused ? "pause" : "clock"} color={isRunning && timeLeft <= 5000 ? "red" : BLACK_1} />
        </Row>
        <Text color={isRunning && timeLeft <= 5000 ? "red" : BLACK_1} size={24} boldType="light-bold">
          {moment.duration(timeLeft, "milliseconds").format("mm:ss", { trim: false })}
        </Text>
      </InBetweenSpacing>
    </Row>
  );
}

export default ItemTimer;
