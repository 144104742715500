import React from "react";
import PropTypes from "prop-types";
import WhitelabelContext from "student-front-commons/src/components/WhitelabelContext";
import numeral from "numeral";
import Row from "core/Row";
import Col from "core/Col";
import Text from "core/Text";
import Separator from "core/Separator";
import Avatar from "core/Avatar";
import { BLACK_1, BRONZE, GOLD, GREY_3, GREY_5, SILVER, WHITE } from "consts/colors";

const isSessionUser = (student) => student.id === sessionStorage.getItem("id");

const RankingListItem = ({ student }) => (
  <WhitelabelContext.Consumer>
    {({ primaryColor, lightColor }) => (
      <Row
        position="relative"
        overflow="hidden"
        boxSizing="borderBox"
        borderRadius={20}
        borderStyle="solid"
        borderColor={isSessionUser(student) ? primaryColor : GREY_5}
        borderWidth={isSessionUser(student) ? 0 : 1}
        justifyContent="space-between"
        alignItems="center"
        padding="5px 15px"
        backgroundColor={isSessionUser(student) ? primaryColor : "transparent"}
        boxShadow={isSessionUser(student) ? `0px 1px 38px 0 ${lightColor}` : "none"}
      >
        <Col
          width={40}
          height="100%"
          position="absolute"
          top={0}
          left={0}
          backgroundColor={
            {
              1: GOLD,
              2: SILVER,
              3: BRONZE,
            }[student.position] || "transparent"
          }
          zIndex={0}
        />
        <Row alignItems="center" overflow="hidden">
          <Col width={40} marginLeft={-10} zIndex={1} justifyContent="center" alignItems="center">
            <Text size={14} color={isSessionUser(student) || student.position <= 3 ? WHITE : BLACK_1} boldType="bold">
              {student.position || "-"}
            </Text>
          </Col>
          <Separator size="sm" />
          <Avatar src={student.profilePicture} />
          <Separator size="sm" />
          <Text
            color={isSessionUser(student) ? WHITE : BLACK_1}
            size={14}
            boldType="light-bold"
            textOverflow="ellipsis"
            overflow="hidden"
            whiteSpace="nowrap"
            maxWidth="60%"
          >
            {student.nickname || student.name}
          </Text>
        </Row>
        <Text color={isSessionUser(student) ? WHITE : GREY_3} size={12} flexShrink={0}>
          {numeral(student.points).format("0,0").replace(",", ".")} POINTS
        </Text>
      </Row>
    )}
  </WhitelabelContext.Consumer>
);

RankingListItem.propTypes = {
  student: PropTypes.shape({
    position: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    points: PropTypes.number.isRequired,
    nickname: PropTypes.string,
    profilePicture: PropTypes.string,
  }).isRequired,
};

export default RankingListItem;
