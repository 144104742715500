import React from "react";
import PropTypes from "prop-types";
import { Block } from "jsxstyle";

class Image extends React.PureComponent {
  static propTypes = {
    src: PropTypes.string.isRequired,
  };

  render() {
    const { src, ...rest } = this.props;
    return (
      <Block
        component="img"
        props={{
          src,
        }}
        width="100%"
        height="100%"
        {...rest}
      />
    );
  }
}

export default Image;
