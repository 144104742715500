import React from "react";
import IconButton from "core/IconButton";
import { PLAY_ITEM_INSTRUCTION_FLOW } from "consts";
import { useSelector } from "react-redux";
import { getCurrentItemExecutionProp } from "student-front-commons/src/selectors/itemExecution";
import { useFlow } from "student-front-commons/src/hooks";

function ExecutionHelpContainer() {
  const [, start] = useFlow(PLAY_ITEM_INSTRUCTION_FLOW);

  const itemType = useSelector(getCurrentItemExecutionProp("item.type"));
  const isDisabled = useSelector(getCurrentItemExecutionProp("isDisabled"));

  if (["VIDEO", "MUSIC"].find((type) => type === itemType)) {
    return null;
  }

  return <IconButton name="help" tooltip="checkIntroduction" isDisabled={isDisabled} onClick={start} />;
}

export default ExecutionHelpContainer;
