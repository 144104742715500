import moment from "moment";
import get from "lodash/get";
import { buffers } from "redux-saga";
import { actionChannel, call, cancelled, put, select, spawn, take, takeLatest } from "redux-saga/effects";
import { endFlow, startFlow } from "student-front-commons/src/actions/flow";
import { getFlowStart, getFlow } from "student-front-commons/src/selectors/flow";
import { getEntityById } from "student-front-commons/src/selectors/entity";
import { getInstructionAudio } from "student-front-commons/src/selectors/configuration";
import browserHistory from "browserHistory";
import {
  CONFIRM_MODAL_FLOW,
  CONFIRM_MODAL_NO_FLOW,
  CONFIRM_MODAL_YES_FLOW,
  CONFIRM_START_UNIT_EXECUTION_FLOW,
} from "consts";
import { logError } from "utils";
import locales from "locales";
import { playAudio, stopAudio } from "stores/audio-store";

export default function* () {
  yield takeLatest(getFlowStart(CONFIRM_START_UNIT_EXECUTION_FLOW), function* () {
    try {
      const flow = yield select(getFlow(CONFIRM_START_UNIT_EXECUTION_FLOW));

      if (flow.params.status === "SIMPLE_REVIEW") {
        const unit = yield select(getEntityById("unit", flow.params.unit));
        let confirmModalKey = "";
        let instructionAudioKey = "";

        if (unit.secondReviewStatus === "COMPLETED") {
          confirmModalKey = "unitExecution.confirmModal.unitCompleted";
          instructionAudioKey = "simpleReview.warning.completed";
        } else if (moment(unit.lastExecutionStartedAt).format("YYYY-MM-DD") === moment().format("YYYY-MM-DD")) {
          confirmModalKey = "unitExecution.confirmModal.executedToday";
          instructionAudioKey = "simpleReview.warning.today";
        } else {
          confirmModalKey = "unitExecution.confirmModal.noPoints";
          instructionAudioKey = "simpleReview.warning.default";
        }

        yield put(
          startFlow(CONFIRM_MODAL_FLOW, {
            message: get(locales, confirmModalKey),
          })
        );

        yield spawn(function* () {
          const instructionAudio = yield select(getInstructionAudio(instructionAudioKey));
          if (instructionAudio) {
            yield call(playAudio, {
              rate: 1,
              isCompleteUrl: false,
              url: instructionAudio,
            });
          }
        });

        const modalAnswerChannel = yield actionChannel(
          (action) => getFlowStart(CONFIRM_MODAL_YES_FLOW)(action) || getFlowStart(CONFIRM_MODAL_NO_FLOW)(action),
          buffers.sliding(1)
        );

        const modalAnswer = yield take(modalAnswerChannel);
        stopAudio();
        yield put(endFlow(CONFIRM_MODAL_FLOW));

        if (modalAnswer.payload.flowId === CONFIRM_MODAL_YES_FLOW) {
          yield call(browserHistory.push, `/modules/${flow.params.module}/units/${flow.params.unit}/execution`);
        }
      } else {
        yield call(browserHistory.push, `/modules/${flow.params.module}/units/${flow.params.unit}/execution`);
      }
    } catch (error) {
      logError({ error, flow: CONFIRM_START_UNIT_EXECUTION_FLOW });
    } finally {
      if (yield cancelled()) {
        stopAudio();
      }
      yield put(endFlow(CONFIRM_START_UNIT_EXECUTION_FLOW));
    }
  });
}
