import React from "react";
import PropTypes from "prop-types";
import PrivateScreen from "core/PrivateScreen";
import Col from "core/Col";
import Spinner from "core/Spinner";
import MasteryTestResultCard from "screens/mastery-test-result/MasteryTestResultCard";
import ThemeContext from "core/ThemeContext";

const MasteryTestResultScreen = ({ isLoading }) => (
  <ThemeContext.Consumer>
    {({ radialGradient }) => (
      <PrivateScreen
        backgroundStyle={{
          background: radialGradient,
        }}
      >
        <Col height="100vh" justifyContent="center" alignItems="center">
          {isLoading ? <Spinner /> : <MasteryTestResultCard />}
        </Col>
      </PrivateScreen>
    )}
  </ThemeContext.Consumer>
);

MasteryTestResultScreen.propTypes = {
  isLoading: PropTypes.bool.isRequired,
};

export default MasteryTestResultScreen;
