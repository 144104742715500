import React from "react";
import PropTypes from "prop-types";
import Col from "core/Col";
import { WHITE } from "consts/colors";

export default class Popover extends React.PureComponent {
  static propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    content: PropTypes.node.isRequired,
    position: PropTypes.oneOf(["top", "bottom", "right"]).isRequired,
  };

  componentDidMount() {
    document.addEventListener("click", this.handleOutsidePopoverClick, false);
  }

  componentWillUnmount() {
    document.removeEventListener("click", this.handleOutsidePopoverClick, false);
  }

  handleOutsidePopoverClick = (e) => {
    if (this.popoverNode && this.popoverNode.contains(e.target)) {
      return false;
    }

    if (this.props.isOpen) {
      this.props.onClose();
    }
  };

  render() {
    const { isOpen, position, content, onClose, children, ...rest } = this.props;
    return (
      <div
        style={{
          position: "relative",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
        ref={(node) => {
          this.popoverNode = node;
        }}
      >
        <Col
          alignItems="center"
          position="absolute"
          maxWidth={window.screen.availWidth * 0.75}
          width="max-content"
          opacity={isOpen ? 1 : 0}
          zIndex={isOpen ? 1 : -1}
          userSelect="none"
          transition="all .5s"
          backgroundColor={WHITE}
          borderRadius={10}
          padding={20}
          boxShadow="0px 10px 20px 0 rgba(0, 0, 0, 0.2);"
          {...{
            ...(position === "bottom" && {
              bottom: this.props.isOpen ? 80 : 0,
            }),
            ...(position === "top" && {
              top: this.props.isOpen ? 80 : 0,
            }),
            ...(position === "right" && {
              right: this.props.isOpen ? 80 : 0,
            }),
          }}
          {...rest}
        >
          <Col
            position="absolute"
            width={0}
            height={0}
            {...{
              ...(position === "bottom" && {
                bottom: -10,
                borderLeft: "10px solid transparent",
                borderRight: "10px solid transparent",
                borderTop: "15px solid #fff",
              }),
              ...(position === "top" && {
                top: -10,
                borderLeft: "10px solid transparent",
                borderRight: "10px solid transparent",
                borderBottom: "15px solid #fff",
              }),
              ...(position === "right" && {
                right: -10,
                borderTop: "10px solid transparent",
                borderBottom: "10px solid transparent",
                borderLeft: "15px solid #fff",
              }),
            }}
            {...rest}
          />
          {isOpen && content}
        </Col>
        {children}
      </div>
    );
  }
}
