import React from "react";
import PropTypes from "prop-types";
import Grid from "core/Grid";
import CourseListItem from "screens/course/CourseListItem";
import { SM_SIZE } from "utils";

const CourseList = ({ courses }) => (
  <Grid
    mediaQueries={{
      sm: `(max-width: ${SM_SIZE}px)`,
      md: `(min-width: ${SM_SIZE}px)`,
    }}
    smGridTemplateColumns={`repeat(auto-fill, minmax(${360}px, 1fr));`}
    mdGridTemplateColumns={`repeat(auto-fill, minmax(${390}px, 1fr));`}
    columnWidth={390}
    rowGap={0}
    width="100%"
    maxWidth={940}
  >
    {courses.map((course) => (
      <CourseListItem key={course.id} course={course} />
    ))}
  </Grid>
);

CourseList.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  courses: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default CourseList;
