import React, { useCallback, useContext } from "react";
import PropTypes from "prop-types";
import Row from "core/Row";
import Text from "core/Text";
import Image from "core/Image";
import InBetweenSpacing from "core/InBetweenSpacing";
import Col from "core/Col";
import Separator from "core/Separator";
import ItemButton from "core/ItemButton";
import UnitTypeThemeContext from "core/ThemeContext";
import Card from "core/Card";
import ThumbsUpIcon from "assets/thumbs-up-icon.png";
import ThumbsDownIcon from "assets/thumbs-down-icon.png";
import { WHITE } from "consts/colors";
import { Flex } from "jsxstyle";
import MediaQuery from "react-responsive";
import { getImage } from "stores/image-store";
import TranslatedText from "core/TranslatedText";
import { useDispatch, useSelector } from "react-redux";
import { useFlow } from "student-front-commons/src/hooks";
import { CHECK_ITEM_ANSWER_FLOW } from "../../consts";
import { getItemExecutionPropById } from "student-front-commons/src/selectors/itemExecution";
import {
  isInstructionEnableForStep,
  isInstructionHighlighted,
} from "student-front-commons/src/selectors/itemInstruction";
import { addItemExecutionAnswer } from "student-front-commons/src/actions/itemExecution";

function TrueFalseItemRender(props) {
  const dispatch = useDispatch();
  const unitTypeThemeContext = useContext(UnitTypeThemeContext);

  const [, startCheckFlow] = useFlow(CHECK_ITEM_ANSWER_FLOW);

  const itemId = useSelector(getItemExecutionPropById(props.itemId, "item.id"));
  const itemText = useSelector(getItemExecutionPropById(props.itemId, "item.text"));
  const itemLinkedAnswers = useSelector(getItemExecutionPropById(props.itemId, "item.linkedAnswers"));
  const isDisabled = useSelector(getItemExecutionPropById(props.itemId, "isDisabled"));
  const showCorrectOption = useSelector(getItemExecutionPropById(props.itemId, "showCorrectOption"));
  const userAnswer = useSelector(getItemExecutionPropById(props.itemId, "answer"));

  const isInstructionEnabled = useSelector(isInstructionEnableForStep("user-away"));
  const shouldHighlighted = useSelector(isInstructionHighlighted);

  const handleChange = useCallback(
    (answer) => {
      dispatch(addItemExecutionAnswer(itemId, { answer }));
      startCheckFlow();
    },
    [itemId, dispatch, startCheckFlow]
  );

  return (
    <React.Fragment>
      <Card
        alignItems="center"
        padding={20}
        showRopes={true}
        ropesStyle={{
          backgroundColor: unitTypeThemeContext.secondary,
        }}
        betweenRopesStyle={{
          width: "70%",
        }}
      >
        <Col
          alignItems="center"
          justifyContent="center"
          backgroundColor={unitTypeThemeContext.secondary}
          borderRadius={25}
          minHeight={50}
          minWidth="65%"
          maxWidth="80%"
          padding="0px 20px"
          boxSizing="border-box"
        >
          <Text boldType="light-bold" size={22} color={WHITE} align="center">
            {itemText}
          </Text>
        </Col>
        <Separator size="lg" />
        <Image
          src={
            getImage(`items/${itemId}/images/lg.png`) ||
            `${process.env.REACT_APP_FILES_URL}/items/${itemId}/images/lg.png`
          }
          width="100%"
          height="auto"
          borderRadius={10}
        />
      </Card>
      <Separator size="xxl" />
      <MediaQuery orientation="landscape">
        {(matches) => (
          <Flex flexDirection={matches ? "row" : "column"}>
            <InBetweenSpacing size={matches ? "xxxl" : "xl"}>
              {itemLinkedAnswers.map((answer) => (
                <ItemButton
                  key={`answer-${answer.id}`}
                  isDisabled={isDisabled}
                  onClick={() => {
                    handleChange(answer);
                  }}
                  height={60}
                  borderRadius={30}
                  padding="0px 20px"
                  status={
                    userAnswer && userAnswer.id === answer.id
                      ? "active"
                      : showCorrectOption && answer.correct
                      ? "outline"
                      : "normal"
                  }
                  transition="all .3s"
                  hoverTransform={isDisabled ? "none" : "scale(1.2)"}
                  className={isInstructionEnabled && shouldHighlighted ? "shake-vertical" : ""}
                >
                  {({ status }) => (
                    <Row alignItems="center">
                      <Image src={answer.text === "True" ? ThumbsUpIcon : ThumbsDownIcon} width={32} height="auto" />
                      <Separator size="lg" />
                      <TranslatedText
                        translateKey={
                          answer.text === "True"
                            ? "unitExecution.itemType.text.true"
                            : "unitExecution.itemType.text.false"
                        }
                        color={
                          {
                            active: WHITE,
                            outline: unitTypeThemeContext.primary,
                            normal: unitTypeThemeContext.primary,
                          }[status]
                        }
                        boldType="light-bold"
                        size={18}
                      />
                    </Row>
                  )}
                </ItemButton>
              ))}
            </InBetweenSpacing>
          </Flex>
        )}
      </MediaQuery>
      <Separator size="xxl" />
    </React.Fragment>
  );
}

TrueFalseItem.propTypes = {
  itemId: PropTypes.string.isRequired,
};

function TrueFalseItem() {
  const items = useSelector((state) => state.itemExecutions.allIds);

  return (
    <Col alignItems="center">
      {items.map((item) => (
        <TrueFalseItemRender key={`item-${item}`} itemId={item} />
      ))}
    </Col>
  );
}

export default TrueFalseItem;
