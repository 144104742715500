import React, { useCallback, useEffect, useState } from "react";
import RecordButton from "core/RecordButton";
import Popover from "core/Popover";
import SpeechRecognitionResultContainer from "core/SpeechRecognitionResultContainer";
import { useFlow } from "student-front-commons/src/hooks";
import { useSelector } from "react-redux";
import { getCurrentItemExecutionProp, getSelectedId } from "student-front-commons/src/selectors/itemExecution";
import { END_RECORD_FLOW, START_RECORD_FLOW } from "consts";
import {
  isInstructionEnableForStep,
  isInstructionHighlighted,
} from "student-front-commons/src/selectors/itemInstruction";

function RecordButtonContainer() {
  const [resultAlreadyShown, setResultAlreadyShown] = useState(false);
  const [showResult, setShowResult] = useState(false);

  const [isEnding, handleEndRecord] = useFlow(END_RECORD_FLOW);
  const [isStarting, handleStartRecord] = useFlow(START_RECORD_FLOW);

  const selectedId = useSelector(getSelectedId);
  const itemType = useSelector(getCurrentItemExecutionProp("item.type.key"));
  const isRecording = useSelector(getCurrentItemExecutionProp("isRecording"));
  const isDisabled = useSelector(getCurrentItemExecutionProp("isDisabled"));
  const answer = useSelector(getCurrentItemExecutionProp("answer"));
  const isSubmittingRecord = useSelector(getCurrentItemExecutionProp("isSubmittingRecord"));
  const qualityScore = useSelector(getCurrentItemExecutionProp("speechRecognitionResult.qualityScore"));
  const isSpeechRecognitionRequired = useSelector(getCurrentItemExecutionProp("extraData.isSpeechRecognitionRequired"));

  const isInstructionEnabled = useSelector(isInstructionEnableForStep("user-away"));
  const shouldHighlighted = useSelector(isInstructionHighlighted);

  useEffect(() => {
    if (isSubmittingRecord) {
      setResultAlreadyShown(false);
    }
  }, [isSubmittingRecord]);

  useEffect(() => {
    if (!isRecording && !isSubmittingRecord && !isDisabled && qualityScore) {
      setShowResult(true);
    } else {
      setShowResult(false);
    }
  }, [isRecording, isSubmittingRecord, isDisabled, qualityScore, setShowResult]);

  const isRecordDisabled = () => {
    if (["SINGLE_CHOICE_GAME", "VIDEO_SHORT", "PHONEME", "VOCABULARY"].find((type) => type === itemType)) {
      return isDisabled || isSubmittingRecord || !selectedId;
    }
    if (["CONNECTING_DOTS"].find((type) => type === itemType)) {
      return isDisabled || isSubmittingRecord || !selectedId || !isSpeechRecognitionRequired;
    }
    if (["GAP_FILL_IMAGE"].find((type) => type === itemType)) {
      return isDisabled || isSubmittingRecord || !selectedId || (!isSpeechRecognitionRequired && !answer);
    }
    return true;
  };

  const handleCloseSpeechRecognitionResult = useCallback(() => {
    setShowResult(false);
    setResultAlreadyShown(true);
  }, []);

  return (
    <Popover
      isOpen={showResult && !resultAlreadyShown}
      onClose={handleCloseSpeechRecognitionResult}
      content={<SpeechRecognitionResultContainer score={qualityScore} />}
      position="bottom"
    >
      <RecordButton
        onClick={isRecording ? handleEndRecord : handleStartRecord}
        isLoading={isEnding || isStarting}
        isRecording={!!isRecording}
        isDisabled={isStarting || isRecordDisabled()}
        shouldShake={!isRecordDisabled() && isInstructionEnabled && shouldHighlighted}
      />
    </Popover>
  );
}

export default RecordButtonContainer;
