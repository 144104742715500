import React from "react";

export default ({ color }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24">
    <path
      fill={color}
      d="M20.936 13.17c.044-.375.073-.765.073-1.17a7.4 7.4 0 0 0-.089-1.17l2.499-1.98a.626.626 0 0 0 .148-.765L21.202 3.93a.598.598 0 0 0-.725-.27l-2.942 1.2a8.622 8.622 0 0 0-1.996-1.17L15.096.51a.593.593 0 0 0-.591-.51H9.774a.579.579 0 0 0-.577.51l-.444 3.18c-.724.3-1.389.705-1.995 1.17l-2.942-1.2a.582.582 0 0 0-.725.27L.726 8.085a.586.586 0 0 0 .148.765l2.498 1.98c-.059.375-.103.78-.103 1.17 0 .39.029.795.088 1.17L.859 15.15a.626.626 0 0 0-.148.765l2.366 4.155c.148.27.458.36.724.27l2.942-1.2c.621.48 1.272.87 1.996 1.17l.444 3.18c.059.3.295.51.591.51h4.731c.295 0 .547-.21.576-.51l.444-3.18a8.863 8.863 0 0 0 1.995-1.17l2.943 1.2a.58.58 0 0 0 .724-.27l2.365-4.155a.587.587 0 0 0-.147-.765l-2.469-1.98zm-8.797 3.33c-2.439 0-4.435-2.025-4.435-4.5S9.7 7.5 12.139 7.5c2.44 0 4.436 2.025 4.436 4.5s-1.996 4.5-4.436 4.5z"
    />
  </svg>
);
