import { all, call, cancelled, delay, put, race, select, spawn, take, takeLatest } from "redux-saga/effects";
import { endFlow, startFlow } from "student-front-commons/src/actions/flow";
import { getFlow, getFlowStart } from "student-front-commons/src/selectors/flow";
import browserHistory from "browserHistory";
import {
  CHECK_ITEM_ANSWER_FLOW,
  CLOSE_UNIT_EXECUTION_FLOW,
  CONFIRM_MODAL_FLOW,
  CONFIRM_MODAL_YES_FLOW,
  END_UNIT_EXECUTION_FLOW,
  GET_NEXT_ITEM_EXECUTION_FLOW,
  PLAY_ITEM_AUDIO_FLOW,
  PLAY_ITEM_VIDEO_FLOW,
  PLAY_RECORD_AUDIO_FLOW,
  SELECT_ITEM_FLOW,
  USER_AWAY_FLOW,
  USER_AWAY_TIMEOUT_FLOW,
} from "consts";
import { playAudio, stopAudio } from "stores/audio-store";
import { logError } from "utils";
import get from "lodash/get";
import locales from "locales";
import { getInstructionAudio } from "student-front-commons/src/selectors/configuration";
import {
  highlightItemInstruction,
  startItemInstruction,
  unhighlightItemInstruction,
} from "student-front-commons/src/actions/itemInstruction";
import {
  ADD_ITEM_EXECUTION_ANSWER,
  ADD_ITEM_EXECUTION_ATTEMPT,
  incrementItemExecutionUserAway,
  START_RECORD_ITEM,
} from "student-front-commons/src/actions/itemExecution";
import { getItemExecutions } from "student-front-commons/src/selectors/itemExecution";
import head from "lodash/head";

export default function* () {
  yield takeLatest(getFlowStart(USER_AWAY_FLOW), function* () {
    const { userDidSomething } = yield race({
      cancel: take(getFlowStart(CLOSE_UNIT_EXECUTION_FLOW)),
      endUnitExecution: take(getFlowStart(END_UNIT_EXECUTION_FLOW)),
      userDidSomething: take([
        getFlowStart(GET_NEXT_ITEM_EXECUTION_FLOW),
        getFlowStart(SELECT_ITEM_FLOW),
        getFlowStart(PLAY_ITEM_AUDIO_FLOW),
        getFlowStart(PLAY_RECORD_AUDIO_FLOW),
        getFlowStart(PLAY_ITEM_VIDEO_FLOW),
        getFlowStart(CHECK_ITEM_ANSWER_FLOW),
        (action) => action.type === ADD_ITEM_EXECUTION_ANSWER,
        (action) => action.type === ADD_ITEM_EXECUTION_ATTEMPT,
        (action) => action.type === START_RECORD_ITEM,
      ]),
      call: call(function* () {
        try {
          const flow = yield select(getFlow(USER_AWAY_FLOW));

          const pendingActionCount = get(flow.params, "pendingActionCount", 0);

          if (pendingActionCount === 1) {
            const itemExecutions = yield select(getItemExecutions);
            const itemType = head(itemExecutions).item.type;
            if (itemType.initialInstructionSound) {
              yield call(playAudio, {
                url: itemType.initialInstructionSound,
                isCompleteUrl: false,
                rate: 1,
              });
            }

            yield put(startFlow(USER_AWAY_TIMEOUT_FLOW, { pendingActionCount }));
          }
          if (pendingActionCount === 2) {
            yield put(startItemInstruction("user-away"));
            yield put(highlightItemInstruction());

            const itemExecutions = yield select(getItemExecutions);
            const itemType = head(itemExecutions).item.type;

            if (itemType.initialInstructionSound) {
              yield call(playAudio, {
                url: itemType.initialInstructionSound,
                rate: 1,
              });
            }

            yield put(unhighlightItemInstruction());
            yield put(startFlow(USER_AWAY_TIMEOUT_FLOW, { pendingActionCount }));
          }
          if (pendingActionCount === 3) {
            const itemExecutions = yield select(getItemExecutions);
            yield all(
              itemExecutions.map(function* (itemExecution) {
                yield put(incrementItemExecutionUserAway(itemExecution.item.id));
              })
            );
            yield put(
              startFlow(CONFIRM_MODAL_FLOW, {
                type: "alert",
                message: get(locales, "userAway.message"),
              })
            );

            yield spawn(function* () {
              const userAwayAudio = yield select(getInstructionAudio("userAway"));
              if (userAwayAudio) {
                yield call(playAudio, {
                  url: userAwayAudio,
                  rate: 1,
                });
              }
            });

            const { timeout } = yield race({
              timeout: delay(30000),
              response: take(getFlowStart(CONFIRM_MODAL_YES_FLOW)),
            });

            yield put(endFlow(CONFIRM_MODAL_FLOW));
            if (timeout) {
              const execution = yield select((state) => state.executions);
              browserHistory.replace({
                pathname: `/modules/${execution.module}/detail`,
                state: { systemNavigation: true },
              });

              yield put(startFlow(CLOSE_UNIT_EXECUTION_FLOW));
            } else {
              stopAudio();
              yield put(startFlow(USER_AWAY_TIMEOUT_FLOW, { pendingActionCount: 0 }));
            }
          }
        } catch (error) {
          if (error !== "required-user-click") {
            logError({ error, flow: USER_AWAY_FLOW });
          }
        } finally {
          if (yield cancelled()) {
            stopAudio();
          }
          yield put(endFlow(USER_AWAY_FLOW));
        }
      }),
    });

    if (userDidSomething) {
      yield put(startFlow(USER_AWAY_TIMEOUT_FLOW, { pendingActionCount: 0 }));
    }
  });
}
