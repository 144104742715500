import React, { useContext } from "react";
import AnimatedCard from "core/AnimatedCard";
import { GREEN_1, RED_1 } from "consts/colors";
import { getErrorMessage } from "student-front-commons/src/selectors/systemMessage";
import { useSelector } from "react-redux";
import TranslatedText from "core/TranslatedText";
import WhitelabelContext from "student-front-commons/src/components/WhitelabelContext";

function FormMessageHandler() {
  const whitelabelContext = useContext(WhitelabelContext);
  const errorMessage = useSelector(getErrorMessage);

  if (!errorMessage || !errorMessage.message) {
    return null;
  }

  return (
    <AnimatedCard
      showRopes={true}
      padding="40px 80px 20px"
      width="100%"
      maxWidth={520}
      flexShrink={0}
      alignItems="center"
      opacity={0}
      backgroundColor={
        {
          success: GREEN_1,
          error: RED_1,
        }[errorMessage.type]
      }
    >
      <TranslatedText
        translateKey={errorMessage.message}
        values={{ name: whitelabelContext.title, url: `https://student.${whitelabelContext.domain}` }}
        boldType="light-bold"
        size={24}
        align="center"
      />
    </AnimatedCard>
  );
}

export default FormMessageHandler;
