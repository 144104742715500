import React from "react";
import PropTypes from "prop-types";
import PrivateScreen from "core/PrivateScreen";
import Col from "core/Col";
import Spinner from "core/Spinner";
import Separator from "core/Separator";
import UnitTypeThemeContext from "core/ThemeContext";
import UnitResultCard from "screens/unit-result/UnitResultCard";
import { MD_SIZE } from "utils";

const TastingResultScreen = ({ isLoading }) => (
  <UnitTypeThemeContext.Consumer>
    {({ radialGradient }) => (
      <PrivateScreen
        backgroundStyle={{
          background: radialGradient,
        }}
      >
        {isLoading ? (
          <Col height="100vh" justifyContent="center" alignItems="center">
            <Spinner />
          </Col>
        ) : (
          <Col
            mediaQueries={{
              sm: `(max-width: ${MD_SIZE}px)`,
              md: `(min-width: ${MD_SIZE}px)`,
            }}
            smWidth="100%"
            mdWidth="100%"
          >
            <Separator size="xl" />
            <Separator size="xl" />
            <UnitResultCard />
          </Col>
        )}
      </PrivateScreen>
    )}
  </UnitTypeThemeContext.Consumer>
);

TastingResultScreen.propTypes = {
  isLoading: PropTypes.bool.isRequired,
};

export default TastingResultScreen;
