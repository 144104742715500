import React from "react";
import PropTypes from "prop-types";
import Image from "core/Image";

export default class AnimatedImage extends React.Component {
  static propTypes = {
    src: PropTypes.string.isRequired,
  };

  state = {
    className: "slide-in-top",
  };

  componentDidMount() {
    this.setState({
      className: "slide-in-top",
    });
  }

  componentWillUnmount() {
    this.setState({
      className: "slide-out-bottom",
    });
  }

  render() {
    return <Image className={this.state.className} {...this.props} />;
  }
}
