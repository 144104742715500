import React from "react";
import PropTypes from "prop-types";
import Text from "core/Text";
import Col from "core/Col";
import { BLACK_1, GREY_3, GREY_4 } from "consts/colors";
import TranslatedText from "core/TranslatedText";

const GradesListStudyQualityColumn = ({ grade, averageScore, isPreview }) => (
  <Col width="22%" justifyContent="center" alignItems="center">
    <Text size={32} color={isPreview ? GREY_3 : BLACK_1} boldType="bold">
      {grade || "N/A"}
    </Text>
    <TranslatedText
      translateKey="grades.averageSq"
      values={{
        score: averageScore || "N/A",
      }}
      size={13}
      color={isPreview ? GREY_3 : GREY_4}
      boldType="light-bold"
    />
  </Col>
);

GradesListStudyQualityColumn.propTypes = {
  grade: PropTypes.number,
  averageScore: PropTypes.number,
  isPreview: PropTypes.bool.isRequired,
};

export default GradesListStudyQualityColumn;
