import React from "react";
import ListenButton from "core/ListenButton";
import { PLAY_RECORD_AUDIO_FLOW } from "consts";
import { useFlow } from "student-front-commons/src/hooks";
import { useSelector } from "react-redux";
import { getCurrentItemExecutionProp, getSelectedId } from "student-front-commons/src/selectors/itemExecution";

function ListenButtonContainer() {
  const [isPending, handleStart] = useFlow(PLAY_RECORD_AUDIO_FLOW);

  const selectedId = useSelector(getSelectedId);
  const itemType = useSelector(getCurrentItemExecutionProp("item.type.key"));
  const isRecording = useSelector(getCurrentItemExecutionProp("isRecording"));
  const isSubmittingRecord = useSelector(getCurrentItemExecutionProp("isSubmittingRecord"));
  const isDisabled = useSelector(getCurrentItemExecutionProp("isDisabled"));
  const recordCount = useSelector(getCurrentItemExecutionProp("recordCount"));

  const isListenDisabled = () => {
    if (["VIDEO_SHORT", "PHONEME", "VOCABULARY", "GAP_FILL_IMAGE"].find((type) => type === itemType)) {
      return isDisabled || isRecording || isSubmittingRecord || !selectedId || !recordCount;
    }
    return true;
  };

  return <ListenButton onClick={handleStart} isDisabled={isPending || isListenDisabled()} />;
}

export default ListenButtonContainer;
