import React, { useCallback, useContext } from "react";
import PropTypes from "prop-types";
import { Flex, InlineBlock } from "jsxstyle";
import MediaQuery from "react-responsive";
import Col from "core/Col";
import Card from "core/Card";
import Text from "core/Text";
import Image from "core/Image";
import Separator from "core/Separator";
import ItemButton from "core/ItemButton";
import InBetweenSpacing from "core/InBetweenSpacing";
import { WHITE, YELLOW_4 } from "consts/colors";
import { getImage } from "stores/image-store";
import { useDispatch, useSelector } from "react-redux";
import UnitTypeThemeContext from "../ThemeContext";
import { useFlow } from "student-front-commons/src/hooks";
import { CHECK_ITEM_ANSWER_FLOW } from "../../consts";
import { getItemExecutionPropById } from "student-front-commons/src/selectors/itemExecution";
import {
  isInstructionEnableForStep,
  isInstructionHighlighted,
} from "student-front-commons/src/selectors/itemInstruction";
import { addItemExecutionAnswer } from "student-front-commons/src/actions/itemExecution";

function GapFillImageItemRender(props) {
  const dispatch = useDispatch();
  const unitTypeThemeContext = useContext(UnitTypeThemeContext);

  const [, startCheckFlow] = useFlow(CHECK_ITEM_ANSWER_FLOW);

  const itemText = useSelector(getItemExecutionPropById(props.itemId, "item.text"));
  const itemLinkedAnswers = useSelector(getItemExecutionPropById(props.itemId, "item.linkedAnswers"));
  const isDisabled = useSelector(getItemExecutionPropById(props.itemId, "isDisabled"));
  const isFinished = useSelector(getItemExecutionPropById(props.itemId, "isFinished"));
  const showCorrectOption = useSelector(getItemExecutionPropById(props.itemId, "showCorrectOption"));
  const userAnswer = useSelector(getItemExecutionPropById(props.itemId, "answer"));

  const isInstructionEnabled = useSelector(isInstructionEnableForStep("user-away"));
  const shouldHighlighted = useSelector(isInstructionHighlighted);

  const handleChange = useCallback(
    (answer) => {
      dispatch(addItemExecutionAnswer(props.itemId, { answer }));
      startCheckFlow();
    },
    [props.itemId, startCheckFlow, dispatch]
  );

  return (
    <Col alignItems="center">
      <Card
        alignItems="center"
        padding={20}
        showRopes={true}
        ropesStyle={{
          backgroundColor: unitTypeThemeContext.secondary,
        }}
        betweenRopesStyle={{
          width: "70%",
        }}
      >
        <Image
          src={
            getImage(`items/${props.itemId}/images/lg.png`) ||
            `${process.env.REACT_APP_FILES_URL}/items/${props.itemId}/images/lg.png`
          }
          width="100%"
          height="auto"
          borderRadius={10}
        />
        <Separator size="md" />
      </Card>
      <Col
        zIndex={1}
        marginTop={-25}
        alignItems="center"
        justifyContent="center"
        backgroundColor={unitTypeThemeContext.secondary}
        borderRadius={25}
        minHeight={50}
        minWidth="65%"
        maxWidth="80%"
        padding="0px 20px"
        boxSizing="border-box"
      >
        <Text boldType="light-bold" size={36} color={WHITE} align="center">
          {itemText.split(" ").map((slice, sliceIndex) => {
            if (itemLinkedAnswers.find((answer) => answer.index === sliceIndex)) {
              if (userAnswer) {
                return (
                  <InlineBlock key={slice} marginRight={10}>
                    <Text boldType="light-bold" size={36} color={YELLOW_4}>
                      {userAnswer}
                    </Text>
                  </InlineBlock>
                );
              }
              return (
                <React.Fragment key={slice}>
                  <InlineBlock borderBottomWidth={3} borderBottomColor={WHITE} borderBottomStyle="solid" width={60} />
                  {/[!.?,]$/.test(slice) ? slice.substring(slice.length - 1) : ""}{" "}
                </React.Fragment>
              );
            }
            return `${slice} `;
          }, "")}
        </Text>
      </Col>
      <Separator size="xxl" />
      <MediaQuery orientation="landscape">
        {(matches) => (
          <Flex flexDirection={matches ? "row" : "column"}>
            <InBetweenSpacing size="xxl">
              {itemLinkedAnswers.map((availableAnswer) => (
                <ItemButton
                  key={`answer-${availableAnswer.id}`}
                  isDisabled={isDisabled || isFinished}
                  onClick={() => {
                    handleChange(availableAnswer);
                  }}
                  status={
                    showCorrectOption && availableAnswer.correct
                      ? "outline"
                      : userAnswer && userAnswer.id === availableAnswer.id
                      ? "active"
                      : "normal"
                  }
                  height={55}
                  borderRadius={25}
                  padding="0px 20px"
                  transition="all .3s"
                  hoverTransform={isDisabled ? "none" : "scale(1.2)"}
                  className={isInstructionEnabled && shouldHighlighted ? "shake-vertical" : ""}
                >
                  {({ status }) => (
                    <Text
                      boldType="light-bold"
                      size={36}
                      color={
                        {
                          active: YELLOW_4,
                          outline: unitTypeThemeContext.primary,
                          normal: unitTypeThemeContext.primary,
                        }[status]
                      }
                    >
                      {availableAnswer.text.replace(/[!.?,]$/, "")}
                    </Text>
                  )}
                </ItemButton>
              ))}
            </InBetweenSpacing>
          </Flex>
        )}
      </MediaQuery>
      <Separator size="xxl" />
    </Col>
  );
}

GapFillImageItemRender.propTypes = {
  itemId: PropTypes.string,
};

function GapFillImageItem() {
  const items = useSelector((state) => state.itemExecutions.allIds);

  return items.map((item) => <GapFillImageItemRender key={`item-${item}`} itemId={item} />);
}

export default GapFillImageItem;
