import React from "react";
import { withRouter } from "react-router-dom";
import Entity from "student-front-commons/src/components/Entity";
import Row from "core/Row";
import IconButton from "core/IconButton";
import Text from "core/Text";
import ModuleHeaderItem from "screens/module-detail/ModuleHeaderItem";
import CurrentStudyQualityContainer from "core/CurrentStudyQualityContainer";
import { WHITE } from "consts/colors";
import { SM_SIZE } from "utils";
import { addSentryUserAction } from "../../utils";

const sentryUserAction = { mainComponent: "ModuleHeader" };

const ModuleHeader = ({ history, match }) => (
  <Row justifyContent="space-between" alignItems="center" flexShrink={0}>
    <Row>
      <IconButton
        name="back"
        onClick={() => {
          history.push("/");
          addSentryUserAction({
            ...sentryUserAction,
            clickedComponent: "IconButton",
            action: `Navigate to /`,
          });
        }}
      />
      <Row
        mediaQueries={{
          sm: `(max-width: ${SM_SIZE}px)`,
          md: `(min-width: ${SM_SIZE}px)`,
        }}
        smWidth={15}
        mdWidth={60}
      />
      <ModuleHeaderItem title="module">
        <Entity name="module">
          {({ getById }) => (
            <Text color={WHITE} boldType="extra-bold" size={26}>
              {getById(match.params.moduleId).name}
            </Text>
          )}
        </Entity>
      </ModuleHeaderItem>
    </Row>
    <ModuleHeaderItem title="studyQuality.title">
      <CurrentStudyQualityContainer />
    </ModuleHeaderItem>
  </Row>
);

export default withRouter(ModuleHeader);
