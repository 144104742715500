import { Howl } from "howler";
import platform from "platform";
import { delay, call, cancelled, put, race, select, spawn, take, takeLatest } from "redux-saga/effects";
import get from "lodash/get";
import sample from "lodash/sample";
import { endFlow, startFlow } from "student-front-commons/src/actions/flow";
import { getFlowStart } from "student-front-commons/src/selectors/flow";
import { getEntityById } from "student-front-commons/src/selectors/entity";
import { getOption, getScore } from "student-front-commons/src/services/speechRecognitionService";
import { checkAnswer } from "student-front-commons/src/services/itemService";
import SrSuccessAudioOne from "assets/audio/sr-success-1.wav";
import SrSuccessAudioTwo from "assets/audio/sr-success-2.wav";
import {
  CHECK_ITEM_ANSWER_FLOW,
  CLOSE_MASTERY_TEST_EXECUTION_FLOW,
  CLOSE_UNIT_EXECUTION_FLOW,
  CONFIRM_MODAL_FLOW,
  CONFIRM_MODAL_YES_FLOW,
  END_RECORD_BY_TIMEOUT_FLOW,
  END_RECORD_FLOW,
  MICROPHONE_ERROR_MODAL_FLOW,
  START_RECORD_FLOW,
  USER_AWAY_TIMEOUT_FLOW,
} from "consts";
import { insertSpeechRecognitionAudio, playAudio } from "stores/audio-store";
import { logError } from "utils";
import locales from "locales";
import {
  addItemExecutionAnswer,
  addItemExecutionAttempt,
  disableItemExecution,
  enableItemExecution,
  finishRecordItem,
  saveRecordItemResult,
  startRecordItem,
  submitRecordItem,
  unselectItem,
} from "student-front-commons/src/actions/itemExecution";
import { COMPANY_SCHEMA, PROFILE_SCHEMA, SCHOOL_CLASS_SCHEMA, SCHOOL_SCHEMA } from "student-front-commons/src/schemas";
import { getItemExecutionPropById } from "student-front-commons/src/selectors/itemExecution";

let recordStream = null;
let recorderInstance = null;

export function* startRecordFlow() {
  yield takeLatest(getFlowStart(START_RECORD_FLOW), function* () {
    yield race({
      cancel: take(getFlowStart(CLOSE_UNIT_EXECUTION_FLOW)),
      closeMasteryTest: take(getFlowStart(CLOSE_MASTERY_TEST_EXECUTION_FLOW)),
      call: call(function* () {
        const itemId = yield select((state) => state.itemExecutions.selectedId);

        try {
          if (window.navigator && window.navigator.mediaDevices) {
            recordStream = yield window.navigator.mediaDevices.getUserMedia({
              audio: true,
              video: false,
            });
          } else if (
            window.navigator.getUserMedia ||
            window.navigator.webkitGetUserMedia ||
            window.navigator.mozGetUserMedia
          ) {
            recordStream = yield new Promise((resolve, reject) => {
              (
                window.navigator.getUserMedia ||
                window.navigator.webkitGetUserMedia ||
                window.navigator.mozGetUserMedia
              )(
                { audio: true, video: false },
                (stream) => resolve(stream),
                (error) => reject(error)
              );
            });
          } else {
            yield put(
              startFlow(MICROPHONE_ERROR_MODAL_FLOW, {
                type: "update",
                message: get(locales, "error.startRecord"),
              })
            );
            return;
          }

          recorderInstance = new window.RecordRTCPromisesHandler(recordStream, {
            type: "audio",
            recorderType: platform.name === "Safari" ? window.StereoAudioRecorder : window.MediaStreamRecorder,
            disableLogs: false,
          });
          yield recorderInstance.startRecording();

          yield put(startRecordItem(itemId));
          yield put(startFlow(END_RECORD_BY_TIMEOUT_FLOW));
        } catch (error) {
          if (
            error.name === "PermissionDeniedError" ||
            error.name === "NotAllowedError" ||
            error.message === "Could not start audio source"
          ) {
            yield put(
              startFlow(MICROPHONE_ERROR_MODAL_FLOW, {
                type: "permission",
                message: get(locales, "error.noMicrophonePermission"),
              })
            );
          } else if (error.message === "Requested device not found") {
            yield put(
              startFlow(MICROPHONE_ERROR_MODAL_FLOW, {
                type: "general",
                message: get(locales, "error.microphoneNotFound"),
              })
            );
          } else {
            logError({ error, flow: START_RECORD_FLOW });
          }

          yield put(endFlow(END_RECORD_BY_TIMEOUT_FLOW));
          yield put(finishRecordItem(itemId));
          if (recorderInstance) {
            yield recorderInstance.stopRecording();
            recorderInstance.recordRTC.destroy();
            recordStream.getAudioTracks().forEach((track) => track.stop());
          }
        } finally {
          if (yield cancelled()) {
            if (recorderInstance) {
              yield recorderInstance.stopRecording();
              recorderInstance.recordRTC.destroy();
              recordStream.getAudioTracks().forEach((track) => track.stop());
            }
          }
          yield put(endFlow(START_RECORD_FLOW));
        }
      }),
    });
  });
}

export function* endRecordFlow() {
  yield takeLatest(getFlowStart(END_RECORD_FLOW), function* () {
    yield race({
      cancel: take(getFlowStart(CLOSE_UNIT_EXECUTION_FLOW)),
      closeMasteryTest: take(getFlowStart(CLOSE_MASTERY_TEST_EXECUTION_FLOW)),
      call: call(function* () {
        const itemId = yield select((state) => state.itemExecutions.selectedId);

        try {
          yield recorderInstance.stopRecording();
          recordStream.getAudioTracks().forEach((track) => track.stop());

          const recordBlob = yield recorderInstance.getBlob();
          const recordUrl = yield recorderInstance.getDataURL();
          yield recorderInstance.destroy();

          yield put(disableItemExecution());
          yield put(submitRecordItem(itemId, { recordFile: recordBlob, recordUrl }));

          const currentExecution = yield select((state) => state.itemExecutions.byId[itemId]);

          yield call(insertSpeechRecognitionAudio, {
            data: recordUrl,
            isDeletable: true,
          });

          const id = sessionStorage.getItem("id");
          let profile = {
            isDemoStudent: false,
          };
          let schoolClass = {};
          let school = { allowSpeechRecognitionBonus: true };
          let company = { allowSpeechRecognitionBonus: true };
          if (id !== "tasting_user") {
            profile = yield select(getEntityById(PROFILE_SCHEMA, id));
            company = yield select(getEntityById(COMPANY_SCHEMA, profile.company));

            if (!profile.apiVersion) {
              schoolClass = yield select(getEntityById(SCHOOL_CLASS_SCHEMA, profile.schoolClass));
              school = yield select(getEntityById(SCHOOL_SCHEMA, schoolClass.school));
            }
          }

          if (
            ["VIDEO_SHORT", "VOCABULARY", "PHONEME", "GAP_FILL_IMAGE", "CONNECTING_DOTS"].find(
              (type) => type === currentExecution.item.type.key
            )
          ) {
            try {
              const speechRecognitionResult = yield call(getScore, {
                id: `kids-${id}`,
                isDemoStudent: !!profile.isDemoStudent,
                isStagingEnvironment: process.env.REACT_APP_ENVIRONMENT !== "production",
                record: recordBlob,
                text: ["VIDEO_SHORT", "GAP_FILL_IMAGE"].find((type) => type === currentExecution.item.type.key)
                  ? currentExecution.item.text
                  : currentExecution.item.postPhrase,
                wordsDictionary: currentExecution.item.speechRecognitionDictionary || {},
                allowSpeechRecognitionBonus:
                  currentExecution.recordCount >= 3 &&
                  (profile.apiVersion === "V2"
                    ? company.allowSpeechRecognitionBonus
                    : school.allowSpeechRecognitionBonus),
              });
              yield put(saveRecordItemResult(itemId, { speechRecognitionResult }));

              const minimumSpeechRecognitionScore = yield select(
                (state) => state.configurations.scoreToPassOfSpeechRecognition
              );

              if (speechRecognitionResult.qualityScore >= minimumSpeechRecognitionScore) {
                yield spawn(function () {
                  const audio = new Howl({
                    src: [sample([SrSuccessAudioOne, SrSuccessAudioTwo])],
                    autoplay: false,
                    loop: false,
                    volume: 1,
                  });
                  audio.once("end", () => audio.unload());
                  audio.play();
                });
              }

              // just set as answer for specific item types
              if (["VIDEO_SHORT", "VOCABULARY", "PHONEME"].find((type) => type === currentExecution.item.type.key)) {
                const answerResult = yield call(checkAnswer, {
                  item: currentExecution.item,
                  answer: speechRecognitionResult.qualityScore,
                  minimumSpeechRecognitionScore,
                });

                // we need to store the attempts here, because every SR should be stored as an attempt
                const currentAnswer = {
                  answer: answerResult.answer,
                  wordScoreList: speechRecognitionResult.wordScoreList,
                  correct: answerResult.status === "CORRECT",
                };
                yield put(addItemExecutionAttempt(itemId, { answer: currentAnswer }));
              }
              if (["GAP_FILL_IMAGE", "CONNECTING_DOTS"].find((type) => type === currentExecution.item.type.key)) {
                const isSpeechRecognitionRequired =
                  speechRecognitionResult.qualityScore < minimumSpeechRecognitionScore &&
                  currentExecution.recordCount < 3;

                const answer = yield select(getItemExecutionPropById(itemId, "answer"));
                yield put(addItemExecutionAnswer(itemId, { answer, extraData: { isSpeechRecognitionRequired } }));

                if (!isSpeechRecognitionRequired && currentExecution.item.type.key === "CONNECTING_DOTS") {
                  yield put(unselectItem());
                }
              }
            } catch (error) {
              if (error.code === "error_no_speech") {
                yield spawn(function* () {
                  yield put(
                    startFlow(CONFIRM_MODAL_FLOW, {
                      type: "alert",
                      message: get(locales, "error.noSpeechDetected"),
                    })
                  );
                  yield take(getFlowStart(CONFIRM_MODAL_YES_FLOW));
                  yield put(endFlow(CONFIRM_MODAL_FLOW));
                });
              } else {
                const speechErrorAudio = yield select((state) => state.configurations.speechRecognitionErrorAudios);
                const randomAudio = sample(speechErrorAudio);
                if (randomAudio) {
                  yield call(playAudio, {
                    rate: 1,
                    url: randomAudio.path || randomAudio.generatedAudio,
                  });
                }
              }
            }
          }

          if (currentExecution.item.type.key === "SINGLE_CHOICE_GAME") {
            try {
              const answer = yield call(getOption, {
                id: `kids-${id}`,
                isDemoStudent: !!profile.isDemoStudent,
                isStagingEnvironment: process.env.REACT_APP_ENVIRONMENT !== "production",
                record: recordBlob,
                answers: currentExecution.item.answers,
                wordsDictionary: currentExecution.item.speechRecognitionDictionary || {},
              });

              yield put(saveRecordItemResult(itemId, { speechRecognitionResult: null }));
              yield put(addItemExecutionAnswer(itemId, { answer }));

              yield put(
                startFlow(CHECK_ITEM_ANSWER_FLOW, {
                  isTasting: id === "tasting_user",
                })
              );
            } catch (error) {
              if (error.code === "error_no_speech") {
                yield spawn(function* () {
                  yield put(
                    startFlow(CONFIRM_MODAL_FLOW, {
                      type: "alert",
                      message: get(locales, "error.noSpeechDetected"),
                    })
                  );
                  yield take(getFlowStart(CONFIRM_MODAL_YES_FLOW));
                  yield put(endFlow(CONFIRM_MODAL_FLOW));
                });
              } else {
                const speechErrorAudio = yield select((state) => state.configurations.speechRecognitionErrorAudios);
                const randomAudio = sample(speechErrorAudio);
                if (randomAudio) {
                  yield call(playAudio, {
                    rate: 1,
                    url: randomAudio.path || randomAudio.generatedAudio,
                  });
                }
              }
            }
          }

          yield put(finishRecordItem(itemId));

          if (window.location.pathname.indexOf("/units") > 0) {
            // start use away timeout flow because it was stopped on record start
            yield put(startFlow(USER_AWAY_TIMEOUT_FLOW));
          }
        } catch (error) {
          if (error === "Empty blob.") {
            yield spawn(function* () {
              yield put(
                startFlow(CONFIRM_MODAL_FLOW, {
                  type: "alert",
                  message: get(locales, "error.noSpeechDetected"),
                })
              );
              yield take(getFlowStart(CONFIRM_MODAL_YES_FLOW));
              yield put(endFlow(CONFIRM_MODAL_FLOW));
            });
            yield recorderInstance.destroy();
          } else {
            logError({ error, flow: END_RECORD_FLOW });
          }
          yield put(finishRecordItem(itemId));
        } finally {
          yield put(enableItemExecution());
          yield put(endFlow(END_RECORD_FLOW));
        }
      }),
    });
  });
}

export function* endRecordByTimeoutFlow() {
  yield takeLatest(getFlowStart(END_RECORD_BY_TIMEOUT_FLOW), function* () {
    const raceWinner = yield race({
      timeout: delay(60000),
      exitUnit: take(getFlowStart(CLOSE_UNIT_EXECUTION_FLOW)),
      closeMasteryTest: take(getFlowStart(CLOSE_MASTERY_TEST_EXECUTION_FLOW)),
      userStop: take(getFlowStart(END_RECORD_FLOW)),
    });
    if (raceWinner.timeout) {
      yield put(startFlow(END_RECORD_FLOW));
    }
  });
}
