import React, { useContext } from "react";
import hexToRgba from "hex-rgba";
import UnitTypeThemeContext from "core/ThemeContext";
import ProgressBar from "core/ProgressBar";
import { getExecutionProgress } from "student-front-commons/src/selectors/execution";
import { useSelector } from "react-redux";
import { isNil } from "lodash";

function UnitExecutionProgressBar() {
  const unitTypeContext = useContext(UnitTypeThemeContext);
  const percentage = useSelector(getExecutionProgress);

  if (isNil(percentage)) {
    return null;
  }

  return (
    <ProgressBar
      value={percentage}
      total={1}
      contentColor={unitTypeContext.secondary}
      backgroundColor={hexToRgba(unitTypeContext.primary, 20)}
    />
  );
}

export default UnitExecutionProgressBar;
