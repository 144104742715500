import React, { useState } from "react";
import { getDetailedCurrentScore } from "student-front-commons/src/services/studyQualityService";
import CurrentStudyQuality from "core/CurrentStudyQuality";
import { useEntity, useService } from "student-front-commons/src/hooks";
import { PROFILE_SCHEMA, SCHOOL_CLASS_SCHEMA } from "student-front-commons/src/schemas";

function CurrentStudyQualityContainer() {
  const profile = useEntity(PROFILE_SCHEMA, sessionStorage.getItem("id"));
  const schoolClass = useEntity(SCHOOL_CLASS_SCHEMA, profile.schoolClass);

  const [data, setData] = useState(null);

  const [isLoading] = useService(getDetailedCurrentScore, {
    autoStart: true,
    params: {
      id: sessionStorage.getItem("id"),
    },
    onData: setData,
    onError: () => setData(null),
  });

  return (
    <CurrentStudyQuality
      isLoading={isLoading}
      studyQuality={data}
      hasGrades={profile.apiVersion === "V2" ? !!profile.evaluationTemplate : !!schoolClass.evaluationTemplate}
      isInEvaluationPeriod={profile.isInEvaluationPeriod}
    />
  );
}

export default CurrentStudyQualityContainer;
