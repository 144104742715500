import { all, spawn, call } from "redux-saga/effects";

import logoutFlow from "flows/logoutFlow";
import autoCloseSystemMessageFlow from "flows/autoCloseSystemMessageFlow";
import loadConfigurationFlow from "flows/loadConfigurationFlow";
import loadStudentFlow from "flows/loadStudentFlow";
import loadModulesFlow from "flows/loadModulesFlow";
import { endRecordByTimeoutFlow, endRecordFlow, startRecordFlow } from "flows/recordFlow";
import getNexItemFlow from "flows/getNexItemFlow";
import selectItemFlow from "flows/selectItemFlow";
import playItemAudioFlow from "flows/playItemAudioFlow";
import playItemVideoFlow from "flows/playItemVideoFlow";
import playItemInstructionFlow from "flows/playItemInstructionFlow";
import playRecordAudioFlow from "flows/playRecordAudioFlow";
import checkItemAnswerFlow from "flows/checkItemAnswerFlow";
import startUnitExecutionFlow from "flows/startUnitExecutionFlow";
import confirmStartUnitExecutionFlow from "flows/confirmStartUnitExecutionFlow";
import saveUnitItemExecutionAnswerFlow from "flows/saveUnitItemExecutionAnswerFlow";
import endUnitExecutionFlow from "flows/endUnitExecutionFlow";
import closeUnitExecutionFlow from "flows/closeUnitExecutionFlow";
import userAwayTimeoutFlow from "flows/userAwayTimeoutFlow";
import userAwayFlow from "flows/userAwayFlow";
import startMasteryTestExecutionFlow from "flows/startMasteryTestExecutionFlow";
import saveMasteryTestItemExecutionAnswerFlow from "flows/saveMasteryTestItemExecutionAnswerFlow";
import endMasteryTestExecutionFlow from "flows/endMasteryTestExecutionFlow";
import closeMasteryTestExecutionFlow from "flows/closeMasteryTestExecutionFlow";
import requireUserPlayFlow from "flows/requireUserPlayFlow";
import startTastingExecutionFlow from "flows/startTastingExecutionFlow";
import saveTastingItemExecutionAnswerFlow from "flows/saveTastingItemExecutionAnswerFlow";
import endTastingExecutionFlow from "flows/endTastingExecutionFlow";
import loadLocalizedLabelsFlow from "flows/loadLocalizedLabelsFlow";
import monitorNetworkConnectionFlow from "flows/monitorNetworkConnectionFlow";
import { logError } from "utils";

export default function* () {
  const sagas = [
    logoutFlow,
    autoCloseSystemMessageFlow,
    loadConfigurationFlow,
    loadStudentFlow,
    loadModulesFlow,
    startRecordFlow,
    endRecordFlow,
    endRecordByTimeoutFlow,
    getNexItemFlow,
    selectItemFlow,
    playItemAudioFlow,
    playItemVideoFlow,
    playItemInstructionFlow,
    playRecordAudioFlow,
    checkItemAnswerFlow,
    startUnitExecutionFlow,
    confirmStartUnitExecutionFlow,
    saveUnitItemExecutionAnswerFlow,
    endUnitExecutionFlow,
    closeUnitExecutionFlow,
    userAwayTimeoutFlow,
    userAwayFlow,
    startMasteryTestExecutionFlow,
    saveMasteryTestItemExecutionAnswerFlow,
    endMasteryTestExecutionFlow,
    requireUserPlayFlow,
    startTastingExecutionFlow,
    saveTastingItemExecutionAnswerFlow,
    endTastingExecutionFlow,
    loadLocalizedLabelsFlow,
    monitorNetworkConnectionFlow,
    closeMasteryTestExecutionFlow,
  ];

  yield all(
    sagas.map((saga) =>
      spawn(function* () {
        while (true) {
          try {
            yield call(saga);
            break;
          } catch (error) {
            logError({ flow: "ROOT_SAGA", error });
          }
        }
      })
    )
  );
}
