import { all, call, put, race, select, spawn, take, takeLatest } from "redux-saga/effects";
import get from "lodash/get";
import max from "lodash/max";
import orderBy from "lodash/orderBy";
import { endFlow, startFlow } from "student-front-commons/src/actions/flow";
import { getFlow, getFlowStart } from "student-front-commons/src/selectors/flow";
import { getEntityById } from "student-front-commons/src/selectors/entity";
import { startUnitExecution } from "student-front-commons/src/services/unitExecutionService";
import { addBreadcrumb, addImageDataToItems, addSoundToItems, logError, getInstructionSound } from "utils";
import {
  CLOSE_UNIT_EXECUTION_FLOW,
  ERROR_MODAL_FLOW,
  GET_NEXT_ITEM_EXECUTION_FLOW,
  LOAD_CONFIGURATION_FLOW,
  START_UNIT_EXECUTION_FLOW,
  USER_AWAY_TIMEOUT_FLOW,
} from "consts";
import browserHistory from "browserHistory";
import snakeCase from "lodash/snakeCase";
import locales from "locales";
import { PROFILE_SCHEMA } from "student-front-commons/src/schemas";
import { cleanExecution, startExecution } from "student-front-commons/src/actions/execution";

let retryFlowCount = 1;

export default function* () {
  yield takeLatest(getFlowStart(START_UNIT_EXECUTION_FLOW), function* () {
    yield race({
      closeUnit: take(getFlowStart(CLOSE_UNIT_EXECUTION_FLOW)),
      call: call(function* () {
        const flow = yield select(getFlow(START_UNIT_EXECUTION_FLOW));
        try {
          yield put(cleanExecution());

          addBreadcrumb({
            category: "flow",
            message: `Started execution of unit ${flow.params.unit} from module ${flow.params.module}`,
          });

          const result = yield call(startUnitExecution, {
            module: flow.params.module,
            unit: flow.params.unit,
          });
          if ((result.unitExecution.answers || []).length >= result.items.length) {
            browserHistory.replace(`${browserHistory.location.pathname}/${result.unitExecution.id}/result`);

            addBreadcrumb({
              category: "flow",
              action: `Unit finished. Navigate to: ${browserHistory.location.pathname}/${result.unitExecution.id}/result`,
            });
            return;
          }

          const configurations = yield select((state) => state.configurations.id);
          if (!configurations) {
            yield put(startFlow(LOAD_CONFIGURATION_FLOW));
            addBreadcrumb({
              category: "flow",
              message: "Start load configuration flow",
            });
          }

          const profile = yield select(getEntityById(PROFILE_SCHEMA, sessionStorage.getItem("id")));
          const orderedItems = orderBy(result.items, ["order"], ["asc"]).map((unitItem) => ({
            ...unitItem,
            item: {
              ...unitItem.item,
              type: {
                ...unitItem.item.type,
                itemInstructionSound: getInstructionSound(unitItem.item.type, "BETWEEN_ITEMS", profile.locale),
                initialInstructionSound: getInstructionSound(unitItem.item.type, "INITIAL", profile.locale),
              },
            },
          }));

          const answersCount = (result.unitExecution.answers || []).length;
          const currentOrder = max([
            0,
            ...(result.unitExecution.answers || []).map(
              (answer) => orderedItems.find((orderItem) => orderItem.item.id === answer.item).order
            ),
          ]);
          const itemsInOrder = orderedItems.filter((unitItem) => unitItem.order === currentOrder + 1).length;
          yield all([
            ...addSoundToItems(orderedItems.slice(answersCount, answersCount + max([4, itemsInOrder]))),
            ...addImageDataToItems(orderedItems.slice(answersCount, answersCount + max([4, itemsInOrder]))),
          ]);

          addBreadcrumb({
            category: "flow",
            action: `Added sounds and images to first order Items`,
          });

          const unit = yield select(getEntityById("unit", flow.params.unit));
          const unitType = yield select(getEntityById("unitType", unit.type));
          const isGame = ["connecting_dots_game", "single_choice_game", "memory_game", "vocabulary_game"].some(
            (type) => type === snakeCase(unitType.name.toLowerCase())
          );

          yield put(
            startExecution({
              ...result.unitExecution,
              type: "unit",
              items: orderedItems,
              module: flow.params.module,
              unit: flow.params.unit,
              isGame,
            })
          );

          yield spawn(function* () {
            yield all([
              ...addSoundToItems(orderedItems.slice(answersCount + max([4, itemsInOrder]))),
              ...addImageDataToItems(orderedItems.slice(answersCount + max([4, itemsInOrder]))),
            ]);
            addBreadcrumb({
              category: "flow",
              message: "Finished loading all audios and images",
            });
          });

          retryFlowCount = 1;
          yield put(endFlow(ERROR_MODAL_FLOW));

          yield put(startFlow(GET_NEXT_ITEM_EXECUTION_FLOW));
          yield put(startFlow(USER_AWAY_TIMEOUT_FLOW));

          addBreadcrumb({
            category: "flow",
            message: `Advance to next Unit Item`,
          });
        } catch (error) {
          yield put(endFlow(USER_AWAY_TIMEOUT_FLOW));
          logError({ error, flow: START_UNIT_EXECUTION_FLOW });

          yield put(
            startFlow(ERROR_MODAL_FLOW, {
              message:
                retryFlowCount > 3
                  ? get(locales, "unitExecution.error.startExecution.defaultMessage")
                  : get(locales, "unitExecution.error.startExecution.tryAgain"),
              retryFlow: {
                id: START_UNIT_EXECUTION_FLOW,
                params: flow.params,
              },
              attemptCount: retryFlowCount,
            })
          );
          retryFlowCount = retryFlowCount + 1;
        } finally {
          yield put(endFlow(START_UNIT_EXECUTION_FLOW));
        }
      }),
    });
  });
}
