import React from "react";
import PropTypes from "prop-types";
import Col from "core/Col";
import Icon from "core/Icon";
import Image from "core/Image";
import { GREY_6, WHITE } from "consts/colors";

const Avatar = ({ src, size, hasShadow }) => (
  <Col
    backgroundColor={GREY_6}
    width={
      {
        xs: 30,
        sm: 40,
        xxxl: 180,
      }[size]
    }
    height={
      {
        xs: 30,
        sm: 40,
        xxxl: 180,
      }[size]
    }
    borderRadius="100%"
    justifyContent="center"
    alignItems="center"
    overflow="hidden"
    boxShadow={hasShadow ? "0px 1px 3px 0 rgba(0, 0, 0, 0.2);" : "none"}
  >
    {src ? (
      <Image src={`${process.env.REACT_APP_FILES_URL}/${src}`} />
    ) : (
      <Icon
        name="user"
        color={WHITE}
        size={
          {
            xs: 12,
            sm: 16,
            xxxl: 82,
          }[size]
        }
      />
    )}
  </Col>
);

Avatar.propTypes = {
  src: PropTypes.string,
  hasShadow: PropTypes.bool,
  size: PropTypes.oneOf(["xs", "sm", "xxxl"]),
};

Avatar.defaultProps = {
  src: null,
  hasShadow: false,
  size: "xs",
};

export default Avatar;
