import LogRocket from "logrocket";
import * as Sentry from "@sentry/react";
import { insertAudio } from "stores/audio-store";
import { insertImage } from "stores/image-store";
import { head } from "lodash";
import sample from "lodash/sample";
import get from "lodash/get";

export const XL_SIZE = 1420;
export const LG_SIZE = 1280;
export const MD_SIZE = 868;
export const SM_SIZE = 638;

export const addSoundToItems = (items) =>
  items.map(async (associativeItem) => {
    if (associativeItem.item.audio || associativeItem.item.generatedAudio) {
      await insertAudio({
        isCompleteUrl: false,
        isDeletable: true,
        url: associativeItem.item.audio || associativeItem.item.generatedAudio,
      });
    }
    if (associativeItem.item.postPhraseAudio || associativeItem.item.generatedPostPhraseAudio) {
      await insertAudio({
        isCompleteUrl: false,
        isDeletable: true,
        url: associativeItem.item.postPhraseAudio || associativeItem.item.generatedPostPhraseAudio,
      });
    }
    if (associativeItem.item.titleAudio || associativeItem.item.generatedTitleAudio) {
      await insertAudio({
        isCompleteUrl: false,
        isDeletable: true,
        url: associativeItem.item.titleAudio || associativeItem.item.generatedTitleAudio,
      });
    }
    if (associativeItem.item.type.itemInstructionSound) {
      await insertAudio({
        isCompleteUrl: false,
        isDeletable: true,
        url: associativeItem.item.type.itemInstructionSound,
      });
    }
    if (associativeItem.item.type.initialInstructionSound) {
      await insertAudio({
        isCompleteUrl: false,
        isDeletable: true,
        url: associativeItem.item.type.initialInstructionSound,
      });
    }

    await Promise.all(
      (associativeItem.item.answers || [])
        .filter((answer) => answer.audio || answer.generatedAudio)
        .map(async (answer) => {
          await insertAudio({
            isCompleteUrl: false,
            isDeletable: true,
            url: answer.audio || answer.generatedAudio,
          });
        })
    );
    return associativeItem;
  });

export const addImageDataToItems = (items) =>
  items.map(async (associativeItem) => {
    if (associativeItem.item.image) {
      await insertImage({
        url: `items/${associativeItem.item.id}/images/lg.png`,
      });
    }
    if (associativeItem.item.postPhraseImage) {
      await insertImage({
        url: `items/${associativeItem.item.id}/images/post-phrase-lg.png`,
      });
    }
    if (associativeItem.item.type.key === "SINGLE_CHOICE_IMAGE") {
      await Promise.all(
        associativeItem.item.answers.map(
          async (answer) =>
            await insertImage({
              url: `items/${associativeItem.item.id}/images/${answer.id}.png`,
            })
        )
      );
    }
    return associativeItem;
  });

export const getInstructionSound = (itemType, soundType, locale) => {
  locale = head(locale.split("-"));
  const instruction = sample(
    get(itemType, "instructions", [])
      .filter((instruction) => instruction.type === soundType)
      .filter((instruction) => head(instruction.locale.split("-")) === locale)
  );
  if (instruction) {
    return instruction.audio || instruction.generatedAudio;
  }
  return null;
};

export const addSentryUserAction = ({ mainComponent, clickedComponent, action }) => {
  Sentry.addBreadcrumb({
    type: "user",
    category: "click",
    level: "info",
    message: `User clicked on ${mainComponent} > ${clickedComponent} to ${action}`,
    data: {
      mainComponent,
      clickedComponent,
      action,
    },
  });
};

export const addBreadcrumb = ({ category, message, data }) => {
  Sentry.addBreadcrumb({
    type: "user",
    level: "info",
    category,
    message,
    data,
  });
};

export const logError = ({ flow, error }) => {
  LogRocket.log(error);
  Sentry.withScope((scope) => {
    scope.setTag("flow", flow);
    scope.setExtras({
      error: JSON.stringify(error),
      request: error.axiosError,
      sessionURL: LogRocket.sessionURL,
    });
    Sentry.captureException(error);
  });
};
