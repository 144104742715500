import { all, call, put, select, takeLatest } from "redux-saga/effects";
import { getFlowStart } from "student-front-commons/src/selectors/flow";
import { endFlow } from "student-front-commons/src/actions/flow";
import { getConfiguration } from "student-front-commons/src/services/configurationService";
import { getInstructionAudios } from "student-front-commons/src/services/instructionAudiosService";
import { getEntityById } from "student-front-commons/src/selectors/entity";
import {
  requestMergeConfiguration,
  requestMergeInstructionAudios,
} from "student-front-commons/src/actions/configuration";
import { LOAD_CONFIGURATION_FLOW } from "consts";
import { insertAudio } from "stores/audio-store";
import { logError } from "utils";

export default function* () {
  yield takeLatest(getFlowStart(LOAD_CONFIGURATION_FLOW), function* () {
    try {
      const configuration = yield call(getConfiguration);
      const profile = yield select(getEntityById("profile", sessionStorage.getItem("id")));
      const instructionAudios = yield call(getInstructionAudios, {
        type: "KIDS",
        keys: [
          "simpleReview.warning.completed",
          "simpleReview.warning.default",
          "simpleReview.warning.today",
          "userAway",
        ],
        locale: profile.locale || "en",
      });

      const instructionAudiosInserted = yield all(
        instructionAudios.map(async (item) => {
          try {
            await insertAudio({
              isDeletable: false,
              url: item.audio || item.generatedAudio,
            });
            return item;
          } catch (error) {
            console.log(error);
          }
        })
      );

      yield all(
        ["correctAudios", "errorAudios", "firstTryAudios", "secondTryAudios", "speechRecognitionErrorAudios"].map(
          async (path) => {
            configuration[path] = await Promise.all(
              configuration[path].map(async (item) => {
                try {
                  await insertAudio({
                    isCompleteUrl: false,
                    isDeletable: false,
                    url: item.path || item.generatedAudio,
                  });
                } catch (e) {
                  console.log(e);
                }
                return item;
              })
            );
          }
        )
      );

      yield put(requestMergeInstructionAudios(instructionAudiosInserted));
      yield put(requestMergeConfiguration(configuration));
    } catch (error) {
      logError({ error, flow: LOAD_CONFIGURATION_FLOW });
    } finally {
      yield put(endFlow(LOAD_CONFIGURATION_FLOW));
    }
  });
}
