import React from "react";
import PropTypes from "prop-types";
import RecordIcon from "core/icons/Record";
import ListenIcon from "core/icons/Listen";
import RepeatIcon from "core/icons/Repeat";
import ReadIcon from "core/icons/Read";
import TranslateIcon from "core/icons/Translate";
import NextIcon from "core/icons/Next";
import SettingsIcon from "core/icons/Settings";
import BackIcon from "core/icons/Back";
import UserIcon from "core/icons/User";
import GameIcon from "core/icons/Game";
import ArrowUpIcon from "core/icons/ArrowUp";
import StopIcon from "core/icons/Stop";
import LockIcon from "core/icons/Lock";
import RadioCheckedIcon from "core/icons/RadioChecked";
import RadioUnCheckedIcon from "core/icons/RadioUnChecked";
import HelpIcon from "core/icons/Help";
import CloseIcon from "core/icons/Close";
import InfoIcon from "core/icons/Info";
import CheckIcon from "core/icons/Check";
import PauseIcon from "core/icons/Pause";
import ClockIcon from "core/icons/Clock";
import PlayIcon from "core/icons/Play";
import StarIcon from "core/icons/Star";
import StarOutline from "core/icons/StarOutline";
import TransferLeft from "./icons/TransferLeft";
import TransferRight from "./icons/TransferRight";
import Pencil from "./icons/Pencil";
import ArrowTopLeft from "./icons/ArrowTopLeft";
import RectangleOutline from "./icons/RectangleOutline";
import { GREY_4 } from "consts/colors";

const pathByName = {
  settings: SettingsIcon,
  back: BackIcon,
  game: GameIcon,
  record: RecordIcon,
  stop: StopIcon,
  listen: ListenIcon,
  repeat: RepeatIcon,
  read: ReadIcon,
  translate: TranslateIcon,
  next: NextIcon,
  "arrow-up": ArrowUpIcon,
  user: UserIcon,
  lock: LockIcon,
  "checked-radio": RadioCheckedIcon,
  "unchecked-radio": RadioUnCheckedIcon,
  help: HelpIcon,
  close: CloseIcon,
  info: InfoIcon,
  check: CheckIcon,
  pause: PauseIcon,
  clock: ClockIcon,
  play: PlayIcon,
  "rating-star": StarIcon,
  "rating-star-outline": StarOutline,
  "transfer-left": TransferLeft,
  "transfer-right": TransferRight,
  "rectangle-outline": RectangleOutline,
  "arrow-top-left": ArrowTopLeft,
  pencil: Pencil,
};

class Icon extends React.PureComponent {
  static propTypes = {
    name: PropTypes.string.isRequired,
    size: PropTypes.number,
    color: PropTypes.string,
    shouldSpin: PropTypes.bool,
  };

  static defaultProps = {
    color: GREY_4,
    shouldSpin: false,
  };

  render() {
    const { name, size, color, shouldSpin } = this.props;
    return React.createElement(pathByName[name] || RecordIcon, {
      color,
      size,
      shouldSpin,
    });
  }
}

export default Icon;
