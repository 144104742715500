import React from "react";

export default ({ color }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30">
    <path
      fill={color}
      d="M28.242 22.91a1.758 1.758 0 0 1-1.758-1.758v-3.516a1.757 1.757 0 0 1 3.515 0v3.516c0 .971-.787 1.758-1.757 1.758zm-3.516-5.274v3.516a3.52 3.52 0 0 0 3.516 3.516v.879a.879.879 0 0 1-.879.878c-4.506 0-8.109 1.423-11.485 3.575V15.21c3.391-1.944 7.037-2.847 11.485-2.847.485 0 .879.393.879.879v.879a3.52 3.52 0 0 0-3.516 3.515zm-4.442-6.165a22.094 22.094 0 0 0-5.285 2.189 22.087 22.087 0 0 0-5.284-2.189c1.089-.545 2.287-.866 3.527-.866H16.757c1.24 0 2.438.321 3.527.866zM9.726 5.332C9.726 2.424 12.091 0 14.999 0c2.909 0 5.274 2.424 5.274 5.332a5.279 5.279 0 0 1-5.274 5.273 5.278 5.278 0 0 1-5.273-5.273zM14.121 30c-3.376-2.152-6.979-3.575-11.485-3.575a.879.879 0 0 1-.879-.878v-.879a3.52 3.52 0 0 0 3.516-3.516v-3.516a3.52 3.52 0 0 0-3.516-3.515v-.879c0-.486.394-.879.879-.879 4.448 0 8.094.903 11.485 2.847V30zM3.515 17.636v3.516a1.758 1.758 0 1 1-3.516 0v-3.516a1.758 1.758 0 0 1 3.516 0z"
    />
  </svg>
);
