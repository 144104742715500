import React from "react";
import PropTypes from "prop-types";
import { Block } from "jsxstyle";
import WhitelabelContext from "student-front-commons/src/components/WhitelabelContext";
import Col from "core/Col";
import Row from "core/Row";
import Text from "core/Text";
import Icon from "core/Icon";
import Separator from "core/Separator";
import { GREY_4, RED_2 } from "consts/colors";
import InBetweenSpacing from "core/InBetweenSpacing";
import { SM_SIZE } from "utils";
import TranslatedText from "core/TranslatedText";

const RadioInput = ({ options, value, errorText, name, onChange, isDisabled }) => (
  <WhitelabelContext.Consumer>
    {({ primaryColor }) => (
      <Col>
        <Row
          mediaQueries={{
            sm: `(max-width: ${SM_SIZE}px)`,
            md: `(min-width: ${SM_SIZE}px)`,
          }}
          smFlexDirection="column"
          lgFlexDirection="row"
        >
          <InBetweenSpacing size="lg">
            {options.map((option) => (
              <Row key={`option-${option.label}`} position="relative">
                <Block
                  component="input"
                  props={{
                    name,
                    disabled: isDisabled,
                    defaultChecked: value === option.value,
                    value,
                    type: "radio",
                  }}
                  position="absolute"
                  visibility="hidden"
                  width={0}
                  height={0}
                  pointerEvents="none"
                />
                <Row
                  component="button"
                  props={{
                    type: "button",
                    onClick: () => onChange(option.value),
                    disabled: isDisabled,
                  }}
                  cursor={isDisabled ? "not-allowed" : "pointer"}
                  alignItems="center"
                  outline="none"
                  backgroundColor="transparent"
                  border="none"
                >
                  <Icon
                    name={value === option.value ? "checked-radio" : "unchecked-radio"}
                    color={value === option.value ? primaryColor : GREY_4}
                  />
                  <Separator size="sm" />
                  <TranslatedText translateKey={option.label} isBold={true} size={14} color={GREY_4} />
                </Row>
              </Row>
            ))}
          </InBetweenSpacing>
        </Row>
        {errorText && (
          <Block marginTop={5} marginLeft={30}>
            <Text color={RED_2} size={11}>
              {errorText}
            </Text>
          </Block>
        )}
      </Col>
    )}
  </WhitelabelContext.Consumer>
);

RadioInput.propTypes = {
  onChange: PropTypes.func,
  isDisabled: PropTypes.bool,
  errorText: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  value: PropTypes.oneOfType([PropTypes.string]),
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    })
  ).isRequired,
};

RadioInput.defaultProps = {
  onChange: null,
  errorText: null,
  value: "",
  isDisabled: false,
};

export default RadioInput;
