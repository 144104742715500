import React from "react";

export default ({ color, size }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={size || 32} height={size || 32} viewBox="0 0 24 24">
    <path
      fill={color}
      d="M13,9H11V7H13M13,17H11V11H13M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2Z"
    />
  </svg>
);
