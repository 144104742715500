import React from "react";

export default ({ color }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="27" height="27">
    <path
      fill={color}
      d="M19.37 22.109c-.795 0-1.436-.73-1.436-1.628 0-.897.641-1.618 1.436-1.618 2.392 0 4.338-2.194 4.338-4.885 0-2.692-1.623-4.875-3.61-4.875h-3.011l1.861 2.104c.565.634.565 1.659 0 2.295a1.353 1.353 0 0 1-1.014.476c-.374 0-.742-.159-1.024-.476l-5.352-6.028 5.352-6.027c.565-.634 1.475-.634 2.038 0 .565.636.565 1.661 0 2.295l-1.861 2.104h3.011c3.581 0 6.493 3.653 6.493 8.132 0 4.486-3.237 8.131-7.221 8.131zM8.244 14.455c.565-.636 1.482-.636 2.046 0l5.352 6.026-5.352 6.036a1.372 1.372 0 0 1-1.022.477c-.374 0-.74-.16-1.024-.477-.563-.635-.563-1.667 0-2.304l1.87-2.104h-3.01c-3.59 0-6.502-3.645-6.502-8.131 0-4.479 3.237-8.132 7.22-8.132.796 0 1.446.732 1.446 1.628 0 .897-.65 1.629-1.446 1.629-2.39 0-4.328 2.183-4.328 4.875 0 2.691 1.613 4.885 3.61 4.885h3.01l-1.87-2.106c-.563-.635-.563-1.667 0-2.302z"
    />
  </svg>
);
