import React from "react";
import ReactDOM from "react-dom";
import { createStore, applyMiddleware } from "redux";
import { Provider } from "react-redux";
import createSagaMiddleware from "redux-saga";
import { createLogger } from "redux-logger";
import changeFormValueValidator from "student-front-commons/src/middlewares/changeFormValueValidator";
import submitFormValueValidator from "student-front-commons/src/middlewares/submitFormValueValidator";
import addSentryBreadcrumb from "student-front-commons/src/middlewares/addSentryBreadcrumb";
import { setConfig } from "student-front-commons/src/config";
import LogRocket from "logrocket";
import * as Sentry from "@sentry/react";
import flows from "flows";
import reducers from "reducers";
import App from "./App";
import "./index.css";
import "moment/min/locales";

setConfig({
  endpointUrl: process.env.REACT_APP_API_URL,
  speechRecognitionEndpointUrl: process.env.REACT_APP_SPEECH_RECOGNITION_API_URL + "/speech-recognition",
  speechRecognitionKey: process.env.REACT_APP_SPEECH_RECOGNITION_API_KEY,
  setToken: async (token) => await sessionStorage.setItem("accessToken", token),
  getToken: async () => await sessionStorage.getItem("accessToken"),
  onLogout: async () => {
    sessionStorage.removeItem("accessToken");
    if (window.location.pathname !== "/login") {
      window.location.reload();
    }
  },
});

const sagaMiddleware = createSagaMiddleware({
  onError: (error) => {
    Sentry.captureException(error);
  },
});
const middleware = [addSentryBreadcrumb, changeFormValueValidator, submitFormValueValidator, sagaMiddleware];

if (process.env.REACT_APP_ENVIRONMENT !== "production") {
  middleware.push(createLogger());
}

if (process.env.REACT_APP_LOG_ROCKET_KEY) {
  middleware.push(LogRocket.reduxMiddleware());
}

if (process.env.REACT_APP_SENTRY_DSN) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    release: process.env.REACT_APP_RELEASE,
    environment: process.env.REACT_APP_ENVIRONMENT,
    // integrations: [new Integrations.BrowserTracing()],
    // tracesSampleRate: process.env.REACT_APP_ENVIRONMENT === "production" ? 0.5 : 1.0,
    attachStacktrace: true,
    ignoreErrors: ["InvalidTokenError"],
    beforeSend: (event, hint) => {
      const error = hint.originalException;
      const exceptionsToFilterOut = /invalid password|invalid or expired token/i;
      if (error && error.message && error.message.match(exceptionsToFilterOut)) {
        return null;
      }
      return event;
    },
    beforeBreadcrumb: (breadcrumb, hint) => {
      if (hint && hint.xhr) {
        const logRocketUrl = /r\.lr-ingest\.io/i;
        if (hint.xhr.responseURL.match(logRocketUrl)) {
          return null;
        }
      }
      return breadcrumb;
    },
  });
}

const store = createStore(reducers, applyMiddleware(...middleware));
sagaMiddleware.run(flows);

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById("root")
);
