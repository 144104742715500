import React from "react";
import PropTypes from "prop-types";
import Col from "core/Col";
import { WHITE } from "consts/colors";

const ProgressBar = ({ value, total, backgroundColor, contentColor }) => (
  <Col flexShrink={0} width="100%" backgroundColor={WHITE} zIndex={11}>
    <Col position="relative" overflow="hidden" flexShrink={0} width="100%" height={5} backgroundColor={backgroundColor}>
      <Col
        flexShrink={0}
        position="absolute"
        top={0}
        left={0}
        width={`${(value / total) * 100}%`}
        height={5}
        backgroundColor={contentColor}
        transition="width .5s"
      />
    </Col>
  </Col>
);

ProgressBar.propTypes = {
  value: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
  backgroundColor: PropTypes.string.isRequired,
  contentColor: PropTypes.string.isRequired,
};

export default ProgressBar;
