import React from "react";
import get from "lodash/get";
import WhitelabelContext from "student-front-commons/src/components/WhitelabelContext";
import Image from "core/Image";
import Entity from "student-front-commons/src/components/Entity";

const Logo = (props) => (
  <WhitelabelContext.Consumer>
    {({ kidsLogoUrl }) => (
      <Entity name="profile">
        {({ getById: getProfileById }) => (
          <Entity name="company">
            {({ getById: getCompanyById }) => (
              <Entity name="schoolClass">
                {({ getById: getSchoolClassById }) => (
                  <Entity name="school">
                    {({ getById: getSchoolById }) => {
                      const student = getProfileById(sessionStorage.getItem("id"));

                      if (student.apiVersion === "V2") {
                        const company = getCompanyById(student.company);
                        return (
                          <Image
                            src={
                              company.logoUrl ? `${process.env.REACT_APP_FILES_URL}/${company.logoUrl}` : kidsLogoUrl
                            }
                            {...props}
                          />
                        );
                      }

                      const schoolClass = getSchoolClassById(student.schoolClass);
                      const school = getSchoolById(schoolClass?.school);
                      return (
                        <Image
                          src={
                            get(school, "logoUrl", null)
                              ? `${process.env.REACT_APP_FILES_URL}/${school.logoUrl}`
                              : kidsLogoUrl
                          }
                          {...props}
                        />
                      );
                    }}
                  </Entity>
                )}
              </Entity>
            )}
          </Entity>
        )}
      </Entity>
    )}
  </WhitelabelContext.Consumer>
);

export default Logo;
