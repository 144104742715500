import React from "react";
import { useSelector } from "react-redux";
import { getItemsExecutionsType } from "student-front-commons/src/selectors/itemExecution";
import PhonemeItem from "core/items/PhonemeItem";
import GapFillLetterItem from "core/items/GapFillLetterItem";
import VocabularyItem from "core/items/VocabularyItem";
import ConnectingDotsItem from "core/items/ConnectingDotsItem";
import SingleChoiceGameItem from "core/items/SingleChoiceGameItem";
import TrueFalseItem from "core/items/TrueFalseItem";
import MemoryGameItem from "core/items/MemoryGameItem";
import VocabularyGameItem from "core/items/VocabularyGameItem";
import UnscrambleItem from "core/items/UnscrambleItem";
import GapFillImageItem from "core/items/GapFillImageItem";
import SingleChoiceImageItem from "core/items/SingleChoiceImageItem";
import VideoItem from "core/items/VideoItem";
import SingleChoiceItem from "core/items/SingleChoiceItem";

function ItemExecution() {
  const itemType = useSelector(getItemsExecutionsType);

  return (
    <React.Fragment>
      {itemType === "PHONEME" && <PhonemeItem />}
      {itemType === "GAP_FILL_LETTER" && <GapFillLetterItem />}
      {itemType === "VOCABULARY" && <VocabularyItem />}
      {itemType === "CONNECTING_DOTS" && <ConnectingDotsItem />}
      {itemType === "SINGLE_CHOICE_GAME" && <SingleChoiceGameItem />}
      {itemType === "TRUE_FALSE_KIDS" && <TrueFalseItem />}
      {itemType === "MEMORY_GAME" && <MemoryGameItem />}
      {itemType === "VOCABULARY_GAME" && <VocabularyGameItem />}
      {itemType === "GAP_FILL_MULTIPLE" && <UnscrambleItem />}
      {itemType === "GAP_FILL_IMAGE" && <GapFillImageItem />}
      {itemType === "SINGLE_CHOICE_IMAGE" && <SingleChoiceImageItem />}
      {itemType === "SINGLE_CHOICE_KIDS" && <SingleChoiceItem />}
      {(itemType === "VIDEO" || itemType === "VIDEO_SHORT" || itemType === "MUSIC") && <VideoItem />}
    </React.Fragment>
  );
}

export default ItemExecution;
