import React from "react";
import PropTypes from "prop-types";
import ControlBarButton from "core/ControlBarButton";
import { addBreadcrumb } from "../utils";

const RecordButton = ({ onClick, isLoading, isRecording, isDisabled, shouldShake }) => (
  <ControlBarButton
    name={isRecording ? "stop" : "record"}
    className={shouldShake ? "shake-vertical" : ""}
    onClick={() => {
      onClick();
      addBreadcrumb({
        category: "ui",
        message: `${isRecording ? "Stop" : "Start"} Recording Unit Item`,
      });
    }}
    isActive={isRecording}
    isLoading={isLoading}
    isDisabled={isDisabled}
    zIndex={2}
  />
);

RecordButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isRecording: PropTypes.bool,
};

RecordButton.defaultProps = {
  isLoading: false,
  isDisabled: false,
  isRecording: false,
};

export default RecordButton;
