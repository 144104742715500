export const SYSTEM_MESSAGE_FLOW = "SYSTEM_MESSAGE_FLOW";

export const REQUIRE_USER_PLAY_FLOW = "REQUIRE_USER_PLAY_FLOW";
export const FINISH_USER_PLAY_FLOW = "FINISH_USER_PLAY_FLOW";

export const CONFIRM_MODAL_FLOW = "CONFIRM_MODAL_FLOW";
export const CONFIRM_MODAL_YES_FLOW = "CONFIRM_MODAL_YES_FLOW";
export const CONFIRM_MODAL_NO_FLOW = "CONFIRM_MODAL_NO_FLOW";

export const ERROR_MODAL_FLOW = "ERROR_MODAL_FLOW";

export const MICROPHONE_ERROR_MODAL_FLOW = "MICROPHONE_ERROR_MODAL_FLOW";
export const MICROPHONE_ERROR_DISMISS_MODAL_FLOW = "MICROPHONE_ERROR_DISMISS_MODAL_FLOW";

export const LOGOUT_FLOW = "LOGOUT_FLOW";

export const USER_AWAY_FLOW = "USER_AWAY_FLOW";
export const USER_AWAY_TIMEOUT_FLOW = "USER_AWAY_TIMEOUT_FLOW";

export const LOAD_STUDENT_FLOW = "LOAD_STUDENT_FLOW";
export const LOAD_MODULES_FLOW = "LOAD_MODULES_FLOW";

export const LOAD_CONFIGURATION_FLOW = "LOAD_CONFIGURATION_FLOW";

export const START_RECORD_FLOW = "START_RECORD_FLOW";
export const END_RECORD_FLOW = "END_RECORD_FLOW";
export const END_RECORD_BY_TIMEOUT_FLOW = "END_RECORD_BY_TIMEOUT_FLOW";
export const PLAY_RECORD_AUDIO_FLOW = "PLAY_RECORD_AUDIO_FLOW";

export const GET_NEXT_ITEM_EXECUTION_FLOW = "GET_NEXT_ITEM_EXECUTION_FLOW";
export const PLAY_ITEM_AUDIO_FLOW = "PLAY_ITEM_AUDIO_FLOW";
export const PLAY_ITEM_VIDEO_FLOW = "PLAY_ITEM_VIDEO_FLOW";
export const PLAY_ITEM_INSTRUCTION_FLOW = "PLAY_ITEM_INSTRUCTION_FLOW";
export const SELECT_ITEM_FLOW = "SELECT_ITEM_FLOW";
export const CHECK_ITEM_ANSWER_FLOW = "CHECK_ITEM_ANSWER_FLOW";

export const CONFIRM_START_UNIT_EXECUTION_FLOW = "CONFIRM_START_UNIT_EXECUTION_FLOW";
export const START_UNIT_EXECUTION_FLOW = "START_UNIT_EXECUTION_FLOW";
export const CLOSE_UNIT_EXECUTION_FLOW = "CLOSE_UNIT_EXECUTION_FLOW";
export const SAVE_UNIT_ITEM_EXECUTION_ANSWER_FLOW = "SAVE_UNIT_ITEM_EXECUTION_ANSWER_FLOW";
export const END_UNIT_EXECUTION_FLOW = "END_UNIT_EXECUTION_FLOW";

export const START_MASTERY_TEST_EXECUTION_FLOW = "START_MASTERY_TEST_EXECUTION_FLOW";
export const CLOSE_MASTERY_TEST_EXECUTION_FLOW = "CLOSE_MASTERY_TEST_EXECUTION_FLOW";
export const SAVE_MASTERY_TEST_ITEM_EXECUTION_ANSWER_FLOW = "SAVE_MASTERY_TEST_ITEM_EXECUTION_ANSWER_FLOW";
export const END_MASTERY_TEST_EXECUTION_FLOW = "END_MASTERY_TEST_EXECUTION_FLOW";

export const START_TASTING_EXECUTION_FLOW = "START_TASTING_EXECUTION_FLOW";
export const SAVE_TASTING_ITEM_EXECUTION_ANSWER_FLOW = "SAVE_TASTING_ITEM_EXECUTION_ANSWER_FLOW";
export const END_TASTING_EXECUTION_FLOW = "END_TASTING_EXECUTION_FLOW";

export const LOAD_LOCALIZED_LABELS_FLOW = "LOAD_LOCALIZED_LABELS_FLOW";

export const MONITOR_NETWORK_CONNECTION_FLOW = "MONITOR_NETWORK_CONNECTION_FLOW";
