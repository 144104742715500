import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import Col from "core/Col";
import Spinner from "core/Spinner";
import Separator from "core/Separator";
import IconButton from "core/IconButton";
import PublicScreen from "core/PublicScreen";
import ScreenContainer from "core/ScreenContainer";
import ThemeContext from "core/ThemeContext";
import MasteryTestExecutionProgressBar from "screens/mastery-test-execution/MasteryTestExecutionProgressBar";
import CloseMasteryTestExecutionContainer from "screens/mastery-test-execution/CloseMasteryTestExecutionContainer";
import ItemExecution from "../../core/items";
import MasteryTestExecutionControlBar from "./MasteryTestExecutionControlBar";

const MasteryTestExecutionScreen = ({ isLoading, history, match }) => (
  <ThemeContext.Consumer>
    {({ radialGradient }) => (
      <PublicScreen
        backgroundStyle={{
          background: radialGradient,
        }}
      >
        {!isLoading && <MasteryTestExecutionProgressBar />}
        <Separator size="lg" />
        <Col height="100vh" zIndex={1}>
          <ScreenContainer>
            <CloseMasteryTestExecutionContainer />
            {isLoading ? (
              <Col alignItems="center" justifyContent="center" height="100vh">
                <Spinner />
              </Col>
            ) : (
              <Col flexGrow={1} position="relative">
                <Col position="absolute" top={0} left={0}>
                  <IconButton name="back" onClick={() => history.replace(`/modules/${match.params.moduleId}/detail`)} />
                </Col>
                <Separator size="lg" />
                <Col flexGrow={1} justifyContent="space-between">
                  <ItemExecution />
                  <MasteryTestExecutionControlBar />
                </Col>
              </Col>
            )}
          </ScreenContainer>
        </Col>
      </PublicScreen>
    )}
  </ThemeContext.Consumer>
);

MasteryTestExecutionScreen.propTypes = {
  isLoading: PropTypes.bool.isRequired,
};

export default withRouter(MasteryTestExecutionScreen);
