import React from "react";

export default ({ color }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="12" height="16">
    <path
      fill={color}
      d="M11.82 5.726L6.421.192a.647.647 0 0 0-.422-.186.539.539 0 0 0-.418.186l-5.4 5.534a.558.558 0 0 0-.182.428c0 .186.061.31.182.433l.841.859a.57.57 0 0 0 .838 0l2.399-2.459c.181-.184.542-.06.542.246v10.144c0 .37.24.616.601.616H6.6c.36 0 .6-.246.6-.616V5.173c0-.246.299-.433.539-.246l2.403 2.458a.568.568 0 0 0 .838 0l.84-.861a.565.565 0 0 0 .179-.429c0-.124-.059-.247-.179-.369z"
    />
  </svg>
);
