const labels = { 
'attention': '¡Atención!', 
'checkIntroduction': 'Escuchar las intrucciones', 
'close': 'Cerrar', 
'courseScreen.chooseCourse': 'Elige tu curso', 
'courseScreen.greetings': 'Hola, {name} ;)', 
'error.invalid_date': 'Datos inválidos', 
'error.invalid_email': 'Correo inválido', 
'error.invalid_format': 'Formato inválido', 
'error.microphoneNotFound': 'No se puede acceder a tu micrófono. Comprueba la configuración de tu navegador.', 
'error.noMicrophonePermission': 'El permiso para acceder al micrófono no ha sido aceptado, por favor comprueba la configuración de tu navegador.', 
'error.noSpeechDetected': 'Tu habla no ha podido ser detectada. Comprueba tu micrófono, puede estar silenciado o desconectado.', 
'error.required': 'Obligatorio', 
'error.required_date': 'Obligatorio', 
'error.startRecord': 'No se puede iniciar la grabación, por favor, asegúrate de que tu dispositivo y/o navegador están actualizados.', 
'grades.averageSq': 'Promedio: {score}', 
'grades.classRoom': 'Clase:', 
'grades.finalGradeColumn': 'Calificación Final', 
'grades.hoursColumn': 'Horas ({weight}%)', 
'grades.maximumGrade': 'Calificación Máxima', 
'grades.maximumSchoolGrade': 'Calificación Máxima de la Escuela', 
'grades.noData': 'Tus notas todavía no están disponibles', 
'grades.preview': 'Vista previa', 
'grades.requiredStudyTime': 'Tiempo de estudio obligatorio', 
'grades.requiredStudyTimePeriod': 'Tiempo de estudio obligatorio: {time}', 
'grades.school': 'Escuela', 
'grades.student': 'Estudiante', 
'grades.studyQualityColumn': 'Calidad de Estudio ({weight}%)', 
'grades.teacher': 'Profesor', 
'grades.title': 'Tus Calificaciones - {year}', 
'grades.totalStudyTimePeriod': 'Tiempo total de estudio: {time}', 
'grades.yourSchoolGrade': 'Tus notas en la escuela', 
'invalidBrowser.android': 'Por favor, usa Google Chrome para acceder a la plataforma.', 
'invalidBrowser.button.reload': 'Actualizar', 
'invalidBrowser.desktop': 'Por favor, usa Google Chrome para acceder a la plataforma.', 
'invalidBrowser.ios': 'Por favor, usa Safari para acceder a la plataforma.', 
'login.button.access': 'Entrar', 
'login.error.academic_user': 'Entrar a {name} - {url}', 
'login.error.classroom_not_found': 'Tu usuario no está asociado a una clase.', 
'login.error.email_not_found': 'Correo no encontrado', 
'login.error.inactive_classroom': 'Salón inactivo', 
'login.error.invalid_password': 'Contraseña inválida', 
'login.error.out_of_access_period': 'Período de acesso invalido', 
'login.error.payment_required': 'Existe un pago pendiente. Por favor, verifica tu correo.', 
'login.error.student_contract_blocked': 'Oops, parece que tu acceso ha sido bloqueado. Ponte en contacto con tu profesor para obtener más información.', 
'login.error.unexpected_error': 'No fue posible conectarse. Intenta nuevamente más tarde.', 
'login.error.user_disabled': 'Tu usuario ha sido deshabilitado, verifica con tu institución.', 
'login.error.user_not_found_in_company': 'Correo no encontrado', 
'login.error.user_not_found_in_distributor': 'Correo no encontrado', 
'login.field.email': 'Correo', 
'login.field.password': 'Contraseña', 
'menu.grades': 'Calificaciones', 
'menu.logout': 'Salir', 
'menu.profile': 'Perfil', 
'microphoneErrorModal.general.button': 'De acuerdo', 
'microphoneErrorModal.permission.button': 'Ok, ya activé el micrófono', 
'microphoneErrorModal.permission.message': 'Haz clic aquí para ver cómo cambiar la configuración de tu navegador.', 
'microphoneErrorModal.title': 'Acción requerida', 
'microphoneErrorModal.update.button': 'Ok, ya actualicé', 
'microphoneErrorModal.update.message': 'Haz clic aquí y ve cómo cambiar y actualizar tu dispositivo.', 
'module': 'Módulo', 
'no': 'No', 
'notFound.button.back': 'Volver', 
'notFound.default': 'Esta página no está disponible.', 
'notFound.noCourse': 'No tienes ningún curso, por favor contacta con tu profesor.', 
'profile.button.cancel': 'Cancelar', 
'profile.button.save': 'Guardar', 
'profile.errorMessage': 'No se pueden actualizar los datos.', 
'profile.field.birthDate': 'Fecha de Nacimiento', 
'profile.field.changeAvatar': 'Cambiar Avatar', 
'profile.field.currentPassword': 'Contraseña Actual', 
'profile.field.email': 'Correo', 
'profile.field.female': 'Femenino', 
'profile.field.male': 'Masculino', 
'profile.field.newPassword': 'Nueva Contraseña', 
'profile.field.nickname': 'Alias', 
'profile.field.notRankingParticipant': 'No quiero ser parte del Ranking.', 
'profile.field.rankingParticipant': 'Quiero participar en el Ranking.', 
'profile.passwordSection.description': 'Para cambiar tu contraseña, escribe tu contraseña actual y la nueva.', 
'profile.passwordSection.title': 'Contraseña', 
'profile.successMessage': 'Tus datos han sido actualizados.', 
'profile.title': 'Datos del Estudiante', 
'ranking.mySchoolPosition': 'Mi posición en la escuela', 
'ranking.national': 'Nacional', 
'ranking.noDataMessage': '¡Completa un ejercicio para desbloquear el ranking!', 
'ranking.regional': 'Regional', 
'ranking.school': 'Escuela', 
'reportError': 'Reportar Error', 
'studyQuality.modalTitle': 'Cómo mejorar tu Calidad de Estudio', 
'studyQuality.noEvaluationPeriod': 'No estás en periodo de evaluación.', 
'studyQuality.oneHourRequired': 'Tu Calidad de Estudio será generada luego de estudiar 1 hora.', 
'studyQuality.requirements.DAYS_STUDIED': 'Estudia por lo menos 7 días cada 15 días.', 
'studyQuality.requirements.DAYS_STUDIED_AT_LEAST_30_MINUTES': 'Estudia por lo menos 30 minutos al día', 
'studyQuality.requirements.LISTEN_USAGE': 'Uso del botón Escuchar', 
'studyQuality.requirements.REPEAT_USAGE': 'Uso del botón Repetir', 
'studyQuality.requirements.SINGLE_CHOICE_AVERAGE_SCORE': 'Respuestas de las preguntas', 
'studyQuality.requirements.SPEECH_RECOGNITION_AVERAGE_SCORE': 'Nota Media - Pronunciación', 
'studyQuality.requirements.TIME_STUDIED': 'Estudiar el tiempo semanal requerido', 
'studyQuality.requirements.UNIT_AVERAGE_SCORE': 'Nota Media - Unidades', 
'studyQuality.title': 'Calidad de Estudio', 
'tryAgain': 'Intenta Nuevamente', 
'tryAgainMessage': 'Intenta nuevamente en {seconds} segundos.', 
'unitExecution.confirmModal.executedToday': 'Ya completaste este ejercicio. Si vuelves a hacerlo, no ganarás nuevas estrellas. ¿Quieres continuar?', 
'unitExecution.confirmModal.noPoints': 'Ya completaste este ejercicio. Si vuelves a hacerlo, no ganarás nuevas estrellas. ¿Quieres continuar?', 
'unitExecution.confirmModal.unitCompleted': 'Ya ganaste todas las estrellas. Si vuelves a hacer el ejercicio, no ganarás nuevas estrellas. ¿Quieres continuar?', 
'unitExecution.error.endExecution.defaultMessage': 'No se ha podido calcular el resultado de la actividad. Puedes volver a intentarlo o pasar a otra actividad.', 
'unitExecution.error.endExecution.tryAgain': 'Hubo un problema al calcular tu resultado. Por favor, espera unos segundos y lo intentaremos de nuevo.', 
'unitExecution.error.playAudio.defaultMessage': 'No fue posible reproducir el audio del hablante nativo. Puedes volver a intentarlo o pasar a otra actividad.', 
'unitExecution.error.playAudio.tryAgain': 'No fue posible reproducir el audio del hablante nativo. Por favor, espere unos segundos y lo intentaremos de nuevo.', 
'unitExecution.error.saveItem.defaultMessage': 'No fue posible guardar la respuesta. Puedes volver a intentarlo o pasar a otra actividad.', 
'unitExecution.error.saveItem.tryAgain': 'Hubo un problema al guardar la respuesta. Por favor, espera unos segundos y lo intentaremos de nuevo.', 
'unitExecution.error.selectItem.defaultMessage': 'No se puede reproducir la instrucción de la actividad. Puedes volver a intentarlo o pasar a otra actividad.', 
'unitExecution.error.selectItem.tryAgain': 'No se puede reproducir la instrucción de la actividad. Por favor, espera unos segundos y lo intentaremos de nuevo.', 
'unitExecution.error.startExecution.defaultMessage': 'No fue posible iniciar la actividad. Puedes volver a intentarlo o pasar a otra actividad.', 
'unitExecution.error.startExecution.tryAgain': 'Ocurrió un problema. Por favor, intenta de nuevo.', 
'unitExecution.itemType.text.false': 'No, it is not true.', 
'unitExecution.itemType.text.true': 'Yes, it is true.', 
'unitExecution.leave.description': 'Si sales ahora, el progreso de estudio se perderá después de {timeToAllowContinueUnit}. ¿Estás seguro de que deseas salir?', 
'unitExecution.leave.noProgressLossDescription': '¿Estás seguro de que deseas salir?', 
'unitExecution.leave.title': 'Atención', 
'unitExecution.pointsReceived': 'Puntos Ganados', 
'unitExecution.score.computer': 'Puntos del computador', 
'unitExecution.score.my': 'Mi Puntuación', 
'unitExecution.score.yours': 'Tu Puntuación', 
'userAway.message': 'Parece que tienes problemas para hacer el ejercicio. Intenta volver a escuchar el audio de las instrucciones o habla con tu profesor.', 
'userErrorReport.buttonsText.discardChanges': 'Descartar', 
'userErrorReport.buttonsText.submit': 'Enviar', 
'userErrorReport.errorMessages.KIDSNotFound': 'La dirección de correo no está registrada en la plataforma', 
'userErrorReport.errorMessages.apiError': 'No pudimos enviar tu reporte. Por favor, espera un momento e inténtalo de nuevo', 
'userErrorReport.labels.content': 'Cuéntanos lo que pasó', 
'userErrorReport.labels.email': 'Correo', 
'userErrorReport.labels.subject': 'Asunto', 
'userErrorReport.placeholders.content': 'No dudes en enviarnos tus sugerencias y comentarios', 
'userErrorReport.placeholders.email': 'Introduce tu dirección de correo registrada en la plataforma', 
'userErrorReport.placeholders.subject': 'Escribe aquí el motivo de tu reporte', 
'userErrorReport.title': 'Reportar un error', 
'userPlayModal.message': '¡Empecemos!', 
'weekTime': 'Tiempo de la semana', 
'yes': 'Sí', 
}; 
export default labels;