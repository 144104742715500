import React, { useContext } from "react";
import { useHistory, useParams } from "react-router-dom";
import Card from "core/Card";
import UnitTypeThemeContext from "../../core/ThemeContext";
import { useSelector } from "react-redux";
import { GREY_4, GREY_7, WHITE } from "../../consts/colors";
import ShineBackground from "../../core/ShineBackground";
import Col from "../../core/Col";
import Image from "../../core/Image";
import ApprovedIcon from "../../assets/unit-result-game-win.png";
import FailedIcon from "../../assets/unit-result-game-lose.png";
import Separator from "../../core/Separator";
import Text from "../../core/Text";
import InBetweenSpacing from "../../core/InBetweenSpacing";
import IconButton from "../../core/IconButton";
import Row from "../../core/Row";
import WinChar from "../../assets/bob-mastery-test-win.png";
import FailChar from "../../assets/bob-mastery-test-fail.png";

function MasteryTestResultCard() {
  const params = useParams();
  const history = useHistory();
  const themeContext = useContext(UnitTypeThemeContext);

  const executionScore = useSelector((state) => state.executions?.result?.score);
  const executionScoreToPass = useSelector((state) => state.executions?.result?.scoreToPass);

  const isApproved = executionScore >= executionScoreToPass;

  return (
    <Card
      showRopes={true}
      padding="70px 65px 70px 65px"
      alignItems="center"
      maxWidth={720}
      width="100%"
      betweenRopesStyle={{
        width: "70%",
      }}
      ropesStyle={{
        backgroundColor: themeContext.primary,
      }}
    >
      <Col
        position="absolute"
        top={0}
        left={0}
        zIndex={-1}
        opacity={isApproved ? 0.08 : 0.4}
        backgroundColor={isApproved ? "transparent" : GREY_7}
        width="100%"
        height="100%"
      >
        {isApproved && <ShineBackground width="100%" height="100%" color={themeContext.primary} />}
      </Col>
      <Col alignItems="flex-start" width="100%">
        <Image src={isApproved ? ApprovedIcon : FailedIcon} width="auto" height={isApproved ? 105 : 70} />
        <Separator size="xs" />
        <Text color={isApproved ? themeContext.primary : GREY_4} size={65} boldType="bold">
          {isApproved ? "Great!" : "Oh no!"}
        </Text>
        <Text color={GREY_4} size={16} boldType="bold" isUpperCase={true}>
          Your Score
        </Text>
        <Text
          color={isApproved ? themeContext.primary : GREY_4}
          size={178}
          boldType="light-bold"
          lineHeight="160px"
          marginLeft={-15}
        >
          {executionScore}
        </Text>
        <Separator size="sm" />
        <Text color={GREY_4} size={14} boldType="bold" opacity={0.8}>
          Score to pass: {executionScoreToPass}
        </Text>
      </Col>
      <Row position="absolute" bottom={-40}>
        <InBetweenSpacing size="lg">
          <IconButton
            name="next"
            size={80}
            onClick={() => history.push(`/modules/${params.moduleId}/detail`)}
            backgroundColor={isApproved ? themeContext.primary : GREY_4}
            iconColor={WHITE}
          />
        </InBetweenSpacing>
      </Row>
      <Image
        src={isApproved ? WinChar : FailChar}
        width="auto"
        height="100%"
        position="absolute"
        top="20%"
        right={20}
      />
    </Card>
  );
}

export default MasteryTestResultCard;
