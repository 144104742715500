import React from "react";
import PropTypes from "prop-types";
import Col from "core/Col";
import Image from "core/Image";
import RankingIconAsset from "assets/ranking-icon.png";
import { YELLOW_1 } from "consts/colors";

const RankingIcon = ({ size }) => (
  <Col
    width={size}
    height={size}
    boxSizing="border-box"
    justifyContent="center"
    alignItems="center"
    backgroundColor={YELLOW_1}
    borderRadius="100%"
  >
    <Image src={RankingIconAsset} height="57%" width="auto" />
  </Col>
);

RankingIcon.propTypes = {
  size: PropTypes.number,
};

RankingIcon.defaultProps = {
  size: 80,
};

export default RankingIcon;
