import React from "react";
import Col from "core/Col";
import Row from "core/Row";
import { GREY_5 } from "consts/colors";
import Separator from "core/Separator";

const CircularDivider = () => (
  <Row justifyContent="center" alignItems="center">
    <Col backgroundColor={GREY_5} height={4} width={4} borderRadius={4} />
    <Separator size="xxs" />
    <Col backgroundColor={GREY_5} height={4} width={4} borderRadius={4} />
    <Separator size="xxs" />
    <Col backgroundColor={GREY_5} height={4} width={4} borderRadius={4} />
  </Row>
);

export default CircularDivider;
