import React, { useCallback } from "react";
import RepeatButton from "core/RepeatButton";
import { PLAY_ITEM_AUDIO_FLOW, PLAY_ITEM_VIDEO_FLOW } from "consts";
import { useFlow } from "student-front-commons/src/hooks";
import { useSelector } from "react-redux";
import { getCurrentItemExecutionProp, getSelectedId } from "student-front-commons/src/selectors/itemExecution";
import get from "lodash/get";
import last from "lodash/last";

function RepeatButtonContainer() {
  const [isPendingVideo, handleStartVideo] = useFlow(PLAY_ITEM_VIDEO_FLOW);
  const [isPendingAudio, handleStartAudio] = useFlow(PLAY_ITEM_AUDIO_FLOW);

  const selectedId = useSelector(getSelectedId);
  const itemType = useSelector(getCurrentItemExecutionProp("item.type.key"));
  const isRecording = useSelector(getCurrentItemExecutionProp("isRecording"));
  const isSubmittingRecord = useSelector(getCurrentItemExecutionProp("isSubmittingRecord"));
  const isDisabled = useSelector(getCurrentItemExecutionProp("isDisabled"));
  const repeatCount = useSelector(getCurrentItemExecutionProp("repeatCount"));
  const attempts = useSelector(getCurrentItemExecutionProp("attempts"));

  const handleStart = useCallback(() => {
    ["VIDEO", "VIDEO_SHORT", "MUSIC"].find((type) => type === itemType) ? handleStartVideo() : handleStartAudio();
  }, [itemType, handleStartVideo, handleStartAudio]);

  const isRepeatDisabled = () => {
    if (
      [
        "VIDEO",
        "MUSIC",
        "VIDEO_SHORT",
        "PHONEME",
        "VOCABULARY",
        "TRUE_FALSE_KIDS",
        "SINGLE_CHOICE_KIDS",
        "SINGLE_CHOICE_IMAGE",
        "CONNECTING_DOTS",
      ].find((type) => type === itemType)
    ) {
      return (
        isDisabled || isRecording || isSubmittingRecord || !selectedId
        // this.props.isSubmitting ||
      );
    }
    if (itemType === "VOCABULARY_GAME") {
      return (
        isDisabled || isRecording || isSubmittingRecord || !selectedId || repeatCount > 0
        // this.props.isSubmitting ||
      );
    }
    if (itemType === "GAP_FILL_IMAGE") {
      return (
        isDisabled ||
        isRecording ||
        isSubmittingRecord ||
        !selectedId ||
        (!get(last(attempts), "correct", false) && attempts.length < 3)
        // this.props.isSubmitting ||
      );
    }
    return true;
  };

  return <RepeatButton onClick={handleStart} isDisabled={isPendingVideo || isPendingAudio || isRepeatDisabled()} />;
}

export default RepeatButtonContainer;
