import React from "react";
import PropTypes from "prop-types";
import Col from "core/Col";
import Text from "core/Text";
import { WHITE } from "consts/colors";
import { SM_SIZE } from "utils";
import MediaQuery from "react-responsive";
import TranslatedText from "core/TranslatedText";
import { useSelector } from "react-redux";
import { getGameScoreByType } from "student-front-commons/src/selectors/execution";

function ControlBarGameScore(props) {
  const score = useSelector(getGameScoreByType(props.type));
  return (
    <Col alignItems="center" width={110}>
      <MediaQuery minWidth={SM_SIZE}>
        {(matches) => (
          <React.Fragment>
            <TranslatedText
              translateKey={{ student: "unitExecution.score.my", computer: "unitExecution.score.computer" }[props.type]}
              color={WHITE}
              size={12}
              boldType="light-bold"
              textAlign="center"
              isUpperCase={true}
            />
            <Text
              color={WHITE}
              size={matches ? 45 : 35}
              lineHeight={matches ? "55px" : "45px"}
              boldType="light-bold"
              className="jello-horizontal scale-in-center"
            >
              {score}
            </Text>
          </React.Fragment>
        )}
      </MediaQuery>
    </Col>
  );
}

ControlBarGameScore.propTypes = {
  type: PropTypes.oneOf(["student", "computer"]).isRequired,
};

export default ControlBarGameScore;
