import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { Stage, Image, Layer, Arrow, Rect, Line } from "react-konva";
import moment from "moment";
import Icon from "./Icon";
import Button from "./Button";
import { WHITE } from "../consts/colors";

const UserReportPrintEditor = ({ image, onChange }) => {
  const canvasRef = useRef(null);
  const imageRef = useRef(new window.Image());
  const [drawables, setDrawables] = useState([]);
  const [newDrawable, setNewDrawable] = useState(null);
  const [drawableType, setDrawableType] = useState("Rectangle");
  const [isImageSet, setIsImageSet] = useState(false);

  useEffect(() => {
    if (imageRef.current) {
      imageRef.current.src = image;
      setIsImageSet(true);
    }
  }, [image]);

  const handleMouseDown = (e) => {
    const { x, y } = e.target.getStage().getPointerPosition();
    setNewDrawable({
      id: moment().toISOString(),
      startX: x,
      startY: y,
      drawableType,
      ...(drawableType !== "Rectangle" && {
        points: [x, y],
      }),
      ...(drawableType === "Rectangle" && {
        width: 0,
        height: 0,
      }),
    });
  };

  const handleMouseUp = (e) => {
    if (newDrawable) {
      const { x, y } = e.target.getStage().getPointerPosition();
      if (drawableType === "Rectangle") {
        setNewDrawable({ ...newDrawable, width: x - newDrawable.startX, height: y - newDrawable.startY });
      }
      if (drawableType === "ArrowDrawable") {
        setNewDrawable({ ...newDrawable, points: [newDrawable.startX, newDrawable.startY, x, y] });
      }
      if (drawableType === "FreePathDrawable") {
        setNewDrawable({ ...newDrawable, points: [...newDrawable.points, x, y] });
      }

      setNewDrawable(null);
      setDrawables([...drawables, newDrawable]);
      onChange(canvasRef.current.toDataURL());
    }
  };

  const handleMouseMove = (e) => {
    if (newDrawable) {
      const { x, y } = e.target.getStage().getPointerPosition();
      if (drawableType === "Rectangle") {
        setNewDrawable({ ...newDrawable, width: x - newDrawable.startX, height: y - newDrawable.startY });
      }
      if (drawableType === "ArrowDrawable") {
        setNewDrawable({ ...newDrawable, points: [newDrawable.startX, newDrawable.startY, x, y] });
      }
      if (drawableType === "FreePathDrawable") {
        setNewDrawable({ ...newDrawable, points: [...newDrawable.points, x, y] });
      }
    }
  };

  const { innerWidth: width, innerHeight: height } = window;

  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        margin: 0,
        padding: 0,
        width: "100%",
        height: "100vh",
        zIndex: Number.MAX_SAFE_INTEGER,
        cursor: drawableType === "FreePathDrawable" ? "cell" : "crosshair",
        border: "5px solid #013666",
        boxShadow: "inset 0 0 10px #f8a100",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          rowGap: 15,
          position: "absolute",
          top: 10,
          left: 10,
          zIndex: 10,
        }}
      >
        {["Rectangle", "ArrowDrawable", "FreePathDrawable"].map((drawableTypeName) => (
          <Button
            key={drawableTypeName}
            onClick={() => setDrawableType(drawableTypeName)}
            isRounded
            roundSize={50}
            backgroundColor={drawableType === drawableTypeName ? WHITE : "#013666"}
          >
            <Icon
              name={
                {
                  Rectangle: "rectangle-outline",
                  ArrowDrawable: "arrow-top-left",
                  FreePathDrawable: "pencil",
                }[drawableTypeName]
              }
              color={drawableType === drawableTypeName ? "#013666" : WHITE}
            />
          </Button>
        ))}
      </div>
      <Stage
        onMouseDown={handleMouseDown}
        onMouseUp={handleMouseUp}
        onMouseMove={handleMouseMove}
        width={width - 10}
        height={height - 10}
        ref={canvasRef}
      >
        {isImageSet && (
          <Layer>
            <Image image={imageRef.current} x={0} y={0} />
          </Layer>
        )}
        <Layer>
          {[...drawables, newDrawable]
            .filter((drawable) => !!drawable)
            .map((drawable) => {
              if (drawable.drawableType === "ArrowDrawable") {
                return (
                  <Arrow key={drawable.id} points={drawable.points} fill="black" stroke="#013666" strokeWidth={10} />
                );
              }
              if (drawable.drawableType === "FreePathDrawable") {
                return (
                  <Line key={drawable.id} points={drawable.points} fill="black" stroke="#013666" strokeWidth={5} />
                );
              }
              if (drawable.drawableType === "Rectangle") {
                return (
                  <Rect
                    key={drawable.id}
                    x={drawable.startX}
                    y={drawable.startY}
                    width={drawable.width}
                    height={drawable.height}
                    fill="transparent"
                    stroke="#013666"
                    strokeWidth={3}
                  />
                );
              }
              return null;
            })}
        </Layer>
      </Stage>
    </div>
  );
};

UserReportPrintEditor.propTypes = {
  image: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default UserReportPrintEditor;
