import React, { useCallback } from "react";
import sample from "lodash/sample";
import PrivateScreen from "core/PrivateScreen";
import Row from "core/Row";
import Col from "core/Col";
import RankingCard from "core/RankingCard";
import RankingParticipantContainer from "core/RankingParticipantContainer";
import ModuleListContainer from "screens/module-list/ModuleListContainer";
import { MD_SIZE } from "utils";
import { useEntity } from "student-front-commons/src/hooks";
import { COURSE_SCHEMA } from "student-front-commons/src/schemas";

function ModuleListScreen() {
  const course = useEntity(COURSE_SCHEMA, sessionStorage.getItem("course"));

  const getCourseBackground = useCallback(() => {
    try {
      if (course && course.name) {
        const bg = sample(["home-1", "home-2"]);
        return require(`assets/backgrounds/${course.name.toLowerCase()}/${bg}.png`).default;
      }
    } catch (error) {
      return null;
    }
  }, [course]);

  return (
    <PrivateScreen background={getCourseBackground()}>
      <RankingParticipantContainer>
        {({ isRankingParticipant }) => (
          <Row justifyContent="space-between">
            <Col
              mediaQueries={{
                sm: `(max-width: ${MD_SIZE}px)`,
                md: `(min-width: ${MD_SIZE}px)`,
              }}
              smWidth="100%"
              mdWidth={!isRankingParticipant ? "100%" : "67%"}
            >
              <ModuleListContainer />
            </Col>
            {isRankingParticipant && (
              <Col
                mediaQueries={{
                  sm: `(max-width: ${MD_SIZE}px)`,
                  md: `(min-width: ${MD_SIZE}px)`,
                }}
                smDisplay="none"
                mdDisplay="flex"
                smWidth="0%"
                mdWidth="30%"
              >
                <RankingCard />
              </Col>
            )}
          </Row>
        )}
      </RankingParticipantContainer>
    </PrivateScreen>
  );
}

export default ModuleListScreen;
