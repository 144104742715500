import React, { useEffect } from "react";
import { Route, Switch } from "react-router-dom";
import Col from "core/Col";
import Spinner from "core/Spinner";
import UnitExecutionScreenContainer from "screens/unit-execution/UnitExecutionScreenContainer";
import UnitResultScreenContainer from "screens/unit-result/UnitResultScreenContainer";
import MasteryTestExecutionScreenContainer from "screens/mastery-test-execution/MasteryTestExecutionScreenContainer";
import MasteryTestResultScreenContainer from "screens/mastery-test-result/MasteryTestResultScreenContainer";
import NotFoundScreen from "screens/NotFoundScreen";
import ProfileScreen from "screens/profile/ProfileScreen";
import GradesScreen from "screens/grades/GradesScreen";
import RankingScreen from "screens/ranking/RankingScreen";
import ConfirmModalContainer from "core/ConfirmModalContainer";
import MicrophoneErrorModalContainer from "core/MicrophoneErrorModalContainer";
import UserPlayModalContainer from "core/UserPlayModalContainer";
import ErrorModalContainer from "core/ErrorModalContainer";
import { LOAD_MODULES_FLOW, LOAD_STUDENT_FLOW, MONITOR_NETWORK_CONNECTION_FLOW } from "consts";
import { useEntity, useEntityIds, useFlow } from "student-front-commons/src/hooks";
import CourseScreen from "../screens/course/CourseScreen";
import ModuleListScreen from "../screens/module-list/ModuleListScreen";
import { COMPANY_SCHEMA, MODULE_SCHEMA, PROFILE_SCHEMA } from "student-front-commons/src/schemas";
import ModuleDetailScreen from "../screens/module-detail/ModuleDetailScreen";
import SettingsContext from "./SettingsContext";

function CourseRoutes() {
  const moduleIds = useEntityIds(MODULE_SCHEMA);
  const [isLoading, startFlow] = useFlow(LOAD_MODULES_FLOW);

  useEffect(() => {
    if (!moduleIds || !moduleIds.length) {
      startFlow({ course: sessionStorage.getItem("course") });
    }
  }, [moduleIds, startFlow]);

  if (!moduleIds || isLoading) {
    return (
      <Col flexGrow={1} justifyContent="center" alignItems="center">
        <Spinner />
      </Col>
    );
  }

  return (
    <Switch>
      <Route path="/" exact={true} component={ModuleListScreen} />
      <Route path="/modules/:moduleId/detail" exact={true} component={ModuleDetailScreen} />
      <Route path="/modules/:moduleId/units/:unitId/execution" exact={true} component={UnitExecutionScreenContainer} />
      <Route
        path="/modules/:moduleId/units/:unitId/execution/:executionId/result"
        exact={true}
        component={UnitResultScreenContainer}
      />
      <Route
        path="/modules/:moduleId/mastery-test/:masteryTestId/execution"
        exact={true}
        component={MasteryTestExecutionScreenContainer}
      />
      <Route
        path="/modules/:moduleId/mastery-test/:masteryTestId/execution/:executionId/result"
        exact={true}
        component={MasteryTestResultScreenContainer}
      />
      <Route path="*" component={NotFoundScreen} />
    </Switch>
  );
}

function UserRoutesContainer() {
  useFlow(MONITOR_NETWORK_CONNECTION_FLOW, { autoStart: true });
  const [isLoadingStudent] = useFlow(LOAD_STUDENT_FLOW, { autoStart: true });

  const profile = useEntity(PROFILE_SCHEMA, sessionStorage.getItem("id"));
  const company = useEntity(COMPANY_SCHEMA, profile?.company);

  if (isLoadingStudent) {
    return (
      <Col justifyContent="center" alignItems="center" height="100vh">
        <Spinner />
      </Col>
    );
  }
  return (
    <SettingsContext.Provider value={{ forceUpperCase: ["62f3f357bee817431048e4fe"].includes(company?.distributor) }}>
      <Switch>
        <Route path={sessionStorage.getItem("course") ? "/courses" : "*"} exact={true} component={CourseScreen} />
        <Route path="/profile" exact={true} component={ProfileScreen} />
        <Route path="/grades" exact={true} component={GradesScreen} />
        <Route path="/ranking" exact={true} component={RankingScreen} />
        <Route path="/" component={CourseRoutes} />
        <Route path="*" component={NotFoundScreen} />
      </Switch>
      <ConfirmModalContainer />
      <MicrophoneErrorModalContainer />
      <UserPlayModalContainer />
      <ErrorModalContainer />
    </SettingsContext.Provider>
  );
}

export default UserRoutesContainer;
