import React, { useState } from "react";
import moment from "moment";
import get from "lodash/get";
import { getStatsByPeriodAndGroup } from "student-front-commons/src/services/studentStatsService";
import WeekTime from "core/WeekTime";
import { useEntity, useService } from "student-front-commons/src/hooks";
import { PROFILE_SCHEMA, SCHOOL_CLASS_SCHEMA } from "student-front-commons/src/schemas";

function WeekTimeContainer() {
  const profile = useEntity(PROFILE_SCHEMA, sessionStorage.getItem("id"));
  const schoolClass = useEntity(SCHOOL_CLASS_SCHEMA, profile.schoolClass);

  const [time, setTime] = useState(0);

  const [isLoading] = useService(getStatsByPeriodAndGroup, {
    autoStart: true,
    params: {
      id: sessionStorage.getItem("id"),
      from: moment().startOf("isoWeek").format("YYYY-MM-DD HH:mm:ssZZ"),
      to: moment().endOf("isoWeek").format("YYYY-MM-DD HH:mm:ssZZ"),
      group: "week",
    },
    onData: (data) => setTime(get(data, "[0].hours", 0)),
    onError: () => setTime(0),
  });

  return (
    <WeekTime
      isLoading={isLoading}
      hours={time}
      requiredHours={profile.apiVersion === "V2" ? profile.weeklyHoursRequired : schoolClass.weeklyHoursRequired}
    />
  );
}

export default WeekTimeContainer;
