import React from "react";
import PropTypes from "prop-types";
import ControlBarButton from "core/ControlBarButton";
import { addSentryUserAction } from "../utils";

const sentryUserAction = { mainComponent: "ListenButton" };

const ListenButton = ({ onClick, isDisabled }) => (
  <ControlBarButton
    name="listen"
    onClick={() => {
      onClick();
      addSentryUserAction({
        ...sentryUserAction,
        clickedComponent: "ControlBarButton",
        action: `Listen Unit Item`,
      });
    }}
    isDisabled={isDisabled}
    isActive={false}
    isLoading={false}
    zIndex={2}
  />
);

ListenButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool.isRequired,
};

export default ListenButton;
