import React from "react";
import PropTypes from "prop-types";
import UnitTypeThemeProvider from "core/UnitTypeThemeProvider";
import Col from "core/Col";
import Row from "core/Row";
import UnitStatus from "core/UnitStatus";
import Separator from "core/Separator";
import ClickableSound from "core/ClickableSound";
import UnitIcon from "core/UnitIcon";
import UnitName from "core/UnitName";
import { CONFIRM_START_UNIT_EXECUTION_FLOW } from "consts";
import { addSentryUserAction } from "../../utils";
import { useEntity, useFlow } from "student-front-commons/src/hooks";
import { UNIT_SCHEMA } from "student-front-commons/src/schemas";

const sentryUserAction = { mainComponent: "UnitListItem" };

function UnitListItem(props) {
  const unit = useEntity(UNIT_SCHEMA, props.unit);
  const [, startConfirmFlow] = useFlow(CONFIRM_START_UNIT_EXECUTION_FLOW);

  return (
    <ClickableSound
      className={unit.disabled ? "unit-list-item-disabled" : "unit-list-item"}
      isDisabled={unit.disabled}
      onClick={() => {
        startConfirmFlow({
          unit: unit.id,
          module: unit.module,
          status: unit.status,
        });
        addSentryUserAction({
          ...sentryUserAction,
          clickedComponent: "ClickableSound",
          action: `Navigate to /modules/${unit.module}/units/${unit.id}/execution`,
        });
      }}
    >
      <UnitTypeThemeProvider unitType={unit.type}>
        <UnitIcon
          size="md"
          showGameIcon={true}
          isDisabled={unit.disabled}
          isCompleted={unit.secondReviewStatus === "COMPLETED"}
        />
        <Col position="absolute" bottom={0} alignItems="center">
          <Row>
            <UnitStatus status={unit.yourChallengeStatus} />
            <Separator size="xs" />
            <UnitStatus status={unit.firstReviewStatus} />
            <Separator size="xs" />
            <UnitStatus status={unit.secondReviewStatus} />
          </Row>
          <Separator size="sm" />
          <UnitName name={unit.name} isDisabled={unit.disabled} intent="secondary" />
        </Col>
      </UnitTypeThemeProvider>
    </ClickableSound>
  );
}

UnitListItem.propTypes = {
  unit: PropTypes.string.isRequired,
};

export default UnitListItem;
